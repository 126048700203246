import ItemIcon from '@material-ui/icons/Collections';
import SupplierList from './SupplierList';
import SupplierCreate from './SupplierCreate';
import SupplierEdit from './SupplierEdit';


export default {
    list: SupplierList,
    create: SupplierCreate,
    edit: SupplierEdit,
    icon: ItemIcon,
    name: "suppliers/supplier"
};
