import React, { Fragment, Component } from 'react';
import {
    List,
    Datagrid,
    TextField,
    NumberField,
    Filter,
    SearchInput,
    CardActions,
    CreateButton,
    EditButton, RefreshButton, SelectInput
} from 'react-admin';

import { BooleanInput } from 'react-admin';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { push } from 'react-router-redux';
import { withStyles } from '@material-ui/core';
import { Route, Switch } from 'react-router';
import { Drawer } from '@material-ui/core';
import ManufacturerCreate from './ManufacturerCreate';
import ManufacturerEdit from './ManufacturerEdit';
import LinkToRelatedItems from './LinkToRelatedItems'
import GetReportButton from './GetReportButton'
import ManufacturerCreateEditButton from '../../common/ManufacturerCreateEditButton';
import StockResetButton from './StockResetButton';
import StockSyncSwitch  from './StockSyncSwitch';
export const ManufacturerFilter = props => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <SelectInput source="stockSync" alwaysOn choices={[
        { id: 'true',       name: 'ON' },
        { id: 'false',      name: 'OFF' },]
        } />
    </Filter>
    
);


const styles = {
    drawerContent: {
        width: 370
    }
};

const onManufacturerAdd = () => {
    window.location.reload(true)
}

const EditManufacturerButton = ({ record = {} }) =>  <ManufacturerCreateEditButton manufacturerId={record.id} onUpdate={onManufacturerAdd}/>

const ListActions = ({
    bulkActions,
    basePath,
    displayedFilters,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter}) => (
    <CardActions>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        }) }
        
        <RefreshButton />
        <ManufacturerCreateEditButton onCreate={onManufacturerAdd}/>
    </CardActions>
);

class ManufacturerList extends React.Component {
    render() {
        const { push, classes, ...props } = this.props;
        return (
            <Fragment>
                <List
                    exporter={false}  {...props}  
                    perPage={25}
                    filters={<ManufacturerFilter />}
                    sort={{ field: 'totalAmount', order: 'DESC' }}
                    actions={<ListActions />}
                >
                    <Datagrid>
                        <TextField source="id" />
                        {/* <TextField style={{ width: 300 }}  source="name" /> */}
                        {/* <TextField source="englishName" /> */}
                        <EditManufacturerButton />
                        <LinkToRelatedItems label="Name"/>
                        <TextField source="defaultSupplierName" label="Default Supplier"/>
                        {/* <GetReportButton type="screenshot" /> */}
                        <NumberField source="totalItems" />
                        <NumberField source="inStockQty" />
                        <NumberField source="totalAmount" /> 
                        <StockSyncSwitch label="Stock Sync" />
                        <StockResetButton />
                        <GetReportButton type="pdf" />      
                    </Datagrid>
                </List>
            </Fragment>
        );
    }

}

export default compose(
    connect(
        undefined,
        { push }
    ),
    withStyles(styles)
)(ManufacturerList);

