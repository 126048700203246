import React, { useState, useEffect } from 'react';
import { Fragment } from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import MultiAutoSuggest from '../../common/MultiAutoSuggest'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { showNotification, withDataProvider, GET_ONE, CREATE, UPDATE } from 'react-admin';
import { push } from 'react-router-redux';
import { ShippingCarrierTypes } from '../../common/ShippingDetailTypes';
import ManifestArea from './ManifestArea';


const settlementTypes = [
  {
    value: 'CASH',
    label: 'Cash',
  },
  {
    value: 'CHECK',
    label: 'Check',
  },
  {
    value: 'CREDIT',
    label: 'Credit',
  },
  {
    value: 'BANK_TRANSFER',
    label: 'Bank Transfer',
  },
  {
    value: 'POST_DATED_CHECK',
    label: 'Post Dated Check',
  },
  {
    value: 'CREDIT_CARD',
    label: 'Credit Card',
  },
  {
    value: 'PENDING',
    label: 'Pending',
  },
];


const ManifestCreate = (props) => {

    const { id } = props
    const [shippingCarrier, setShippingCarrier] = useState('REGISTERED_POST')
    const [manifestState, setManifestState] = useState({})
    const [submitClicked, setSubmitClicked] = useState(false)
    const [manifestItems, setManifestItems] = useState([])
    const [manifestStatus, setManifestStatus] = useState('OPEN')
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const  validate = () => {
      return (shippingCarrier != null && manifestState.totalItems > 0) 
    }

    useEffect(() => {
      const fetchData = async () => {
        setIsError(false);
        setIsLoading(true);

        var params = { id: id}

        const {
          dispatch,
          dataProvider
          } = props;

        dataProvider(GET_ONE, 'order/shipping-manifests', params)
          .then((json) => {
              setData(json.data);
              setIsLoading(false);
          })
          .catch((e) => {
              setIsError(true);
              dispatch(showNotification('Error: Something went wrong', 'warning'))
              setIsLoading(false);
          });
      };

      if (id) {
        fetchData()
      }

    }, []);

    const setData = (manifest) => {
      const _manifestItems = []
      manifest.manifestItems.forEach((item) => {
        _manifestItems.push(item)
      });
      setManifestItems(_manifestItems)
      setShippingCarrier(manifest.shippingCarrier)
      setManifestStatus(manifest.manifestStatus)
    }

    const handleManifestCreate = (action) => {
      if (!submitClicked) {
        setSubmitClicked(true)
        console.log(manifestState)
        const {
            dispatch,
            dataProvider
        } = props;

        var payload = generatePayload(action)
        console.log(payload)

        dataProvider(CREATE, 'order/shipping-manifests', payload)
        .then((json) => {
            dispatch(showNotification('Manifest created with id:' + json.data.id)) 
            setSubmitClicked(false)
            dispatch(push('/order/shipping-manifests'));
        })
        .catch((e) => {
            setSubmitClicked(false)
            dispatch(showNotification('Error: Something went wrong', 'warning'))
        });
        };

  };


  const handoverItems = () => {
    const {
      dispatch,
      dataProvider
    } = props;
    dataProvider(CREATE, 'order/shipping-manifests/handover/' + id, { data : {} } )
      .then((json) => {
        dispatch(showNotification('Handover Manifest#' + json.data.id)) 
        dispatch(push('/order/shipping-manifests'));
      })
      .catch((e) => {
        dispatch(showNotification('Error: Something went wrong', 'warning'))
      });

  }

  const markAsShipped = () => {
    const {
      dispatch,
      dataProvider
    } = props;
    dataProvider(CREATE, 'order/shipping-manifests/shipped/' + id, { data : {} } )
      .then((json) => {
        dispatch(showNotification('Marked as Shipped for Manifest#' + json.data.id)) 
        dispatch(push('/order/shipping-manifests'));
      })
      .catch((e) => {
        dispatch(showNotification('Error: Something went wrong', 'warning'))
      });

  }

    function generatePayload(action) {
      var payload = {
        id: id,
        shippingCarrier: shippingCarrier,
        manifestItems: manifestState.manifestItems,
        manifestStatus: (action === 'save') ? 'OPEN' : "CREATED"
      }

      return { "data" : payload };
    }

    const onManifestStateChange = manifestState => {
      setManifestState(manifestState)
    }

    return (
            <div>
                <h2>Create Shipping Manifest </h2>
                <Grid container spacing={16}>
                    <Grid item xs={9}>
                      <Paper style={{ paddingTop: 10, paddingBottom: 10, marginBottom: 10 }}>
                        <div style={{ marginTop: 5, marginLeft: 10, display: "flex"}}> 
                            <div style={{ marginLeft: 10,  width: 220 }}>
                                <TextField
                                fullWidth
                                id="select-currency-native"
                                select
                                label="Shipping Carrier"
                                onChange={(e) => setShippingCarrier(e.target.value)}
                                value={shippingCarrier}
                                SelectProps={{
                                    native: true,
                                }}
                                margin="normal"
                                >
                                {ShippingCarrierTypes.map(option => (
                                    <option key={option.id} value={option.id}>
                                    {option.name}
                                    </option>
                                ))}
                                </TextField>
                            </div>

                        </div>

                      </Paper>
                      <Paper>
                            <ManifestArea disabled={ manifestStatus !== 'OPEN' } handedOver={ manifestStatus === 'HANDED_OVER' }  shipped={ manifestStatus === 'SHIPPED' } manifestItems={manifestItems} onManifestStateChange={onManifestStateChange} />
                      </Paper>
                    </Grid>

                    <Grid item xs={3}>
                        <Paper>
                          <div style={{marginLeft: 10}}>
                            <div style={{paddingTop: 5}}><b>Manifest Bill</b></div>
                            <div style={{marginTop: 10}}>Shipping Carrier: {shippingCarrier ? shippingCarrier: '' }</div>
                            <div style={{marginTop: 10}}>Total Items: {manifestState.totalItems}</div>
                             {/* { manifestStatus < 2 ? <Button disabled={submitClicked || !validate() } style={{ marginTop: 10, marginBottom: 10, marginRight:10 }} onClick={() => handleSubmit('save')} variant="contained" color="primary">SAVE</Button> : null } */}
                             { manifestStatus === 'OPEN' ? <Fragment> <Button disabled={submitClicked || !validate() } style={{ marginTop: 10, marginBottom: 10 }} onClick={() => handleManifestCreate('save')} variant="contained" color="primary">SAVE MANIFEST</Button>
                            <Button disabled={submitClicked || !validate() } style={{  marginTop: 10, marginLeft: 10, marginBottom: 10, color: 'whitesmoke', backgroundColor:'maroon' }} onClick={() => handleManifestCreate('create')} variant="contained" color="primary">CREATE MANIFEST</Button> </Fragment> : null }
                            { manifestStatus === 'CREATED' ? <Button disabled={submitClicked || !validate() } style={{ marginTop: 10, marginBottom: 10 }} onClick={() => window.confirm("Please verify the number of packages ready to ship. Are you sure to hand over?") && handoverItems()} variant="contained" color="primary">HAND OVER</Button> : null }
                            { manifestStatus === 'HANDED_OVER' ? <Button disabled={submitClicked || !validate() } style={{ marginTop: 10, marginBottom: 10 }}  onClick={() => window.confirm("This will send SMS to the customers, if the tracking number is present. Are you sure to mark as shipped?") && markAsShipped()} variant="contained" color="primary">MARK AS SHIPPED</Button> : null }
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
    )
}

export default withDataProvider(ManifestCreate)