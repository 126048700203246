import React from 'react';
import compose from 'recompose/compose';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { translate } from 'react-admin';
import { stringify } from 'query-string';

import items from '../items';

const styles = {
    icon: { paddingRight: '0.5em' },
    link: {
        display: 'inline-flex',
        alignItems: 'center',
    },
};


const LinkToRelatedItems = ({ classes, record, translate }) => (

    <Link
        to={{
            pathname: '/v2/catalog/item',
            search: stringify({
                page: 1,
                perPage: 25,
                sort: 'id',
                order: 'DESC',
                filter: JSON.stringify({ manufacturerId: record.id }),
            }),
        }}
    >
        {record.name}
    </Link>
);

const enhance = compose(
    withStyles(styles),
    translate
);
export default enhance(LinkToRelatedItems);
