import React from 'react';
import PropTypes from 'prop-types';

const WeightField = ({ source, record = {} }) => <span>{record[source]} g </span>;

WeightField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default WeightField;
