import React from 'react';
import {
    Edit,
    DateInput,
    required,
    FormTab,
    SelectInput,
    LongTextInput,
    TabbedForm,
    TextInput,
    NumberInput,
    BooleanInput, AutocompleteArrayInput, ReferenceArrayInput 
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';

import { SupplierTypes, SupplierOrderDeliveryType, SupplierOrderSettlementType } from '../purchaseorders/SupplierOrderTypes'

export const styles = {
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    address: { maxWidth: 544 },
    zipcode: { display: 'inline-block' },
    city: { display: 'inline-block', marginLeft: 32 },
    comment: {
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
};

const SupplierEdit = ({ classes, ...props }) => (
    <Edit {...props}>
        <TabbedForm redirect="list">
            <FormTab label="resources.supplier.tabs.identity">
                <div style={{ display: 'inline-flex', width: '100%' }}>
                    <SelectInput source="supplierType"  defaultValue="PUBLISHER" choices={SupplierTypes} />
                    <SelectInput source="settlementType" style={{ marginLeft: 10 }} defaultValue="IMMEDIATE" choices={SupplierOrderSettlementType} />
                    <SelectInput source="deliveryType" style={{ marginLeft: 10 }} defaultValue="SELF_PICKUP" choices={SupplierOrderDeliveryType} />
                    <SelectInput source="zone" 
                        style={{ marginLeft: 10 }} defaultValue="CHENNAI"
                        choices={[
                            { id: 'CHENNAI', name: 'Chennai' },
                            { id: 'NON_CHENNAI', name: 'Outside Chennai' }, 
                        ]} />
                </div>

                <div style={{ display: 'inline-flex', width: '100%' }}>
                    <TextInput autoFocus source="name" style={{ width: 400 }} validate={required()} />
                    <NumberInput source="discountPercent" label="Disc %" style={{ width: 100,  marginLeft: 32 }} />
                    <BooleanInput source="canReturnItems" style={{ width: 200,  marginLeft: 32, marginTop: 20 }} />
                </div>

                <div style={{ width: '100%' }}>
                    <ReferenceArrayInput label="Select Publishers.." reference="v2/catalog/manufacturer" source="manufacturers">
                        <AutocompleteArrayInput  />
                    </ReferenceArrayInput>
                </div>


                <div style={{ display: 'inline-flex', width: '100%' }}>
                    <TextInput source="contactName" style={{ width: 200 }}/>
                    <TextInput source="mobileNumber" style={{ width: 150,  marginLeft: 32 }} />
                    <TextInput source="whatsAppNumber" style={{ width: 150,  marginLeft: 32 }} />
                </div>

                <div style={{ display: 'inline-flex', width: '100%' }}>
                    <TextInput source="email" style={{ width: 400 }} />
                    <TextInput source="landline" style={{ width: 200,  marginLeft: 32 }} />
                </div>  
                
            </FormTab>
            <FormTab label="Account Details">
                    <TextInput autoFocus source="checkName" style={{ width: 400 }}/>
                    <TextInput source="accountName" style={{ width: 400 }}/>
                    <TextInput source="accountNumber" style={{ width: 400  }} />
                    <TextInput source="bankIfscCode" label="IFSC Code" style={{ width: 150 }} />
            </FormTab>
            <FormTab label="Address">
                    <TextInput autoFocus source="address1" style={{ width: 400 }}/>
                    <TextInput source="address2" style={{ width: 400 }}/>
                    <TextInput source="address3" style={{ width: 400  }} />
                    <div style={{ display: 'inline-flex', width: '100%' }}>
                        <TextInput source="city" style={{ width: 250 }} />
                        <TextInput source="pinCode" style={{ width: 150, marginLeft: 32   }} />
                    </div>
                    <div style={{ display: 'inline-flex', width: '100%' }}>
                        <TextInput source="state" defaultValue="Tamil Nadu"  style={{ width: 150  }} />
                        <TextInput source="country" defaultValue="India" style={{ width: 150, marginLeft: 32   }} />
                    </div>
                    
                    <TextInput source="googleMapLocation" style={{ width: 400  }} />
            </FormTab>
        </TabbedForm>
    </Edit>
);

export default withStyles(styles)(SupplierEdit);
