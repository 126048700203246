import React, { useState, useEffect } from 'react';
import { Fragment } from 'react'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { showNotification, withDataProvider, GET_ONE, CREATE, DELETE } from 'react-admin';
import CustomerQuickCreateButton from '../../common/CustomerQuickCreateButton'
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import ItemAutoSuggest from '../../receivings/stocktaking/ItemAutoSuggest'
import DeleteButton from '../../common/DeleteButton'
import FulfillOrderItem from '../../common/FulfillOrderItem'
import DeleteOrderFulfillment from '../../common/DeleteOrderFulfillment'
import ShippingLabelButton from '../../common/ShippingLabelButton'
import CartArea from '../../common/CartArea'
import { push } from 'react-router-redux';
import CustomerAddress from '../../common/CustomerAddress'
import CustomerDetails from '../../common/CustomerDetails'
import AddPaymentRefundButton from '../../common/AddPaymentRefundButton'
import ConfirmAddItem from '../../common/ConfirmAddItem'
import RemoveOrderItem from '../../common/RemoveOrderItem'
import EditOrderDetailButton from '../../common/EditOrderDetailButton'
import Chip from '@material-ui/core/Chip';
import AddEditTrackingButton from '../../common/AddEditTrackingButton'
import PrintFulfillmentButton from '../../common/PrintFulfillmentButton'
import { Link } from 'react-router-dom';
import OrderPaymentTypeField from '../../common/OrderPaymentTypeField'

const ChannelTypes = [
  { value: 'SHOP', label: 'Shop' },
  { value: 'SOCIAL_MEDIA', label: 'Social Media' },
  { value: 'PHONE', label: 'Phone' },
  { value: 'WHATSAPP', label: 'WhatsApp' },
  { value: 'REQUEST_PORTAL', label: 'Request Portal' },
  { value: 'REFERRAL', label: 'Referral' },
  { value: 'PANUVAL_COM', label: 'Panuval.Com' },
  { value: 'BOOK_FAIR', label: 'Book Fair' },
  { value: 'FLIPKART', label: 'Flipkart' },
  { value: 'AMAZON', label: 'Amazon' },
  { value: 'INTERNATIONAL', label: 'Overseas' },
  { value: 'OTHERS', label: 'Others' },
];

const PaymentTypes = [
    { value: 'CASH', label: 'Cash' },
    { value: 'CARD', label: 'Card Swipe' },
    { value: 'BANK_TRANSFER', label: 'Bank Transfer' },
    { value: 'UPI_TRANSFER', label: 'GPay (Panuval)' },
    { value: 'PERSONAL', label: 'GPay (Saravanan)' },
    { value: 'CHECK', label: 'Check' },
    { value: 'PAYUBIZ', label: 'PayUBiz' },
    { value: 'NOT_KNOWN', label: 'Not Known' },
  ];


  const DeliveryTypes = [
    { value: 'STORE_PICKUP', label: 'Store Pickup' },
    { value: 'DOOR_DELIVERY', label: 'In Person Delivery' },
    { value: 'SHIPPING', label: 'Shipping' },
    { value: 'NOT_KNOWN', label: 'Not Known' },
  ];




const initialCartState = {
  cartItems: [],
  selectedItems: [],
  rowCounter: 0,
  totalItems: 0,
  totalQty: 0,
  subTotal: 0.0,
  shippingAmount: 0.0,
  invoiceDiscount: 0.0,
  otherCharges: 0.0,
  totalDiscount: 0.0,
  totalAmount: 0.0,
}

const PrintOrder = (props) =>  <Button variant="contained" color="primary" component={ Link } to={"/order/" + props.orderId +"/show"} color="primary">Print Order</Button>

const OrderProcess = (props) => {
    const { id } = props
    const [orderId] = useState(id)
    const [order, setOrder] = useState(null)
    const [orderDate, setOrderDate] = useState()
    const [orderStatus, setOrderStatus] = useState('OPEN')
    const [customer, setCustomer] = useState({
                                        name: '',
                                        email: '',
                                        mobileNumber: '',
                                        discountPercent: ''
                                    });

    const [address, setAddress] = useState(null);
                              
    const [, setCartState] = useState(initialCartState)
    const [] = useState(initialCartState)

    const [fulfillmentStatus, setFulfillmentStatus] = useState('UNFULFILLED')
    const [paymentStatus, setPaymentStatus] = useState('UNPAID')

    const [] = useState(false)
    const [fulfillClicked, setFulfillClicked] = useState(false)
    
    const [channelType, setChannelType] = useState('SHOP')
    const [paymentType, setPaymentType] = useState('NOT_KNOWN')
    const [deliveryType, setDeliveryType] = useState('NOT_KNOWN')
    const [comment, setComment] = useState('')
    const [, setReferenceId] = useState('')
    const [invoiceDiscount, setInvoiceDiscount] = useState(0)
    const [otherCharges, setOtherCharges] = useState(0)
    const [shippingAmount, setShippingAmount] = useState(0)
    const [discountPercent, setDiscountPercent] = useState(0)    
    const [locationId, setLocationId] = useState(1)

    const [, setCartItems] = useState([])

    const [fulfillments, setFulfillments] = useState([])

    const [unfulfilledItems, setUnfulfilledItems] = useState([])
    const [removedItems, setRemovedItems] = useState([])

    const [selectedItems, setSelectedItems] = useState([])

    const [orderTotals, setOrderTotals] = useState([])
    const [orderPayments, setOrderPayments] = useState([])
    const [totalPayment, setTotalPayment] = useState(0.0)
    const [pendingPayment, setPendingPayment] = useState(0.0)
    const [totalAmount, setTotalAmount] = useState(0.0)
    
    const [totalQty, setTotalQty] = useState(0)
    const [totalItems, setTotalItems] = useState(0)

    const [, setIsLoading] = useState(false);
    const [, setIsError] = useState(false);

    const [addedItem, setAddedItem] = useState(null)
    const [openDialog, setOpenDialog] = useState(false)
    const [maxLine, setMaxLine] = useState(0)



    useEffect(() => {
      const fetchData = async () => {
        setIsError(false);
        setIsLoading(true);

        var params = { id: id}

        const {
          dispatch,
          dataProvider
          } = props;

        dataProvider(GET_ONE, 'order', params)
          .then((json) => {
              setData(json.data);
              setIsLoading(false);
          })
          .catch(() => {
              setIsError(true);
              dispatch(showNotification('Error: Something went wrong', 'warning'))
              setIsLoading(false);
          });
      };

      if (id) {
        fetchData()
      }
      
    }, []);

    const setData = (order) => {
      setOrder(order)
      setOrderDate(new Date(order.orderDate).toISOString().substring(0, 10))
      setOrderStatus(order.status)
      setFulfillmentStatus(order.fulfillmentStatus)
      setPaymentStatus(order.paymentStatus)
      const _customer = { 
                          id: order.customerId,
                          name: order.name, 
                          email: order.email, 
                          mobileNumber:  order.mobileNumber, 
                          whatsAppNummber:  order.whatsAppNummber 
                        }
      setCustomer(_customer)
      setLocationId(order.locationId)
      setDiscountPercent(order.discountPercent)
      const _address = { 
                          id: order.shippingAddressId,
                          name: order.shippingName,
                          address1: order.shippingAddress1,
                          address2: order.shippingAddress2,
                          address3: order.shippingAddress3,
                          city: order.shippingCity,
                          pincode: order.shippingPostCode,
                          state: order.shippingState,
                          country: order.shippingCountry,
                          mobileNumber : order.shippingMobileNumber
                      }
      setAddress(_address)
      setComment(order.comment)
      setChannelType(order.channelType)
      setPaymentType(order.paymentType)
      setDeliveryType(order.deliveryType)    
      setReferenceId(order.referenceId)

      const _cartItems = []

      const unfulfilledItems = []
      const removedItems = []

      let totalItems = 0
      let totalQty = 0
      let line = 0
      order.cartItems.forEach((cartItem) => {
        const  _item = { }
        _item.itemId = cartItem.itemId
        _item.id = cartItem.id
        _item.name = cartItem.name
        _item.manufacturerName = cartItem.manufacturerName
        _item.price = cartItem.price
        _item.discountPercent = cartItem.discountPercent
        _item.discountAmount = cartItem.discountAmount
        _item.total = cartItem.total
        _item.qty = cartItem.qty
        _item.stockQty = cartItem.stockQty
        _item.avlQty = cartItem.avlQty
        _item.shelfCodes = cartItem.shelfCodes
        totalItems = totalItems + 1
        totalQty = totalQty + cartItem.qty
         const __item = {..._item}
        
         if (cartItem.unfulfilledQty > 0) {
          __item.qty = cartItem.unfulfilledQty
          unfulfilledItems.push(__item)
         } 

         if (cartItem.removedQty > 0) {
          __item.qty = cartItem.removedQty
          removedItems.push(__item)
         }
        
         if (cartItem.line >  line ) {
           line = cartItem.line
         }
        _cartItems.push(_item)
      });

      setMaxLine(line)
      setTotalAmount(order.total)
      setTotalQty(totalQty)
      setTotalItems(totalItems)

      setCartItems(_cartItems)
      setUnfulfilledItems(unfulfilledItems)
      setRemovedItems(removedItems)

      order.cartTotals.forEach((cartTotal) => {
        if (cartTotal.totalType === "INVOICE_DISCOUNT") {
          setInvoiceDiscount(cartTotal.amount)
        } else if (cartTotal.totalType === "SHIPPING") {
          setShippingAmount(cartTotal.amount)
        } else if (cartTotal.totalType === "OTHER_CHARGES") {
          setOtherCharges(cartTotal.amount)
        }
      })
      
      setOrderTotals(order.cartTotals)
      setFulfillments(order.fulfillments)
      setOrderPayments(order.orderPayments)
      setTotalPayment(order.totalPayment)
      setPendingPayment(order.pendingPayment)
    }

    const onKeyDown = (e) => {
        if (e.keyCode === 8 || e.keyCode === 46) {
          e.target.value = ""
          e.preventDefault();
        }
    }
 
    const onAddressChange = address => new Promise(function(resolve, reject) {
      const { dispatch, dataProvider } = props;
      dataProvider(CREATE, 'order/' + orderId + '/shipping-address',  { data: address } )
        .then((json) => {
            setAddress(json.data)
            dispatch(showNotification('Shipping Address updated'))
            resolve(json.data)
        })
        .catch((e) => {
            reject(e.message)
        });
    });

    const onCustomerChange = customer => new Promise(function(resolve, reject) {
      const { dispatch, dataProvider } = props;
      dataProvider(CREATE, 'order/' + orderId + '/customer',  { data: customer } )
        .then((json) => {
            setCustomer(json.data)
            dispatch(showNotification('Customer Details updated'))
            resolve(json.data)
        })
        .catch((e) => {
            reject(e.message)
        });
    });


    const handleAddPayment = orderPayment => {
      const { dispatch, dataProvider } = props;
      dataProvider(CREATE, 'order/' + orderId + '/payment',  { data: orderPayment } )
        .then(() => {
            dispatch(showNotification('Payment Added'))
            window.location.reload(true)
        })
        .catch((e) => {
          console.log(e)
        });
    }

    const handleDeletePayment = orderPaymentId => {
      const { dataProvider } = props;
      dataProvider(DELETE, 'order/' + orderId + '/payment', { id : orderPaymentId } )
        .then(() => {
            window.location.reload(true)
        })
        .catch((e) => {
          console.log(e)
        });
    }

    const onConfirmAddItem = cartItem => {
      cartItem.line = maxLine + 1
      const { dispatch, dataProvider } = props;
      dataProvider(CREATE, 'order/' + orderId + '/add-item',  { data: cartItem } )
          .then(() => {
              dispatch(showNotification('Item Added'))
              window.location.reload(true)
          })
          .catch((e) => {
            console.log(e)
          });
      setOpenDialog(false)
      setAddedItem(null)
    }

    const handleAddItem = item => {
      setOpenDialog(true)
      setAddedItem(item)
    }

    const handleEditOrderDetails = orderDetail => {
      const { dataProvider } = props;
      dataProvider(CREATE, 'order/' + orderId + '/detail', { data : orderDetail } )
        .then(() => {
            window.location.reload(true)
        })
        .catch((e) => {
          console.log(e)
        });
    }

    const handleMarkAsComplete = (orderId) => {
      const { dataProvider } = props;
      dataProvider(CREATE, 'order/' + orderId + '/complete', { data : {} } )
        .then(() => {
            window.location.reload(true)
        })
        .catch((e) => {
          console.log(e)
        });

    }

    return (
            <div>
                
                <Grid container spacing={16}>
                
                    <Grid item xs={8}>
                    <div style={{ display: 'flex' }}>
                        {/* <div style={{display: "flex", width: "70%" ,fontSize: 24, lineHeight: "normal", fontWeight: 700, marginBottom: 10, marginLeft: 5 }}> Order Details #{orderId} </div> */}
                        <div style={{width: "100%", marginBottom: 10 }} >
                                <div style={{fontSize: 20, lineHeight: "normal", fontWeight: 700 }}>  Order Details #{orderId} </div>
                                <div style={{fontSize: 16, lineHeight: "normal", marginTop: 2  }}>
                                {/* Order Date: {order && new Date(order.orderDate).toLocaleDateString("en-IN")}  */}
                                <span style={{backgroundColor: "purple", color: "white"}}>{ order && order.referenceId ? "Online Order #: " + order.referenceId  : null } </span> </div>
                          </div>
                        <div style={{ width: "100%",marginBottom: 5, display: "flex", justifyContent: "flex-start" }} >
                          <Chip
                              label={orderStatus}
                              style={{marginLeft: 10}}
                            />
                            <Chip
                              label={fulfillmentStatus}
                              color="primary"
                              style={{marginLeft: 10}}
                            />
                            <Chip
                              label={paymentStatus}
                              color="secondary"
                              style={{ backgroundColor: "maroon", marginLeft: 10}}
                            />
                          </div>
                          <div style={{ width: "100%",marginBottom:20 , display: "flex",  justifyContent: "flex-end" }}>
                            <Button disabled={ !(order && order.fulfillmentStatus === 'FULFILLED' && order.paymentStatus === 'PAID' && order.status === 'OPEN' )} style={{ marginRight: 8 }} variant="contained" color="primary" onClick={() =>
                                window.confirm("Are you sure?") &&
                                handleMarkAsComplete(orderId)
                            }>MARK AS COMPLETE</Button>
                            <PrintOrder orderId={orderId} />
                          </div>
                      </div>
                      <Paper style={{ paddingTop: 10, paddingBottom: 10, marginBottom: 10 }}>

                        <div style={{ display: 'inline-flex' }}>
                          {/* <div>
                              <TextField
                                  disabled
                                  id="select-currency-native"
                                  select
                                  label="Location"                         
                                  onChange={(e) => setLocationId(e.target.value)}
                                  value={locationId}
                                  SelectProps={{
                                      native: true,
                                  }}
                                  margin="normal"
                                  style={{ marginLeft: 10 }}
                              >
                                  {locations.map(option => (
                                  <option key={option.value} value={option.value}>
                                      {option.label}
                                  </option>
                                  ))}
                              </TextField>
                            </div> */}
                            
                            <div style={{ marginTop: 15, marginLeft: 10 }}>
                                <TextField
                                    disabled
                                    id="orderDate"
                                    label="Order Date"
                                    type="date"
                                    value={orderDate}
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                />
                            </div>
                            <div style={{ marginLeft: 10, width: 150 }}>
                                <TextField
                                disabled
                                id="select-currency-native"
                                select
                                label="Sales Channel"                         
                                onChange={(e) => setChannelType(e.target.value)}
                                value={channelType}
                                SelectProps={{
                                    native: true,
                                }}
                                margin="normal"
                                >
                                {ChannelTypes.map(option => (
                                    <option key={option.value} value={option.value}>
                                    {option.label}
                                    </option>
                                ))}
                                </TextField>
                            </div>
                            <div style={{ marginLeft: 10,  width: 180 }}>
                                <TextField
                                disabled
                                id="select-currency-native"
                                fullWidth
                                select
                                label="Payment Type"                         
                                onChange={(e) => setPaymentType(e.target.value)}
                                value={paymentType}
                                SelectProps={{
                                    native: true,
                                }}
                                margin="normal"
                                >
                                {PaymentTypes.map(option => (
                                    <option key={option.value} value={option.value}>
                                    {option.label}
                                    </option>
                                ))}
                                </TextField>
                            </div>
                            <div style={{ marginLeft: 10,  width: 220 }}>
                                <TextField
                                disabled
                                fullWidth
                                id="select-currency-native"
                                select
                                label="Delivery Type"                         
                                onChange={(e) => setDeliveryType(e.target.value)}
                                value={deliveryType}
                                SelectProps={{
                                    native: true,
                                }}
                                margin="normal"
                                >
                                {DeliveryTypes.map(option => (
                                    <option key={option.value} value={option.value}>
                                    {option.label}
                                    </option>
                                ))}
                                </TextField>
                            </div>
                        </div>
                        </Paper>
                        <Paper style={{ paddingTop: 10, paddingBottom: 10, marginBottom: 10 }}>
                       <div style={{  marginLeft: 10 }}>
                            
                            <TextField
                                disabled
                                id="discountPercent"
                                label="Discount %"
                                value={discountPercent}
                                onChange={(e) => setDiscountPercent(e.target.value)}
                                margin="normal"
                                onKeyDown={onKeyDown}
                                style={{ width: 100 }}
                            />

                            <TextField
                                disabled
                                id="shippingAmount"
                                label="Shipping Amount"
                                value={shippingAmount}
                                onChange={(e) => setShippingAmount(e.target.value)}
                                margin="normal"
                                onKeyDown={onKeyDown}
                                style={{ marginLeft: 20, width: 150 }}
                            />
                            <TextField
                                disabled
                                id="invoiceDiscount"
                                label="Bill Discount"
                                value={invoiceDiscount}
                                onChange={(e) => setInvoiceDiscount(e.target.value)}
                                style={{ marginLeft: 20 }}
                                margin="normal"
                                onKeyDown={onKeyDown}
                                style={{ marginLeft: 20, width: 150 }}
                            />
                             <TextField
                                disabled
                                id="otherCharges"
                                label="Other Charges"
                                value={otherCharges}
                                onChange={(e) => setOtherCharges(e.target.value)}
                                style={{ marginLeft: 20 }}
                                margin="normal"
                                onKeyDown={onKeyDown}
                                style={{ marginLeft: 20, width: 150 }}
                            />

                            <EditOrderDetailButton orderId={orderId} 
                              _shippingAmount={shippingAmount} 
                              _invoiceDiscount={invoiceDiscount} 
                              _discountPercent={discountPercent}
                              _otherCharges={otherCharges}
                              _comment={comment}
                              _totalAmount={totalAmount}
                              onSubmit={handleEditOrderDetails}/>

                        </div>
                        {/* <div style={{marginLeft: 10, marginRight: 10}}>
                        <TextField
                            disabled
                            id="comment"
                            label="Notes"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            margin="normal"
                            style={{ width: "100%", color: 'red' }}
                            multiline={true}
                            rows={3}
                            rowsMax={100}
                            />
                        </div> */}
                      </Paper>
                      { comment ? 
                      <Paper style={{ paddingTop: 10, paddingBottom: 10, marginBottom: 10 }}>
                        <div style={{width: "100%", color: 'red', fontSize: 18, lineHeight: "normal", fontWeight: 700, marginLeft: 10,
                                display: "flex", justifyContent: "flex-start"}}> { comment }</div>
                      </Paper> : null }

                      {fulfillments.map(fulfillment => (
                        <Fragment key={"id_"+fulfillment.id}>
                            <div style={{ display: "inline-flex", width: "100%", marginTop: 20}}>
                              <div style={{width: "100%" }} >
                                <div style={{fontSize: 18, lineHeight: "normal", fontWeight: 600 }}> Fulfilled (Qty:  {fulfillment.totalQty}) #{fulfillment.id}</div>
                                <div style={{fontSize: 12, lineHeight: "normal", marginTop: 2  }}>
                                Fulfillment Date: {new Date(fulfillment.createDate).toLocaleDateString("en-IN")} | Delivery Type: {fulfillment.deliveryType} | Location: {fulfillment.locationId} </div>
                              </div>
                              <div style={{ width: "100%",marginBottom: 5, display: "flex",  justifyContent: "flex-end" }}>
                              {/* <Button disabled={fulfillClicked || selectedItems.length === 0} style={{ marginRight: 8 }} variant="contained" color="primary" onClick={fulfillItems} >FULFILL ITEMS</Button> */}
                              <div style={{ marginRight: 8, marginBottom: 10 }}> 
                                <PrintFulfillmentButton order={order} fulfillment={fulfillment} label="PACKING SLIP" />
                              </div>
                              { fulfillment.shippingDetail ? 
                              <Fragment>
                              <div style={{ marginRight: 8, marginBottom: 10 }}><AddEditTrackingButton record={fulfillment.shippingDetail}/> </div>
                              <div style={{ marginRight: 8, marginBottom: 10 }}><ShippingLabelButton shippingDetail={fulfillment.shippingDetail} orderDate={new Date(orderDate).toLocaleDateString("en-IN")} shipDate={new Date().toLocaleDateString("en-IN")} address={address} fulfillmentId={fulfillment.id} orderId={orderId}  /> </div> 
                              </Fragment>
                              : null }
                              
                              <div style={{ marginRight: 8, marginBottom: 10 }}><DeleteOrderFulfillment fulfillmentId={fulfillment.id} orderId={orderId} /> </div>
                              
                              </div>
                            </div>
      
                            <Paper>
                                  <CartArea disabled={false} cartItems={fulfillment.fulfilledItems} 
                                    hideTotal={true} 
                                    hideDiscount={true}
                                    hideSelect={true}
                                    hideId={true}
                                    hideISBN={true}
                                    hideDelete={true} 
                                    hideEdit={true}
                                    hideStockDetails={true}
                                    readOnly={true}
                                    hideCreateItem={true}
                                    onCartStateChange={() =>  {}} 
                                    discountPercent={0}
                                    shippingAmount={0}
                                    invoiceDiscount={0}/>
                            </Paper>
                          </Fragment>
                      ))}

                      { unfulfilledItems.length > 0 ? <Fragment>
                          <div style={{ display: "inline-flex", width: "100%", marginTop: 20, marginBottom: 5 }}>
                              <div style={{width: "100%", fontSize: 24, lineHeight: "normal", fontWeight: 700, marginBottom: 10, 
                                display: "flex", justifyContent: "flex-start"}}> Unfulfilled Items</div>
                            <div style={{ width: "100%",marginBottom: 5, display: "flex",  justifyContent: "flex-end" }}>
                              {/* <Button disabled={fulfillClicked || selectedItems.length === 0} style={{ marginRight: 8 }} variant="contained" color="primary" onClick={fulfillItems} >FULFILL ITEMS</Button> */}
                              <div style={{ marginRight: 8, marginBottom: 10 }}> 
                                <FulfillOrderItem address={address} orderId={orderId} selectedItems={selectedItems} />  
                              </div>
                              <div style={{ marginRight: 8, marginBottom: 10 }}> <RemoveOrderItem orderId={orderId} selectedItems={selectedItems} />   </div>
                            </div>
                          </div>
    
                          <Paper>
                                <div style={{ marginLeft: 10, paddingTop: 20, width: "90%" }}>
                                  <ItemAutoSuggest onItemSuggestionClick={handleAddItem} />
                                </div>
                                <ConfirmAddItem _discountPercent={discountPercent} openDialog={openDialog} item={addedItem} onConfirmAddItem={onConfirmAddItem} />

                                <CartArea disabled={false} cartItems={unfulfilledItems} hideTotal={true} hideDiscount={true} hideSelect={false} hideId={true} hideISBN={true} hideDelete={true} hideEdit={true}
                                  hideStockDetails={false}
                                  readOnly={true}
                                  hideCreateItem={true}
                                  onCartStateChange={(cartState) =>  { 
                                    setSelectedItems(cartState.selectedItems)
                                  }} 
                                  discountPercent={0}
                                  shippingAmount={0}
                                  invoiceDiscount={0}/>
                          </Paper>
                      </Fragment> : null }

                      { removedItems.length > 0 ? <Fragment>
                          <div style={{ display: "inline-flex", width: "100%", marginTop: 20, marginBottom: 5 }}>
                              <div style={{width: "100%", fontSize: 24, lineHeight: "normal", fontWeight: 700, marginBottom: 10, 
                                display: "flex", justifyContent: "flex-start"}}> Removed Items</div>
                          </div>
    
                          <Paper>
                                <CartArea disabled={false} 
                                    cartItems={removedItems} 
                                    hideTotal={true} 
                                    hideDiscount={true}
                                    hideSelect={true}
                                    hideId={true}
                                    hideISBN={true}
                                    hideDelete={true} 
                                    hideEdit={true}
                                    hideStockDetails={false}
                                    readOnly={true}
                                    hideCreateItem={true}
                                    onCartStateChange={() =>  {}} 
                                    discountPercent={0}
                                    shippingAmount={0}
                                    invoiceDiscount={0}/>
                          </Paper>
                      </Fragment> : null }


                      <Fragment>
                          <div style={{ display: "inline-flex", width: "100%", marginTop: 5, marginBottom: 5 }}>
                              <div style={{width: "100%" }} >
                                <div style={{fontSize: 24, lineHeight: "normal", fontWeight: 700 }}> Payments </div>
                                <div style={{fontSize: 12, lineHeight: "normal", marginTop: 2  }}> Amount Received: ₹ {totalPayment} </div>
                              </div>

                            <div style={{ width: "100%",marginBottom: 5, display: "flex",  justifyContent: "flex-end" }}>
                              { pendingPayment >= 0 ? 
                                <AddPaymentRefundButton type="SALE" orderId={orderId} amount={Math.abs(pendingPayment)} onSubmit={handleAddPayment}/>
                                :
                                <AddPaymentRefundButton type="REFUND" orderId={orderId} amount={Math.abs(pendingPayment)} onSubmit={ handleAddPayment }/>
                              }
                            </div>
                          </div>
                          <Paper>
                              {
                                orderTotals.length > 0 ? 
                                <Fragment>
                                  <Table padding="dense" >
                                      <TableHead>
                                          <TableRow>
                                              <TableCell align="right">Date</TableCell>
                                              <TableCell align="right">Type</TableCell>
                                              <TableCell align="right">Payment Method</TableCell>
                                              <TableCell align="right">Message</TableCell>
                                              <TableCell align="right">Amount</TableCell>
                                              <TableCell align="right"></TableCell>
                                          </TableRow>
                                      </TableHead>
                                      <TableBody>
                                          {orderPayments.map( payment => (
                                              <TableRow key={"i_" + payment.id}>
                                                  <TableCell align="right">{new Date(payment.paidDate).toLocaleString('en-IN')}</TableCell>
                                                  <TableCell align="right">{payment.type}</TableCell>
                                                  <TableCell align="right"><OrderPaymentTypeField source="paymentType" record={payment} /></TableCell>
                                                  <TableCell align="right">{payment.message}</TableCell>
                                                  <TableCell align="right">₹ {payment.amount}</TableCell>
                                                  <TableCell align="right"><DeleteButton onDelete={() => handleDeletePayment(payment.id) } /> </TableCell>
                                              </TableRow>))}
                                      </TableBody>
                                  </Table>
                                </Fragment> 
                                : 
                                <Fragment>
                                  <div style={{width: "100%", fontSize: 16, lineHeight: "normal", marginBottom: 10, paddingTop: 15, paddingBottom: 15,
                                      display: "flex", justifyContent: "center"}}> No Payments Received yet!</div>
                                </Fragment>                              
                                }
                          </Paper>
                          
                      </Fragment>                      
                    </Grid>

                    <Grid item xs={3}>
                        
                        <Paper>
                        <div style={{ paddingTop: 10, paddingBottom: 10, marginBottom: 10}}>
                            {customer ? 
                            <Fragment>
                                <CustomerDetails customer={customer} onChange={onCustomerChange} />
                                <CustomerAddress customer={customer} address={address} onChange={onAddressChange} />
                                </Fragment>
                                : 
                                <div style={{ marginLeft: 10  }}>
                                    <CustomerQuickCreateButton onCreate={() => {}} />
                                </div> }
                              </div>
                        </Paper>
                        <Paper>
                        <div style={{ paddingTop: 10, paddingBottom: 20,  marginBottom: 10}}>
                              <div style={{marginLeft: 10}}> 
                                  <div style={{paddingTop: 5}}><b>Order Summary</b></div>
                                  <div style={{marginTop: 5}}>Total Items: {totalItems} &nbsp;&nbsp;|&nbsp;&nbsp;<span>Total Qty: {totalQty}</span></div>
                                  {orderTotals.map(orderTotal => (
                                      <div key={"id_" + orderTotal.id} style={{marginTop: 5}}>{orderTotal.title}: ₹ {orderTotal.amount} </div>
                                  ))}
                              </div>
                        </div>
                        </Paper>
                        <Paper>
                        <div style={{ paddingTop: 10, paddingBottom: 20}}>
                              <div style={{marginLeft: 10}}> 
                                  <div style={{marginTop: 5}}>Amount Received: ₹ {totalPayment} </div>
                                  { pendingPayment > 0 ?
                                     <div style={{marginTop: 5, fontSize: 24, lineHeight: "normal", fontWeight: 700 }}> Customer to Pay: ₹ {pendingPayment} </div>
                                     : null }
                                  { pendingPayment < 0 ?
                                    <div  style={{marginTop: 5, fontSize: 24, lineHeight: "normal", fontWeight: 700 }}> To Refund : ₹ {Math.abs(pendingPayment)} </div>
                                    : null }
                              </div>
                        </div>
                        </Paper>

                    </Grid>
                </Grid>
            </div>
    )
}

export default withDataProvider(OrderProcess)
