/*
 * author:  Tres Finocchiaro, updated by Alethea Rose
 * date:    2017-07-10
 * license: Public Domain; Use as you wish.
 * source:  http://qz.io
 */

const cmds = {
    FO: 2,
    PW: null,
    FT: 2,
    A0: null,
    'A@': null,
    LL: null,
    LH: null,
    GB: null,
    FB: null,
    BY: 1,
    B3: null,
    BC: null,
    B7: 2
  }
  
  /*
   * Scales text from a raw ZPL label from 203 DPI to 300 DPI
   */
  export default function scaleZPL (rawCommands, scaleFactor) {
    const sections = rawCommands.split('^')
    if (!scaleFactor) {
      scaleFactor = 300 / 203
    }
  
    for (let cmd in cmds) {
      for (let j in sections) {
        if (sections[j].indexOf(cmd) === 0) {
          sections[j] = scaleSection(cmd, sections[j], scaleFactor)
        }
      }
    }
  
    return sections.join('^')
  }
  
  /*
   * Scales all integers found in a designated section
   */
  function scaleSection (cmd, section, scaleFactor) {
    section = section.slice(cmd.length, section.length)
    const parts = section.split(',')
  
    for (let p in parts) {
      if (isInt(parts[p]) && (cmds[cmd] === null || p < cmds[cmd])) {
        parts[p] = Math.round(scaleFactor * parts[p])
      }
    }
  
    return cmd + parts.join()
  }
  
  /*
   * Checks if a string is an integer
   */
  function isInt (value) {
    const integer = parseInt(value, 10)
  
    return !isNaN(value) && !isNaN(integer) && integer.toString() === value
  }