import {
  
    ADD_ITEM_CART,
    UPDATE_ITEM_CART,
    REMOVE_ITEM_CART,
    REMOVE_ALLITEMS_CART,
    UPDATE_SHIPPING_AMOUNT,
    UPDATE_OTHER_DISCOUNT,
    INIT_CART,
  } from "./cart.types";

  import currency from "currency.js";
  
  const initialState = {
    items: {
    },
    rowCounter: 0,
    shippingAmount: 0.0,
    otherDiscount: 0.0
  };
  
  const cloneObj = state => {
    const clone = {
      items: {},
      rowCounter: 0,
      shippingAmount: 0.0,
      otherDiscount: 0.0
    };
    Object.assign(clone.items, state.items);
    clone.rowCounter = state.rowCounter;
    clone.shippingAmount = state.shippingAmount;
    clone.otherDiscount = state.otherDiscount;
    return clone;
  };
  
  const setCartItem = (state, item) => {
    // eslint-disable-next-line no-param-reassign
    state.items[item.id] = {};
    const newItem = state.items[item.id];
    newItem.id = item.id;
    newItem.rowid = item.rowid;
    newItem.isbn = item.isbn;
    newItem.name = item.name;
    newItem.qty = item.qty;
    newItem.discountPercent = item.discountPercent;
    newItem.manufacturerId = item.manufacturerId
    newItem.manufacturerName = item.manufacturerName
    newItem.price = currency(item.price).value;
    newItem.costPrice = currency(item.price).multiply(100 - item.discountPercent).divide(100)
  };
  
  const updateItemCart = (oldState, item) => {
    const state = cloneObj(oldState);
    setCartItem(state, item);
    //state.rowCounter =  oldState.rowCounter
    return state;
  };
  
  const addItemCart = (oldState, item) => {
    const state = cloneObj(oldState);
    const _item = {}
    _item.isbn = item.isbn
    _item.name = item.name
    _item.id = item.id
    _item.price = item.price
    _item.manufacturerId = item.manufacturerId
    _item.manufacturerName = item.manufacturerName
    if (item.qty) {
      _item.qty = item.qty
    } else {
      _item.qty = 1
    }
    _item.discountPercent = item.discountPercent;
    _item.rowid = oldState.rowCounter + 1;
  
    if (oldState.items[item.id]) {
      _item.qty = state.items[item.id].qty + 1
      _item.discountPercent = state.items[item.id].discountPercent;
    } 
    setCartItem(state, _item);
    state.rowCounter =  oldState.rowCounter + 1
    return state;
  };
  
  const removeItemCart = (oldState, item) => {
    const state = cloneObj(oldState);
    delete state.items[item.id];
    //state.rowCounter =  oldState.rowCounter
    const isCartEmpty = state.items.length === 0;
    if (isCartEmpty) {
      return initialState;
    }
    return state;
  };
  
  const updateShippingAmount = (oldState, shippingAmount) => {
    const state = cloneObj(oldState);
    //console.log(shippingAmount)
    state.shippingAmount = currency(shippingAmount).value;
    return state;
  }

  const updateOtherDiscount = (oldState, otherDiscount) => {
    const state = cloneObj(oldState);
    //console.log(otherDiscount)
    state.otherDiscount = currency(otherDiscount).value;
    return state;
  }

  const initCart = (oldState, data) => {
    var state = {}
    return state;
  }
  
  export default function cart(state = initialState, action = {}) {
    switch (action.type) {

      case INIT_CART:
        return initCart(state, action.data);

      case ADD_ITEM_CART:
        return addItemCart(state, action.data);
  
      case UPDATE_ITEM_CART:
        return updateItemCart(state, action.data);
  
      case REMOVE_ITEM_CART:
        return removeItemCart(state, action.data);
  
      case REMOVE_ALLITEMS_CART:
        return initialState;
  
      case UPDATE_SHIPPING_AMOUNT:
        return updateShippingAmount(state, action.data);
      
      case UPDATE_OTHER_DISCOUNT:
        return updateOtherDiscount(state, action.data);

      default:
        return state;
    }
  }
  