
import React, { useState, useReducer, useEffect, Fragment } from 'react';
import useManifestState from '../../common/hooks/useManifestState'
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import DeleteButton from '../../common/DeleteButton'
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
    withDataProvider,
    GET_ONE,
    showNotification,
    Button,
    CREATE
} from 'react-admin';


const initialManifestState = {
    manifestItems: [],
    rowCounter: 0,
    totalItems: 0,
}

const ManifestArea = (props) => {

    const [manifestState, manifestActionDispatch] = useManifestState(initialManifestState)
    const [inputShippingId, setInputShippingId] = useState('')

    const [showDialog, setShowDialog] = useState(false)
    const [submitClicked, setSubmitClicked] = useState(false)

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const [currentCustomerName, setCurrentCustomerName] = useState('');
    const [currentCity, setCurrentCity] = useState('');
    const [currentPincode, setCurrentPincode] = useState('');

    const [currentTrackingId, setCurrentTrackingId] = useState('');
    const [currentShippingWeight, setCurrentShippingWeight] = useState('');
    const [currentShippingAmount, setCurrentShippingAmount] = useState(null);

    const resetState = () => {

    }

    const { totalItems } = props
    const { hideId, disabled, manifestItems, shipped, hideEdit, hideDelete, handedOver } = props

    useEffect(() => {
        manifestItems.forEach( item => {
            manifestActionDispatch({type: 'add_item', item: item})
        })
    }, [manifestItems])

    useEffect(() => { 
        props.onManifestStateChange(manifestState); 
    }, [manifestState])


    const resetCurrentState = () => {
        setCurrentCustomerName('')
        setCurrentCity('')
        setCurrentPincode('')
        setCurrentShippingWeight('')
        setCurrentTrackingId('')
        setCurrentShippingAmount(null)
        setInputShippingId('')

    }
    const handleShippingItemOk = () => {
        if (!submitClicked) {
            setSubmitClicked(true)
            const {
                dispatch,
                dataProvider
            } = props;

            var shippingDetail = {
                id: inputShippingId,
                customerName: currentCustomerName,
                city: currentCity,
                pincode: currentPincode,
                shippingWeight: currentShippingWeight,
                trackingId: currentTrackingId,
                shippingAmount: currentShippingAmount
              }

            var payload = { "data" : shippingDetail };

            dataProvider(CREATE, 'order/shipping', payload)
            .then((json) => {
                manifestActionDispatch({type: 'add_item', item: json.data})
                setShowDialog(false);
                dispatch(showNotification('Shipping Detail update for id:' + json.data.id)) 
                resetCurrentState();
            })
            .catch((e) => {
                dispatch(showNotification('Error: Something went wrong', 'warning'))
            });
            setSubmitClicked(false)
        }
    }

    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            // open popup and show.
            setShowDialog(true)
        }
      }

    useEffect(() => {
        const fetchData = async () => {
          setIsError(false);
          setIsLoading(true);

          var params = { id: inputShippingId }

          const {
            dispatch,
            dataProvider
            } = props;

          dataProvider(GET_ONE, 'order/shipping', params)
            .then((json) => {
                setCurrentCustomerName(json.data.customerName);
                setCurrentCity(json.data.city);
                setCurrentPincode(json.data.pincode);
                setCurrentTrackingId(json.data.trackingId)
                setCurrentShippingWeight(json.data.shippingWeight ? json.data.shippingWeight : '')
                setCurrentShippingAmount(json.data.shippingAmount)
                setIsLoading(false);
            })
            .catch((e) => {
                setIsError(true);
                dispatch(showNotification('Shipping Id is not valid', 'warning'))
                setIsLoading(false);
                setShowDialog(false);
            });
        };
        if (showDialog && inputShippingId) {
            fetchData();
        }
    }, [showDialog]);

    const handleShippingIdChange = event => {
        setInputShippingId(event.target.value);
    }

    return (
        <Fragment>
            <Dialog maxWidth="md" open={showDialog} onClose={() => { setShowDialog(false); resetState() }} aria-label="Verify Shipping Item">
                { isLoading ? <h2>Loading..</h2> : 
                <Fragment>
                <DialogTitle>Verify Shipping Item</DialogTitle>
                <DialogContent style={{marginTop: -10}}>
                    <div>
                        <TextField style={{marginTop: 5, width: 150, fontWeight: "700" }}
                        disabled
                        label="Shipping Id"
                        defaultValue={inputShippingId}
                        />
                    </div>
                    <div>
                        <TextField style={{ marginTop: 5, width: 300 }}
                            label="Customer Name"
                            disabled={handedOver}
                            onChange={(event) => setCurrentCustomerName(event.target.value)} 
                            value = {currentCustomerName}
                            />
                    </div>
                    <div style={{marginTop: 5}}>

                        <TextField style={{ width: 200 }}
                            label="City"
                            disabled={handedOver}
                            onChange={(event) => setCurrentCity(event.target.value)} 
                            value = {currentCity}
                            />

                        <TextField style={{ marginLeft: 10, width: 100 }}
                            label="Pincode"
                            disabled={handedOver}
                            onChange={(event) => setCurrentPincode(event.target.value)} 
                            value = {currentPincode}
                            />
                    </div>
                    <div style={{marginTop: 10}}>
                        <TextField style={{ width: 150}}
                        disabled={handedOver}
                        onChange={(event) => setCurrentShippingWeight(event.target.value)} 
                        value = {currentShippingWeight}
                        label="Weight (in gms)"
                        />
                    </div>
                    <div style={{marginTop: 10}}>
                        <TextField style={{ width: 200}}
                            label="Tracking Id#"
                            onChange={(event) => setCurrentTrackingId(event.target.value)} 
                            value = {currentTrackingId}
                            />
                    </div>
                    { (handedOver) ? 
                    <div style={{marginTop: 10}}>
                        <TextField style={{ width: 200}}
                            label="Shipping Amount"
                            onChange={(event) => setCurrentShippingAmount(event.target.value)} 
                            value = {currentShippingAmount}
                            />
                    </div>
                    : null}
                </DialogContent>
                <DialogActions>
                    <Button label="OK" disabled={false} onClick={handleShippingItemOk} />
                    <Button label="Cancel" onClick={() => { setShowDialog(false); resetState() }}></Button>
                </DialogActions>
                </Fragment>
                }
            </Dialog>

            { (disabled) ? null : 
                <div style={{ width: "100%", marginTop: 15, marginBottom: 15, display: "inline-flex" }}>
                    <div style={{ marginLeft: 10, marginTop: 10, display: "flex"}}>
                        <div style={{width: "100%"}}>
                            <TextField
                                id="inputShippingId"
                                label="Type Shipping Id and press Enter"
                                value={inputShippingId}
                                onChange={handleShippingIdChange}
                                onKeyDown={onKeyDown}
                                style={{ width: "300px", marginLeft: 5, marginTop: 5 }}
                                margin="normal"
                                /> 
                        </div>
                    </div>
                </div>
            }

            <Table padding="dense" >
                <TableHead>
                    <TableRow>
                        {!hideId ? <TableCell align="right">Shipping Id</TableCell> : null }
                        <TableCell align="right">Customer Name</TableCell>
                        <TableCell align="right">City</TableCell>
                        <TableCell align="right">PinCode </TableCell>
                        <TableCell align="right">Weight</TableCell>
                        {/* <TableCell align="right">Carrier</TableCell> */}
                        <TableCell align="right">TrackingId</TableCell>
                        {handedOver || shipped ? <TableCell align="right">Amount</TableCell> : null }
                        {!hideDelete ? <TableCell align="right"></TableCell> : null }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {manifestState.manifestItems.map(item => (
                        <TableRow key={"i_" + item.rowid}>
                            {!hideId ? <TableCell align="right">{item.id}</TableCell> : null }
                            <TableCell align="right">{item.customerName}</TableCell>
                            <TableCell align="right">{item.city}</TableCell>
                            <TableCell align="right">{item.pincode}</TableCell>
                            <TableCell align="right">{item.shippingWeight}</TableCell>
                            {/* <TableCell align="right">{item.shippingCarrier}</TableCell> */}
                            <TableCell align="right">{item.trackingId}</TableCell>
                            {handedOver || shipped ? <TableCell align="right">{item.shippingAmount}</TableCell> : null }
                            {!hideEdit ? <TableCell align="right">{!disabled || handedOver ? <a href="#" onClick={(event) => { event.preventDefault(); setInputShippingId(item.id); setShowDialog(true) } }>Edit</a> : null } </TableCell> : null }
                            {!hideDelete ? <TableCell align="right"> {disabled ? null: <DeleteButton onDelete={() => manifestActionDispatch({type: 'remove_item', item: item}) } /> } </TableCell> : null }
                        </TableRow>
                        ))}
                </TableBody>
            </Table>
        </Fragment>
    )
}

export default withDataProvider(ManifestArea)