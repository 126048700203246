import React, { Fragment, Component } from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    ReferenceField,
    NumberField,
    Filter,
    CardActions,
    DateInput, SelectInput
} from 'react-admin';

const NoneActions = props => (
    <CardActions />
);



const StockFilter = (props) => (
    <Filter {...props}>
       {/* <SelectInput allowEmpty={false} label="Location" source="locationId" alwaysOn choices={[
        { id: '1',  name: 'SHOP' },
        // { id: '2',  name: 'BOOKFAIR' },
    ]
        } /> */}
    </Filter>
);


const getLocation = () => {
    const initialValue = 1
    try {
        // Get from local storage by key
        const item = window.localStorage.getItem('locationId');
        // Parse stored json or if none return initialValue
        return item ? JSON.parse(item) : initialValue;
      } catch (error) {
        // If error also return initialValue
        console.log(error);
        return initialValue;
      }
}

const StockTakingList = props => (
    <List exporter={false}  {...props}  filters={<StockFilter />} filterDefaultValues={{ locationId: getLocation() }}  bulkActionButtons={<NoneActions />} 
            perPage={25}
    >
    <Datagrid rowClick="show" >
        <TextField source="id" />
        <DateField source="createDate" />
        <TextField source="shelfCode" />
        <TextField source="createdByName" label="Received By"/>
        <NumberField source="totalUnitsQty" />
        <NumberField source="totalQty" />
        <NumberField source="totalAmount" />
    </Datagrid>
</List>
);

export default StockTakingList;
