import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ThumbUp from '@material-ui/icons/ThumbUp';
import { Button  } from 'react-admin';
import compose from 'recompose/compose';
import { PANUVAL_API_URL } from '../../common/constants'; 


class GetReportButton extends Component {

    state = {
        isLoading: false
    }

    handleClick = () => {
        this.setState( {isLoading: true })
        const { record, type } = this.props;
        const token = localStorage.getItem('token');
        const request = new Request(PANUVAL_API_URL + '/v2/catalog/manufacturer/stock-report/'+record.id, {
            method: 'POST',
            headers: new Headers({ 'Authorization' : `Bearer ${token}`}),
        })
        return fetch(request)
            .then(response => {
                this.setState( {isLoading: false })
                alert('Stock report generated successfully. Please check google drive.')
            })
    };

    render() {
        const { record } = this.props;
        var buttonLabel = ''
        if (this.props.type === "pdf") {
            buttonLabel = 'Stock Report'
        }

        if (this.props.type === "screenshot") {
            buttonLabel = 'Get IMG'
        }

        return (
            <Button disabled={this.state.isLoading || record.totalItems == 0} size="small" variant="contained" label={buttonLabel} onClick={this.handleClick}></Button>
        );
    }
}

export default GetReportButton;
