// hooks to maintain state
// submit using dataProvider
//

import React, { useState, Fragment, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import {
    showNotification,
    Button,
    withDataProvider,
    DELETE
} from 'react-admin';
import useLocalStorage from './hooks/useLocalStorage'


const FulfillOrderItem = (props) => {

    const { fulfillmentId,orderId } = props
    const [showDialog, setShowDialog] = useState(false)
    const [submitClicked, setSubmitClicked] = useState(false)

    const handleDelete = () => {
        if (!submitClicked) {
            setSubmitClicked(true)
            const {
                dispatch,
                dataProvider,
            } = props;
            dataProvider(DELETE, 'order/'+ orderId +'/fulfillment', { id : fulfillmentId })
            .then((json) => {
                dispatch(showNotification('Fulfillment ' + json.data.id + ' deleted')) 
                setSubmitClicked(false)
                setShowDialog(false)
                window.location.reload(true);
            })
            .catch((e) => {
                console.log(e)
                setSubmitClicked(false)
                dispatch(showNotification('Error: Something went wrong', 'warning'))
            });
            };
    }

    return (
        <Fragment>
                <Button disabled={false} size="small" variant="contained" onClick={() => setShowDialog(true)} label="DELETE"></Button>
                
                <Dialog maxWidth="sm" open={showDialog} onClose={() => { setShowDialog(false); }} aria-label="Quick Create Item">
                    <Fragment>
                    <DialogTitle style={{ marginTop: -15}}>Fulfill Items for Order# {orderId} </DialogTitle>
                    <DialogContent style={{marginTop: -15}}>
                        <p>Are you sure you want to delete this fulfillment #{fulfillmentId}</p>
                    </DialogContent>
                    <DialogActions>
                        <Button label="Delete" onClick={handleDelete} />
                        <Button label="Cancel" onClick={() => { setShowDialog(false); }}></Button>
                    </DialogActions>
                    </Fragment>
                </Dialog>
            </Fragment>
    )

}

export default withDataProvider(FulfillOrderItem)