import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { showNotification, withDataProvider, CREATE } from 'react-admin';
import CartArea from '../../common/CartArea'
import locations from '../../common/location'
import { push } from 'react-router-redux';

const StockTakingCreate = (props) => { 
                              
    const [cartState, setCartState] = useState({})
    const [shelfCode, setShelfCode] = useState("")

    const [submitClicked, setSubmitClicked] = useState(false)
    // quoteDate invoiceNumber invoiceAmount settlementType comment
    const [invoiceDiscount] = useState(0)
    const [otherCharges] = useState(0)
    const [shippingAmount] = useState(0)
    const [discountPercent] = useState(0)
    
    const [locationId, setLocationId] = useState(2)
    const [cartItems] = useState([])

    const [] = useState(false);
    const [] = useState(false);

    const  validate = () => {
      return (cartState.totalItems > 0) 
    }

    const handleSubmit = () => {
      if (!submitClicked) {
        setSubmitClicked(true)
        const {
            dispatch,
            dataProvider
        } = props;

        var payload = generatePayload()
    
        dataProvider(CREATE, 'inventory/transfer/shop-to-office', payload)
        .then((json) => {
            dispatch(showNotification('Stock Transfer from Shop to Book Fair created with id:' + json.data.id)) 
            setSubmitClicked(false)
            dispatch(push('/inventory/transfer/shop-to-office'));
        })
        .catch(() => {
            setSubmitClicked(false)
            dispatch(showNotification('Error: Something went wrong', 'warning'))
        });
        };
     
  };

    function generatePayload() {
      var payload = {
        "totalAmount" : cartState.totalAmount,
        "totalQty" : cartState.totalQty,
        "totalUnitsQty" : cartState.totalItems,
        "stockTakingItems" : [],
        "shelfCode" : shelfCode,
        "locationId" : locationId
      }
    
      var line = 1;
    
      cartState.cartItems.forEach(function (item) {
        var receivingItem = {
          "line" : line,
          "itemId" : item.itemId,
          "name" : item.name,
          "qty" : item.qty,
          "price" : item.price
        }
        payload.stockTakingItems.push(receivingItem);
        line++;
      });
      return { "data" : payload };
    }


    const onCartStateChange = cartState => {
        setCartState(cartState)
    }

   
    return (
            <div>
                <div style={{fontSize: 18, lineHeight: "normal", fontWeight: 700, marginBottom: 10 }}>Transfer from Shop to Book fair </div>           
                <Grid container spacing={16}>
                    <Grid item xs={8}>
                      <Paper>
                            <CartArea disabled={false} cartItems={cartItems} hideISBN={false} hideSelect={true} hideDiscount={true}
                            onCartStateChange={onCartStateChange} 
                            discountPercent={discountPercent}
                            shippingAmount={shippingAmount}
                            invoiceDiscount={invoiceDiscount}
                            otherCharges={otherCharges} />
                      </Paper>
                    </Grid>

                    <Grid item xs={3}>
                        <Paper>
                            <div style={{ paddingTop: 10, paddingBottom: 20}}>
                                <div style={{marginLeft: 10}}> 
                                    <div style={{paddingTop: 5}}><b>Stock Taking Details</b></div>
                                    <div style={{marginTop: 5}}>Total Items: {cartState.totalItems} &nbsp;&nbsp;&nbsp;<span>Total Qty: {cartState.totalQty}</span></div>
                                    <div style={{marginTop: 5}}>Sub Total: ₹ {cartState.subTotal} </div>
                                    <div style={{marginTop: 5}}><b>Total Amt: ₹{cartState.totalAmount}</b></div>
                                </div>
                                <div style={{marginLeft: 10}}> 
                                    <Button disabled={submitClicked || !validate() } style={{ marginTop: 10, marginBottom: 10 }} onClick={handleSubmit} variant="contained" color="primary">SUBMIT</Button>
                                </div>
                            </div> 
                        </Paper>
                    </Grid>
                    
                </Grid>
            </div>
    )
}

export default withDataProvider(StockTakingCreate)