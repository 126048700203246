import React, { useState, useEffect } from 'react';
import { Fragment } from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import MultiAutoSuggest from '../../common/MultiAutoSuggest'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { showNotification, withDataProvider, GET_ONE, CREATE, UPDATE } from 'react-admin';
import CustomerQuickCreateButton from '../../common/CustomerQuickCreateButton'
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import ItemAutoSuggest from '../../receivings/stocktaking/ItemAutoSuggest'
import currency from "currency.js";
import DeleteButton from '../../common/DeleteButton'
import AutoSuggest from '../../common/AutoSuggest'
import ItemQuickCreateEditButton from '../../common/ItemQuickCreateEditButton'
import useCartState from '../../common/hooks/useCartState'
import CartArea from '../../common/CartArea'
import locations from '../../common/location'
import { push } from 'react-router-redux';
import useLocalStorage from '../../common/hooks/useLocalStorage'


const settlementTypes = [
  {
    value: 'CASH',
    label: 'Cash',
  },
  {
    value: 'CHECK',
    label: 'Check',
  },
  {
    value: 'CREDIT',
    label: 'Credit',
  },
  {
    value: 'BANK_TRANSFER',
    label: 'Bank Transfer',
  },
  {
    value: 'POST_DATED_CHECK',
    label: 'Post Dated Check',
  },
  {
    value: 'CREDIT_CARD',
    label: 'Credit Card',
  },
  {
    value: 'PENDING',
    label: 'Pending',
  },
];

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.name, query);
  const parts = parse(suggestion.name, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) =>
          part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
          ) : (
              <strong key={String(index)} style={{ fontWeight: 300 }}>
                {part.text}
              </strong>
            ),
        )}
      </div>
    </MenuItem>
  );
}

function getSuggestionValue(suggestion) {
  //console.log(suggestion)
  return suggestion.name;
}

const SupplierBillCreate = (props) => {

    const { id } = props

    const [supplier, setSupplier] = useState(null);
    const [billDetail, setBillDetail] = useState(null);
    const [cartState, setCartState] = useState({})

    const [submitClicked, setSubmitClicked] = useState(false)
    // receiptDate invoiceNumber invoiceAmount settlementType comment
    const [receiptDate, setReceiptDate] = useState(new Date().toISOString().substring(0, 10));
    const [invoiceNumber, setInvoiceNumber] = useState('')
    const [invoiceAmount, setInvoiceAmount] = useState('')
    const [receivingType, setReceivingType] = useState('PURCHASE')
    const [settlementType, setSettlementType] = useState('CASH')
    const [comment, setComment] = useState('')
    const [invoiceDiscount, setInvoiceDiscount] = useState('')
    const [status, setStatus] = useState(-1)
    const [cartItems, setCartItems] = useState([])

    const [locationId, setLocationId] = useLocalStorage('locationId', '1');

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const  validate = () => {
      return (supplier != null && 
              receiptDate != null &&
              cartState.totalItems > 0
              ) 
    }


    useEffect(() => {
      const fetchData = async () => {
        setIsError(false);
        setIsLoading(true);

        var params = { id: id}

        const {
          dispatch,
          dataProvider
          } = props;

        dataProvider(GET_ONE, 'receiving/bill', params)
          .then((json) => {
              setData(json.data);
              setIsLoading(false);
          })
          .catch((e) => {
              setIsError(true);
              dispatch(showNotification('Error: Something went wrong', 'warning'))
              setIsLoading(false);
          });
      };

      if (id) {
        fetchData()
      }
      
    }, []);

    const setData = (bill) => {
      const _supplier = { id: bill.supplierId, name: bill.supplierName, discountPercent: bill.supplierDiscountPercent }
      setSupplier(_supplier)
    // receiptDate invoiceNumber invoiceAmount settlementType comment
      setReceiptDate(new Date(bill.receiptDate).toISOString().substring(0, 10))
      setInvoiceNumber(bill.invoiceNumber)
      setInvoiceAmount(bill.invoiceAmount)
      setReceivingType(bill.receivingType)
      setSettlementType(bill.settlementType)
      setComment(bill.comment)
      setInvoiceDiscount(bill.invoiceAmount)
      setLocationId(bill.locationId)
      setStatus(bill.status)

      const _cartItems = []

      bill.receivingItems.forEach((receivingItem) => {
        const  _item = { }
        _item.id = receivingItem.itemId
        _item.isbn = receivingItem.isbn
        _item.name = receivingItem.name
        _item.price = receivingItem.itemPrice
        _item.qty = receivingItem.qty
        _item.manufacturerName = receivingItem.manufacturerName
        _item.manufacturerId = receivingItem.manufacturerId
        _item.discountPercent = receivingItem.discountPercent
        _item.stockQty = receivingItem.itemQty
        _item.discountAmount = 0
        _item.total = 0.0
        _cartItems.push(_item)
      });

      setCartItems(_cartItems)

    }

    const handleSubmit = (_status) => {
      if (!submitClicked) {
        setSubmitClicked(true)
        const {
            dispatch,
            dataProvider
        } = props;

        var payload = generatePayload(_status)
        console.log(payload)
    
        dataProvider(CREATE, 'receiving/bill', payload)
        .then((json) => {
            dispatch(showNotification('Supplier Bill created with id:' + json.data.id)) 
            setSubmitClicked(false)
            dispatch(push('/receiving/bill'));
        })
        .catch((e) => {
            setSubmitClicked(false)
            dispatch(showNotification('Error: Something went wrong', 'warning'))
        });
        };
     
  };


    function generatePayload(_status) {

      var payload = {
        "id" : id,
        "supplierId": supplier.id,
        "invoiceNumber": invoiceNumber,
        "settlementType" : settlementType,
        "receivingType" : receivingType,
        "receiptDate": new Date(receiptDate),
        "invoiceAmount": invoiceAmount,
        "subTotal": cartState.subTotal,
        "totalDiscount": cartState.totalDiscount,
        "totalAmount": cartState.totalAmount,
        "totalQty": cartState.totalQty,
        "totalUnitsQty": cartState.totalItems,
        "comment": comment,
        "locationId": locationId,
        "receivingItems": [],
        "status": _status
      }

      var line = 1;

      // costPrice":975,"discountPercent":35,"itemId":44025,"line":1,"name":"Ennamo Edho On1","price":1500,"qty":1
    
      cartState.cartItems.forEach(function (item) {
        var receivingItem = {
          "line" : line,
          "itemId" : item.itemId,
          "name" : item.name,
          "qty" : item.qty,
          "price" : item.price,
          "costPrice" : item.price,
          "discountPercent": item.discountPercent,
        }
        payload.receivingItems.push(receivingItem);
        line++;
      });
      return { "data" : payload };
    }

      const onSupplierSelection = supplier => {
        console.log(supplier)
        setSupplier(supplier)
      };


      const onCartStateChange = cartState => {
        setCartState(cartState)
      }

      const onLocationChange = locationId => {
        console.log(locationId)
        setLocationId(locationId)
      }

    return (
            <div>
                <h2>Supplier Bill </h2>           
                <Grid container spacing={16}>
                    <Grid item xs={8}>
                      <Paper style={{ paddingTop: 10, paddingBottom: 10, marginBottom: 10 }}>
                        <div style={{ marginTop: 10, marginLeft: 10, display: "flex"}}>

                        <div style={{ marginTop: -30 }}>
                                <TextField
                                    id="select-currency-native"
                                    select
                                    label="Location"                         
                                    onChange={(e) => setLocationId(e.target.value)}
                                    value={locationId}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    margin="normal"
                                    style={{ marginLeft: 10 }}
                                >
                                    {locations.map(option => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                    ))}
                                </TextField>
                            </div>

                          <div style={{marginLeft: 10, width: "50%"}}>
                            <AutoSuggest placeholder="Type Supplier Name.." fetchUrl="suppliers/supplier" onItemSuggestionClick={onSupplierSelection} renderSuggestion={renderSuggestion} getSuggestionValue={getSuggestionValue} parameterName='name'  />
                          </div>
                        </div>
                        {/*receipt date, invoice number, invoice amount, settlement type, credit, */ }
                        
                        <div style={{ marginTop: 15, marginLeft: 10 }}>
                          <TextField
                            id="receiptDate"
                            label="Bill Date"
                            type="date"
                            onChange={(e) => setReceiptDate(e.target.value)}
                            value={receiptDate}
                            InputLabelProps={{
                              shrink: true,
                            }}
                        />
                        <TextField
                          id="invoiceNumber"
                          label="Bill No"
                          value={invoiceNumber}
                          onChange={(e) => setInvoiceNumber(e.target.value)}
                          style={{ marginLeft: 20 }}
                          margin="normal"
                        />

                        <TextField
                          id="invoiceAmount"
                          label="Bill Amount"
                          value={invoiceAmount}
                          onChange={(e) => setInvoiceAmount(e.target.value)}
                          style={{ marginLeft: 20 }}
                          margin="normal"
                        />
                        
                        <TextField
                          id="select-currency-native"
                          select
                          label="Settlement Type"                         
                          onChange={(e) => setSettlementType(e.target.value)}
                          value={settlementType}
                          SelectProps={{
                            native: true,
                          }}
                          margin="normal"
                          style={{ marginLeft: 10 }}
                        >
                          {settlementTypes.map(option => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </TextField>
                          
                       </div>
                       <div style={{marginLeft: 10, marginRight: 10}}>
                       <TextField
                          id="comment"
                          label="Notes"
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                          margin="normal"
                          style={{ width: "100%" }}
                        />
                       </div>
                    
                      </Paper>
                      <Paper>
                            <CartArea disabled={ status === 1 }cartItems={cartItems} onCartStateChange={onCartStateChange} discountPercent={supplier ? supplier.discountPercent : 0} />
                      </Paper>
                    </Grid>

                    <Grid item xs={4}>
                        <Paper>
                          <div style={{marginLeft: 10}}>
                            <div style={{paddingTop: 5}}><b>Supplier Bill</b></div>
                            <div style={{marginTop: 10}}>Supplier Name: {supplier ? supplier.name: '' }</div>
                            <div style={{marginTop: 10}}>Discount: {supplier ? supplier.discountPercent: ''}%</div>  
                            <div style={{marginTop: 10}}>Bill No: {invoiceNumber}</div>
                            <div style={{marginTop: 10}}>Bill Amount: ₹ {invoiceAmount}</div>
                            <div style={{marginTop: 10}}>Total Items: {cartState.totalItems}</div>
                            <div style={{marginTop: 10}}>Total Qty: {cartState.totalQty} </div>
                            <div style={{marginTop: 10}}>Sub Total: {cartState.subTotal} </div>
                            <div style={{marginTop: 10}}>Discount: {cartState.totalDiscount} </div>
                            <div style={{marginTop: 10}}>Total Amount: ₹ {cartState.totalAmount}</div>
                            <Button disabled={ status === 1 || submitClicked || !validate() } style={{ marginTop: 10, marginBottom: 10 }} onClick={() => handleSubmit(2)} variant="contained" color="primary">SUSPEND</Button> &nbsp; &nbsp;&nbsp;
                            <Button disabled={submitClicked || !validate() } style={{ marginTop: 10, marginBottom: 10 }} onClick={() => handleSubmit(1)} variant="contained" color="primary">{status === 1 ? "SAVE" : "SUBMIT"}</Button>
                            </div>
                        </Paper>
                    </Grid>
                    
                </Grid>
            </div>
    )
}

export default withDataProvider(SupplierBillCreate)