import QuoteIcon from '@material-ui/icons/Receipt';
import QuoteList from './QuoteList';
import QuoteCreate from './QuoteCreate';
// import QuoteShow from './ReceiptShow';
// import QuoteEdit from './ReceiptEdit';


export default {
    edit: QuoteCreate,
    list: QuoteList,
    create: QuoteCreate,
    // show: QuoteShow,
    icon: QuoteIcon,
    name: "order/quote"
};
