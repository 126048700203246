import BillIcon from '@material-ui/icons/Receipt';
import PurchaseOrderList from './PurchaseOrderList';
import PurchaseOrderCreate from './PurchaseOrderCreate';


export default {
    list: PurchaseOrderList,
    edit: PurchaseOrderCreate,
    create: PurchaseOrderCreate,
    icon: BillIcon,
    name: "suppliers/order"
};
