import React from 'react';
import { Route } from 'react-router-dom';
import Configuration from './configuration/Configuration';
import WebItem from './catalog/items/WebItem'
import UploadWebImage from './catalog/items/UploadWebImage'
import WebOrderProcess from './sales/WebsiteOrders/WebOrderProcess'
import ShippingLabel from './sales/WebsiteOrders/ShippingLabel'
import PurchaseOrderCreate from './receivings/purchaseorders/PurchaseOrderCreate'

export default [
    <Route exact path="/configuration" component={Configuration} />,
    <Route exact path="/v2/catalog/item/web/:id" component={WebItem} />,
    <Route exact path="/v2/catalog/item/web/images/:id" component={UploadWebImage} />,
    <Route exact path="/order/web/:id/process" component={WebOrderProcess} />,
    <Route exact path="/order/web/shipping-label" component={ShippingLabel} />,
    <Route exact path="/suppliers/order/:orderId/receive" component={PurchaseOrderCreate} />,
];
