export const SupplierOrderSettlementType = [
    { id: 'IMMEDIATE', name: 'Cash & Carry', color: 'YellowGreen' }, // name for drop down in order list; name for select field.
    { id: 'CREDIT', name: 'Credit', color: 'Plum' },
    // { id: 'POST_DATED_CHECK', name: 'Post Dated Check', color: 'IndianRed' },
    // { id: 'BIL_ON_BILL', name: 'Bill on Bill', color: 'LightSalmon' },
    // { id: 'OTHER', name: 'Other', color: '' },
  ];


export const SupplierOrderPaymentType = [
  { id: 'CASH', name: 'Cash', color: 'DarkSeaGreen'  },
  { id: 'DEBIT_CARD', name: 'Debit Card', color: 'Orange'  },
  { id: 'BANK_TRANSFER', name: 'Bank Transfer', color: 'LightBlue' },
  { id: 'UPI_TRANSFER', name: 'GPay', color: 'LightSalmon' },
  // { id: 'DEMAND_DRAFT', name: 'Demand Draft', color: 'Plum' },
  // { id: 'PERSONAL', name: 'Personal', color: 'LightSalmon' },
  { id: 'CREDIT_CARD', name: 'Credit Card', color: 'LimeGreen'  },
  { id: 'CHECK', name: 'Check', color: 'IndianRed' }, // name for drop down in order list; name for select field.
  { id: 'OTHER',         name: 'Other', color: '' },
  { id: 'NOT_APPLICABLE',         name: '-', color: '' },
  
];

export const SupplierOrderDeliveryType =  [
  { id: 'SELF_PICKUP',           name: 'Self Pickup', color: 'Lavender'},
  { id: 'PARCEL_SERVICE',         name: 'Parcel Service', color: 'MistyRose' },
  { id: 'SHOP_DELIVERY',       name: 'Shop Delivery', color: 'MistyRose' },
  { id: 'COURIER', name: 'Courier', color: 'Orange' },
  { id: 'OTHER',       name: 'Other', color: '' },
  ]

export const SupplierInformedType = [
  { id: 'WHATSAPP', name: 'WhatsApp', color: 'Cornsilk'  },
  { id: 'EMAIL', name: 'Email', color: 'MistyRose'  },
  { id: 'SOCIAL_MEDIA', name: 'Social Media', color: 'Moccasin'  },
  { id: 'PHONE', name: 'Phone', color: 'Lavender'  },
  { id: 'IN_PERSON', name: 'In Person', color: 'YellowGreen' },
 { id: 'OTHER', name: 'Other', color: 'Plum' },
];


export const SupplierOrderClosedReason = [
    { id: 'RECEIVED', name: 'Order Received' , color: 'Cornsilk'  },
    { id: 'CANCELLED_BY_US', name: 'Cancelled by Us', color: 'MistyRose'  },
    { id: 'CANCELLED_BY_SUPPLIER', name: 'Cancelled by Supplier', color: 'Moccasin'  },
    { id: 'DELAYED', name: 'Delayed', color: 'Lavender'  },
    { id: 'NEW_ORDER', name: 'New Order', color: 'Lavender'  },
    { id: 'OTHER', name: 'POS', color: 'Other'  },

  ];

export const SupplierOrderStatus = [
    { id: 'DRAFT', name: 'Draft', color: 'Cornsilk'  },
    { id: 'CREATED', name: 'Created', color: 'MistyRose'  },
    { id: 'INFORMED', name: 'Informed', color: 'Moccasin'  },
    { id: 'CLOSED', name: 'Closed', color: 'Lavender'  },
  ];


export const SupplierBillStatus = [
  { id: 'RECEIVED', name: 'Received', color: 'Cornsilk'  },
  { id: 'DIRECT', name: 'Direct', color: 'MistyRose'  },
  { id: 'ONLY_BILL', name: 'Only Bill', color: 'Moccasin'  },
];

export const SupplierBillPaymentStatus = [
  { id: 'PAID', name: 'Paid', color: 'Cornsilk'  },
  { id: 'UNPAID', name: 'UnPaid', color: 'MistyRose'  },
  { id: 'PARTIALLY_PAID', name: 'Partially Paid', color: 'MistyRose'  },
  { id: 'OVERPAID', name: 'Over Paid', color: 'Moccasin'  },
];

export const ResolveItemStatus = [
  { id: 'EMPTY', name: '', color: 'Cornsilk'  },
  { id: 'OUT_OF_STOCK', name: 'Out of Stock', color: 'Cornsilk'  },
  { id: 'OUT_OF_PRINT', name: 'Out of Print', color: 'MistyRose'  },
  { id: 'ALREADY_IN_STOCK', name: 'Already in Stock', color: 'MistyRose'  },
  { id: 'PARTIAL_BILL', name: 'Partial Bill', color: 'Moccasin'  },
  { id: 'NEW_EDITION', name: 'New Edition', color: 'Moccasin'  },
  { id: 'PURCHASE_LATER', name: 'Will Purchase Later', color: 'Moccasin'  },
  { id: 'RESOLVE_LATER', name: 'Will Resolve Later', color: 'Moccasin'  },
];


export const SupplierTypes =  [
  { id: 'PUBLISHER',           name: 'Publisher', color: 'YellowGreen'},
  { id: 'DISTRIBUTOR',         name: 'Distributor', color: 'IndianRed' },
  { id: 'SELLER',       name: 'Seller', color: 'DarkGreen' },
  { id: 'AUTHOR', name: 'Author', color: 'Orange' },
  { id: 'OTHERS',       name: 'Other', color: '' },
  ]
