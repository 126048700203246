// hooks to maintain state
// submit using dataProvider
//

import React, { useState, Fragment, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import {
    showNotification,
    Button,
    withDataProvider,
    CREATE,
    UPDATE,
    GET_ONE
} from 'react-admin';

import TextField from '@material-ui/core/TextField';

const AddressCreateEditButton = (props) => {

    const { customer, onCreateEdit, address } = props

    const [showDialog, setShowDialog] = useState(false)
    const [submitClicked, setSubmitClicked] = useState(false)

    const [id, setId] = useState(null)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [mobileNumber, setMobileNumber] = useState('')

    const setData = (customer) => {
        if (customer) {
            setId(customer.id)
            setName(customer.name)
            setEmail(customer.email)
            setMobileNumber(customer.mobileNumber)
        }
    }

    const resetState = () => {
        setId(null);
        setName('')
        setEmail('')
        setMobileNumber('')
    }

    const getCustomer = () => {
        const customer = {
                id : id,
                name: name,
                email: email,
                mobileNumber: mobileNumber
        }
        return customer
    }


    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    useEffect(() => {

        if (showDialog && customer != null) {
            setData(customer)
        }
        
    }, [showDialog]);


     const handleSubmit = () => {
        if (!submitClicked) {
            setSubmitClicked(true)
            onCreateEdit(getCustomer()).then((msg) => {
                setShowDialog(false)
                setSubmitClicked(false)
                resetState()
            })
            .catch((e) => {
                setSubmitClicked(false)
            });
        }
     }

     const validate = () => {
         return true
     }

    return (
        <Fragment>
            <a href="#" onClick={(event) => { event.preventDefault(); setShowDialog(true) }}>Add/Edit</a>
            <Dialog fullWidth open={showDialog} onClose={() => { setShowDialog(false); resetState() }} aria-label="Add/Edit Address">
                    <Fragment>
                            <DialogTitle>Edit Customer Details</DialogTitle>
                            <DialogContent>
                            <div>
                                <TextField
                                    required
                                    onChange={(event) => setName(event.target.value)} 
                                    label="Customer Name"
                                    value={name}
                                    style={{ width: 400 }}
                                />
                            </div>
                            <div style={{ marginTop: 7 }}>
                                <TextField
                                    onChange={(event) => setEmail(event.target.value)} 
                                    label="Email"
                                    value={email}
                                    style={{ width: 400 }}
                                />
                            </div>
                            <div style={{ marginTop: 7 }}>
                                <TextField
                                    onChange={(event) => setMobileNumber(event.target.value)} 
                                    label="Mobile Number"
                                    value={mobileNumber}
                                    style={{ width: 400 }}
                                />
                            </div>
                            </DialogContent>
                            <DialogActions>
                                <Button label="Submit" disabled={!validate()} onClick={handleSubmit} />
                                <Button label="Cancel" onClick={() => { setShowDialog(false); resetState() }}></Button>
                            </DialogActions>
                    </Fragment>
            </Dialog>
        </Fragment>
    )

}


export default AddressCreateEditButton