import React, { Component, Fragment } from 'react';
import { FilePond, registerPlugin } from "react-filepond";

import "filepond/dist/filepond.min.css";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { PANUVAL_API_URL } from '../../common/constants'; 

//Register the plugins
registerPlugin(FilePondPluginImagePreview);


class UploadWebImage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ocId: 0,
            token: '',
            fetchApi: false,
            files: [],
            additionalFiles: []
        };
    }

    componentDidMount() {
        const ocId = this.props.match.params.id
        const token = localStorage.getItem('token');
        this.setState({
            ocId: ocId,
            token: token
        })
        const fetchURL = PANUVAL_API_URL + '/catalog/product/'+ ocId + '/images'
        fetch(fetchURL,  {
            headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            },
        }).then(response => {
            response.json().then(data => {
                if (data.coverImage) {
                    this.setState({
                        files: [
                            {
                                source: data.coverImage.imageUrl,
                                options: {
                                    type: "local"
                                }
                            }
                        ]
                    })
                }

                if (data.additionalImages) {
                    this.setState({
                        additionalFiles : data.additionalImages.map( element => { return { source: element.imageUrl, options: { type: "local" } } })
                    })
                }

                this.setState({
                    fetchApi: true
                })
            })
        })
    }

    
    handleInit() {
        // console.log("FilePond instance has initialised", this.pond1);
        // console.log("FilePond instance has initialised", this.pond2);
      }

    render() {
        return (
            <Fragment>                
                    { this.state.fetchApi ? 
                    <div>
                        <span>Cover Image</span>
                        <FilePond
                        name="cover"
                        ref={ref => (this.pond1 = ref)}
                        files={this.state.files}
                        allowMultiple={false}
                        maxFiles={1}
                        allowRevert
                        maxParallelUploads={1}
                        //  server="http://localhost:9000/v2/catalog/upload-image"
                        server={
                            {
                                    url: PANUVAL_API_URL + '/v2/catalog/'+this.state.ocId+'/upload-image',
                                    process: {
                                        url: '?type=cover',
                                        method: 'POST',
                                        withCredentials: true,
                                        headers: { 'Authorization': `Bearer ${this.state.token}`,},
                                        timeout: 7000,
                                        onerror: null,
                            
                                    },

                                    revert: (uniqueFileId, load, error) => {

                                        fetch(PANUVAL_API_URL + '/v2/catalog/'+ this.state.ocId + '/upload-image?type=cover', {
                                            method: 'DELETE',
                                            headers: {  'Authorization': `Bearer ${this.state.token}`,},
                                            body: uniqueFileId
                                        })
                                        .then(res => res.text()) // OR res.json()
                                        .then(res => console.log(res))
                
                                        // Should remove the earlier created temp file here
                                        // ...
                            
                                        // Can call the error method if something is wrong, should exit after
                                        //error('oh my goodness');
                            
                                        // Should call the load method when done, no parameters required
                                        load();
                                    }, 
                                    remove: (source, load, error) => {
                    
                                        //console.log(source)

                                        fetch(PANUVAL_API_URL + '/v2/catalog/'+ this.state.ocId + '/upload-image?type=cover', {
                                            method: 'DELETE',
                                            headers: {'Authorization': `Bearer ${this.state.token}`,},
                                            body: source
                                        })
                                        .then(res => res.text()) // OR res.json()
                                        .then(res => console.log(res))
                                        // Should somehow send `source` to server so server can remove the file with this source
                            
                                        // Can call the error method if something is wrong, should exit after
                                        //error('oh my goodness');
                            
                                        // Should call the load method when done, no parameters required
                                        load();
                                }
                                }
                            }
                        oninit={() => this.handleInit()}
                        onremovefile={(error, file) => {
                            // Set currently active file objects to this.state
                            console.log("remove File")
                            // this.setState({
                            //   files: fileItems.map(fileItem => fileItem.file)
                            // });
                        }}
                        onupdatefiles={fileItems => {
                            // Set currently active file objects to this.state                          
                            this.setState({
                                files: fileItems.map(fileItem => fileItem.file)
                            });
                        }}
                        />
                    </div>
                    : <div> Loading.. </div> }

                { this.state.fetchApi ? 
                    <div>
                        
                        <span style={{ marginBottom: 5 }}>Additional Images</span>
                        <FilePond
                        name="additional"
                        ref={ref => (this.pond2 = ref)}
                        files={this.state.additionalFiles}
                        allowMultiple={true}
                        maxFiles={5} 
                        allowRevert
                        maxParallelUploads={1}
                        //  server="http://localhost:9000/v2/catalog/upload-image"
                        server={
                            {
                                    url: PANUVAL_API_URL + '/v2/catalog/'+this.state.ocId+'/upload-image',
                                    process: {
                                        url: '?type=additional',
                                        method: 'POST',
                                        withCredentials: true,
                                        headers: { 'Authorization': `Bearer ${this.state.token}`,},
                                        timeout: 7000,
                                        onerror: null,
                                    },
                                    revert: (uniqueFileId, load, error) => {
                                        console.log('revert called - 1')
                                        fetch(PANUVAL_API_URL + '/v2/catalog/'+ this.state.ocId + '/upload-image?type=additional', {
                                            method: 'DELETE',
                                            headers: {  'Authorization': `Bearer ${this.state.token}`,},
                                            body: uniqueFileId
                                        })
                                        .then(res => res.text()) // OR res.json()
                                        .then(res => console.log(res))
                                        // Should remove the earlier created temp file here
                                        // ...
                            
                                        // Can call the error method if something is wrong, should exit after
                                        //error('oh my goodness');
                            
                                        // Should call the load method when done, no parameters required
                                        load();
                                    }, 
                                    remove: (source, load, error) => {
                    
                                        //console.log(source)

                                        console.log('remove called - 1')

                                        fetch(PANUVAL_API_URL + '/v2/catalog/'+ this.state.ocId + '/upload-image?type=additional', {
                                            method: 'DELETE',
                                            headers: {'Authorization': `Bearer ${this.state.token}`,},
                                            body: source
                                        })
                                        .then(res => res.text()) // OR res.json()
                                        .then(res => console.log(res))
                                        // Should somehow send `source` to server so server can remove the file with this source
                                        // Can call the error method if something is wrong, should exit after
                                        //error('oh my goodness');
                            
                                        // Should call the load method when done, no parameters required
                                        load();
                                }
                                }
                            }
                        oninit={() => this.handleInit()}
                        onupdatefiles={fileItems => {
                            // Set currently active file objects to this.state
                            console.log("update File")
                            console.log(fileItems)
                            this.setState({
                                additionalFiles: fileItems.map(fileItem => fileItem.file)
                            });
                        }}
                        />
                    </div>
                    : <div> Loading.. </div> }

            </Fragment>
        )
    }
}


export default UploadWebImage;
