// hooks to maintain state
// submit using dataProvider
//

import React, { useState, Fragment, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import {
    showNotification,
    withDataProvider,
    CREATE,
    UPDATE,
    GET_ONE,
} from 'react-admin';

import {refreshView} from 'react-admin'
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';

const ShippingTypes = [
{ value: 'FRANCH_EXPRESS', label: 'Franch Express' },
{ value: 'REGISTERED_POST', label: 'Registered Post' },
{ value: 'PROFESSIONAL_COURIER', label: 'Professional Courier' },
{ value: 'TRACKON_COURIER', label: 'Trackon Courier' },
{ value: 'SPEED_POST', label: 'Speed Post' },
{ value: 'KPN_PARCEL', label: 'KPN Parcel Service' },
{ value: 'NOT_KNOWN', label: 'Not Known' },
{ value: 'OTHERS', label: 'Others' },
];


const AddEditTrackingButton = (props) => {
    console.log(props)
    const { record } = props
    const [showDialog, setShowDialog] = useState(false)
    const [submitClicked, setSubmitClicked] = useState(false)
    
    const [shippingType, setShippingType] = useState('FRANCH_EXPRESS')
    const [trackingId, setTrackingId] = useState('')
    const [trackingUrl, setTrackingUrl] = useState('')
    const [shippingAmount, setShippingAmount] = useState(0)
    const [notify, setNotify] = useState(true)

    const resetState = () => {
        setShippingAmount(0)
        setShippingType('FRANCH_EXPRESS')
        setTrackingUrl('')
        setTrackingId('')
    }

    useEffect(() => {
        setShippingAmount(record.shippingAmount)
        setShippingType(record.shippingCarrier)
        setTrackingUrl(record.trackUrl)
        setTrackingId(record.trackingId)
      
    }, [showDialog]);

    const handleSubmit = () => {
        if (!submitClicked) {
            setSubmitClicked(true)
            const {
                dispatch,
                dataProvider
            } = props;
    
            var shippingDetail = {
                orderId: record.orderId,
                fulfillmentId: record.fulfillmentId,
                shippingCarrier: shippingType,
                shippingAmount: shippingAmount,
                trackingId: trackingId,
                trackUrl:  trackingUrl
            }
        
            dataProvider(CREATE, 'order/' + record.orderId + '/fulfillment/' + record.fulfillmentId + '/tracking?notify=' + notify, { data: shippingDetail })
            .then((json) => {
                dispatch(showNotification('Tracking details updated')) 
                setShowDialog(false)
                setSubmitClicked(false)
                resetState()
                //dispatch(refreshView())
                window.location.reload()
            })
            .catch((e) => {
                setSubmitClicked(false)
                dispatch(showNotification(e.message, 'warning'))
            });
            };
    }

    const validate = () => {
        return strlength(trackingId) > 0
        // return ( name.length > 1 && name.length <= 70 &&
        //     address1.length > 1 && address1.length <= 70 &&
        //     address2.length > 1 && address2.length <= 70 &&
        //     address3.length <= 70 &&
        //     city.length > 1 && city.length <= 40 &&
        //     pincode.length > 1 && pincode.length <= 10 &&
        //     mobileNumber.length <= 20) 
    }

    const strlength = (string) => {
        if (string != null) {
            return string.length
        } else {
            return 0
        }
    }

    return (
        <Fragment>
            {record.trackingId ? 
                    <div style={{ textAlign: "center" }}>
                        <div style={{ fontSize: 18}}>
                            {record.trackUrl ? 
                                <a href={record.trackUrl ? record.trackUrl : "#" }> {record.trackingId}</a>
                            : 
                                <Fragment>{record.trackingId}</Fragment>
                            }
                        </div>
                        <div style={{ fontSize: 12}}><a href="#" onClick={(event) => { event.preventDefault(); setShowDialog(true) } }>Edit Tracking</a></div>
                    </div>
                    : 
                    // <div><a href="#" onClick={(event) => { event.preventDefault(); setShowDialog(true) } }>Add Tracking</a></div>
                        record.shippingStatus === 'HANDED_OVER' || record.shippingStatus === 'SHIPPED'  ? 
                            <div style={{ textAlign: "center" }}>
                            <Button variant="contained" size="small" color="primary" onClick={(event) => { event.preventDefault(); setShowDialog(true) }}>
                            Add Tracking</Button>
                            </div> 
                        : null
            }

            <Dialog maxWidth="sm" open={showDialog} onClose={() => { setShowDialog(false); resetState() }} aria-label="Payment and Delivery Details">
                    <Fragment>
                            <DialogTitle>Add Tracking url for Fulfillment# {record.fulfillmentId}</DialogTitle>
                            <DialogContent>
                            <div style={{ display: 'inline-flex' }}>
                                <div style={{ width: 150 }}>
                                    <TextField
                                    id="shippingType"
                                    fullWidth
                                    select
                                    label="Shipping Type"                         
                                    onChange={(e) => setShippingType(e.target.value)}
                                    value={shippingType}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    margin="normal"
                                    >
                                    {ShippingTypes.map(option => (
                                        <option key={option.value} value={option.value}>
                                        {option.label}
                                        </option>
                                    ))}
                                    </TextField>
                                </div>

                                
                                <div style={{ marginLeft: 10,  width: 100 }}>
                                    <TextField
                                        fullWidth
                                        id="shippingAmount"
                                        label="Amount"
                                        value={shippingAmount}
                                        onChange={(e) => setShippingAmount(e.target.value)}
                                        margin="normal"
                                        //onKeyDown={onKeyDown}
                                    />
                                </div>
                                
                            </div>
                            <div style={{ marginTop: 10, display: 'inline-flex' }}>
                                <div style={{ width: 200 }}>
                                    <TextField
                                        fullWidth
                                        id="trackingId"
                                        label="Tracking Id"
                                        value={trackingId}
                                        onChange={(e) => setTrackingId(e.target.value)}
                                        margin="normal"
                                    />
                                </div>

                                <div style={{ marginLeft: 20,  width: 300 }}>
                                    <TextField
                                        fullWidth
                                        id="trackingUrl"
                                        label="Tracking Url"
                                        value={trackingUrl}
                                        onChange={(e) => setTrackingUrl(e.target.value)}
                                        margin="normal"
                                    />
                                </div>
                            </div>

                                                            
                            <FormControlLabel
                                    control={
                                    <Checkbox
                                        checked={notify}
                                        defaultValue={true}
                                        onChange={(event,checked) =>  { setNotify(checked) }}
                                    />
                                    }
                                    label="Notify Customer"
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button variant="contained" color="primary" disabled={submitClicked || !validate()} onClick={handleSubmit}> SUBMIT </Button>
                                <Button variant="contained" onClick={() => { setShowDialog(false); resetState() }}> CANCEL </Button>
                            </DialogActions>
                    </Fragment>
            </Dialog>
        </Fragment>
    )

}

export default withDataProvider(AddEditTrackingButton)