import React, { useState, useEffect } from 'react';
import { Fragment } from 'react'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { showNotification, withDataProvider, GET_ONE, CREATE } from 'react-admin';
import CustomerQuickCreateButton from '../../common/CustomerQuickCreateButton'
import AutoSuggest from '../../common/AutoSuggest'
import CartArea from '../../common/CartArea'
import { OrderChannelTypes } from '../../common/OrderTypes'
import { push } from 'react-router-redux';
import CustomerAddress from '../../common/CustomerAddress'
import QuoteToOrderButton from '../../common/QuoteToOrderButton'

const PaymentTypes = [
    { value: 'CASH', label: 'Cash' },
    { value: 'CARD', label: 'Card Swipe' },
    { value: 'BANK_TRANSFER', label: 'Bank Transfer' },
    { value: 'UPI_TRANSFER', label: 'GPay (Panuval)' },
    { value: 'PERSONAL', label: 'GPay (Saravanan)' },
    { value: 'CHECK', label: 'Check' },
    { value: 'NOT_KNOWN', label: 'Not Known' },
  ];


  const DeliveryTypes = [
    { value: 'STORE_PICKUP', label: 'Store Pickup' },
    { value: 'DOOR_DELIVERY', label: 'In Person Delivery' },
    { value: 'SHIPPING', label: 'Shipping' },
    { value: 'POS', label: 'POS' },
    { value: 'NOT_KNOWN', label: 'Not Known' },
  ];


function renderSuggestion(suggestion, { query, isHighlighted }) {
    const matches = match(suggestion.name, query);
    const parts = parse(suggestion.name + " [" + suggestion.mobileNumber + "] " + "[" + suggestion.email + "]", matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) =>
          part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
          ) : (
              <strong key={String(index)} style={{ fontWeight: 300 }}>
                {part.text}
              </strong>
            ),
        )}
      </div>
    </MenuItem>
  );
}

function getSuggestionValue(suggestion) {
  //console.log(suggestion)
  return suggestion.name;
}

const QuoteCreate = (props) => { 

    const { id } = props
    const [quoteDate, setQuoteDate] = useState(new Date().toISOString().substring(0, 10));
    const [quoteStatus, setQuoteStatus] = useState()
    const [customer, setCustomer] = useState({
                                        name: '',
                                        email: '',
                                        mobileNumber: '',
                                        discountPercent: ''
                                    });

    const [address, setAddress] = useState(null);
                              
    const [cartState, setCartState] = useState({})

    const [submitClicked, setSubmitClicked] = useState(false)
    // quoteDate invoiceNumber invoiceAmount settlementType comment
    

    const [channelType, setChannelType] = useState('SHOP')
    const [paymentType, setPaymentType] = useState('NOT_KNOWN')
    const [deliveryType, setDeliveryType] = useState('NOT_KNOWN')
    const [comment, setComment] = useState('')
    const [referenceId, setReferenceId] = useState('')
    const [invoiceDiscount, setInvoiceDiscount] = useState(0)
    const [otherCharges, setOtherCharges] = useState(0)
    const [shippingAmount, setShippingAmount] = useState(0)
    const [discountPercent, setDiscountPercent] = useState(0)
    
    const [locationId, setLocationId] = useState(1)
    const [cartItems, setCartItems] = useState([])

    const [, setIsLoading] = useState(false);
    const [, setIsError] = useState(false);

    const  validate = () => {
      return (customer != null && 
              quoteDate != null &&
              cartState.totalItems > 0
              ) 
    }

    const  _validate = () => {
      return (customer != null && 
              quoteDate != null &&
              (comment !== '' || cartState.totalItems > 0)
              ) 
    }


    useEffect(() => {
      const fetchData = async () => {
        setIsError(false);
        setIsLoading(true);

        var params = { id: id}

        const {
          dispatch,
          dataProvider
          } = props;

        dataProvider(GET_ONE, 'order/quote', params)
          .then((json) => {
              setData(json.data);
              setIsLoading(false);
          })
          .catch(() => {
              setIsError(true);
              dispatch(showNotification('Error: Something went wrong', 'warning'))
              setIsLoading(false);
          });
      };

      if (id) {
        fetchData()
      }
      
    }, []);

    const setData = (quote) => {
      setQuoteDate(new Date(quote.quoteDate).toISOString().substring(0, 10))
      setQuoteStatus(quote.status)
      const _customer = { 
                          id: quote.customerId,
                          name: quote.name, 
                          email: quote.email, 
                          mobileNumber:  quote.mobileNumber, 
                          whatsAppNummber:  quote.whatsAppNummber 
                        }
      setCustomer(_customer)
      setLocationId(quote.locationId)
      console.log ( "quote location=" + quote.locationId)
      setDiscountPercent(quote.discountPercent)
      const _address = { 
                          id: quote.shippingAddressId,
                          name: quote.shippingName,
                          address1: quote.shippingAddress1,
                          address2: quote.shippingAddress2,
                          address3: quote.shippingAddress3,
                          city: quote.shippingCity,
                          pincode: quote.shippingPostCode,
                          state: quote.shippingState,
                          country: quote.shippingCountry,
                          mobileNumber : quote.shippingMobileNumber
                      }
      setAddress(_address)
      setComment(quote.comment)
      setChannelType(quote.channelType)
      setPaymentType(quote.paymentType)
      setDeliveryType(quote.deliveryType)    
      setReferenceId(quote.referenceId)

      const _cartItems = []

      quote.cartItems.forEach((cartItem) => {
        const  _item = { }
        _item.itemId = cartItem.itemId
        _item.name = cartItem.name
        _item.manufacturerName = cartItem.manufacturerName
        _item.qty = cartItem.qty
        _item.price = cartItem.price
        _item.discountPercent = cartItem.discountPercent
        _item.discountAmount = cartItem.discountAmount
        _item.total = cartItem.total 
        _item.shelfCodes = cartItem.shelfCodes
        _item.stockQty = cartItem.stockQty
        _item.avlQty = cartItem.avlQty
        _cartItems.push(_item)
      });

      setCartItems(_cartItems)

      quote.cartTotals.forEach((cartTotal) => {
        if (cartTotal.totalType === "INVOICE_DISCOUNT") {
          setInvoiceDiscount(cartTotal.amount)

        } else if (cartTotal.totalType === "SHIPPING") {
          setShippingAmount(cartTotal.amount)
        } else if (cartTotal.totalType === "OTHER_CHARGES") {
          setOtherCharges(cartTotal.amount)
        }
      })

    }



    const handleOrderCreate = (orderPayment, deliveryType, shippingType, print) => {
      const {
        dispatch,
        dataProvider, 
      } = props;

      var order = generatePayload()
      

      order.orderPayments = []
      if (orderPayment) {
        order.orderPayments.push(orderPayment)
        order.paymentType = orderPayment.paymentType
      }
      
      order.orderDate = new Date()
      order.deliveryType = deliveryType
      delete order.status
      if (id) {
        order.quoteId = id
      }
      // order.shippingType = "NA"
      
      // if (order.deliveryType === 'SHIPPING') {
      //   order.shippingType = shippingType
      // }
      
      dataProvider(CREATE, 'order', { data: order })
      .then((json) => {
          dispatch(showNotification('Order created with id:' + json.data.id)) 
          setSubmitClicked(false)
          if (print) {
            dispatch(push('/order/' + json.data.id + '/show'));
          } else {
            dispatch(push('/order/' + json.data.id ));
          }
          
      })
      .catch(() => {
          setSubmitClicked(false)
          dispatch(showNotification('Error: Something went wrong', 'warning'))
      });
  };
   

    const handleSubmit = (suspendedFlag) => {
      if (!submitClicked) {
        setSubmitClicked(true)
        const {
            dispatch,
            dataProvider
        } = props;

        var quote = generatePayload(suspendedFlag)
    
        dataProvider(CREATE, 'order/quote', { data: quote })
        .then((json) => {
            dispatch(showNotification('Quote created with id:' + json.data.id)) 
            setSubmitClicked(false)
            dispatch(push('/order/quote'));
        })
        .catch(() => {
            setSubmitClicked(false)
            dispatch(showNotification('Error: Something went wrong', 'warning'))
        });
        };
    };

    function generatePayload(suspendedFlag) {
      //console.log('----------address payload----')
      //console.log(address)
      

      var payload = {
        id : id,
        quoteDate : new Date(quoteDate),
        status : "OPEN",
        customerId : customer.id,
        name: customer.name,
        email : customer.email,
        mobileNumber : customer.mobileNumber,
        whatsAppNumber : customer.whatsAppNumber,
        locationId: locationId,
        discountPercent: discountPercent,
        shippingAddressId: address ? address.id : null,
        shippingName : address ? address.name : null,
        shippingAddress1 : address ? address.address1: null,
        shippingAddress2 : address ? address.address2: null,
        shippingAddress3 : address ? address.address3: null,
        shippingCity : address ? address.city : null,
        shippingPostCode : address ? address.pincode : null,
        shippingState : address ? address.state : null,
        shippingCountry : address? address.country: null ,
        shippingMobileNumber: address ? address.mobileNumber : null,
        comment : comment,
        channelType : channelType,
        paymentType : paymentType,
        deliveryType : deliveryType,
        referenceId: referenceId,
        total : cartState.totalAmount,
        cartItems : [],
        cartTotals : []
      }

      var line = 1;

      // costPrice":975,"discountPercent":35,"itemId":44025,"line":1,"name":"Ennamo Edho On1","price":1500,"qty":1
    
      cartState.cartItems.forEach(function (item) {
        var cartItem = {
          "line" : line,
          "itemId" : item.itemId,
          "name" : item.name,
          "manufacturerName" : item.manufacturerName,
          "qty" : item.qty,
          "price" : item.price,
          "discountPercent": item.discountPercent,
          "total" : item.total
        }
        payload.cartItems.push(cartItem);
        line++;
      });


      payload.cartTotals = [ { totalType: 'SUB_TOTAL', amount: cartState.subTotal },
      { totalType: 'TOTAL', amount: cartState.totalAmount }, 
      { totalType: 'ITEM_DISCOUNT', amount: cartState.totalDiscount }, 
      { totalType: 'INVOICE_DISCOUNT', amount: cartState.invoiceDiscount },
      { totalType: 'SHIPPING', amount: cartState.shippingAmount }, 
      { totalType: 'OTHER_CHARGES', amount: cartState.otherCharges } ]

      let _status = "OPEN"
      if (suspendedFlag) {
        _status = "SUSPENDED"
      } else {
        if (payload.cartItems.length > 0) {
          _status = "WORKING"
        }
      }

      payload.status = _status

      return payload
    }

    const onCustomerSelection = customer => {
        //console.log(customer)
        setCustomer(customer)
        setAddress(null)
        if (customer && customer.discountPercent) {
          setDiscountPercent(customer.discountPercent)
        } else {
          setDiscountPercent(0)
        }
    };

    const onKeyDown = (e) => {
        if (e.keyCode === 8 || e.keyCode === 46) {
          e.target.value = ""
          e.preventDefault();
        }
    }
 
    const onCartStateChange = cartState => {
        setCartState(cartState)
    }

    // const onLocationChange = (oldLocationId, newlocationId) => {
    //     if (oldLocationId != newlocationId ) {
    //       setLocationId(newlocationId)
    //     }
        
    // }

    // const onAddressChange = address => {
    //   console.log('--address--')
    //   console.log(address)
    //   setAddress(address)
    // }


     // const handleSubmit = () => {
    //     if (!submitClicked) {
    //         setSubmitClicked(true)
    //         const {
    //             dispatch,
    //             dataProvider
    //         } = props;

    //         var payload = generatePayload()
    //         console.log(payload)

    //         dataProvider(CREATE, 'sale/customer/' + customer.id + '/address', payload)
    //             .then((json) => {
    //                 console.log(json)
    //                 onCreateEdit(json.data)
    //                 dispatch(showNotification('Item created with id:' + json.data.id))
    //                 setShowDialog(false)
    //                 setSubmitClicked(false)
    //                 resetState()
    //             })
    //             .catch((e) => {
    //                 setSubmitClicked(false)
    //                 dispatch(showNotification(e.message, 'warning'))
    //             });
    //     };
    // }


    const onAddressChange = address => new Promise(function(resolve, reject) {
      const { dispatch, dataProvider } = props;
      dataProvider(CREATE, 'sale/customer/' + customer.id + '/address',  { data: address } )
        .then((json) => {
            setAddress(json.data)
            dispatch(showNotification('Address created with id:' + json.data.id))
            resolve(json.data)
        })
        .catch((e) => {
            reject(e.message)
        });
    });

    return (
            <div>
                <div style={{fontSize: 18, lineHeight: "normal", fontWeight: 700, marginBottom: 10 }}>Create Quote </div>           
                <Grid container spacing={16}>
                    <Grid item xs={8}>
                      <Paper style={{ paddingTop: 10, paddingBottom: 10, marginBottom: 10 }}>
                        <div style={{ marginTop: 10, marginLeft: 10, display: "flex"}}>
                          <div style={{width: "40%"}}>
                            <AutoSuggest placeholder="Type Customer Name or Mobile Number or Email" fetchUrl="sale/customer" onItemSuggestionClick={onCustomerSelection} renderSuggestion={renderSuggestion} getSuggestionValue={getSuggestionValue} parameterName='name'  />
                          </div>
                          <div style={{ marginLeft: 30  }}>
                                    <CustomerQuickCreateButton onCreate={onCustomerSelection} />
                           </div>
                          {/* <div style={{ marginTop: -25, marginLeft: 20}}>
                            <TextField
                                id="select-currency-native"
                                select
                                label="Location"                         
                                onChange={(e) => setLocationId(e.target.value)}
                                value={locationId}
                                SelectProps={{
                                    native: true,
                                }}
                                margin="normal"
                                style={{ marginLeft: 10 }}
                            >
                                {locations.map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                                ))}
                            </TextField>
                          </div> */}
                        </div>
                        {/*receipt date, invoice number, invoice amount, settlement type, credit, */ }
                        
                        <div style={{ display: 'inline-flex' }}>
                            <div style={{ marginTop: 15, marginLeft: 10 }}>
                                <TextField
                                    id="quoteDate"
                                    label="Quote Date"
                                    type="date"
                                    onChange={(e) => setQuoteDate(e.target.value)}
                                    value={quoteDate}
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                />
                            </div>
                            <div style={{ marginLeft: 10, width: 150 }}>
                                <TextField
                                id="select-currency-native"
                                select
                                label="Enquiry Channel"                         
                                onChange={(e) => setChannelType(e.target.value)}
                                value={channelType}
                                SelectProps={{
                                    native: true,
                                }}
                                margin="normal"
                                >
                                {OrderChannelTypes.map(option => (
                                    <option key={option.id} value={option.id}>
                                    {option.name}
                                    </option>
                                ))}
                                </TextField>
                            </div>
                            <div style={{ marginLeft: 10,  width: 180 }}>
                                <TextField
                                id="select-currency-native"
                                fullWidth
                                select
                                label="Expected Payment Type"                         
                                onChange={(e) => setPaymentType(e.target.value)}
                                value={paymentType}
                                SelectProps={{
                                    native: true,
                                }}
                                margin="normal"
                                >
                                {PaymentTypes.map(option => (
                                    <option key={option.value} value={option.value}>
                                    {option.label}
                                    </option>
                                ))}
                                </TextField>
                            </div>
                            <div style={{ marginLeft: 10,  width: 220 }}>
                                <TextField
                                fullWidth
                                id="select-currency-native"
                                select
                                label="Expected Delivery Type"                         
                                onChange={(e) => setDeliveryType(e.target.value)}
                                value={deliveryType}
                                SelectProps={{
                                    native: true,
                                }}
                                margin="normal"
                                >
                                {DeliveryTypes.map(option => (
                                    <option key={option.value} value={option.value}>
                                    {option.label}
                                    </option>
                                ))}
                                </TextField>
                            </div>
                        </div>

                       <div style={{  marginLeft: 10 }}>
                            
                            <TextField
                                id="discountPercent"
                                label="Discount %"
                                value={discountPercent}
                                onChange={(e) => setDiscountPercent(e.target.value)}
                                margin="normal"
                                onKeyDown={onKeyDown}
                                style={{ width: 100 }}
                            />

                            <TextField
                                id="shippingAmount"
                                label="Shipping Amount"
                                value={shippingAmount}
                                onChange={(e) => setShippingAmount(e.target.value)}
                                margin="normal"
                                onKeyDown={onKeyDown}
                                style={{ marginLeft: 20, width: 150 }}
                            />
                            <TextField
                                id="invoiceDiscount"
                                label="Bill Discount"
                                value={invoiceDiscount}
                                onChange={(e) => setInvoiceDiscount(e.target.value)}
                                style={{ marginLeft: 20 }}
                                margin="normal"
                                onKeyDown={onKeyDown}
                                style={{ marginLeft: 20, width: 150 }}
                            />

                            <TextField
                                id="otherCharges"
                                label="Other Charges"
                                value={otherCharges}
                                onChange={(e) => setOtherCharges(e.target.value)}
                                style={{ marginLeft: 20 }}
                                margin="normal"
                                onKeyDown={onKeyDown}
                                style={{ marginLeft: 20, width: 150 }}
                            />

                        </div>
                        <div style={{marginLeft: 10, marginRight: 10}}>
                        <TextField
                            id="comment"
                            label="Notes"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            margin="normal"
                            style={{ width: "100%" }}
                            multiline={true}
                            rows={5}
                            rowsMax={100}
                            />
                        </div>
                      </Paper>
                      <Paper>
                            <CartArea disabled={ quoteStatus === 1 } cartItems={cartItems} hideISBN={true} hideSelect={true}
                            onCartStateChange={onCartStateChange} 
                            discountPercent={discountPercent}
                            shippingAmount={shippingAmount}
                            invoiceDiscount={invoiceDiscount}
                            otherCharges={otherCharges} />
                      </Paper>
                    </Grid>

                    <Grid item xs={3}>
                        <Paper>
                            
                            <div style={{ paddingTop: 10, paddingBottom: 20}}>
                            {customer ? 
                            <Fragment>
                                <div style={{marginLeft: 10}}> 
                                    <div><b>Customer Details</b></div>
                                    <div style={{marginTop: 7}}><b>{customer.name}</b></div>
                                    <div style={{marginTop: 5}}>{customer.mobileNumber}</div>
                                    <div style={{marginTop: 5}}>{customer.email}</div>
                                </div>
                                <CustomerAddress customer={customer} address={address} onChange={onAddressChange} onLoad={setAddress} />
                                </Fragment>
                                : null }
                                <div style={{marginLeft: 10}}> 
                                    <div style={{paddingTop: 5}}><b>Quote Details</b></div>
                                    <div style={{marginTop: 5}}>Total Items: {cartState.totalItems} &nbsp;&nbsp;&nbsp;<span>Total Qty: {cartState.totalQty}</span></div>
                                    <div style={{marginTop: 5}}>Sub Total: ₹ {cartState.subTotal} </div>
                                    <div style={{marginTop: 5}}>Item Discount: -₹{cartState.totalDiscount} </div>
                                    <div style={{marginTop: 5}}>Bill Discount: -₹{cartState.invoiceDiscount} </div>
                                    <div style={{marginTop: 5}}>Other Charges: +₹{cartState.otherCharges} </div>
                                    <div style={{marginTop: 5}}>Shipping Amt: +₹{cartState.shippingAmount} </div>
                                    <div style={{marginTop: 5}}><b>Total Amt: ₹{cartState.totalAmount}</b></div>
                                </div>
                                <div style={{marginLeft: 10}}> 
                                    <Button disabled={submitClicked || !_validate() } style={{ marginTop: 10, marginBottom: 10 }} onClick={ () => handleSubmit(false)} variant="contained" color="primary">SAVE</Button>
                                    <Button disabled={submitClicked || !validate() } style={{ marginTop: 10, marginLeft: 10, marginBottom: 10, backgroundColor:'maroon' }}
                                    onClick={() =>
                                        window.confirm("Are you sure you want to suspend?") &&
                                        handleSubmit(true)
                                    } variant="contained" color="primary">SUSPEND</Button>
                                    {/* <Button disabled={submitClicked || !validate() } style={{ marginTop: 10, marginLeft: 10, marginBottom: 10 }} onClick={handleSubmit}  variant="contained" color="primary">PAID</Button> */}
                                </div>
                                <div> 
                                    <QuoteToOrderButton disabled={submitClicked || !validate() } totalAmount={cartState.totalAmount} paid={true} label="PAID" onSubmit={handleOrderCreate}/>
                                    <QuoteToOrderButton disabled={submitClicked || !validate() } paid={false} label="CREDIT" onSubmit={handleOrderCreate} />
                                    {/* <Button disabled={submitClicked || !validate() } style={{ marginTop: 10, marginLeft: 10, marginBottom: 10 }} onClick={handleSubmit}  variant="contained" color="primary">CREDIT</Button> */}
                                </div>
                            </div> 
                            
                        </Paper>
                    </Grid>
                    
                </Grid>
            </div>
    )
}

export default withDataProvider(QuoteCreate)