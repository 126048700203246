// hooks to maintain state
// submit using dataProvider
//

import React, { useState, Fragment, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import {
    showNotification,
    withDataProvider,
    CREATE,
    UPDATE,
    GET_ONE,
} from 'react-admin';

import Button from '@material-ui/core/Button';


const HandOverItemsButton = (props) => {

    const { selectedIds } = props

    const [showDialog, setShowDialog] = useState(false)
    const [submitClicked, setSubmitClicked] = useState(false)

    const [isLoading, setIsLoading] = useState(false);

    // const resetState = () => {
    //     setSelectedItems([])
    // }

    const handleSubmit = () => {
        if (!submitClicked) {
            setSubmitClicked(true)
            const {
                dispatch,
                dataProvider,
            } = props;
    
            let fulfillmentIds = []
            selectedIds.forEach(function (fulfillmentId) {
                fulfillmentIds.push(fulfillmentId)
            });
        
            // console.log(fulfillmentIds)
            dataProvider(CREATE, 'order/shipping/handover/id', { data: { fulfillmentIds: fulfillmentIds } })
            .then((json) => {
                dispatch(showNotification('Items handed over')) 
                setSubmitClicked(false)
                setShowDialog(false)
                window.location.reload(true);
            })
            .catch((e) => {
                setSubmitClicked(false)
                dispatch(showNotification('Error: Something went wrong', 'warning'))
            });
            };
    }

    return (
        <Fragment>
                <Button variant="contained" color="primary" onClick={() => setShowDialog(true)}> HANDOVER ITEMS </Button>

                <Dialog maxWidth="md" open={showDialog} onClose={() => { setShowDialog(false); }} aria-label="Quick Create Item">
                    { isLoading ? <h2>Loading..</h2> : 
                    <Fragment>
                    <DialogTitle style={{ marginTop: -15}}>Handover Packed Items to Shipping Partner </DialogTitle>
                    <DialogContent style={{marginTop: -15}}>
                        
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="primary" onClick={handleSubmit}> SUBMIT </Button>
                        <Button variant="contained" onClick={() => { setShowDialog(false); }}> CANCEL </Button>
                    </DialogActions>
                    </Fragment>
                    }
                </Dialog>
            </Fragment>
    )

}

export default withDataProvider(HandOverItemsButton)