import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { change, reset, submit, isSubmitting } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import {
    showNotification,
    Button,
    SaveButton,
    SimpleForm,
    TextInput,
    UPDATE,
    withDataProvider,
    refreshView

} from 'react-admin';
 
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';

const styles = {
    drawerContent: {
        width: 450
    }, 
    left: { display: 'inline-block' },
    right: { display: 'inline-block', marginLeft: 32 },
    // name: { display: 'inline-block' },
    // englishName: { display: 'inline-block', marginLeft: 32 },
    // manufactuerName: { display: 'inline-block', marginLeft: 32 },
};


class UpdateShelfButton extends Component {
    state = {
        error: false,
        showDialog: false, 
        submitClicked: false
    };

    handleClick = (event) => {
        event.preventDefault()
        this.setState({ showDialog: true });
    };

    handleCloseClick = () => {
        this.setState({ showDialog: false });
    };

    handleSaveClick = () => {
        const { submit } = this.props;

        // Trigger a submit of our custom quick create form
        // This is needed because our modal action buttons are oustide the form
        submit('post-shelfcode-update');
    };

    handleSubmit = values => {
        if (!this.state.submitClicked) {
            this.setState({ submitClicked: true });
            const {
                showNotification,
                dispatch,
                dataProvider,
                record
            } = this.props;
            var shelfCodeItem = {
                id: record.id,
                shelfCodes: values.shelfCodes
            }
            var payload = { id: record.id, data: shelfCodeItem }
            dataProvider(UPDATE, 'v2/catalog/item/shelfCodes', payload)
            .then((data) => {
                dispatch(showNotification('Shelf code updated for Item id:' + record.id)) 
                this.setState({ showDialog: false, submitClicked: false });
                dispatch(reset('post-shelfcode-update'));
                dispatch(refreshView())
            })
            .catch((e) => {
                this.setState({ submitClicked: false });
                dispatch(showNotification('Error: Something went wrong', 'warning'))
            });
        }
    };


    render() {
        const { showDialog } = this.state;
        const { isSubmitting, record } = this.props;
        return (
            <Fragment>
                {/* <Button size="small" variant="text" onClick={this.handleClick} label="UPDATE SHELF">
                </Button> */}
                <div style={{ marginBottom: 3}}>{record.shelfCodes}</div>
                <a href="#" onClick={this.handleClick}>
                    Update
                </a>

                <Dialog open={showDialog} onClose={this.handleCloseClick} aria-label="Create Item">
                    <DialogContent>
                         <SimpleForm form="post-shelfcode-update"  onSubmit={this.handleSubmit} toolbar={null}>
                            <TextInput helperText="Eg:A11,A12 (comma separated, no spaces)" label="Shelf Codes" source="shelfCodes" defaultValue={record.shelfCodes} style={{ width: 200 }}  />
                        </SimpleForm>
                    </DialogContent>
                    <DialogActions>
                        <SaveButton saving={isSubmitting} onClick={this.handleSaveClick}/>
                        <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
                            <IconCancel />
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    isSubmitting: isSubmitting('post-shelfcode-update')(state)
});

const mapDispatchToProps = {
    change,
    showNotification,
    submit,
    refreshView
};

UpdateShelfButton.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  
  
const enhance = compose(
    withDataProvider,
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
  );
  
  
export default enhance(UpdateShelfButton);
