import {
    ADD_ITEM_CART,
    UPDATE_ITEM_CART,
    REMOVE_ITEM_CART,
    REMOVE_ALLITEMS_CART,
    UPDATE_SHIPPING_AMOUNT,
    UPDATE_OTHER_DISCOUNT,
    INIT_CART,
  } from "./cart.types";
  
  export const updateShippingAmount = data => ({
    type: UPDATE_SHIPPING_AMOUNT,
    data
  });

  export const updateOtherDiscount = data => ({
    type: UPDATE_OTHER_DISCOUNT,
    data
  });

  export const addItemCart = data => ({
    type: ADD_ITEM_CART,
    data
  });
  
  export const updateItemCart = data => ({
    type: UPDATE_ITEM_CART,
    data
  });
  
  export const removeItemCart = data => ({
    type: REMOVE_ITEM_CART,
    data
  });
  
  export const removeAllItemsCart = data => ({
    type: REMOVE_ALLITEMS_CART
  });
  
  export const initCart = data => ({
    type: INIT_CART,
    data
  });
