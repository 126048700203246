
import React, { useState, useEffect, Fragment } from 'react';
import TextField from '@material-ui/core/TextField';
import {
    showNotification,
    Button,
    withDataProvider,
    CREATE,
    UPDATE,
    GET_ONE
} from 'react-admin';
import AddressCreateEditButton from './AddressCreateEditButton'



function CustomerAddress(props) {
    const { customer, onChange, onLoad, address  } = props
    
    const [id, setId] = useState(null)
    const [name, setName] = useState('')
    const [address1, setAddress1] = useState('')
    const [address2, setAddress2] = useState('')
    const [address3, setAddress3] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [pincode, setPincode] = useState('')
    const [mobileNumber, setMobileNumber] = useState('')
    const [country, setCountry] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const setData = (address) => {
        if (address) {
            setId(address.id)
            setName(address.name)
            setAddress1(address.address1)
            setAddress2(address.address2)
            setAddress3(address.address3)
            setCity(address.city)
            setPincode(address.pincode)
            setState(address.state)
            setCountry(address.country)
            setMobileNumber(address.mobileNumber)
        }

        //onChange(address)
    }

    const getAddress = () => {
        const address = {
                id : id,
                name: name,
                address1: address1,
                address2: address2,
                address3: address3,
                city: city,
                pincode: pincode,
                state: state,
                country: country,
                mobileNumber: mobileNumber
        }
        return address
    }

    const resetState = () => {
        setId(null);
        setName('')
        setAddress1('')
        setAddress2('')
        setAddress3('')
        setCity('')
        setPincode('')
        setState('')
        setCountry('')
        setMobileNumber('')
    }

    useEffect(() => {
        const fetchData = async () => {
            setIsError(false);
            setIsLoading(true);

            var params = { id: customer.defaultAddressId }

            const {
                dispatch,
                dataProvider
            } = props;

            dataProvider(GET_ONE, 'sale/customer/'+ customer.id + '/address', params)
                .then((json) => {
                    console.log(json)
                    setData(json.data);
                    onLoad(json.data)
                    setIsLoading(false);
                })
                .catch((e) => {
                    setIsError(true);
                    dispatch(showNotification('Error: Something went wrong', 'warning'))
                    setIsLoading(false);
                });
        };
        
        if (address != null) {
            setData(address)
        } else if (customer.defaultAddressId && customer.defaultAddressId != 0) {
            fetchData()
        } else {
            resetState()
        }

    }, [customer, address]);
    return (
        <Fragment>
            <div style={{marginTop: 15, marginLeft: 10}}>
                <div><b>Address</b> <AddressCreateEditButton customer={customer} onCreateEdit={onChange} address={getAddress()} /> </div>
                <div style={{marginTop: 7}}>{name}</div>
                <div style={{marginTop: 3}}>{address1}</div>  
                <div style={{marginTop: 3}}>{address2}</div>
                <div style={{marginTop: 3}}>{address3}</div>
                <div style={{marginTop: 3}}>{city} {pincode}</div>
                <div style={{marginTop: 3}}>{state} {country}</div>
                <div style={{marginTop: 3}}>{mobileNumber}</div>
            </div>
        </Fragment>
    )
}

export default withDataProvider(CustomerAddress)