import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    NumberField,
    Filter,
    DateField,
    SearchInput,SelectInput,ReferenceInput,AutocompleteInput,
    CardActions} from 'react-admin';
import PendingPurchaseItemsExportButton from './PendingPurchaseItemsExportButton'

import CurrencyField from '../../common/CurrencyField';


const CustomerListActions = ({
    bulkActions,
    basePath,
    displayedFilters,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter}) => (
    <CardActions>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        }) }
        
        <PendingPurchaseItemsExportButton filterValues={filterValues} />

    </CardActions>
);



const PendingPurchaseFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <ReferenceInput alwaysOn source="manufacturerId" reference="v2/catalog/manufacturer">
            <AutocompleteInput
                optionText={choice =>
                    `${choice.name}`
                }
            />
        </ReferenceInput>
        <SelectInput allowEmpty={true} label="Order Status" source="fulfillmentStatus" alwaysOn choices={[
            { id: 'UNFUFILLED',  name: 'New' },
            { id: 'PARTIALLY_FUFILLED',  name: 'Partially Fuflfilled' }
        ]} />

        <SelectInput allowEmpty={true} label="Date Range" source="dateRange" alwaysOn choices={[
            { id: 'PAST_7_DAYS',  name: 'Past 7 days' },
            { id: 'PAST_30_DAYS',  name: 'Past 30 days' },
            { id: 'PAST_90_DAYS',  name: 'Past 90 days' },
            { id: 'BEFORE_90_DAYS',  name: 'Before 90 days' },
        ]} />


        <SelectInput allowEmpty={true} label="Stock Status" source="stockStatus" alwaysOn choices={[
            { id: 'IN_STOCK',  name: 'In Stock' },
            { id: 'PARTIAL_STOCK',  name: 'Partial Stock' },
            { id: 'OUT_OF_STOCK',  name: 'Out of Stock' },
        ]} />

        <SelectInput source="singleItemOrder" label="Order Items" alwaysOn choices={[
            { id: 'true',   name: 'One Item Order' },
            { id: 'false',  name: 'One Or More Items' },]
        } />
    </Filter>
);

const PendingPurchaseList = props => (
    <List filters={<PendingPurchaseFilter />}  exporter={false}  {...props}  
    perPage={25}
    sort={{ field: 'lastOrderDate', order: 'DESC' }}
    filterDefaultValues={{ dateRange: "PAST_90_DAYS" }}
    actions={<CustomerListActions />}
    //aside={<Aside data={props} />}
    >
        <Datagrid>
            <TextField source="id" label="Item Id" sortable={false} />
            <DateField source="lastOrderDate" label="Last Order Date" />
            <TextField source="lastOrderId" label="Last Order Id#" />
            <TextField source="itemName" label="Name" sortable={false} />
            <TextField source="manufacturerName" label="Publisher" />
            <CurrencyField source="price" label="Price" sortable={false} />
            <NumberField source="totalOrders" label="Total Orders" sortable={false}  />
            <NumberField source="stockQty" label="StockQty" sortable={false}  />
            <NumberField source="orderQty" label="orderQty" sortable={false} />
            <NumberField source="pendingQty" label="Pending Qty" sortable={false}  />
        </Datagrid>
    </List>
);

export default PendingPurchaseList;
