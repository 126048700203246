import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';

const OrderStockStatusField = ({ source, record = {} }) => {
    let inStockQty = record['inStockQty']
    let unFulfilledQty = record['unFulfilledQty']
    let color = ""
    let text = ""
    if (unFulfilledQty === 0) {
        color = "SlateGray"
        text = "All Fulfilled"
    }
    else if (inStockQty === 0) {
        color = "Tomato"
        text = "No Stock"
    }   
    else if (unFulfilledQty === inStockQty) {
        color = "LimeGreen"
        text = "All in Stock"
    }
    else if (inStockQty < unFulfilledQty) {
        color = "Orange"
        text = "Partial Stock"
    }

    return (<span><Chip label={ text + " - " + inStockQty + "/" + unFulfilledQty} style={{ backgroundColor: color, color: "Navy" }} /></span>)
}

OrderStockStatusField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default OrderStockStatusField;
