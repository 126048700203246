// hooks to maintain state
// submit using dataProvider
//

import React, { useState, Fragment, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import {
    showNotification,
    withDataProvider,
    CREATE,
    UPDATE,
    GET_ONE,
} from 'react-admin';
import currency from "currency.js";
import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField';
import { } from './SupplierOrderTypes'

import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';

import {SupplierOrderSettlementType, SupplierOrderDeliveryType, SupplierOrderPaymentType, ResolveItemStatus} from './SupplierOrderTypes'

const CreateSupplierBillButton = (props) => {

    const { label, disabled, onSubmit, cartState, supplier, orderDate, receiveItems, _settlementType, _deliveryType } = props
    const [showDialog, setShowDialog] = useState(false)
    const [showBillDialog, setShowBillDialog] = useState(false)
    const [showResolveDialog, setShowResolveDialog] = useState(false)
    const [submitClicked, setSubmitClicked] = useState(false)
    const [billDate, setBillDate] = useState(new Date(orderDate).toISOString().substring(0, 10))
    const [billNumber, setBillNumber] = useState('')
    const [billAmount, setBillAmount] = useState('')
    const [missingItems, setMissingItems] = useState([])

    const [deliveryType, setDeliveryType] = useState('SELF_PICKUP')
    const [settlementType, setSettlementType] = useState('IMMEDIATE')

    const defaultPayment = {
        paidDate: new Date(),
        paymentType: 'CASH',
        type: 'SALE',
        paymentAmount: cartState.totalAmount,
        paymentMessage: '',
    }

    const [billPayments, setBillPayments] = useState([defaultPayment])


    const resetState = () => {
        setDeliveryType('SELF_PICKUP')
        setSettlementType('IMMEDIATE')
        setBillPayments([defaultPayment])
        setShowBillDialog(false); 
        setShowResolveDialog(false);
        setShowDialog(false)
    }
    
    useEffect(() => {
        if (showDialog) {
            let missingItems = [];
            receiveItems.forEach(orderItem => {
                let foundFlag = false
                cartState.cartItems.forEach(cartItem => {
                    if (cartItem.itemId === orderItem.itemId) {
                        foundFlag = true
                    }
                })
                if (!foundFlag) {
                    orderItem.resolveStatus = 'EMPTY'
                    missingItems.push(orderItem)
                }
            })
            setMissingItems(missingItems)
            if (missingItems.length === 0) {
                setShowBillDialog(true)
            } else {
                setShowResolveDialog(true)
            }
            setShowDialog(false)
        }
    }, [showDialog])


    const changeResolveStatus = (itemId, resolveStatus) => {
        const _missingItems = [...missingItems]

        _missingItems.forEach(item => {
            if(item.itemId === itemId) {
                item.resolveStatus = resolveStatus
            }
        })

        setMissingItems(_missingItems);

    }
    const resolveValidate = () => {
        let noEmptyFlag = true
        missingItems.forEach(item => {
            if(item.resolveStatus === 'EMPTY') {
                noEmptyFlag = false
            }
        })

        return noEmptyFlag 
    }

    useEffect(() => {
        const defaultPayment = {
            paidDate: new Date(),
            paymentType: 'CASH',
            type: 'SALE',
            paymentAmount: cartState.totalAmount,
            paymentMessage: '',
        }
        setBillPayments([defaultPayment])
        
    }, [cartState])

    useEffect(() => {
        setBillDate(new Date(orderDate).toISOString().substring(0, 10))
    }, [orderDate])

    // useEffect(() => {
    //     setSettlementType(_settlementType)
    // }, [_settlementType])


    // useEffect(() => {
    //     setDeliveryType(_deliveryType)
    // }, [_deliveryType])



    const handleSubmit = () => {

        if (!submitClicked) {
            setSubmitClicked(true)
            const billDetail = {
                billDate: billDate,
                billNumber: billNumber,
                billAmount: currency(billAmount).value, 
                settlementType: settlementType,
                deliveryType: deliveryType,
                billPayments: billPayments,
                missingItems: missingItems
            }
            onSubmit(billDetail, setSubmitClicked)
        }
       
    }
    const strlength = (string) => {
        if (string != null) {
            return string.length
        } else {
            return 0
        }
    }

 
    const addPayment = () => {  
        const total = billPayments.reduce((total, payment) => {
            return currency(total).add(payment.paymentAmount);
          }, 0)
        const pendingAmount = currency(cartState.totalAmount).subtract(total).value;
        const newPayment = {
            paidDate: new Date(),
            type: 'SALE',
            paymentType: 'CASH',
            paymentAmount: pendingAmount,
            paymentMessage: '',
        }
        let newArr = [...billPayments, newPayment ]; // copying the old datas array    
        setBillPayments(newArr); // ??
    }

    const removePayment = (index) => {  
        let newArr = [...billPayments ]; // copying the old datas array   
        newArr.splice(index, index) 
        setBillPayments(newArr); // ??
    }


    const changeInPayment = (index, field, value) => {
        // console.log('index: ' + index);
        // console.log('field: ' + field)
        // console.log('value: ' + value)
        let newArr = [...billPayments]; // copying the old datas array
        newArr[index][field] = value; // replace e.target.value with whatever you want to change it to
        setBillPayments(newArr); // ??
    }

    const validate = () => {
        return ( strlength(billNumber) > 0 && strlength(billAmount) > 0) 
    }

    return (
        <Fragment>

            <Button disabled={disabled} style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }} variant="contained" color="primary" 
            onClick={(event) => {  event.preventDefault(); setShowDialog(true)  }}>
            {label}
            </Button>

            <Dialog maxWidth="md" open={showResolveDialog} onClose={() => { resetState() }} aria-label="Resolve missing items">
                    <Fragment>
                        <DialogTitle>Missing items from supplier order </DialogTitle>
                            <DialogContent>
                            <Table padding="dense" >
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="right">ItemId</TableCell> 
                                        <TableCell align="right">Name</TableCell>
                                        <TableCell align="right">Publisher</TableCell>
                                        <TableCell align="right">Price</TableCell>
                                        <TableCell align="right">Resolve Status</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {missingItems.map(item => (
                                    <TableRow key={"i_" + item.itemId}>
                                        <TableCell align="right">{item.itemId} </TableCell>
                                        <TableCell align="right">{item.name} </TableCell>
                                        <TableCell  align="right">{item.manufacturerName}</TableCell>
                                        <TableCell align="right">₹{item.price} </TableCell>
                                        <TableCell style={{width: 200}} align="right">
                                            <TextField
                                            id="settlementType"
                                            select
                                            label="Resolve Status"                         
                                            onChange={(e) => changeResolveStatus(item.itemId, e.target.value)}
                                            value={item.resolveStatus}
                                            SelectProps={{
                                                native: true,
                                            }}
                                            margin="normal"
                                            error={  item.resolveStatus == 'EMPTY'}
                                            helperText={ item.resolveStatus == 'EMPTY' ? "Please select" : ""}
                                            >
                                            {ResolveItemStatus.map(option => (
                                                <option key={option.id} value={option.id}>
                                                {option.name}
                                                </option>
                                            ))}
                                            </TextField>         
                                        </TableCell>
                                    </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            </DialogContent>
                            <DialogActions>
                                <Button variant="contained" color="primary" disabled={submitClicked || !resolveValidate()} onClick={() =>  { setShowResolveDialog(false); setShowBillDialog(true); }}> RESOLVE ITEMS </Button>
                                <Button variant="contained" onClick={() => { resetState() }}> CANCEL </Button>
                            </DialogActions>
                    </Fragment>
            </Dialog>

            <Dialog fullWidth open={showBillDialog} onClose={() => { resetState() }} aria-label="Create Supplier Bill">
                    <Fragment>
                            <DialogTitle>Supplier Bill for {supplier ? supplier.name : null} </DialogTitle>
                            <DialogContent>
                            <div>
                                  {/* <div style={{marginTop: 0}}>Total Items: {cartState.totalItems} &nbsp;&nbsp;|&nbsp;&nbsp;<span>Total Qty: {cartState.totalQty}</span></div> */}
                                  {/* <div style={{marginTop: 10}}>Sub Total: ₹ {cartState.subTotal}  &nbsp;&nbsp;|&nbsp;&nbsp; Discount: ₹ {cartState.totalDiscount} </div> */}
                                  <div style={{marginTop: 0, fontSize: 18, fontWeight: 700}}>Total Amount: ₹ {cartState.totalAmount} </div>
                            </div>

                            <div style={{ display: 'inline-flex' }}>
                                <div style={{ marginTop: 16 }}>
                                    <TextField
                                        id="billDate"
                                        label="Bill Date"
                                        type="date"
                                        onChange={(e) => setBillDate(e.target.value)}
                                        value={billDate}
                                        InputLabelProps={{
                                        shrink: true,
                                        }}
                                    />
                                </div>
                                <div style={{ marginLeft: 10  }}>
                                    <TextField
                                    required
                                    id="billNumber"
                                    label="Bill No"
                                    value={billNumber}
                                    onChange={(e) => setBillNumber(e.target.value)}
                                    margin="normal"
                                    error={ strlength(billNumber) === 0}
                                    helperText={ strlength(billNumber) === 0 ? "Can't be empty" : ""}
                                    />
                                </div>
                                <div style={{ marginLeft: 10 }}>
                                    <TextField
                                    required
                                    id="billAmount"
                                    label="Bill Amount"
                                    value={billAmount}
                                    onChange={(e) => setBillAmount(e.target.value)}
                                    error={ strlength(billAmount) === 0}
                                    helperText={ strlength(billAmount) === 0 ? "Can't be empty" : ""}
                                    margin="normal"
                                    />
                                </div>
                            </div>
                            <div style={{ marginTop: 7, display: 'inline-flex' }}>
                                <div style={{  width: 200 }}>
                                    <TextField
                                    id="settlementType"
                                    fullWidth
                                    select
                                    label="Settlement Type"                         
                                    onChange={(e) => setSettlementType(e.target.value)}
                                    value={settlementType}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    margin="normal"
                                    >
                                    {SupplierOrderSettlementType.map(option => (
                                        <option key={option.id} value={option.id}>
                                        {option.name}
                                        </option>
                                    ))}
                                    </TextField>
                                </div>
                                <div style={{ marginLeft: 10,  width: 200 }}>
                                    <TextField
                                    id="deliveryType"
                                    fullWidth
                                    select
                                    label="Delivery Type"                         
                                    onChange={(e) => setDeliveryType(e.target.value)}
                                    value={deliveryType}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    margin="normal"
                                    >
                                    {SupplierOrderDeliveryType.map(option => (
                                        <option key={option.id} value={option.id}>
                                        {option.name}
                                        </option>
                                    ))}
                                    </TextField>
                                </div>
                            </div>
                            
                            { settlementType === 'IMMEDIATE' ? 
                            <Fragment>
                                { billPayments.map( (data, index) => (
                                    <div key="{index}" style={{ marginTop: 7, display: 'inline-flex' }}>
                                        <div style={{  width: 150 }}>
                                            <TextField
                                            id="paymentType"
                                            fullWidth
                                            select
                                            label="Payment Type"                         
                                            onChange={(e) => changeInPayment(index, 'paymentType', e.target.value)}
                                            value={data.paymentType}
                                            SelectProps={{
                                                native: true,
                                            }}
                                            margin="normal"
                                            >
                                            {SupplierOrderPaymentType.map(option => (
                                                <option key={option.id} value={option.id}>
                                                {option.name}
                                                </option>
                                            ))}
                                            </TextField>
                                        </div>
                                        <div style={{ marginLeft: 10,  width: 100 }}>
                                            <TextField
                                                fullWidth
                                                required
                                                id="paymentAmount"
                                                label="Amount"
                                                value={data.paymentAmount}
                                                onChange={(e) => changeInPayment(index, 'paymentAmount', currency(e.target.value).value)}
                                                margin="normal"
                                                error={ strlength(data.paymentAmount) === 0}
                                                helperText={ strlength(data.paymentAmount) === 0 ? "Can't be empty" : ""}
                                                // onKeyDown={onKeyDown}
                                            />
                                        </div> 
                                        <div style={{ marginLeft: 20,  width: 200 }}>
                                            <TextField
                                                fullWidth
                                                id="paymentMessage"
                                                label="Payment Message"
                                                value={data.paymentMessage}
                                                onChange={(e) => changeInPayment(index, 'paymentMessage', e.target.value)}
                                                margin="normal"
                                                // onKeyDown={onKeyDown}
                                            />
                                        </div>
                                        <div style={{ marginLeft: 20, marginTop: 27 }}>
                                            { index === 0 ? <Button variant="contained" color="primary" onClick={addPayment}> + </Button> : 
                                            <Button variant="contained" color="primary" onClick={() => removePayment(index)}> - </Button> }
                                        </div>
                                    </div>))}
                            </Fragment>
                                : null }
                            </DialogContent>
                            <DialogActions>
                                <Button variant="contained" color="primary" disabled={submitClicked || !validate()} onClick={() =>  handleSubmit()}> CREATE SUPPLIER BILL </Button>
                                <Button variant="contained" onClick={() => { resetState() }}> CANCEL </Button>
                            </DialogActions>
                    </Fragment>
            </Dialog>
        </Fragment>
    )

}

export default CreateSupplierBillButton