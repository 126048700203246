import ManifestIcon from '@material-ui/icons/LocalShipping';
import ManifestList from './ManifestList';
import ManifestCreate from './ManifestCreate';
// import QuoteShow from './ReceiptShow';
// import QuoteEdit from './ReceiptEdit';


export default {
    edit: ManifestCreate,
    list: ManifestList,
    create: ManifestCreate,
    // show: QuoteShow,
    icon: ManifestIcon,
    name: "order/shipping-manifests"
};
