import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    NumberField,
    BooleanField,
    Filter,
    BooleanInput,   
    EditButton,
    SearchInput,
    DateInput,
    SelectInput,
    RadioButtonGroupInput
    
} from 'react-admin';

const CreateDateField = ({ record = {} }) => <span>{record.createDate}</span>;
CreateDateField.defaultProps = { label: 'Create Date' };

function getStatus(param) {
    switch(param) {
      case 1:
        return 'Complete';
      case 2:
        return 'Suspended';
      default:
        return 'Unkonwn';
    }
  }

  const getLocation = () => {
    const initialValue = 1
    try {
        // Get from local storage by key
        const item = window.localStorage.getItem('locationId');
        // Parse stored json or if none return initialValue
        return item ? JSON.parse(item) : initialValue;
      } catch (error) {
        // If error also return initialValue
        console.log(error);
        return initialValue;
      }
}



const StatusField = ({ record = {} }) => <span>{getStatus(record.status)}</span>;
StatusField.defaultProps = { label: 'Status' };

const ReceiptFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <DateInput source="receiptDate" alwaysOn />
        <SelectInput source="status" alwaysOn choices={[
        { id: 'COMPLETE',        name: 'Complete' },
        { id: 'SUSPENDED',      name: 'Suspended' },    ]
        } />
        {/* <SelectInput allowEmpty={false} label="Location" source="locationId" alwaysOn choices={[
        { id: '1',  name: 'SHOP' },
        // { id: '2',  name: 'BOOKFAIR' },
      ]
        } /> */}
        <SelectInput source="salesPaymentType" alwaysOn choices={[
        { id: 'CASH',        name: 'Cash' },
        { id: 'CARD',        name: 'Card' },
        { id: 'BANK_TRANSFER', name: 'Bank Transfer' },
        { id: 'PAYTM',       name: 'Paytm' },
        { id: 'PAYUBIZ',     name: 'PayuBiz' },
        { id: 'CHECK',       name: 'Check' },
        { id: 'CREDIT',      name: 'Credit' },
        { id: 'VPP',         name: 'VPP' },
        { id: 'MONEY_ORDER', name: 'Money Order' },
        { id: 'GIVE_AWAY',   name: 'Gift' },
        { id: 'AMAZON',      name: 'Amazon' },
        { id: 'FLIPKART',    name: 'Flipkart' },
        { id: 'OTHERS',      name: 'Others' },
        ]
        } />
        <SelectInput source="salesChannelType" alwaysOn choices={ [
        { id: 'SHOP',        name: 'Shop' },
        { id: 'PANUVAL_COM', name: 'Panuval.Com' },
        { id: 'PHONE',       name: 'Phone' },
        { id: 'WHATSAPP',    name: 'WhatsApp' },
        { id: 'AMAZON',      name: 'Amazon' },
        { id: 'REQUEST_PORTAL', name: 'Request Portal' },
        { id: 'REFERRAL',    name: 'Referral' },
        { id: 'FLIPKART',    name: 'Flipkart' },
        { id: 'SOCIAL_MEDIA',name: 'Social Media' },
        { id: 'ANDROID_APP', name: 'Android App' },
        { id: 'IOS_APP',     name: 'iOS App' },
        { id: 'BOOK_FAIR',   name: 'Book Fair' },
        { id: 'OTHERS',      name: 'Others' },
        ]
        } />

    </Filter>
);

const ReceiptList = props => (
    <List filters={<ReceiptFilter />}  exporter={false}  {...props}  filterDefaultValues={{ locationId: getLocation() }} perPage={25}>
        <Datagrid rowClick="show" >
            <TextField source="id" />
            <DateField source="createDate" showTime />
            <DateField source="receiptDate"/>
            <TextField source="name" />
            <TextField source="mobileNumber" />
            <TextField source="salesChannelType" />
            <TextField source="salesPaymentType" />
            <StatusField />
            <NumberField source="totalAmount" />
            <BooleanField source="paid" />
            <TextField label="Order #" source="invoiceNumber" />
            <EditButton />
        </Datagrid>
    </List>
);

export default ReceiptList;
