import ItemIcon from '@material-ui/icons/Collections';
import ShopToOfficeList from './ShopToOfficeList';
import ShopToOfficeCreate from './ShopToOfficeCreate';
import ShopToOfficeShow from './ShopToOfficeShow';


export default {
    list: ShopToOfficeList,
    create: ShopToOfficeCreate,
    show: ShopToOfficeShow,
    icon: ItemIcon,
    name: "inventory/transfer/shop-to-office"
};
