import React, { Component} from 'react';
import { crudCreate, SaveButton } from 'react-admin';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';

const myCrudCreateRefresh = (resource, data, basePath, redirectTo) => {
    const action = crudCreate(resource, data, basePath, redirectTo);
    action.meta.refresh = true;

    return action;
}

const saveWithRefresh = (resource, values, basePath, redirectTo) =>
    myCrudCreateRefresh(resource, { ...values}, basePath, redirectTo);

class SaveWithRefreshButtonComponent extends Component {
    handleClick = () => {
        const { resource, basePath, handleSubmit, redirect, saveWithRefresh } = this.props;

        return handleSubmit(values => {
            saveWithRefresh(resource, values, basePath, redirect);
        });
    };

    render() {
        const { handleSubmitWithRedirect, saveWithRefresh, ...props } = this.props;

        return (
            <SaveButton
                handleSubmitWithRedirect={this.handleClick}
                {...props}
            />
        );
    }
}


export default connect(
    undefined,
    { saveWithRefresh }
)(SaveWithRefreshButtonComponent);
