// hooks to maintain state
// submit using dataProvider
//

import React, { useState, Fragment, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import {
    showNotification,
    Button,
    withDataProvider,
    CREATE,
    UPDATE,
    GET_ONE
} from 'react-admin';

import TextField from '@material-ui/core/TextField';
import MultiAutoSuggest from './MultiAutoSuggest'
import { ItemAvailabilityTypes } from './ItemTypes'
import { ItemStockSyncStatusTypes } from './ItemStockSyncStatusTypes';

const ItemQuickCreateEditButton = (props) => {

    const { itemId, onCreate, onUpdate } = props

    const [item, setItem] = useState({});

    const [manufacturer, setManufacturer] = useState({});
    const [itemName, setItemName] = useState('');
    const [englishName, setEnglishName] = useState('');
    const [isbn, setIsbn] = useState('');
    const [price, setPrice] = useState('');
    const [itemAvailability, setItemAvailability] = useState('AVAILABLE');
    const [stockSyncStatus, setStockSyncStatus] = useState('PUBLISHER');
    const [qty, setQty] = useState(0);

    const [showDialog, setShowDialog] = useState(false)
    const [submitClicked, setSubmitClicked] = useState(false)


    const setData = (item) => {
        setItem(item)
        const _manufacturer = { id: item.manufacturerId, name: item.manufacturerName }
        setManufacturer(_manufacturer)
        setItemName(item.name)
        setEnglishName(item.englishName)
        setPrice(item.price)
        setIsbn(item.isbn)
        setItemAvailability(item.itemAvailability)
        setQty(item.qty)
        setStockSyncStatus(item.stockSyncStatus)
    }

    const resetState = () => {
        setManufacturer({})
        setItemName('')
        setEnglishName('')
        setPrice('');
        setItemAvailability('AVAILABLE')
        setStockSyncStatus('PUBLISHER')
        setQty(0)
    }

    const generatePayload = () => {
        var payload = {
            data : {
                name: itemName,
                englishName: englishName,
                isbn: isbn,
                price: price,
                manufacturerId: manufacturer.id,
                itemAvailability: itemAvailability,
                qty: qty,
                stockSyncStatus: stockSyncStatus
            }
        }
        return payload
    }

    const generateUpdatePayload = () => {
        var payload = {
            id: item.id,
            data : {
                ...item,
                name: itemName,
                englishName: englishName,
                isbn: isbn,
                price: price,
                manufacturerId: manufacturer.id,
                itemAvailability: itemAvailability,
                qty: qty,
                stockSyncStatus: stockSyncStatus
            }
        }
        return payload
    }

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
          setIsError(false);
          setIsLoading(true);

          var params = { id: itemId}

          const {
            dispatch,
            dataProvider
            } = props;

          dataProvider(GET_ONE, 'v2/catalog/_item', params)
            .then((json) => {
                setData(json.data);
                setIsLoading(false);
            })
            .catch((e) => {
                setIsError(true);
                dispatch(showNotification('Error: Something went wrong', 'warning'))
                setIsLoading(false);
            });
        };
        if (showDialog && itemId) {
            fetchData();
        }
        
      }, [showDialog]);

    const handleUpdate = () => {
        if (!submitClicked) {
            setSubmitClicked(true)
            const {
                dispatch,
                dataProvider
            } = props;
    
            var payload = generateUpdatePayload()
        
            dataProvider(UPDATE, 'v2/catalog/_item', payload)
            .then((json) => {
                onUpdate(json.data)
                dispatch(showNotification('Item updated with id:' + json.data.id)) 
                setShowDialog(false)
                setSubmitClicked(false)
                resetState()
            })
            .catch((e) => {
                console.log(e)
                setSubmitClicked(false)
                dispatch(showNotification('Error: Something went wrong', 'warning'))
            });
            };
    }

    const handleSubmit = () => {
        if (!submitClicked) {
            setSubmitClicked(true)
            const {
                dispatch,
                dataProvider
            } = props;
    
            var payload = generatePayload()
            console.log(payload)
        
            dataProvider(CREATE, 'v2/catalog/item', payload)
            .then((json) => {
                console.log(json)
                onCreate(json.data)
                dispatch(showNotification('Item created with id:' + json.data.id)) 
                setShowDialog(false)
                setSubmitClicked(false)
                resetState()
            })
            .catch((e) => {
                setSubmitClicked(false)
                dispatch(showNotification(e.message, 'warning'))
            });
            };
    }

    const onManufacturerSelection = (selectedManufacturer) => {
        if (selectedManufacturer) {
            const manufacturer = { id: selectedManufacturer.value, name: selectedManufacturer.label }
            setManufacturer(manufacturer)
        } else {
            setManufacturer(null)
        }
       
      }

    const  validate = () => {
        let flag =  (manufacturer != null && 
                itemName != null && itemName.length > 0 &&
                englishName != null && englishName.length > 0 &&
                price != null && String(price).length > 0 && Number(price) >=0
                ) 
        console.log(flag)
        return flag
    }


    const getManufacturer = () => {
        if (manufacturer) {
            const _manufacturer = { value: manufacturer.id, label: manufacturer.name }
            return [_manufacturer]
        } else {
            return []
        }
      }

    return (
        <Fragment>
                {itemId ? 
                    <a href="#" onClick={(event) => { event.preventDefault(); setShowDialog(true) } }>Edit</a>
                    : 
                    <Button size="small" variant="contained" onClick={() => setShowDialog(true)} label="QUICK CREATE ITEM"></Button>
                }

                <Dialog maxWidth="sm" open={showDialog} onClose={() => { setShowDialog(false); resetState() }} aria-label="Quick Create Item">
                    { isLoading ? <h2>Loading..</h2> : 
                    <Fragment>
                    <DialogTitle>Create New Item</DialogTitle>
                    <DialogContent style={{marginTop: -10}}>
                        <div style={{ width: "100%" }}>

                        <MultiAutoSuggest inputId="MANUFACTURER" placeholder="Select Publisher" apiUrl="/v2/catalog/manufacturer"  selectedValue={getManufacturer()} onSelected={onManufacturerSelection} />
                        {/*
                        <AutoSuggest initialValue="Kalachuvadu" placeholder="Type Publisher Name.." fetchUrl="v2/catalog/manufacturer" onItemSuggestionClick={this.onManufacturerSelection} renderSuggestion={renderSuggestion} getSuggestionValue={getSuggestionValue} parameterName='name' /> */}
                        </div>
                        <div style={{marginTop: 10}}>
                            <TextField  style={{marginTop: 10}}
                                required
                                onChange={(event) => setItemName(event.target.value)} 
                                label="Name"
                                value={itemName}
                                style={{ width: 300 }}
                            />
                        </div>
                        <div style={{marginTop: 10}}>
                            <TextField style={{marginTop: 10}}
                            required
                            onChange={(event) => setEnglishName(event.target.value)} 
                            label="English Name"
                            value={englishName}
                            style={{ width: 300 }}
                        />
                        </div>
                        <div style={{marginTop: 10}}>
                            <TextField 
                            onChange={(event) => setIsbn(event.target.value)} 
                            label="ISBN"
                            value={isbn}
                            style={{ width: 300 }}
                        />
                        </div>
                        <div style={{ marginTop: 7, display: 'inline-flex' }}>
                            <div style={{marginTop: 17  }}>
                                <TextField 
                                    onChange={(event) => setPrice(event.target.value)} 
                                    label="Price"
                                    value={price}
                                    style={{ width: 100 }}
                                />
                            </div>
                            <div style={{ marginLeft: 10, width: 200 }}>
                                <TextField
                                id="itemAvailability"
                                fullWidth
                                select
                                label="Item Availability"                         
                                onChange={(e) => setItemAvailability(e.target.value)}
                                value={itemAvailability}
                                SelectProps={{
                                    native: true,
                                }}
                                margin="normal"
                                >
                                {ItemAvailabilityTypes.map(option => (
                                    <option key={option.id} value={option.id}>
                                    {option.name}
                                    </option>
                                ))}
                                </TextField>
                            </div>
                        </div>
                            
                        <div style={{marginTop: 15,  display: 'inline-flex' }}>

                            <div style={{marginTop: 17  }}>
                                <TextField 
                                    onChange={(event) => setQty(event.target.value)} 
                                    label="Stock Qty"
                                    value={qty}
                                    style={{ width: 100 }}
                                />
                            </div>
                            <div style={{ marginLeft: 10, width: 200 }}>
                                <TextField
                                id="stockSyncStatus"
                                fullWidth
                                select
                                label="Stock Sync"                         
                                onChange={(e) => setStockSyncStatus(e.target.value)}
                                value={stockSyncStatus}
                                SelectProps={{
                                    native: true,
                                }}
                                margin="normal"
                                >
                                {ItemStockSyncStatusTypes.map(option => (
                                    <option key={option.id} value={option.id}>
                                    {option.name}
                                    </option>
                                ))}
                                </TextField>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                    {itemId ? 
                        <Button label="Update" disabled={!validate()} onClick={handleUpdate} />
                        : 
                        <Button label="Submit" disabled={!validate()} onClick={handleSubmit} />
                    }   
                    <Button label="Cancel" onClick={() => { setShowDialog(false); resetState() }}></Button>
                    </DialogActions>
                    </Fragment>
                    }
                </Dialog>
            </Fragment>
    )

}

export default withDataProvider(ItemQuickCreateEditButton)