import ReceiptIcon from '@material-ui/icons/Receipt';
import ReceiptList from './ReceiptList';
import ReceiptCreate from './ReceiptCreate';
import ReceiptShow from './ReceiptShow';
import ReceiptEdit from './ReceiptEdit';


export default {
    edit: ReceiptCreate,
    list: ReceiptList,
    create: ReceiptCreate,
    show: ReceiptShow,
    icon: ReceiptIcon,
    name: "sale/receipt"
};
