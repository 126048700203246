// hooks to maintain state
// submit using dataProvider
//

import React, { Fragment, useState } from 'react';
import {
    showNotification,
    withDataProvider,
    CREATE,
} from 'react-admin';

import Button from '@material-ui/core/Button';
import { push } from 'react-router-redux';

const PendingPurchaseItemsExportButton = (props) => {

    console.log(JSON.stringify(props.filterValues))
    const [isLoading, setIsLoading] = useState(false);
    const refreshPendingPurchases = () => {
        const {
          dispatch,
          dataProvider
          } = props;
          setIsLoading(true);
          dataProvider(CREATE, 'order/pending-items/google-sheet', { data : props.filterValues})
          .then((json) => {
              dispatch(showNotification('Exported to google sheet!!'))
              //window.location.reload()
              setIsLoading(false);
          })
          .catch(() => {
              dispatch(showNotification('Error: Something went wrong', 'warning'))
              //window.location.reload()
              setIsLoading(false);
          });
      }

    return (
        <Fragment>
            <Button disabled={isLoading} style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }} variant="contained" color="primary"
             onClick={(event) => { refreshPendingPurchases() }}>
            EXPORT TO GOOGLE SHEET</Button>
            </Fragment>
    )

}

export default withDataProvider(PendingPurchaseItemsExportButton)