// hooks to maintain state
// submit using dataProvider
//

import React, { useState, Fragment, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import {
    showNotification,
    withDataProvider,
    CREATE,
    UPDATE,
    GET_ONE,
} from 'react-admin';

import {refreshView} from 'react-admin'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

const QuoteClosedReasonTypes = [
{ value: 'CUSTOMER_ORDERED', label: 'Customer Ordered' },
{ value: 'WILL_PURCHASE_LATER', label: 'Customer will Purchase Later' },
{ value: 'ENQUIRY', label: 'Customer Enquiry' },
{ value: 'NO_RESPONSE', label: 'No Response from Customer' },
{ value: 'NOT_ABLE_TO_PROCURE', label: 'Unable to Procure' },
{ value: 'OUT_OF_PRINT', label: 'Books Out of Stock/Print' },
{ value: 'OLD_REQUEST', label: 'Very Old Request' },
{ value: 'OTHERS', label: 'Other Reason' }
];

const CloseQuoteButton = (props) => {
    console.log(props)
    const { record } = props
    const [showDialog, setShowDialog] = useState(false)
    const [submitClicked, setSubmitClicked] = useState(false)
    
    const [quoteClosedReason, setQuoteClosedReason] = useState('CUSTOMER_ORDERED')
    

    const resetState = () => {
        setQuoteClosedReason('CUSTOMER_ORDERED')
    }

    // useEffect(() => {
    //     setShippingAmount(record.shippingAmount)
    //     setQuoteClosedReason(record.shippingCarrier)
    //     setTrackingUrl(record.trackUrl)
    //     setTrackingId(record.trackingId)
      
    // }, [showDialog]);

    const handleSubmit = () => {
        if (!submitClicked) {
            setSubmitClicked(true)
            const {
                dispatch,
                dataProvider
            } = props;
    
           
        
            dataProvider(CREATE, 'order/quote/' + record.id + '/close/' + quoteClosedReason, { data: null })
            .then((json) => {
                dispatch(showNotification('Draft closed')) 
                setShowDialog(false)
                setSubmitClicked(false)
                resetState()
                //dispatch(refreshView())
                window.location.reload()
            })
            .catch((e) => {
                setSubmitClicked(false)
                dispatch(showNotification(e.message, 'warning'))
            });
            };
    }
   
    return (
        <Fragment>
            <div style={{ textAlign: "center" }}>
                <Button variant="contained" size="small" color="primary" onClick={(event) => { event.preventDefault(); setShowDialog(true) }}>
                    Close Draft
                </Button>
            </div> 

            <Dialog maxWidth="md" open={showDialog} onClose={() => { setShowDialog(false); resetState() }} >
                    <Fragment>
                            <DialogTitle>Close Draft# {record.id}</DialogTitle>
                            <DialogContent>
                            <div style={{ display: 'inline-flex' }}>
                                <div style={{ width: 250 }}>
                                    <TextField
                                    id="quoteClosedReason"
                                    fullWidth
                                    select
                                    label="Close Reason"                         
                                    onChange={(e) => setQuoteClosedReason(e.target.value)}
                                    value={quoteClosedReason}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    margin="normal"
                                    >
                                    {QuoteClosedReasonTypes.map(option => (
                                        <option key={option.value} value={option.value}>
                                        {option.label}
                                        </option>
                                    ))}
                                    </TextField>
                                </div>                                
                            </div>
                            </DialogContent>
                            <DialogActions>
                                <Button variant="contained" color="primary" disabled={submitClicked} onClick={handleSubmit}> SUBMIT </Button>
                                <Button variant="contained" onClick={() => { setShowDialog(false); resetState() }}> CANCEL </Button>
                            </DialogActions>
                    </Fragment>
            </Dialog>
        </Fragment>
    )

}

export default withDataProvider(CloseQuoteButton)