import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { showNotification, GET_LIST, withDataProvider } from 'react-admin';
import { debounce } from 'throttle-debounce'

function renderInputComponent(inputProps) {
  const { classes, inputRef = () => {}, ref, ...other } = inputProps;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input,
        },
      }}
      {...other}
    />
  );
}


function getSuggestionValue(suggestion) {
  //console.log(suggestion)
  return suggestion.name;
}

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});

class IntegrationAutosuggest extends React.Component {

  
  state = {
    single: '',
    popper: '',
    
    suggestions: [],
  };

  componentWillMount() {
    this.onSuggestionsFetchRequested = debounce(
      500,
      this.onSuggestionsFetchRequested
    )
  }

  handleSuggestionSelected = name => (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method })  => {
    if (this.props.parameterName) {
      this.setState({
        [name]: suggestion[this.props.parameterName],
      });
    } else {
      this.setState({
        [name]: '',
      });
    }
    
    this.props.onItemSuggestionClick(suggestion)
   
  };

  handleSuggestionsFetchRequested = ({value}) => {

    if (value.length > 2) {
      
      const { dataProvider, dispatch } = this.props;

      var params = {
        "pagination": {
          "page": 1,
          "perPage": 20
        },
        "sort": {
          "field": "id",
          "order": "DESC"
        },
        "filter": {
          "q" : value
        }
      }

      dataProvider(GET_LIST, this.props.fetchUrl, params)
        .then((json) => {
          this.setState({
            suggestions: json.data,
          });
          if (json.total === 0) {
            //dispatch(showNotification('Item not found'));
          } 
        })
        .catch((e) => {
          dispatch(showNotification('Error: Something went wrong', 'warning'))
        });
    }
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  handleChange = name => (event, { newValue }) => {
    this.setState({
      [name]: newValue,
    });
  };

  render() {
    const { classes } = this.props;

    const autosuggestProps = {
      renderInputComponent,
      suggestions: this.state.suggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      onSuggestionSelected : this.handleSuggestionSelected('single'),
      getSuggestionValue: this.props.getSuggestionValue,
      renderSuggestion : this.props.renderSuggestion,
    };

    return (
      <div className={classes.root}>
        <Autosuggest
          {...autosuggestProps}
          inputProps={{
            classes,
            placeholder: this.props.placeholder,
            value: this.state.single,
            onChange: this.handleChange('single'),
          }}
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
          renderSuggestionsContainer={options => (
            <Paper {...options.containerProps} square>
              {options.children}
            </Paper>
          )}
        />
        <div className={classes.divider} />
      </div>
    );
  }
}

IntegrationAutosuggest.propTypes = {
  classes: PropTypes.object.isRequired,
};

const enhance = compose(
    withDataProvider,
    withStyles(styles)
);

export default enhance(IntegrationAutosuggest);