import React from 'react';
import {
    Create,
    TextInput,
    SimpleForm,
    required,
    Toolbar,
    SelectInput,
    translate,
    BooleanInput,
} from 'react-admin';
import Button from '@material-ui/core/Button';
import SaveWithRefreshButton from '../../common/SaveWithRefreshButton'


const CustomerCreateToolbar = translate(({ onCancel, translate, ...props }) => (
    <Toolbar {...props}>
        <SaveWithRefreshButton
            label="Create"
            redirect="list"
            submitOnEnter={false}
            variant="flat"
        />
        <Button onClick={onCancel}>{translate('ra.action.cancel')}</Button>
    </Toolbar>
));


// "name": "Saravanan",
// "email": "vsr.saravanan@gmail.com",
// "mobileNumber": "9790712847",
// "defaultAddressId": 1,
// "alternatePhone": "9790712848",
// "customerIntroType": "SHOP",
// "customerType": "CUSTOMER",
// "sendNewsletter": true,
// "sendWhatsAppSms": true,
// "whatsAppNumber": "9790712847",
// "notes": "notes",
// "area": "Chrompet",

const CustomerCreate = ({ onCancel, ...props }) => (
    <Create title=" " {...props}>
        <SimpleForm redirect="list" toolbar={<CustomerCreateToolbar onCancel={onCancel} />}>
            <SelectInput source="customerType" defaultValue={"CUSTOMER"} choices={[
                { id: 'CUSTOMER', name: 'Customer' },
                { id: 'ORGANISATION', name: 'Organisation' },
                { id: 'SELLER', name: 'Seller' },
                { id: 'PUBLISHER', name: 'Publisher' },
                { id: 'OTHERS', name: 'Others' },
            ]} />

            <SelectInput source="customerIntroType" choices={[
                    { id: 'SHOP', name: 'Shop' },
                    { id: 'PANUVAL_COM', name: 'Panuval.com' },
                ]} />

            <TextInput autoFocus source="name" style={{ width: 400 }} validate={required()} />
            <TextInput source="mobileNumber" style={{ width: 400 }} validate={required()} />
            <TextInput source="email" style={{ width: 400 }} />
            <BooleanInput label="Send SMS/WhatsApp" source="sendWhatsAppSms" />
            <BooleanInput label="Send Email" source="sendNewsletter" />
            <TextInput source="area" style={{ width: 400 }}  />
            <TextInput source="notes" style={{ width: 400 }}  />
            <TextInput source="whatsAppNumber" style={{ width: 400 }}  />
            {/* <TextInput source="alternatePhone" style={{ width: 400 }}  /> */}
        </SimpleForm>
    </Create>
);

export default CustomerCreate;
