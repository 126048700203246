// hooks to maintain state
// submit using dataProvider
//

import React, { useState, Fragment, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import {
    showNotification,
    withDataProvider,
    CREATE,
    UPDATE,
    GET_ONE
} from 'react-admin';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DisplayField from './DisplayField'
import { push } from 'react-router-redux';
import * as qz from 'qz-tray';
import scaleZPL from './scale-zpl'
import sha256 from 'js-sha256';

const ShippingCarrierTypes = [
    {
      value: 'PROFESSIONAL_COURIER',
      label: 'Professional Courier',
    },
    {
      value: 'FRANCH_EXPRESS',
      label: 'Franch Express',
    },
    {
        value: 'TRACKON_COURIER',
        label: 'Trackon Courier',
    },
    {
      value: 'REGISTERED_POST',
      label: 'Registered Post / Printed Books',
    },
    {
      value: 'SPEED_POST',
      label: 'Speed Post',
    },
    ,
    {
      value: 'KPN_PARCEL',
      label: 'KPN Parcel',
    },
    {
      value: 'OTHERS',
      label: 'Other',
    },
  ];


  function connect() {
    return new Promise(function(resolve, reject) {
        if (qz.websocket.isActive()) {	// if already active, resolve immediately
            resolve();
        } else {
            // try to connect once before firing the mimetype launcher
            qz.api.setPromiseType(function promise(resolver) { return new Promise(resolver); });
            qz.api.setSha256Type(function(data) { return sha256(data); });
            qz.websocket.connect().then(resolve, function retry() {
                // if a connect was not succesful, launch the mimetime, try 3 more times
                window.location.assign("qz:launch");
                qz.websocket.connect({ retries: 2, delay: 1 }).then(resolve, reject);
            });
        }
    });
}

function printLabel(data) {
    var printer = "TSC DA310";
    var options =  { size: { width: 4, height: 6}, units: "in" };
    var config = qz.configs.create(printer, options); 
    //var config = qz.configs.create(printer); 
    //var data = [{ type: 'pdf', data: 'assets/pdf_sample.pdf' }];
    // return the promise so we can chain more .then().then().catch(), etc.
    return qz.print(config, data);
}

function connectAndPrint (data) {
    // our promise chain
    connect().then(function() {
        return printLabel(data);
    }).then(function() {
        success();              // exceptions get thrown all the way up the stack
    }).catch(fail);             // so one catch is often enough for all promises
    
    // NOTE:  If a function returns a promise, you don't need to wrap it in a fuction call.
    //        The following is perfectly valid:
    //
    //        connect().then(print).then(success).catch(fail);
    //
    // Important, in this case success is NOT a promise, so it should stay wrapped in a function() to avoid confusion
}

// notify successful print
function success () { 
    alert("Success");
}

// exception catch-all
function fail (e) {
    alert("Error: " + e);
}


const ShippingLabelButton = (props) => {

    const { onCreateEdit, orderId, orderDate, shipDate, fulfillmentId, address, shippingDetail  } = props
    console.log(shippingDetail)

    const [showDialog, setShowDialog] = useState(false)
    const [submitClicked, setSubmitClicked] = useState(false)

    const [id, setId] = useState(null)
    const [shippingCarrier, setShippingCarrier] = useState('FRANCH_EXPRESS')
    const [shippingWeight, setShippingWeight] = useState('')
    const [disablePrint, setDisablePrint] = useState(false)

    useEffect(() => {
        if (shippingDetail.shippingCarrier) {
            setShippingCarrier(shippingDetail.shippingCarrier)
        } else {
            setShippingCarrier('FRANCH_EXPRESS')
        }

        if (shippingDetail.shippingWeight) {
            setShippingWeight(shippingDetail.shippingWeight)
        } else {
            setShippingWeight('')
        }

    }, [showDialog])

    // const resetState = () => {
    //     setId(null);
    //     setName('')
    //     setAddress1('')
    //     setAddress2('')
    //     setAddress3('')
    //     setCity('')
    //     setPincode('')
    //     setState('')
    //     setCountry('')
    //     setMobileNumber('')
    // }

    // <DisplayField fieldName="name" fieldValue={address.name} canBeEmpty={false} maxChars={70} /><br/>
    //                                     <DisplayField fieldName="address1" fieldValue={address.address1} canBeEmpty={false} maxChars={70} /><br/>
    //                                     <DisplayField fieldName="address2" fieldValue={address.address2} canBeEmpty={false} maxChars={70} /><br/>
    //                                     {address.address3 ? <Fragment><DisplayField fieldName="address3" fieldValue={address.address3} canBeEmpty={true} maxChars={70} /><br/> </Fragment>: null }
    //                                     <DisplayField fieldName="city" fieldValue={address.city} canBeEmpty={false} maxChars={40} /><br/>
    //                                     PIN: <DisplayField fieldName="pincode" fieldValue={address.pincode} canBeEmpty={false} maxChars={10} />&nbsp;
    //                                     <DisplayField fieldName="state" fieldValue={address.state} canBeEmpty={false} maxChars={40} />&nbsp;
    //                                     <DisplayField fieldName="country" fieldValue={address.country} canBeEmpty={false} maxChars={20} /><br/>
    //                                     PH: <DisplayField fieldName="mobileNumber" fieldValue={address.mobileNumber} canBeEmpty={false} maxChars={30} /><br/>

    const getCarrierName = (shippingCarrier) => {
        for (const obj of ShippingCarrierTypes) {
            if (obj.value === shippingCarrier)
                return obj.label
        }
    }

    const handlePrint = () => {
        var data = [
            '^XA\n',
            scaleZPL('^FX Shipping label info.\n'),
            scaleZPL('^CF0,45\n'),
            scaleZPL('^FO750,400^FWr^FD' + getCarrierName(shippingCarrier) + '^FS\n'),
            scaleZPL('^FO670,100^FWr^FDTO^FS\n'),
            scaleZPL('^FO600,100^FWr^FD' + address.name +'^FS\n'),
            scaleZPL('^FO550,100^FWr^FD' + address.address1 +'^FS\n'),
            scaleZPL('^FO500,100^FWr^FD' + address.address2 +'^FS\n'),
            scaleZPL('^FO450,100^FWr^FD' + address.address3 +'^FS\n'),
            scaleZPL('^FO400,100^FWr^FD' + address.city +'^FS\n'),
            scaleZPL('^FO350,100^FWr^FDPIN: ' + address.pincode + ' ' + address.state + ' ' + address.country + '^FS\n'),
            scaleZPL('^FO300,100^FWr^FDPh: ' + address.mobileNumber + '^FS\n'),
            scaleZPL('^FO250,0^GB3,1300,2^FS\n'),
            scaleZPL( '^CF0,30\n'),
            scaleZPL('^FO200,30^FWr^FDFrom^FS\n'),
            scaleZPL('^FO160,30^FWr^FDPanuval Bookstore^FS\n'),
            scaleZPL('^FO130,30^FWr^FD112, First Floor, Thiruvalluvar Salai^FS\n'),
            scaleZPL('^FO100,30^FWr^FDThiruvanmiyur. Chennai - 41^FS\n'),
            scaleZPL('^FO70,30^FWr^FDPh: 044 43100442 | 9789009666^FS\n'),
            scaleZPL('^FO40,30^FWr^FDwww.panuval.com | buybooks@panuval.com^FS\n'),
            scaleZPL('^FO200,600^FWr^FDOrder#: ' + orderId + ' (' + orderDate + ')' + '^FS\n'),
            scaleZPL('^FO160,600^FWr^FDOnlineOrder#: ' + shippingDetail.referenceId + '^FS\n'),
            scaleZPL('^FO200,900^FWr^FDShip Date: ' + shipDate + '^FS\n'),
            scaleZPL('^FO160,900^FWr^FDWeight: ^FS\n'),
            scaleZPL('^BY3,2,70\n'),
            scaleZPL('^FO50,700^BC^FD' + shippingDetail.id  + '^FS\n'),
            '^XZ\n',
         ];

         console.log(data)
         if (strlength(address.address3) === 0 ) {
            data.splice(8,1)
         }
         
        connectAndPrint(data);
    }

   
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const handleSubmit = () => {
        if (!submitClicked) {
            setSubmitClicked(true)
            const {
                dispatch,
                dataProvider
            } = props;

            var shippingAddress = address.name + "\n" +  address.address1 + "\n" + address.address2 + "\n" + (address.address3 ?  address.address3 + "\n" : null) + address.city + "\n" + 
            "PIN: " + address.pincode + " " + address.city + " " +  address.pincode + "\n"  + "PH: " + address.mobileNumber


            var payload = {
                shippingWeight: shippingWeight,
                shippingCarrier: shippingCarrier,
                customerName: address.name,
                city: address.city,
                pincode: address.pincode,
                shippingAddress: shippingAddress
            }
            console.log(payload)

            dataProvider(CREATE, 'order/' + orderId + '/fulfillment/' + fulfillmentId + '/packed', {data: payload})
                .then((json) => {
                    dispatch(showNotification('Updated Shipping Detail with id:' + json.data.id))
                    setShowDialog(false)
                    setSubmitClicked(false)
                    window.location.reload(true)
                })
                .catch((e) => {
                    setSubmitClicked(false)
                    dispatch(showNotification(e.message, 'warning'))
                });
        };
    }

    const strlength = (string) => {
        if (string != null) {
            return string.length
        } else {
            return 0
        }
    }


    const validate = () => {
        return (
        strlength(address.name) > 1 && strlength(address.name) <= 70 &&
        strlength(address.address1) > 1 && strlength(address.address1) <= 70 &&
        strlength(address.address2) > 1 && strlength(address.address2) <= 70 &&
        strlength(address.address3) <= 70 &&
        strlength(address.city) > 1 && strlength(address.city) <= 40 &&
        strlength(address.pincode) > 1 && strlength(address.pincode) <= 10 &&
        strlength(address.state) > 1 && strlength(address.state) <= 40 &&
        strlength(address.country) > 1 && strlength(address.country) <= 20 &&
        strlength(address.mobileNumber) > 1 && strlength(address.mobileNumber) <= 30) 
    }

    return (
        <Fragment>

            <Button disabled={false} size="small" variant="contained" color="primary" onClick={(event) => { event.preventDefault(); setShowDialog(true) }}>SHIPPING LABEL</Button>
            <Dialog fullWidth maxWidth="sm" open={showDialog} onClose={() => { setShowDialog(false); }} aria-label="Quick Create Item">
                {isLoading ? <h2>Loading..</h2> :
                    <Fragment>
                            <DialogTitle>Shipping Label for Fulfillment# {fulfillmentId} </DialogTitle>
                            <DialogContent>
                                <div>
                                    <TextField
                                        id="select-shipping-carrier"
                                        select
                                        label="Shipping Carrier"
                                        value={shippingCarrier}
                                        onChange={(event) => {
                                            //setDisablePrint(true)
                                            setShippingCarrier(event.target.value)
                                        }
                                        }
                                        SelectProps={{
                                            native: true,
                                        }}
                                        margin="normal"
                                    >
                                        {ShippingCarrierTypes.map(option => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                        ))}
                                    </TextField>&nbsp;&nbsp;

                                    {/* <TextField
                                        // onChange={(event) => {
                                        //     setDisablePrint(true)
                                        //     setShippingWeight(event.target.value)
                                        // }}
                                        label="Weight (in grams)"
                                        value={shippingWeight}
                                        style={{ width: 150, marginLeft: 5 }}
                                    /> */}
                                </div>
                                { address ? 
                                <div> 
                                    <h3>Shipping Address</h3>
                                    <div style={{marginTop: -10}}>
                                        <DisplayField fieldName="name" fieldValue={address.name} canBeEmpty={false} maxChars={70} /><br/>
                                        <DisplayField fieldName="address1" fieldValue={address.address1} canBeEmpty={false} maxChars={70} /><br/>
                                        <DisplayField fieldName="address2" fieldValue={address.address2} canBeEmpty={false} maxChars={70} /><br/>
                                        {address.address3 ? <Fragment><DisplayField fieldName="address3" fieldValue={address.address3} canBeEmpty={true} maxChars={70} /><br/> </Fragment>: null }
                                        <DisplayField fieldName="city" fieldValue={address.city} canBeEmpty={false} maxChars={40} /><br/>
                                        PIN: <DisplayField fieldName="pincode" fieldValue={address.pincode} canBeEmpty={false} maxChars={10} />&nbsp;
                                        <DisplayField fieldName="state" fieldValue={address.state} canBeEmpty={false} maxChars={40} />&nbsp;
                                        <DisplayField fieldName="country" fieldValue={address.country} canBeEmpty={false} maxChars={20} /><br/>
                                        PH: <DisplayField fieldName="mobileNumber" fieldValue={address.mobileNumber} canBeEmpty={false} maxChars={30} /><br/>
                                    </div>
                                </div>
                                : null
                                }
                            </DialogContent>
                            <DialogActions>
                                { shippingDetail.shippingStatus === 'PACKED' ? <Button variant="contained" color="primary" disabled={disablePrint} onClick={handlePrint}> PRINT </Button> : null }
                                <Button variant="contained" color="primary" disabled={!validate()} onClick={handleSubmit}> SAVE </Button>
                                <Button variant="contained" onClick={() => { setShowDialog(false); }}> CANCEL </Button>
                            </DialogActions>
                    </Fragment>
                }
            </Dialog>
        </Fragment>
    )

}

export default withDataProvider(ShippingLabelButton)