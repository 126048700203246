import React, { Component, Fragment } from 'react';
import * as qz from 'qz-tray';
import sha256 from 'js-sha256';
import Button  from '@material-ui/core/Button'

function connect() {
    return new Promise(function(resolve, reject) {
        if (qz.websocket.isActive()) {	// if already active, resolve immediately
            resolve();
        } else {
            // try to connect once before firing the mimetype launcher
            qz.api.setPromiseType(function promise(resolver) { return new Promise(resolver); });
            qz.api.setSha256Type(function(data) { return sha256(data); });
            qz.websocket.connect().then(resolve, function retry() {
                // if a connect was not succesful, launch the mimetime, try 3 more times
                window.location.assign("qz:launch");
                qz.websocket.connect({ retries: 2, delay: 1 }).then(resolve, reject);
            });
        }
    });
}

function printLabel() {
    var printer = "TSC DA310";
    var options =  { size: { width: 4, height: 6}, units: "in" };
    var config = qz.configs.create(printer, options); 
    //var config = qz.configs.create(printer); 
    //var data = [{ type: 'pdf', data: 'assets/pdf_sample.pdf' }];

    

    var data = [
        '^XA\n',
        '^FX Second section with recipient address and permit information.\n',
        '^CF0,60\n',
        '^FO750,400^FWr^FDRegistered Post^FS\n',
        '^FO670,100^FWr^FDTO^FS\n',
        '^FO600,100^FWr^FDManikandan சரவணன்C ^FS\n',
        '^FO550,100^FWr^FDBlock Resource Center ^FS\n',
        '^FO500,100^FWr^FDS2-II floor, plot No.171,172-Methas Akshayam, Majestic Homes,  ^FS\n',
        '^FO450,100^FWr^FDRam nagar south 12th main road, near Srushtee play school, Madipakkam,  ^FS\n',
        '^FO400,100^FWr^FDThanjavur ^FS\n',
        '^FO350,100^FWr^FDPin Code - 614903, Tamil Nadu, India ^FS\n',
        '^FO300,100^FWr^FDPh: 9790712847 ^FS\n',
        '^FO250,0^GB0,1300,2^FS\n',
        '^CF0,50\n',
        '^FO200,30^FWr^FDFrom^FS\n',
        '^FO160,30^FWr^FDPanuval Bookstore^FS\n',
        '^FO130,30^FWr^FD112, First Floor, Thiruvalluvar Salai^FS\n',
        '^FO100,30^FWr^FDThiruvanmiyur. Chennai - 41^FS\n',
        '^FO70,30^FWr^FDPh: 044 43100442 | 9789009666^FS\n',
        '^FO40,30^FWr^FDwww.panuval.com | buybooks@panuval.com^FS\n',
        '^FO200,600^FWr^FDOrder Date: 23-Nov-19^FS\n',
        '^FO160,600^FWr^FDOrder #: 13456^FS\n',
        '^FO200,900^FWr^FDShip Date: 25-Nov-19^FS\n',
        '^FO160,900^FWr^FDWeigth: 100g^FS\n',
        '^BY3,2,70\n',
        '^FO50,700^BC^FD12345^FS\n',
        '^XZ\n',
     ];
  

    // return the promise so we can chain more .then().then().catch(), etc.
    return qz.print(config, data);
}

function connectAndPrint () {
    // our promise chain
    connect().then(function() {
        return printLabel();
    }).then(function() {
        success();              // exceptions get thrown all the way up the stack
    }).catch(fail);             // so one catch is often enough for all promises
    
    // NOTE:  If a function returns a promise, you don't need to wrap it in a fuction call.
    //        The following is perfectly valid:
    //
    //        connect().then(print).then(success).catch(fail);
    //
    // Important, in this case success is NOT a promise, so it should stay wrapped in a function() to avoid confusion
}

// notify successful print
function success () { 
    alert("Success");
}

// exception catch-all
function fail (e) {
    alert("Error: " + e);
}


class ShippingLabel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ocId: 0,
        };
    }

    // console.log('connected')
    // qz.printers.find("TEC").then(function(data) {
    //     var list = '';
    //     for(var i = 0; i < data.length; i++) {
    //        list += "&nbsp; " + data[i] + "<br/>";
    //    }
    //    console.log(list);
    // }).catch(function(e) { console.error(e); });

    _connectAndPrint() {
        connectAndPrint();
    }

    handleInit() {
        // console.log("FilePond instance has initialised", this.pond1);
        // console.log("FilePond instance has initialised", this.pond2);
      }

    render() {
        return (
            <Fragment>                
                  <h1>Shipping Label</h1>
                 <Button variant="contained" color="primary" onClick={this._connectAndPrint}>Print</Button>
            </Fragment>
        )
    }
}


export default ShippingLabel;
