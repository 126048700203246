import ItemIcon from '@material-ui/icons/Collections';
import OfficeToShopList from './OfficeToShopList';
import OfficeToShopCreate from './OfficeToShopCreate';
import OfficeToShopShow from './OfficeToShopShow';


export default {
    list: OfficeToShopList,
    create: OfficeToShopCreate,
    show: OfficeToShopShow,
    icon: ItemIcon,
    name: "inventory/transfer/office-to-shop"
};
