  
export const ShippingStatusTypes = [
    { id: 'INIT', name: 'Fulfilled', color: 'IndianRed' }, // name for drop down in order list; name for select field.
    { id: 'PACKED', name: 'Packed', color: 'YellowGreen' },
    { id: 'HANDED_OVER', name: 'Handed Over', color: 'Plum' },
    { id: 'SHIPPED', name: 'Shipped', color: 'LightSalmon' },
    { id: 'DELIVERED', name: 'Delivered', color: 'SeaGreen' },
    { id: 'RETURNED', name: 'Returned', color: 'RosyBrown' },
    { id: 'READY_TO_SHIP', name: 'Ready to Ship', color: 'IndianRed' },
  ];

export const ShippingCarrierTypes = [
  { id: 'REGISTERED_POST', name: 'India Post', color: 'Orange'  },
  { id: 'FRANCH_EXPRESS', name: 'Franch Express', color: 'LimeGreen'  },
  { id: 'PROFESSIONAL_COURIER', name: 'Professional Courier', color: 'LightSalmon'  },
  { id: 'TRACKON_COURIER', name: 'Trackon Courier', color: 'RosyBrown'  },
  { id: 'SPEED_POST', name: 'Speed Post', color: 'Orange'  },
  { id: 'KPN_PARCEL', name: 'KPN Parcel', color: 'Plum'  },
  { id: 'OTHERS', name: 'Others', color: 'IndianRed'  },
];

export const ManifestStatusTypes = [
  { id: 'OPEN', name: 'Open', color: 'IndianRed' }, 
  { id: 'CREATED', name: 'Created', color: 'Plum'  },
  { id: 'HANDED_OVER', name: 'Handed Over', color: 'Plum' },
  { id: 'SHIPPED', name: 'Shipped', color: 'LightSalmon' }
];

