import React from 'react';
import PropTypes from 'prop-types';

const PriceQtyField = ({ qty, price, record = {} }) => <span>{record[qty]} (₹ {record[price]})</span>;

// const ShippingStatusField = ({ source, record = {} }) => {
//     let status = {}
//     ShippingStatusTypes.forEach( item => {
//         if (item.id === record[source]) {
//             status = item;
//         }
//     } )
//     return (<span><Chip label={status.name} style={{ backgroundColor: status.color }} /></span>)
// }


PriceQtyField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    //source: PropTypes.string.isRequired,
};

export default PriceQtyField;
