import React, { Fragment } from 'react';
import { ShowController, ShowView, SimpleShowLayout, TextField } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
// import compose from 'recompose/compose';
import currency from "currency.js";
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
// import { ReactComponent as Logo } from '../../panuval-logo.svg';
import PanuvalLogo from './PanuvalLogo'


const cardActionStyle = {

    zIndex: 2,
    display: 'flex',
    padding: 0,
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-end'

};

const printIt = (elementId, uniqueIframeId) => {
    const content = document.getElementById(elementId)
    let pri
    if (document.getElementById(uniqueIframeId)) {
        pri = document.getElementById(uniqueIframeId).contentWindow
    } else {
        const iframe = document.createElement('iframe')
        iframe.setAttribute('title', uniqueIframeId)
        iframe.setAttribute('id', uniqueIframeId)
        iframe.setAttribute('style', 'height: 0px; width: 0px; position: absolute;')
        document.body.appendChild(iframe)
        pri = iframe.contentWindow
    }
    pri.document.open()
    pri.document.write(content.innerHTML)
    pri.document.close()
    pri.focus()
    pri.print()

}

// const printIt = () => {
//     var content = document.getElementById("print-me");
//     var pri = document.getElementById("ifmcontentstoprint").contentWindow;
//     pri.document.open();
//     pri.document.write(content.innerHTML);
//     pri.document.close();
//     pri.focus();
//     pri.print();
// };



const Actions = ({ basePath, data, resource }) => {
    return (
        <CardActions style={cardActionStyle}>
            {/* Add your custom actions */}
            <Button color="primary" onClick={() => printIt('print-me', 'unique-iframeid-qswde')}>Print</Button>
        </CardActions>
    )
};

const SupplierBillShow = (props) => (
    <ShowController   {...props}>
        {controllerProps =>
            <ShowView actions={<Actions />} {...props} {...controllerProps}>
                <SimpleShowLayout>
                    <div id="print-me" style={{ maxWidth: 600, width: "100%", fontSize: "10px" }}>
                        <div style={{ textAlign: "center" }}>
                            <div style={{ verticalAlign: "middle", border: 0 }}>
                                <PanuvalLogo/>   
                            </div>
                            <div style={{ fontSize: "12px", fontWeight: "700", fontSize: "120%" }}>Panuval Bookstore</div>
                            <div style={{ fontSize: "12px"}}>
                                PANUVAL<br />
                                112, First Floor, Thiruvalluvar Salai<br />
                                Thiruvanmiyur. Chennai - 41
                            </div>
                            <div style={{ fontSize: "12px", marginBottom: "5px" }}>9789009666</div>
                            <div style={{ fontSize: "12px"}}> Supplier Bill Receiving </div>
                            <div style={{ fontSize: "12px", marginBottom: "5px" }}>{controllerProps.record && controllerProps.record.createDate}</div>
                        </div>
                        <div style={{ fontSize: "12px", marginBottom: "5px" }}>
                            <div>Receivings ID: {controllerProps.record && controllerProps.record.id} </div>
                            <div>Received By: {controllerProps.record && controllerProps.record.createdByName}</div>
                            <div>Supplier: {controllerProps.record && controllerProps.record.supplierName}</div>
                            <div>Bill No: {controllerProps.record && controllerProps.record.invoiceNumber} </div>
                            <div>Bill Date: {controllerProps.record && controllerProps.record.receiptDate.substring(0, 10) }</div>
                            <div>Bill Amount: ₹ {controllerProps.record && controllerProps.record.invoiceAmount} </div>
                        </div>

                        <table style={{ fontSize: "10px", borderSpacing: 0, backgroundColor: "transparent", marginBottom: "15px", marginTop: "15px", width: "100%", borderCollapse: "collapse", position: "relative" }}>
                            <tbody>
                                <tr>
                                    <th style={{ width: "5%", textAlign: "left", padding: 0 }}>No</th>
                                    <th style={{ width: "50%", textAlign: "center", padding: 0 }}>Item</th>
                                    <th style={{ width: "20%", textAlign: "left", padding: 0  }}>Price</th>
                                    <th style={{ width: "15%", textAlign: "left", padding: 0  }}>Qty</th>
                                    <th style={{ width: "20%", textAlign: "right", padding: 0  }}>Total </th>
                                </tr>
                                {controllerProps.record && controllerProps.record.receivingItems && controllerProps.record.receivingItems.map(item => (
                                    <Fragment>
                                    <tr style={{ height: 20 }}>
                                        <td style={{  padding: "3px", position: "relative"}}>{item.line}</td>
                                        <td style={{  padding: "3px", position: "relative"}}>{item.name}</td>
                                        <td style={{  padding: "3px", position: "relative"}}>₹{item.price}</td>
                                        <td style={{  padding: "3px", position: "relative"}}>{item.qty}</td>
                                        <td style={{  padding: "3px", position: "relative", textAlign: "right" }}>₹ {item.price * item.qty}</td>
                                    </tr>
                                    
                                    <tr >
                                        <td style={{  padding: "3px", position: "relative"}} colSpan={4} className="discount">
                                        {item.discountPercent} % Discount
                                        </td>
                                        <td style={{  padding: "3px", position: "relative"}} style={{ textAlign: "right" }}>₹ {currency(item.price).multiply(item.qty).multiply(100 - item.discountPercent).divide(100).value} </td>
                                    </tr>
                                    </Fragment>
                                ))}
                                <tr/>
                                <tr style={{ height: 10 }}>
                                    <td colSpan={4} style={{ padding: "3px", position: "relative"}} style={{ textAlign: "right" }}>Sub Total</td>
                                    <td style={{ padding: "3px", position: "relative"}} style={{ textAlign: "right" }}>₹ {controllerProps.record && controllerProps.record.subTotal}</td>
                                </tr>
                                <tr style={{ height: 10 }}>
                                    <td colSpan={4} style={{ padding: "3px", position: "relative"}} style={{ textAlign: "right" }}>Discount</td>
                                    <td style={{ padding: "3px", position: "relative"}} style={{ textAlign: "right" }}>₹ {controllerProps.record && controllerProps.record.totalDiscount}</td>
                                </tr>
                                <tr style={{ height: 10 }}>
                                    <td colSpan={4} style={{ padding: "3px", position: "relative"}} style={{ textAlign: "right" }}>Total</td>
                                    <td style={{ padding: "3px", position: "relative"}} style={{ textAlign: "right" }}>₹ {controllerProps.record && controllerProps.record.totalAmount}</td>
                                </tr>

                                {/* <tr >
                                    <td style={{  padding: "3px", position: "relative"}} colSpan={5}>&nbsp;</td>
                                </tr>  */}
                                {/* <tr >
                                    <td style={{  padding: "3px", position: "relative"}} colSpan={3} style={{ textAlign: "right" }}>
                                        Cash{" "}
                                    </td>
                                    <td style={{  padding: "3px", position: "relative"}} style={{ textAlign: "right" }}>-₹108.00</td>
                                </tr>
                                <tr >
                                    <td style={{  padding: "3px", position: "relative"}} colSpan={4}>&nbsp;</td>
                                </tr> */}
                                {/* <tr >
                                    <td colSpan={3} style={{  padding: "3px", position: "relative"}} style={{ textAlign: "right" }}>
                                        Change Due
                                    </td>
                                    <td style={{ padding: "3px", position: "relative"}} style={{ textAlign: "right" }}>₹{controllerProps.record && controllerProps.record.totalAmount}</td>
                                </tr> */}
                            </tbody>
                        </table>
                    </div>
                </SimpleShowLayout>
            </ShowView>
        }
    </ShowController>
);


// const enhance = compose(
//     withStyles(styles),
// );


export default SupplierBillShow;
