import React, { Fragment, Component } from 'react';
import {
    List,
    Datagrid,
    TextField,
    Filter,
    SearchInput,
    CardActions,
    CreateButton,
    EditButton,
    ReferenceInput,
    AutocompleteInput,
    NumberField,
    RefreshButton
} from 'react-admin';

import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { push } from 'react-router-redux';
import { withStyles } from '@material-ui/core';
import { Route, Switch } from 'react-router';
import { Drawer } from '@material-ui/core';
import CustomerCreate from './CustomerCreate';
import CustomerEdit from './CustomerEdit';


const styles = {
    drawerContent: {
        width: 450
    }
};

export const ItemFilter = props => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <ReferenceInput source="manufacturerId" reference="v2/catalog/manufacturer">
            <AutocompleteInput
                optionText={choice =>
                    `${choice.name}`
                }
            />
        </ReferenceInput>
    </Filter>
);


const CustomerListActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total
}) => (
    <CardActions>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        }) }
        <CreateButton basePath={basePath} />
        
        <RefreshButton />

    </CardActions>
);



class ItemList extends React.Component {
    render() {
        const { push, classes, ...props } = this.props;
        return (
            <Fragment>
                <List
                    title="Items"
                    {...props}  
                    exporter={false} 
                    perPage={25}
                    filters={<ItemFilter />}
                    sort={{ field: 'id', order: 'DESC' }}
                    actions={<CustomerListActions />}
                >
                    <Datagrid>
                        <TextField source="id" />
                        <TextField source="name" />
                        <TextField source="mobileNumber" />
                        <TextField source="email" />
                        <EditButton/>
                    </Datagrid>
                </List>
                <Route path="/sale/customer/create">
                    {({ match }) => (
                        <Drawer
                            open={!!match}
                            anchor="right"
                            onClose={this.handleClose}
                        >
                            <CustomerCreate
                                className={classes.drawerContent}
                                onCancel={this.handleClose}
                                {...props}
                            />
                        </Drawer>
                    )}
                </Route>
                <Route path="/sale/customer/:id">
                    {({ match }) => {
                        const isMatch =
                            match &&
                            match.params &&
                            match.params.id !== 'create';

                        return (
                            <Drawer
                                open={isMatch}
                                anchor="right"
                                onClose={this.handleClose}
                            >
                                {isMatch ? (
                                    <CustomerEdit
                                        className={classes.drawerContent}
                                        id={isMatch ? match.params.id : null}
                                        onCancel={this.handleClose}
                                        {...props}
                                    />
                                ) : (
                                    <div className={classes.drawerContent} />
                                )}
                            </Drawer>
                        );
                    }}
                </Route>
            </Fragment>
        );
    }

    handleClose = () => {
        this.props.push('/sale/customer');
    };
}

export default compose(
    connect(
        undefined,
        { push }
    ),
    withStyles(styles)
)(ItemList);

