import React, { useState, Fragment, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import {
    showNotification,
    Button,
    withDataProvider,
    CREATE,
    UPDATE,
    GET_ONE
} from 'react-admin';
import MultiAutoSuggest from '../../common/MultiAutoSuggest';
import {SupplierOrderSettlementType, SupplierOrderDeliveryType } from '../purchaseorders/SupplierOrderTypes'
import currency from "currency.js";


import TextField from '@material-ui/core/TextField';


// load the supploier bill
// show popup with values
// work on edit.

const SupplierBillEditButton = (props) => {

    const { itemId, onCreate, onUpdate } = props

    const [supplier, setSupplier] = useState({});
    const [billAmount, setBillAmount] = useState('');
    const [billDate, setBillDate] = useState()
    const [billNumber, setBillNumber] = useState('');
    const [deliveryType, setDeliveryType] = useState('');
    const [settlementType, setSettlementType] = useState('');

    const [item, setItem] = useState({});

    const [showDialog, setShowDialog] = useState(false)
    const [submitClicked, setSubmitClicked] = useState(false)

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const setData = (item) => {
        setItem(item)
        const _supplier = { id: item.supplierId, name: item.supplierName }
        setSupplier(_supplier)
        setBillAmount(item.billAmount)
        setBillDate(new Date(item.billDate).toISOString().substring(0, 10))
        setBillNumber(item.billNumber)
        setDeliveryType(item.deliveryType)
        setSettlementType(item.settlementType)
    }

    const resetState = () => {
        setSupplier({})
        setBillAmount('')
        setBillDate()
        setBillNumber('')
        setDeliveryType('')
        setSettlementType('')
    }

    const  validate = () => {
        let flag =  (supplier != null && 
                billAmount != null && String(billAmount).length > 0 && Number(billAmount) >=0 &&
                billDate != null &&
                billNumber != null && billNumber.length > 0 
                ) 
        console.log(flag)
        return true
    }

    useEffect(() => {
        const fetchData = async () => {
          setIsError(false);
          setIsLoading(true);

          var params = { id: itemId}

          const {
            dispatch,
            dataProvider
            } = props;

          dataProvider(GET_ONE, 'suppliers/bill', params)
            .then((json) => {
                setData(json.data);
                setIsLoading(false);
            })
            .catch((e) => {
                setIsError(true);
                dispatch(showNotification('Error: Something went wrong', 'warning'))
                setIsLoading(false);
            });
        };
        if (showDialog && itemId) {
            fetchData();
        }

      }, [showDialog]);

      const generateUpdatePayload = () => {
        var payload = {
            id: item.id,
            data : {
                id: item.id,
                billAmount: currency(billAmount).value,
                billNumber: billNumber,
                billDate: new Date(billDate),
                supplierId: supplier.id,
                supplierName: supplier.name,
                deliveryType: deliveryType,
                settlementType: settlementType
            }
        }
        return payload
    }

      const handleUpdate = () => {
        if (!submitClicked) {
            setSubmitClicked(true)
            const {
                dispatch,
                dataProvider
            } = props;
    
            var payload = generateUpdatePayload()
        
            dataProvider(UPDATE, 'suppliers/bill', payload)
            .then((json) => {
                onUpdate(json.data)
                dispatch(showNotification('Item updated with id:' + json.data.id)) 
                setShowDialog(false)
                setSubmitClicked(false)
                resetState()
            })
            .catch((e) => {
                console.log(e)
                setSubmitClicked(false)
                dispatch(showNotification('Error: Something went wrong', 'warning'))
            });
            };
        }

        const onSupplierSelection = (selectedSupplier) => {
            if (selectedSupplier) {
                const supplier = { id: selectedSupplier.value, name: selectedSupplier.label }
                setSupplier(supplier)
            } else {
                setSupplier(null)
            }
          }

        const getSupplier = () => {
            if (supplier) {
                const _supplier = { value: supplier.id, label: supplier.name }
                return [_supplier]
            } else {
                return []
            }
          }

        const strlength = (string) => {
        if (string != null) {
            return string.length
        } else {
            return 0
        }
    }


    return (
        <Fragment>
            <a href="#" onClick={(event) => { event.preventDefault(); setShowDialog(true) } }>Edit</a>
            <Dialog fullWidth open={showDialog} onClose={() => { setShowDialog(false); resetState() }} aria-label="Update Supplier Bill">
            { isLoading ? <h2>Loading..</h2> : 
                <Fragment>
                    <DialogTitle>Update Supplier Bill</DialogTitle>
                    <DialogContent style={{marginTop: -10}}>
                    <MultiAutoSuggest inputId="SUPPLIER" placeholder="Select Supplier" apiUrl="/suppliers/supplier"  selectedValue={getSupplier()} onSelected={onSupplierSelection} />

                        <div style={{ display: 'inline-flex' }}>
                                <div style={{ marginTop: 16 }}>
                                    <TextField
                                        id="billDate"
                                        label="Bill Date"
                                        type="date"
                                        onChange={(e) => setBillDate(e.target.value)}
                                        value={billDate}
                                        InputLabelProps={{
                                        shrink: true,
                                        }}
                                    />
                                </div>
                                <div style={{ marginLeft: 10  }}>
                                    <TextField
                                    required
                                    id="billNumber"
                                    label="Bill No"
                                    value={billNumber}
                                    onChange={(e) => setBillNumber(e.target.value)}
                                    margin="normal"
                                    error={ strlength(billNumber) === 0}
                                    helperText={ strlength(billNumber) === 0 ? "Can't be empty" : ""}
                                    />
                                </div>
                                <div style={{ marginLeft: 10 }}>
                                    <TextField
                                    required
                                    id="billAmount"
                                    label="Bill Amount"
                                    value={billAmount}
                                    onChange={(e) => setBillAmount(e.target.value)}
                                    error={ strlength(billAmount) === 0}
                                    helperText={ strlength(billAmount) === 0 ? "Can't be empty" : ""}
                                    margin="normal"
                                    />
                                </div>
                            </div>
                            <div style={{ marginTop: 7, display: 'inline-flex' }}>
                                <div style={{  width: 200 }}>
                                    <TextField
                                    id="settlementType"
                                    fullWidth
                                    select
                                    label="Settlement Type"                         
                                    onChange={(e) => setSettlementType(e.target.value)}
                                    value={settlementType}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    margin="normal"
                                    >
                                    {SupplierOrderSettlementType.map(option => (
                                        <option key={option.id} value={option.id}>
                                        {option.name}
                                        </option>
                                    ))}
                                    </TextField>
                                </div>
                                <div style={{ marginLeft: 10,  width: 200 }}>
                                    <TextField
                                    id="deliveryType"
                                    fullWidth
                                    select
                                    label="Delivery Type"                         
                                    onChange={(e) => setDeliveryType(e.target.value)}
                                    value={deliveryType}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    margin="normal"
                                    >
                                    {SupplierOrderDeliveryType.map(option => (
                                        <option key={option.id} value={option.id}>
                                        {option.name}
                                        </option>
                                    ))}
                                    </TextField>
                                </div>
                            </div>
                    </DialogContent>
                    <DialogActions>
                        <Button label="Update" disabled={!validate()} onClick={handleUpdate} />
                    <Button label="Cancel" onClick={() => { setShowDialog(false); resetState() }}></Button>
                    </DialogActions>
                </Fragment>
            }
            </Dialog>
        </Fragment>);
}   


export default withDataProvider(SupplierBillEditButton)
