import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    NumberField,
    BooleanField,
    Filter,
    BooleanInput,   
    EditButton,
    SearchInput,
    DateInput,
    SelectInput,
    RadioButtonGroupInput,  CardActions, RefreshButton, CreateButton
    
} from 'react-admin';
import UncapturedOrdersButton from '../../common/UncapturedOrdersButton';
import CloseQuoteButton from './CloseQuoteButton';

const CreateDateField = ({ record = {} }) => <span>{record.createDate}</span>;
CreateDateField.defaultProps = { label: 'Create Date' };

//   const getLocation = () => {
//     const initialValue = 1
//     try {
//         // Get from local storage by key
//         const item = window.localStorage.getItem('locationId');
//         // Parse stored json or if none return initialValue
//         return item ? JSON.parse(item) : initialValue;
//       } catch (error) {
//         // If error also return initialValue
//         console.log(error);
//         return initialValue;
//       }
// }

const QuoteListActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total
}) => (

<CardActions>
{bulkActions && React.cloneElement(bulkActions, {
    basePath,
    filterValues,
    resource,
    selectedIds,
    onUnselectItems,
})}
{filters && React.cloneElement(filters, {
    resource,
    showFilter,
    displayedFilters,
    filterValues,
    context: 'button',
}) }

<UncapturedOrdersButton/>
<CreateButton basePath={basePath} />

</CardActions>
);

const QuoteFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <DateInput source="quoteDate" alwaysOn />
       

        {/* <SelectInput allowEmpty={false} label="Location" source="locationId" alwaysOn choices={[
        { id: '1',  name: 'SHOP' },
        { id: '2',  name: 'BOOKFAIR' },]
        } /> */}
        
        <SelectInput source="channelType" alwaysOn choices={ [
        { id: 'SHOP',        name: 'Shop' },
        { id: 'PANUVAL_COM', name: 'Panuval.Com' },
        { id: 'PHONE',       name: 'Phone' },
        { id: 'WHATSAPP',    name: 'WhatsApp' },
        { id: 'REQUEST_PORTAL', name: 'Request Portal' },
        { id: 'SOCIAL_MEDIA',name: 'Social Media' },
        { id: 'OTHERS',      name: 'Others' },
        ]
        } />

        <SelectInput source="status" alwaysOn choices={[
        { id: 'OPEN',        name: 'Open' },
        { id: 'WORKING',      name: 'Working' }, 
        { id: 'SUSPENDED',      name: 'Suspended' },
        { id: 'CLOSED',      name: 'Closed' },   ]
        } />

    </Filter>
);


const QuoteList = props => (
    <List filters={<QuoteFilter />}  actions={<QuoteListActions />}  exporter={false}  {...props}  
            //filterDefaultValues={{ locationId: getLocation() }} 
            perPage={25}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            {/* <DateField source="createDate" showTime /> */}
            <DateField source="quoteDate"/>
            <TextField source="name" />
            <TextField source="mobileNumber" />
            <TextField source="channelType" />
            <TextField source="quoteClosedReason" label="closedReason" />
            <TextField source="status" />
            <NumberField source="total" />
            <TextField label="Orders #" source="orderId" />
            <CloseQuoteButton />
        </Datagrid>
    </List>
);

export default QuoteList;
