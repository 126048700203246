import React, { Fragment } from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    NumberField,
    BooleanField,
    Filter,
    BooleanInput,   
    EditButton,
    SearchInput,
    DateInput,
    SelectInput,
    RadioButtonGroupInput, CardActions, RefreshButton
    
} from 'react-admin';

import CreateHandOverButton from '../../common/CreateHandOverButton'
import HandOverItemsButton from '../../common/HandOverItemsButton'
import AddEditTrackingButton from '../../common/AddEditTrackingButton' 

import { ShippingCarrierTypes, ShippingStatusTypes } from '../../common/ShippingDetailTypes'
import ShippingCarrierField from '../../common/ShippingCarrierField'
import ShippingStatusField from '../../common/ShippingStatusField'
import CurrencyField from '../../common/CurrencyField'
import WeightField from '../../common/WeightField'

const BulkActionButtons = props => {
    const { selectedIds } = props
    return (<Fragment>
        <HandOverItemsButton selectedIds={selectedIds} />
    </Fragment>)
}

const ShippingListActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total
}) => (

<CardActions>
{bulkActions && React.cloneElement(bulkActions, {
    basePath,
    filterValues,
    resource,
    selectedIds,
    onUnselectItems,
})}
{filters && React.cloneElement(filters, {
    resource,
    showFilter,
    displayedFilters,
    filterValues,
    context: 'button',
}) }

<RefreshButton />

</CardActions>
);



const ShippingFilter = (props) => (
    <Filter {...props}  >
        <SearchInput source="q" alwaysOn />
    
        <SelectInput source="shippingStatus" alwaysOn choices={ShippingStatusTypes} />
        <SelectInput source="shippingCarrier" alwaysOn choices={ShippingCarrierTypes} />
        <DateInput source="shippingDate" alwaysOn />
        <DateInput source="fulfillmentDate" alwaysOn />
        

    </Filter>
);



const ShippingList = props => {
    return (
    <List filters={<ShippingFilter />} bulkActionButtons={<BulkActionButtons />}  actions={<ShippingListActions />} exporter={false}  {...props}  perPage={25}>
        <Datagrid rowClick="show" >
            <TextField source="id" label="Id"/>
            <TextField source="fulfillmentId" label="Fulfillment#"/>
            <DateField source="fulfillmentDate" label="FulfillmentDate"/>
            <TextField source="orderId" label="Order#"/>
            <TextField source="referenceId" label="Online#"/>
            <TextField source="customerName" label="Customer" />
            <TextField source="city" />
            <TextField source="pincode" />
            <DateField source="shippingDate" />
            <ShippingStatusField source="shippingStatus" />
            <WeightField source="shippingWeight" />
            <CurrencyField source="shippingAmount" />
            <ShippingCarrierField source="shippingCarrier" />
            <AddEditTrackingButton />
        </Datagrid>
    </List>
    );
    }
export default ShippingList;

