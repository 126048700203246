import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { change, reset, submit, isSubmitting, unregisterField } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import {
    fetchEnd,
    fetchStart,
    required,
    showNotification,
    crudGetMatching,
    Button,
    SaveButton,
    SimpleForm,
    TextInput,
    LongTextInput,
    CREATE,UPDATE,
    REDUX_FORM_NAME,
    ReferenceInput,
    AutocompleteInput,
    NumberInput, 
    withDataProvider,
    Edit
} from 'react-admin';

import IconContentEdit from '@material-ui/icons/Edit';
import IconCancel from '@material-ui/icons/Cancel';
import Drawer from '@material-ui/core/Drawer';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import DrawerAutocompleteInput from '../../common/DrawerAutocompleteInput'
import dataProvider from '../../dataProvider';

const styles = {
    // name: { display: 'inline-block' },
    // englishName: { display: 'inline-block', marginLeft: 32 },
    // manufactuerName: { display: 'inline-block', marginLeft: 32 },
};


class QuickItemCreateButton extends Component {
    state = {
        error: false,
        showDialog: false
    };

    handleClick = () => {
        this.setState({ showDialog: true });
    };

    handleCloseClick = () => {
        this.setState({ showDialog: false });
    };

    handleSaveClick = () => {
        const { submit } = this.props;

        // Trigger a submit of our custom quick create form
        // This is needed because our modal action buttons are oustide the form
        submit('post-quick-edit');
    };

    handleSubmit = values => {
        const {
            change,
            crudGetMatching,
            fetchStart,
            fetchEnd,
            showNotification,
            dispatch,
            dataProvider,
            onUpdate,
            data
        } = this.props;

        var _data = {
            id: data.id,
            isbn: values.isbn,
            price: values.price,
            manufacturerId: values.manufacturerId
        }
        var payload = { id: data.id, data: _data }
        dataProvider(UPDATE, 'v2/catalog/item/quickedit', payload)
        .then((json) => {
            // console.log(data)
            var _item = {}
            _item.isbn = json.data.isbn
            _item.name = json.data.name
            _item.id = json.data.id
            _item.price = json.data.price
            var obj = { ...data , isbn: json.data.isbn, price: json.data.price, manufacturerId: json.data.manufacturerId, manufacturerName: json.data.manufacturerName }
            onUpdate(obj);
            dispatch(showNotification('Item Updated :' + json.data.id)) 
            this.setState({ showDialog: false });
            dispatch(reset('post-quick-edit'));
        })
        .catch((e) => {
          dispatch(showNotification('Error: Something went wrong', 'warning'))
        });

        // //usewithdataprovider
        // // Dispatch an action letting react-admin know a API call is ongoing
        
        // fetchStart();
        // // add mutation here 

        // // As we want to know when the new post has been created in order to close the modal, we use the
        // // dataProvider directly
        // dataProvider(CREATE, 'posts', { data: values })
        //     .then(({ data }) => {
        //         // Refresh the choices of the ReferenceInput to ensure our newly created post
        //         // always appear, even after selecting another post
        //         crudGetMatching(
        //             'posts',
        //             'comments@post_id',
        //             { page: 1, perPage: 25 },
        //             { field: 'id', order: 'DESC' },
        //             {}
        //         );

        //         // Update the main react-admin form (in this case, the comments creation form)
        //         // add the item to receiving here
        //         change(REDUX_FORM_NAME, 'post_id', data.id);
        //         
        //     })
        //     .catch(error => {
        //         showNotification(error.message, 'error');
        //     })
        //     .finally(() => {
        //         // Dispatch an action letting react-admin know a API call has ended
        //         fetchEnd();
        //     });
    };

    render() {
        const { showDialog } = this.state;
        const { isSubmitting, onCreate, classes, data } = this.props;
        console.log(this.props)
        
        return (
            <Fragment>
                <Button onClick={this.handleClick} label="ra.action.edit">
                    <IconContentEdit />
                </Button>
                <Drawer open={showDialog} className={classes.drawerContent} anchor="right" onClose={this.handleCloseClick}>
                    <SimpleForm defaultValue={data} form="post-quick-edit" resource="v2/catalog/item" onSubmit={this.handleSubmit} toolbar={null}>
                        <TextInput disabled autoFocus source="name" style={{ width: 400 }} validate={required()} />
                        <ReferenceInput required label="Publisher" source="manufacturerId" reference="v2/catalog/manufacturer">
                            <DrawerAutocompleteInput optionText={choice => `${choice.name}` } />
                        </ReferenceInput>
                        <TextInput source="isbn" label="ISBN"/>
                        <NumberInput source="price" style={{ width: 150 }} validate={required()} label="Price"/>
                    </SimpleForm>
                    <DialogActions>
                    <SaveButton saving={isSubmitting} onClick={this.handleSaveClick}/>
                    <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
                            <IconCancel />
                    </Button>
                    </DialogActions>

                </Drawer>
                {/* <Dialog fullWidth open={showDialog} onClose={this.handleCloseClick} aria-label="Create Item">
                    <DialogTitle>Edit Item</DialogTitle>
                    <DialogContent>
                   
                    <SimpleForm defaultValue={data} form="post-quick-edit" resource="v2/catalog/item" onSubmit={this.handleSubmit} toolbar={null}>
                        <TextInput disabled autoFocus source="name" style={{ width: 400 }} validate={required()} />
                        <ReferenceInput required label="Publisher" source="manufacturerId" reference="v2/catalog/manufacturer">
                            <DrawerAutocompleteInput optionText={choice => `${choice.name}` } />
                        </ReferenceInput>
                        <TextInput source="isbn" label="ISBN"/>
                        <NumberInput source="price" style={{ width: 150 }} validate={required()} label="Price"/>
                    </SimpleForm>
                   
                    </DialogContent>
                    <DialogActions>
                        <SaveButton saving={isSubmitting} onClick={this.handleSaveClick}/>
                        <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
                            <IconCancel />
                        </Button>
                    </DialogActions>
                </Dialog> */}
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    isSubmitting: isSubmitting('post-quick-edit')(state)
});

const mapDispatchToProps = {
    change,
    crudGetMatching,
    fetchEnd,
    fetchStart,
    showNotification,
    submit
};

QuickItemCreateButton.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  
  
const enhance = compose(
    withDataProvider,
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
  );
  
  
export default enhance(QuickItemCreateButton);
