import React, { Component } from 'react';
import { Fragment } from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ItemAutoSuggest from '../../receivings/stocktaking/ItemAutoSuggest'
import Typography from '@material-ui/core/Typography';
import { connect } from "react-redux";
import { createSelector } from "reselect";
import DeleteButton from '../../common/DeleteButton'
import compose from 'recompose/compose';
import { Mutation, withDataProvider, GET_ONE  } from 'react-admin';
import currency from "currency.js";
import CustomerQuickCreateButton from '../../common/CustomerQuickCreateButton'
import ItemQuickCreateButton from '../../receivings/stocktaking/ItemQuickCreateButton'
import ItemQuickEditButton from '../../receivings/stocktaking/ItemQuickEditButton'
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import MenuItem from '@material-ui/core/MenuItem';
import locations from '../../common/location'

import * as cartActions from '../../common/cart.actions';
import AutoSuggest from '../../common/AutoSuggest'

const sideEffects = {
  onSuccess: {
    notification: {
      body: 'Sale Submitted',
      level: 'info',
    },
    redirectTo: '/sale/receipt',
  },
  onFailure: {
    notification: {
      body: 'Receiving failed',
      level: 'warning',
    },
  },
};

const salesChannelTypes = [
      { value: 'OTHERS',      label: 'Others' },
      { value: 'SHOP',        label: 'Shop' },
      { value: 'BOOK_FAIR',   label: 'Book Fair' },
      { value: 'PANUVAL_COM', label: 'Panuval.Com' },
      { value: 'AMAZON',      label: 'Amazon' },
      { value: 'FLIPKART',    label: 'Flipkart' },
      { value: 'PHONE',       label: 'Phone' },
      { value: 'WHATSAPP',    label: 'WhatsApp' },
      { value: 'SOCIAL_MEDIA',label: 'Social Media' },
      { value: 'REFERRAL',    label: 'Referral' },
      { value: 'REQUEST_PORTAL', label: 'Request Portal' },
      { value: 'ANDROID_APP', label: 'Android App' },
      { value: 'IOS_APP',     label: 'iOS App' },
    ];

const salesPaymentTypes = [
  { value: 'OTHERS',      label: 'Others' },
  { value: 'CASH',        label: 'Cash' },
  { value: 'CARD',        label: 'Card' },
  { value: 'CHECK',       label: 'Check' },
  { value: 'CREDIT',      label: 'Credit' },
  { value: 'VPP',         label: 'VPP' },
  { value: 'BANK_TRANSFER', label: 'Bank Transfer' },
  { value: 'MONEY_ORDER', label: 'Money Order' },
  { value: 'PAYTM',       label: 'Paytm' },
  { value: 'GIVE_AWAY',   label: 'Gift' },
  { value: 'PAYUBIZ',     label: 'PayuBiz' },
  { value: 'AMAZON',      label: 'Amazon' },
  { value: 'FLIPKART',    label: 'Flipkart' },
];

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 2,
    overflowX: 'auto',
  },
  textField: {
    marginBottom: 20,
    width: 200,
  },
  table: {
    minWidth: 700,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 2,
    //textAlign: 'center',
    //color: theme.palette.text.secondary,
  },
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
});


function sortProperties(obj, sortedBy, isNumericSort, reverse) {
  sortedBy = sortedBy || 1; // by default first key
  isNumericSort = isNumericSort || false; // by default text sort
  reverse = reverse || false; // by default no reverse

  var reversed = (reverse) ? -1 : 1;

  var sortable = [];
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      sortable.push([key, obj[key]]);
    }
  }
  if (isNumericSort)
    sortable.sort(function (a, b) {
      return reversed * (a[1][sortedBy] - b[1][sortedBy]);
    });
  else
    sortable.sort(function (a, b) {
      var x = a[1][sortedBy].toLowerCase(),
        y = b[1][sortedBy].toLowerCase();
      return x < y ? reversed * -1 : x > y ? reversed : 0;
    });
  return sortable; // array in format [ [ key1, val1 ], [ key2, val2 ], ... ]
}

const summaryReducer = (_summary, cur) => {
  var _item = cur[1];
  _summary.totalItems++;
  _summary.totalQty = _summary.totalQty + _item.qty;
  _summary.totalAmount = currency(_summary.totalAmount).add(currency(_item.costPrice).multiply(_item.qty)).value;
  _summary.totalDiscount = currency(_summary.totalDiscount).add(currency(_item.price).multiply(_item.discountPercent).divide(100).multiply(_item.qty)).value;
  _summary.subTotal = currency(_summary.subTotal).add(currency(_item.price).multiply(_item.qty)).value;
  return _summary;
}

function getSuggestionValue(suggestion) {
  //console.log(suggestion)
  return suggestion.name;
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.name, query);
  const parts = parse(suggestion.name + " [" + suggestion.mobileNumber + "] " + "[" + suggestion.email + "]", matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) =>
          part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
          ) : (
              <strong key={String(index)} style={{ fontWeight: 300 }}>
                {part.text}
              </strong>
            ),
        )}
      </div>
    </MenuItem>
  );
}

const getCartArraySelector = createSelector(
  state => state.cart.items,
  state => state.cart.shippingAmount,
  state => state.cart.otherDiscount,
  (items, shippingAmount, otherDiscount)   => {
    const arr = sortProperties(items, 'rowid', true, true);
    const _arr = arr.map(x => x[1])
    var summary = {
      totalItems: 0,
      totalQty: 0,
      totalAmount: 0.0,
      totalDiscount: 0.0,
      subTotal: 0.0,
      otherDiscount: 0.0,
      shippingAmount: 0.0
    }
  
    const _summary = arr.reduce(summaryReducer, summary);
    _summary.totalAmount = currency(_summary.totalAmount).subtract(currency(otherDiscount)).add(currency(shippingAmount)).value
    _summary.otherDiscount = currency(otherDiscount).value
    _summary.shippingAmount = currency(shippingAmount).value
    var _obj = {
      summary: _summary,
      items: _arr
    }
    return _obj
  }
);


const getLocation = () => {
  const initialValue = 1
  try {
      // Get from local storage by key
      const item = window.localStorage.getItem('locationId');
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
}


const setLocation = (value) => {
  window.localStorage.setItem('locationId', value);
}

class ReceiptCreate extends Component {

  state = {
    id: '',
    customer: {},
    receiptDate: new Date().toISOString().substring(0, 10),
    invoiceNumber: '',
    comment: '',
    salesPaymentType: 'CASH',
    salesChannelType: 'SHOP',
    requireShipping: false,
    shippingAmount: 0,
    invoiceAmount: '',
    locationId: getLocation(),
    subTotal: '',
    otherDiscount: 0,
    totalDiscount: 0.0,
    totalAmount: 0.0,
    totalQty: '',
    totalUnitsQty: '',
    paid: true
  }

  onDeleteCartItemClick = row => {
    this.props.removeItemReceipt(row)
  };

  onCustomerSelection = customer => {
    console.log(customer)
    this.setState({
      customer: customer
    })
  };
 
  onItemSuggestionClick = _item => {
    if  (!(_item.id in this.props.cartObj.items)) {
      var  item = { }
      item.id = _item.id
      item.isbn = _item.isbn
      item.name = _item.name
      item.price = _item.price
      item.manufacturerName = _item.manufacturerName
      item.manufacturerId = _item.manufacturerId

      if (this.state.customer.discountPercent) {
        item.discountPercent = this.state.customer.discountPercent;
      } else {
        item.discountPercent = 0
      }
    }
    this.props.addItemReceipt(item)
  };

  handlePriceChange = item => (e) => {
    //var obj = { id: item.id, isbn: item.isbn, rowid: item.rowid, name: item.name, price: e.target.value, qty: item.qty }
    var obj = { ...item, price: e.target.value }
    this.props.updateItemReceipt(obj)
  }

  handleQtyChange = item => (e) => {
    //var obj = { id: item.id, isbn: item.isbn, rowid: item.rowid, name: item.name, price: item.price , qty: parseInt(e.target.value) }
    var obj = { ...item, qty: parseInt(e.target.value) }
    this.props.updateItemReceipt(obj)
  }

  handleDiscountChange = item => (e) => {
    //var obj = { id: item.id, isbn: item.isbn, rowid: item.rowid, name: item.name, price: item.price , qty: parseInt(e.target.value) }
    var obj = { ...item, discountPercent: parseInt(e.target.value) }
    this.props.updateItemReceipt(obj)
  }

  onKeyDown = (e) => {
    if (e.keyCode === 8 || e.keyCode === 46) {
      e.target.value = ""
      e.preventDefault();
    }
  }

  componentWillUnmount() {
    this.props.removeAllItemsReceipt()

  }

  componentDidMount() {
    const {
      dataProvider,
    } = this.props;
    if (this.props.id) {
      var params = { id: this.props.id }
      dataProvider(GET_ONE, 'sale/receipt', params)
      .then((json) => {
          //dispatch(showNotification('Fetched Receipt :' + json.data.id))
          this.setState({
            id:  this.props.id,
            customer: {
              name: json.data.name,
              email: json.data.email,
              mobileNumber: json.data.mobileNumber,
              id: json.data.customerId,
              discountPercent: json.data.customerDiscountPercent,
            },
            receiptDate: new Date(json.data.receiptDate).toISOString().substring(0, 10),
            invoiceNumber: json.data.invoiceNumber,
            comment: json.data.comment,
            salesPaymentType: json.data.salesPaymentType,
            salesChannelType: json.data.salesChannelType,
            requireShipping: json.data.requireShipping,
            shippingAmount: json.data.shippingAmount,
            otherDiscount: json.data.otherDiscount,
            paid: json.data.paid,
            status: json.data.status
          })
          
          json.data.salesReceiptItems.forEach((receiptItem) => {
            const  _item = { }
            _item.id = receiptItem.itemId
            _item.isbn = receiptItem.isbn
            _item.name = receiptItem.name
            _item.price = receiptItem.itemPrice
            _item.qty = receiptItem.qty
            _item.manufacturerName = receiptItem.manufacturerName
            _item.manufacturerId = receiptItem.manufacturerId
            _item.discountPercent = receiptItem.discountPercent
            this.props.addItemReceipt(_item);
          });
          
          this.props.updateShippingAmount(json.data.shippingAmount);
          this.props.updateOtherDiscount(json.data.otherDiscount);

      })
      .catch((e) => {
          //dispatch(showNotification('Error: Something went wrong', 'warning'))
      });  
    }
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  changeLocation = event => {
    this.setState({
      locationId: event.target.value,
    });
    setLocation(event.target.value)
  };

  handleShippingAmountChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
    this.props.updateShippingAmount(event.target.value)
  };


  handleOtherDiscountChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
    this.props.updateOtherDiscount(event.target.value)
  };


  handleChecked = name => (event, checked) => {
    this.setState({
      [name]: checked,
    });
  };

  render() {
    const { classes, cartObj, ...props } = this.props;
    return (
      <Fragment>
        <Grid container direction="row" justify="flex-end" alignItems="flex-start" >
          <CustomerQuickCreateButton onCreate={this.onCustomerSelection} />
        </Grid>

        <AutoSuggest placeholder="Type Customer Name or Mobile Number or Email" fetchUrl="sale/customer" onItemSuggestionClick={this.onCustomerSelection} renderSuggestion={renderSuggestion} getSuggestionValue={getSuggestionValue} parameterName='name' />

        <Grid container spacing={8}>
          <Grid item xs={8}>
            <Paper className={classes.paper}>


              <TextField
                  id="select-currency-native"
                  select
                  label="Location"                         
                  onChange={this.changeLocation}
                  value={this.state.locationId}
                  SelectProps={{
                      native: true,
                  }}
                  margin="normal"
                  style={{ marginLeft: 10 }}
              >
                  {locations.map(option => (
                  <option key={option.value} value={option.value}>
                      {option.label}
                  </option>
                  ))}
              </TextField>

              <TextField
                id="receiptDate"
                label="Bill Date"
                type="date"
                className={classes.textField}
                value={this.state.receiptDate}
                style={{ marginLeft: 10 }}
                onChange={this.handleChange('receiptDate')}
                // defaultValue={this.state.receiptDate}
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <TextField
                id="invoiceNumber"
                label="Order #"
                className={classes.textField}
                value={this.state.invoiceNumber}
                onChange={this.handleChange('invoiceNumber')}
                style={{ marginLeft: 10 }}
                margin="normal"
              />

              <TextField
                id="select-currency-native"
                select
                label="Payment Type"
                className={classes.textField}
                value={this.state.salesPaymentType}
                onChange={this.handleChange('salesPaymentType')}
                SelectProps={{
                  native: true,
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                //helperText="Please select your currency"
                margin="normal"
                style={{ marginLeft: 10 }}
              >
                {salesPaymentTypes.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>

              <TextField
                id="select-currency-native"
                select
                label="Sales Channel"
                className={classes.textField}
                value={this.state.salesChannelType}
                onChange={this.handleChange('salesChannelType')}
                SelectProps={{
                  native: true,
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                //helperText="Please select your currency"
                margin="normal"
                style={{ marginLeft: 10 }}
              >
                {salesChannelTypes.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ marginLeft: 10 }}
                    checked={this.state.paid}
                    defaultValue={this.state.paid}
                    onChange={this.handleChecked('paid')}
                  />
                }
                label="Paid"
              />

            <FormControlLabel
                control={
                  <Checkbox
                    //style={{ marginLeft: 20 }}
                    checked={this.state.requireShipping}
                    defaultValue={this.state.requireShipping}
                    onChange={this.handleChecked('requireShipping')}
                  />
                }
                label="Shipping"
              />

              <TextField
                id="shippingAmount"
                disabled={!this.state.requireShipping}
                label="Shipping Amount"
                className={classes.shippingAmount}
                value={this.state.shippingAmount}
                onChange={this.handleShippingAmountChange('shippingAmount')}
                style={{ marginLeft: 10, width: 150 }}
                margin="normal"
              />

            <TextField
                id="otherDiscount"
                label="Other Discount"
                defaultValue = "0"
                className={classes.otherDiscount}
                value={this.state.otherDiscount}
                onChange={this.handleOtherDiscountChange('otherDiscount')}
                style={{ marginLeft: 10, width: 150 }}
                margin="normal"
              />

              </div>

              <TextField
                id="comment"
                label="Notes"
                className={classes.textField}
                value={this.state.comment}
                onChange={this.handleChange('comment')}
                margin="normal"
                style={{ width: "100%" }}
              />
            </Paper>
          </Grid>

          <Grid item xs={4}>
            <Paper className={classes.paper}>
              <Typography variant="subheading">
                Customer: {this.state.customer.name} <br />
                Discount: {this.state.customer.discountPercent}% <br />
                Total Titles:  {cartObj.summary.totalItems} <br />
                Total Quantity: {cartObj.summary.totalQty} <br />
                SubTotal: Rs. {cartObj.summary.subTotal} <br />
                &nbsp;&nbsp;&nbsp;&nbsp; ShippingAmount: +Rs. {cartObj.summary.shippingAmount}<br />
                &nbsp;&nbsp;&nbsp;&nbsp; Discount: -Rs. {cartObj.summary.totalDiscount} <br />
                &nbsp;&nbsp;&nbsp;&nbsp; OtherDiscount: -Rs. {cartObj.summary.otherDiscount}<br />
                <font color='red'><b>To Pay:  Rs. {cartObj.summary.totalAmount} </b></font> <br />
              </Typography>
            </Paper>
          </Grid>
        </Grid> 
        {(this.state.status != 1) &&
        <Fragment>
          <Grid container direction="row" justify="flex-end" alignItems="flex-start" >
                <ItemQuickCreateButton onCreate={this.onItemSuggestionClick} />
          </Grid> 
          <ItemAutoSuggest onItemSuggestionClick={this.onItemSuggestionClick} />
        </Fragment>
      }
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell align="right">ISBN</TableCell>
                <TableCell align="right">Name</TableCell>
                <TableCell align="right">Publisher</TableCell>
                <TableCell align="right">Price</TableCell>
                <TableCell align="right">Qty</TableCell>
                <TableCell align="right">Disc%</TableCell>
                <TableCell align="right">Total</TableCell>
                <TableCell align="right">Edit</TableCell>
                <TableCell align="right">Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cartObj.items.map(row => (
                <TableRow key={row.id}>
                  <TableCell>{row.id}</TableCell>
                  <TableCell align="right">{row.isbn}</TableCell>
                  <TableCell align="right">{row.name}</TableCell>
                  <TableCell align="right">{row.manufacturerName}</TableCell>
                  {/* <TableCell align="right"> */}
                  {/* <input defaultValue={row.price} onChange={this.handleChange}/> */}
                  <TableCell style={{ width: 20 }} align="right">{row.price}</TableCell>
                  {/* <TextField value={row.price} onChange={this.handlePriceChange(row)}/> */}
                  {/* </TableCell> */}
                  <TableCell align="right">
                    <TextField disabled={(this.state.status == 1)} style={{ width: 30 }} value={row.qty} onKeyDown={this.onKeyDown} onChange={this.handleQtyChange(row)} />
                  </TableCell>
                  <TableCell align="right">
                    <TextField disabled={(this.state.status == 1)} style={{ width: 30 }} value={row.discountPercent} onKeyDown={this.onKeyDown} onChange={this.handleDiscountChange(row)} />
                  </TableCell>
                  <TableCell style={{ width: 10 }} align="right">{currency(row.costPrice).multiply(row.qty).value}</TableCell>
              <TableCell style={{ width: 10 }} align="right">{(this.state.status != 1) && <ItemQuickEditButton onUpdate={this.props.updateItemReceipt} data={row} />} </TableCell>
              <TableCell style={{ width: 10 }} align="right">{(this.state.status != 1) && <DeleteButton onDelete={() => this.onDeleteCartItemClick(row)} /> }</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
        <Paper className={classes.paper}>
          <SaveButton action={(this.state.status == 1) ? 'SAVE': 'SUSPEND'} payload={generateReceiptPayload(this.props.cartObj, this.state, 2)} classes={classes} />
          <SubmitButton disabled={!cartObj.items.length || (this.state.status == 1)}  payload={generateReceiptPayload(this.props.cartObj, this.state, 1)} classes={classes} />
        </Paper>
      </Fragment>
    );
  }

}


const SaveButton = ({ payload, classes, disabled, action }) => (
  <Mutation
    type="CREATE"
    resource="sale/receipt"
    payload={payload}
    options={sideEffects}>
    {(approve) => (
      <Button disabled={disabled}  variant="contained" color="secondary" className={classes.button} onClick={approve}>
        {action}
    </Button>
    )}
  </Mutation>
)


const SubmitButton = ({ payload, classes, disabled }) => (
  <Mutation
    type="CREATE"
    resource="sale/receipt"
    payload={payload}
    options={sideEffects}>
    {(approve) => (
      <Button disabled={disabled} style={{ marginLeft: 10 }} variant="contained" color="secondary" className={classes.button} onClick={approve}>
        SUBMIT
    </Button>
    )}
  </Mutation>
)

function generateReceiptPayload(cartObj, state, status) {
  var receipt = {
    "id" : state.id,
    "customerId": state.customer.id,
    "name" : state.customer.name,
    "mobileNumber": state.customer.mobileNumber,
    "email" : state.customer.email,
    "whatsAppNumber" : state.customer.whatsAppNumber,
    "salesPaymentType": state.salesPaymentType,
    "salesChannelType": state.salesChannelType,
    "requireShipping": state.requireShipping,
    "shippingAmount": cartObj.summary.shippingAmount,
    "receiptDate": new Date(state.receiptDate),
    "invoiceAmount": state.invoiceAmount,
    "subTotal": cartObj.summary.subTotal,
    "totalDiscount": cartObj.summary.totalDiscount,
    "otherDiscount": cartObj.summary.otherDiscount,
    "totalAmount": cartObj.summary.totalAmount,
    "totalQty": cartObj.summary.totalQty,
    "totalUnitsQty": cartObj.summary.totalItems,
    "paid": state.paid,
    "comment": state.comment,
    "invoiceNumber" : state.invoiceNumber,
    "salesReceiptItems": [],
    "status": status,
    "locationId": state.locationId
  };
  var line = 1;
  cartObj.items.forEach(function (item) {
    var receiptItem = {
      "line": line,
      "itemId": item.id,
      "name": item.name,
      "qty": item.qty,
      "unitsQty": 0,
      "price": item.price,
      "discountPercent": item.discountPercent,
      "salePrice": item.costPrice
    }
    receipt.salesReceiptItems.push(receiptItem);
    line++;
  });
  return { "data": receipt };
}

function mapStateToProps(state) {
  return {
    cartObj: getCartArraySelector(state),
  }
}

const mapDispatchToProps = {
  updateShippingAmount: cartActions.updateShippingAmount,
  updateOtherDiscount: cartActions.updateOtherDiscount,
  addItemReceipt: cartActions.addItemCart,
  removeAllItemsReceipt: cartActions.removeAllItemsCart,
  removeItemReceipt: cartActions.removeItemCart,
  updateItemReceipt: cartActions.updateItemCart,
};


ReceiptCreate.propTypes = {
  classes: PropTypes.object.isRequired,
};


const enhance = compose(
  withDataProvider,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(ReceiptCreate);