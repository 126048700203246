import React, { useState, Fragment, useEffect } from 'react';
import Switch from '@material-ui/core/Switch';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import {
    showNotification,
    Button,
    withDataProvider,
    CREATE
} from 'react-admin';

const StockResetButton = (props) => {
  const [isStockSync, setIsStockSync] = useState(false)
  const [showDialog, setShowDialog] = useState(false)
  const [submitClicked, setSubmitClicked] = useState(false)

  const handleChange = (event) => {
    setShowDialog(true)
    setIsStockSync(event.target.checked);
  };

  const handleCancel = () => {
    console.log('canceled');
    setShowDialog(false);
  }

  const handleStockReset = () => {
    console.log(props.record.manufacturerId);
    if (!submitClicked) {
        setSubmitClicked(true)
        const {
            dispatch,
            dataProvider,
        } = props;
        // console.log(fulfillmentIds)
        dataProvider(CREATE, 'catalog/manufacturer/reset-stock/' + props.record.manufacturerId, { data: { } })
        .then(() => {
            dispatch(showNotification('Updated stock to zero'))
            setSubmitClicked(false)
            setShowDialog(false)
        })
        .catch((e) => {
            setSubmitClicked(false)
            dispatch(showNotification('Error: Something went wrong', 'warning'))
        });
        };
}


  return (
    <Fragment>
        <Button size="small" variant="contained" onClick={() => setShowDialog(true)} label="ZERO STOCK"></Button>
        <Dialog maxWidth="sm" open={showDialog} onClose={() => { setShowDialog(false); }} aria-label="Quick Create Item">
            <Fragment>
            <DialogTitle style={{ marginTop: -15}}>Stock Sync </DialogTitle>
            <DialogContent style={{marginTop: -15}}>
                <p>Are you sure you want to reset stock to zero?</p>
            </DialogContent>
            <DialogActions>
                <Button label="Ok" onClick={handleStockReset} />
                <Button label="Cancel" onClick={handleCancel}></Button>
            </DialogActions>
            </Fragment>
        </Dialog>
    </Fragment>
  );
}

export default withDataProvider(StockResetButton)
