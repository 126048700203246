// hooks to maintain state
// submit using dataProvider
//

import React, { useState, Fragment, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import {
    showNotification,
    withDataProvider,
    CREATE,
    UPDATE,
    GET_ONE,
} from 'react-admin';
import { push } from 'react-router-redux';
import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField';
import {SupplierOrderSettlementType, SupplierOrderDeliveryType, SupplierOrderPaymentType} from '../../receivings/purchaseorders/SupplierOrderTypes'
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import MenuItem from '@material-ui/core/MenuItem';
import AutoSuggest from '../../common/AutoSuggest'
import currency from "currency.js";

function renderSuggestion(suggestion, { query, isHighlighted }) {
    const matches = match(suggestion.name, query);
    const parts = parse(suggestion.name, matches);
  
    return (
      <MenuItem selected={isHighlighted} component="div">
        <div>
          {parts.map((part, index) =>
            part.highlight ? (
              <span key={String(index)} style={{ fontWeight: 500 }}>
                {part.text}
              </span>
            ) : (
                <strong key={String(index)} style={{ fontWeight: 300 }}>
                  {part.text}
                </strong>
              ),
          )}
        </div>
      </MenuItem>
    );
  }

function getSuggestionValue(suggestion) {
//console.log(suggestion)
    return suggestion.name;
}

const CreatePurchaseOrderButton = (props) => {
    //console.log(props)
    const { record } = props
    const [showDialog, setShowDialog] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [submitClicked, setSubmitClicked] = useState(false)
    const [deliveryType, setDeliveryType] = useState('SELF_PICKUP')
    const [settlementType, setSettlementType] = useState('IMMEDIATE')
    const [supplier, setSupplier] = useState(null);
    const [supplierId, setSupplierId] = useState(null)

    useEffect(() => {
        if (record.defaultSupplier) {
            setSupplier(record.defaultSupplier)
        }
    }, [showDialog]);


    const resetState = () => {
        setDeliveryType('SELF_PICKUP')
        setSettlementType('IMMEDIATE')
    }

    const  validate = () => {
        if (supplier && supplier.name) {
            return true
        } else {
            return false
        }

    }

      
    const onSupplierSelection = supplier => {
        setSupplier(supplier)
    };

    const handlePurchaseOrder = () => {
        const {
          dispatch,
          dataProvider
          } = props;
          setIsLoading(true);

          var payload = {}
          
          dataProvider(CREATE, 'inventory/pending-purchase/'+  record.id + 
                '/create-purchase-order?settlementType='+ settlementType + '&deliveryType=' + deliveryType + '&supplierId=' + supplier.id, payload)
          .then((json) => {
              dispatch(showNotification('Purchase Order Created:' + json.data.id))
              dispatch(push('/suppliers/order/' + json.data.id ));
              setIsLoading(false);
          })
          .catch(() => {
              dispatch(showNotification('Error: Something went wrong', 'warning'))
              window.location.reload();
              setIsLoading(false);
          });
        
      }

    return (
        <Fragment>
            <Button disabled={isLoading || record.pendingQty < 1} style={{ marginLeft: 10, marginTop: 0, marginBottom: 0 }} variant="contained" color="primary" onClick={(event) => { event.preventDefault(); setShowDialog(true) }}>
            ADD TO PURCHASE </Button>
            <Dialog maxWidth="sm" open={showDialog} onClose={() => { setShowDialog(false); resetState() }} aria-label="Purchase Order/Draft">
                    <Fragment>
                            <DialogTitle>Purchase Order for {record.manufacturerName}</DialogTitle>
                            <DialogContent>
                            <div>
                            <AutoSuggest placeholder="Select Supplier.." fetchUrl="suppliers/supplier" onItemSuggestionClick={onSupplierSelection} renderSuggestion={renderSuggestion} getSuggestionValue={getSuggestionValue} parameterName='name' />
                            </div>
                            <div style={{marginTop: 5, marginLeft: 0}}>
                                { supplier ? 
                                    <Fragment>
                                        <div style={{marginTop: 5}}>Supplier Name: <b>{ supplier.name }</b></div>
                                        <div style={{marginTop: 3}}>Discount: {supplier.discountPercent}%</div>
                                    </Fragment>
                                : null
                                }
                            </div>          

                            <div style={{marginLeft: 0}}> 
                                  <div style={{marginTop: 5}}>Pending Qty: {record.pendingQty}</div>
                                  <div style={{marginTop: 5}}>Pending Amount: ₹ {record.pendingAmount} </div>
                            </div>

                            <div style={{ display: 'inline-flex' }}>
                                <div style={{  width: 200 }}>
                                    <TextField
                                    id="settlementType"
                                    fullWidth
                                    select
                                    label="Settlement Type"                         
                                    onChange={(e) => setSettlementType(e.target.value)}
                                    value={settlementType}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    margin="normal"
                                    >
                                    {SupplierOrderSettlementType.map(option => (
                                        <option key={option.id} value={option.id}>
                                        {option.name}
                                        </option>
                                    ))}
                                    </TextField>
                                </div>
                                <div style={{ marginLeft: 10,  width: 200 }}>
                                    <TextField
                                    id="deliveryType"
                                    fullWidth
                                    select
                                    label="Delivery Type"                         
                                    onChange={(e) => setDeliveryType(e.target.value)}
                                    value={deliveryType}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    margin="normal"
                                    >
                                    {SupplierOrderDeliveryType.map(option => (
                                        <option key={option.id} value={option.id}>
                                        {option.name}
                                        </option>
                                    ))}
                                    </TextField>
                                </div>
                            </div>
                            </DialogContent>
                            <DialogActions>
                                <Button variant="contained" color="primary" disabled={submitClicked || !validate()} onClick={() =>  handlePurchaseOrder()}> ADD TO PURCHASE </Button>
                                <Button variant="contained" onClick={() => { setShowDialog(false); resetState() }}> CANCEL </Button>
                            </DialogActions>
                    </Fragment>
            </Dialog>
        </Fragment>
    )

}

export default withDataProvider(CreatePurchaseOrderButton)