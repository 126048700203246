import React, { Fragment } from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    Filter,
    SearchInput,
} from 'react-admin';

import CurrencyField from '../../common/CurrencyField'
import DeleteButtonWithConfirmation from '../../common/DeleteButtonWithConfirmation'
import { Link } from 'react-router-dom';

import { BulkDeleteButton } from 'react-admin';


const CreateDateField = ({ record = {} }) => <span>{record.createDate}</span>;
CreateDateField.defaultProps = { label: 'Create Date' };

const EditOrder = ({ record = {} }) => 
<Link to={"/suppliers/order/"+record.id}><span>OPEN</span></Link>

const BulkActionButtons = props => (
    <Fragment>
        {/* Add the default bulk delete action */}
        <DeleteButtonWithConfirmation {...props} />
    </Fragment>
);

const ReceiveOrder = ({ record = {} }) => {
    if (record.status === 'CREATED' || record.status === 'INFORMED')
        return (<Link to={"/suppliers/order/" + record.id + "/receive"}><span>RECEIVE</span></Link>)
    else
        return null
}


const OrderFilter = (props) => (
    <Filter {...props}  bulkActionButtons={<BulkActionButtons />}>
        <SearchInput source="q" alwaysOn />
        {/* <DateInput source="orderDate" alwaysOn /> */}
        {/* <SelectInput allowEmpty={false} label="Location" source="locationId" alwaysOn choices={[
        { id: '1',  name: 'SHOP' },
        { id: '2',  name: 'BOOKFAIR' },]
        } />
         */}
{/* 
        <SelectInput source="channelType" alwaysOn choices={OrderChannelTypes} />
        <SelectInput source="paymentStatus" alwaysOn choices={OrderPaymentStatusTypes} />
        <SelectInput source="fulfillmentStatus" alwaysOn choices={OrderFulfillmentStatusTypes} /> */}

        {/* <SelectInput source="status" alwaysOn choices={[
        { id: 'OPEN',        name: 'Open' },
        { id: 'COMPLETE',      name: 'Complete' }, 
        { id: 'CANCELLED', name: 'Cancelled' },
        ]} /> */}

    </Filter>
);


const PurchaseOrderList = props => (
    
    <List filters={<OrderFilter />}  exporter={false}  {...props}  
    // filterDefaultValues={{ status: "OPEN" }} 
    perPage={25} >
        <Datagrid>
            <EditOrder/>
            <TextField source="id" />
            <DateField source="orderDate" />
            <TextField source="supplier.name" />
            <TextField source="supplier.mobileNumber" />
            <TextField source="settlementType" />
            <TextField source="deliveryType" />
            <TextField source="status" />
            <CurrencyField source="total" />
            <ReceiveOrder/>      
        </Datagrid>
    </List>
);

export default PurchaseOrderList;
