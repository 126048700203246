import React, { Component } from 'react';
import { Fragment } from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import MultiAutoSuggest from '../../common/MultiAutoSuggest'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { showNotification, withDataProvider, GET_ONE, CREATE, UPDATE } from 'react-admin';
import Checkbox from '@material-ui/core/Checkbox';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import CreateReceiptButton from './CreateReceiptButton'
import UpdateShippingDetailButton from './UpdateShippingDetailButton'
import currency from "currency.js";
import { Link } from 'react-router-dom';
//import AddAuthorButton from '../../catalog/items/AddAuthorButton'

import { push } from 'react-router-redux';

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 2,
        overflowX: 'auto',
    },
    textField: {
        marginBottom: 20,
        width: 200,
    },
    table: {
        minWidth: 600,
    },
    paper: {
        padding: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 2,
        //textAlign: 'center',
        //color: theme.palette.text.secondary,
    },
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
});

function generatePayload(state) {
    //   console.log(state)
    //   const _newAuthorList = []
    //   const newAuthorList = _newAuthorList.concat(state.authorList.map( element => { return {authorId: element.value, authorType: 'AUTHOR'} }),
    //                         state.translatorList.map( element => { return {authorId: element.value, authorType: 'TRANSLATOR'} }),
    //                         state.compilerList.map( element => { return {authorId: element.value, authorType: 'COMPILER'} }),
    //                         state.editorList.map( element => { return {authorId: element.value, authorType: 'EDITOR'} }),
    //                         state.illustratorList.map( element => { return {authorId: element.value, authorType: 'ILLUSTRATOR'} })
    //                       )

    //   var payload = {
    //     id: state.id,
    //     name: state.name,
    //     englishName: state.englishName,
    //     isbn: state.isbn,
    //     ocId: state.ocId,
    //     webProduct: {
    //       id: state.webProductId,
    //       friendlyUrl: state.friendlyUrl,
    //       manufacturerId: state.manufacturerId,
    //       name: state.name,
    //       subtitle: state.subtitle,
    //       isbn: state.isbn,
    //       price: state.price,
    //       englishName: state.englishName,
    //       webQty: state.webQty,
    //       year: state.year,
    //       format: state.format,
    //       edition: state.edition,
    //       page: state.page,
    //       bookLanguage: state.bookLanguage,
    //       datePublished: state.datePublished,
    //       description: state.description,
    //       weight: state.weight,
    //       length: state.length,
    //       width: state.width,
    //       height: state.height,
    //       manufacturerCategoryList: [],
    //       categoryList: state.categoryList.map(element => element.value),
    //       authorList: newAuthorList,
    //       relatedProducts: state.relatedProductsList.map(element => element.value),
    //       itemAvailability: state.itemAvailability
    //     }
    //}

    //   console.log(payload)

    //   if (state.ocId === 0) {
    //     return { data: payload }
    //   } else {
    //     return { id: state.id, data: payload }
    //   } 

}

class WebOrderProcess extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            webOrder: {},
            checkedItems: [],
            itemTotal : 0.0,
            itemDiscount: 0.0,
            webTotal: 0.0,
            totalQty: 0
        };
    }

    handleInit() {
        console.log("FilePond instance has initialised", this.pond);
    }

    handleChange = value => event => {

        const selected = this.state.checkedItems;
        console.log("Selected Items = " + selected)
        console.log("Selected Value = " + value)

        if (event.target.checked) {
            console.log('add')
            //true - add to the list
            if (selected.indexOf(value) === -1) {
                selected.push(value) 
            }

            this.setState({
                checkedItems: selected
            })

        } else {
            //false - remove from the list
            console.log('remove')
            const _selected = selected.filter( e =>  e !== value)
            this.setState({
              checkedItems: _selected
            })  
        }

    };

    // getAddress() {
    //     var address = {
    //         fullName: thi
    //     }

    // }

    calculateReceipt() {

        // const itemTotal = this.state.webOrder.orderItems.reduce( (_total, curItem) => currency(_total).add(currency(curItem.item.price).multiply(curItem.qty)).value, 0.0 )
        // const webTotal = this.state.webOrder.orderItems.reduce( (_total, curItem) => currency(_total).add(currency(curItem.total)).value, 0.0 )
        // const itemDiscount = this.state.webOrder.orderItems.reduce( (_total, curItem) => currency(_total).add(currency(curItem.item.price).multiply(curItem.qty).subtract(currency(curItem.total))).value, 0.0 )

        
        const itemTotal = this.state.checkedItems.reduce( (_total, curItem) => currency(_total).add(currency(curItem.item.price).multiply(curItem.qty)).value, 0.0 )
        const webTotal = this.state.checkedItems.reduce( (_total, curItem) => currency(_total).add(currency(curItem.total)).value, 0.0 )
        const itemDiscount = this.state.checkedItems.reduce( (_total, curItem) => currency(_total).add(currency(curItem.item.price).multiply(curItem.qty).subtract(currency(curItem.total))).value, 0.0 )

        const selectedQty = this.state.checkedItems.reduce( (_qty, curItem) => _qty + curItem.qty, 0 )
        const partialShipped = this.state.totalQty !== selectedQty 
       
        // get shipping amount
        // get bundle discount/
        var shippingAmount = currency(0.0).value
        var otherDiscount = currency(0.0).value
        if (!partialShipped) {
            const shippingTotal = this.state.webOrder.orderTotals.filter( e => e.code === 'shipping')
            shippingAmount = currency(shippingTotal[0].value).value
            console.log('Shipping Amount = ' + shippingAmount )

            const bundle = this.state.webOrder.orderTotals.filter( e => e.code === 'bundle')
            if (bundle.length > 0) {
                otherDiscount = currency(Math.abs(bundle[0].value)).value
            }
            console.log('Bundle Discount = ' + otherDiscount )
            
        }

        const totalAmount = currency(itemTotal).add(currency(shippingAmount)).subtract(currency(itemDiscount)).subtract(currency(otherDiscount)).value
        
        const receipt = {
            id: null,
            partialShipped: partialShipped,
            comment: "Open cart order",
            customerId: 0,
            email: this.state.webOrder.email,
            invoiceAmount: "",
            invoiceNumber: String(this.state.webOrder.id),
            mobileNumber: this.state.webOrder.telephone,
            name: this.state.webOrder.fullName,
            otherDiscount: otherDiscount,
            paid: true,
            receiptDate: new Date(new Date().toISOString().substring(0, 10)),
            requireShipping: true,
            salesChannelType: "PANUVAL_COM",
            salesPaymentType: "PAYUBIZ",
            shippingAmount: shippingAmount,
            status: 1,
            subTotal: itemTotal,
            totalAmount: totalAmount,
            totalDiscount: itemDiscount,
            totalQty: selectedQty,
            totalUnitsQty: selectedQty,
            salesReceiptItems: this.state.checkedItems.map( e => {
                let line = 1;
                const item = {
                    discountPercent: 0.0,
                    discountAmount: currency(e.item.price).multiply(e.qty).subtract(currency(e.total)).value,
                    itemId: e.item.id,
                    manufacturerName: e.item.manufacturerName,
                    line: line,
                    name: e.item.name,
                    price: e.item.price,
                    qty: e.qty,
                    salePrice: currency(e.item.price).multiply(e.qty).value,
                    unitsQty: e.qty

                }
                line++;
                return item
            })
        }

        console.log(receipt)

        // {
        //     "discountPercent": 0,
        //     "itemId": 43816,
        //     "line": 1,
        //     "name": "இச்சா",
        //     "price": 270,
        //     "qty": 1,
        //     "salePrice": 270,
        //     "unitsQty": 0
        // },
        // {
        //     
        // }

        return receipt
    }


    componentDidMount() {
        const {
            dataProvider,
        } = this.props;
        const _id = this.props.match.params.id;
        if (_id) {
            var params = { id: _id }
            dataProvider(GET_ONE, 'order/web', params)
                .then((json) => {
                    //dispatch(showNotification('Fetched Receipt :' + json.data.id))
                     if (json.data) {
                        const itemTotal = json.data.orderItems.reduce( (_total, curItem) => currency(_total).add(currency(curItem.item.price).multiply(curItem.qty)).value, 0.0 )
                        const webTotal = json.data.orderItems.reduce( (_total, curItem) => currency(_total).add(currency(curItem.total)).value, 0.0 )
                        const itemDiscount = json.data.orderItems.reduce( (_total, curItem) => currency(_total).add(currency(curItem.item.price).multiply(curItem.qty).subtract(currency(curItem.total))).value, 0.0 )
                        const totalQty = json.data.orderItems.reduce( (_qty, curItem) => _qty + curItem.qty, 0 )
                        console.log(itemTotal,webTotal,itemDiscount, totalQty)
                        this.setState({
                            webOrder: json.data,
                            itemTotal: itemTotal,
                            webTotal: webTotal,
                            itemDiscount: itemDiscount,
                            loading: false,
                            totalQty: totalQty
                        })
                    }
                })
                .catch((e) => {
                    //dispatch(showNotification('Error: Something went wrong', 'warning'))
                });
        }
    }

    // handleCreateReceipt = () => {
    //     this.state.checkedItems.forEach( o => console.log(o))

    // }


    handleSubmit = () => {
        if (!this.state.submitClicked) {
            this.setState({ submitClicked: true });
            const {
                dispatch,
                dataProvider
            } = this.props;

            var payload = generatePayload(this.state)

            if (this.state.ocId == 0) {
                dataProvider(CREATE, 'v2/catalog/item/web', payload)
                    .then((json) => {
                        console.log(json)
                        dispatch(showNotification('Item created with id:' + json.data.id))
                        this.setState({ submitClicked: false });
                        dispatch(push('/v2/catalog/item'));
                    })
                    .catch((e) => {
                        this.setState({ submitClicked: false });
                        dispatch(showNotification('Error: Something went wrong', 'warning'))
                    });
            } else {
                dataProvider(UPDATE, 'v2/catalog/item/web', payload)
                    .then((json) => {
                        console.log(json)
                        dispatch(showNotification('Item Updated with id:' + json.data.id))
                        this.setState({ submitClicked: false });
                        dispatch(push('/v2/catalog/item'));
                    })
                    .catch((e) => {
                        this.setState({ submitClicked: false });
                        dispatch(showNotification('Error: Something went wrong', 'warning'))
                    });
            }
        }

    };

    render() {
        const { classes, cartObj, ...props } = this.props;

        console.log(this.props)
        if (this.state.loading) {
            return <h1>Loading..</h1>
        } else {
            return (
                <Fragment>
                    
                    <Grid container spacing={8}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <h2> Process Order  - { this.state.webOrder.id} (Status: <mark>{this.state.webOrder.orderStatus}</mark>)</h2>
                                Order Date: {new Date(this.state.webOrder.orderDate).toLocaleString()} <br/>
                                <div style={{marginTop: 10, display: "inline-flex" }}>
                                <div>
                                <b>Customer Details</b> <br/>
                                {this.state.webOrder.customerName } <br/>
                                {this.state.webOrder.telephone } <br/>
                                {this.state.webOrder.email } <br/>
                                </div>
                                <div style={{ marginLeft: 200 }}>
                                    <b>Shipping Address</b> <br/>
                                    {this.state.webOrder.fullName } <br/>
                                    {this.state.webOrder.address1 } <br/>
                                    {this.state.webOrder.address2 } <br/>
                                    {this.state.webOrder.city } <br/>
                                    Pin Code - {this.state.webOrder.postCode }, {this.state.webOrder.state }, {this.state.webOrder.country } <br/>
                                    Ph: {this.state.webOrder.company === '' ?  this.state.webOrder.telephone : this.state.webOrder.company }

                                </div>
                                </div>

                                { this.state.webOrder.salesReceipts.length !== 0 ? 
                                <Fragment>
                                <h3>Sales Receipts</h3>
                                <Table padding="none" className={classes.table}>
                                    <TableHead>
                                    <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Id</TableCell>
                                        <TableCell align="right">Qty</TableCell>
                                        <TableCell align="right">Sub Total ₹</TableCell>
                                        <TableCell align="right">Discount ₹</TableCell>
                                        <TableCell align="right">Other Discount ₹</TableCell>
                                        <TableCell align="right">Shipping ₹</TableCell>
                                        <TableCell align="right">Total ₹</TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {this.state.webOrder.salesReceipts.map(row => (
                                         <TableRow key={"i_" + row.id}>
                                            <TableCell align="right">{row.receiptDate}</TableCell>
                                            <TableCell align="right">{row.id}</TableCell>
                                            <TableCell align="right">{row.totalQty}</TableCell>
                                            <TableCell align="right">₹ {row.subTotal}</TableCell>
                                            <TableCell align="right">₹ {row.totalDiscount}</TableCell>
                                            <TableCell align="right">₹ {row.otherDiscount}</TableCell>
                                            <TableCell align="right">₹ {row.shippingAmount}</TableCell>
                                            <TableCell align="right">₹ {row.totalAmount} </TableCell>
                                            <TableCell align="right"><Link to={"/sale/receipt/"+row.id+"/show"}>Print Receipt</Link></TableCell>
                                            <TableCell align="right"> 
                                                <UpdateShippingDetailButton order={this.state.webOrder}/>
                                             </TableCell>

                                            <TableCell align="right"><Link to={"/sale/receipt/"+row.id+"/show"}>Print Shipping Label</Link></TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                    </Table>
                                    </Fragment>
                                : null}
                                <h3>Order Items</h3>
                                <div style={{ width: "100%", justifyContent: 'right', marginBottom: 5, }}>
                                    <CreateReceiptButton data={this.calculateReceipt()} />
                                    {/* <Button disabled={this.state.createReceiptClicked} style={{ marginLeft: 8 }} variant="contained" color="primary" onClick={this.handleCreateReceipt}>CREATE RECEIPT</Button> */}
                                </div>
                                
                                <Table padding="none" className={classes.table}>
                                    <TableHead>
                                    <TableRow>
                                        <TableCell padding="checkbox"></TableCell>
                                        <TableCell>Id</TableCell>
                                        <TableCell align="right">Name</TableCell>
                                        <TableCell align="right">Publisher</TableCell>
                                        <TableCell align="right">Item ₹</TableCell>
                                        <TableCell align="right">Qty</TableCell>
                                        <TableCell align="right">Discount</TableCell>
                                        <TableCell align="right">Web Total ₹</TableCell>
                                        <TableCell align="right">Status</TableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {this.state.webOrder.orderItems.map(row => (
                                         <TableRow padding="checkbox" key={"i_" + row.id}>
                                            <TableCell align="right">
                                                <Checkbox disabled={row.item.qty < 1 || row.status === 'BILLED' ||  row.status === 'SHIPPED' } 
                                                    // checked={this.state.checkedA}
                                                    onChange={this.handleChange(row)}
                                                    //value={row.productId}
                                                />
                                            </TableCell>
                                            <TableCell align="right">{row.item.id}</TableCell>
                                            <TableCell align="right">{row.name}<br/> 
                                                <span style={{ fontSize: 12, color: "grey" }}>Available Qty: {row.item.qty} Shelf: {row.item.shelfCodes} </span></TableCell>
                                            <TableCell align="right">{row.item.manufacturerName}</TableCell>
                                            <TableCell align="right"><b>₹ {currency(row.item.price).value}</b></TableCell>
                                            <TableCell align="right">{row.qty}</TableCell>
                                            <TableCell align="right">₹ {currency(row.item.price).multiply(row.qty).subtract(currency(row.total)).value}</TableCell>
                                            <TableCell align="right">₹ {row.total} </TableCell>
                                            <TableCell align="right">{row.status} </TableCell>
                                        </TableRow>
                                    ))}
{/* <br/>₹ {currency(row.price).value} */}
{/* ₹ {currency(row.item.price).multiply(row.qty).value}</b> */}
                                    <TableRow>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right">Item Total</TableCell>
                                            <TableCell align="right">₹ {currency(this.state.itemTotal).value}</TableCell>

                                    </TableRow>

                                    <TableRow>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right">Discount</TableCell>
                                            <TableCell align="right">₹ {currency(this.state.itemDiscount).value}</TableCell>

                                    </TableRow>

                                    {this.state.webOrder.orderTotals.map(row => (
                                         <TableRow  key={"t_" + row.id}>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right">{row.title}</TableCell>
                                            <TableCell align="right">₹ {currency(row.value).value}</TableCell>

                                        </TableRow>
                                    ))}

                                    </TableBody>

                                    </Table>
                                
                            </Paper>
                        </Grid>
                    </Grid>
                </Fragment>
            );
        }
        
    }
}


WebOrderProcess.propTypes = {
    classes: PropTypes.object.isRequired,
    dataProvider: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
};

const enhance = compose(
    withDataProvider,
    withStyles(styles),
);

export default enhance(WebOrderProcess);