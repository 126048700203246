
import React, { useState, useReducer, useEffect, Fragment } from 'react';
import useCartState from './hooks/useCartState'
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import ItemAutoSuggest from '../receivings/stocktaking/ItemAutoSuggest'
import DeleteButton from './DeleteButton'
import ItemQuickCreateEditButton from './ItemQuickCreateEditButton'
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';

const initialCartState = {
    cartItems: [],
    selectedItems: [],
    rowCounter: 0,
    totalItems: 0,
    totalQty: 0,
    subTotal: 0.0,
    shippingAmount: 0.0,
    otherCharges: 0.0,
    invoiceDiscount: 0.0,
    totalDiscount: 0.0,
    totalAmount: 0.0,
}


const CartArea = (props) => {

    const [cartState, cartActionDispatch] = useCartState(initialCartState)

    const { discountPercent, cartItems, disabled, shippingAmount, invoiceDiscount, otherCharges } = props
    const { readOnly, hideStockDetails, hideId, hideISBN, hideEdit, hideDelete, hideSelect, hideCreateItem, hideDiscount, hideTotal } = props

    //Id	ISBN	Name	Publisher	Price ₹	Qty	Disc %	Total Amt

    const onKeyDown = (e) => {
        if (e.keyCode === 8 || e.keyCode === 46) {
          e.target.value = ""
          e.preventDefault();
        }
      }

    useEffect(() => {
        cartItems.forEach( cartItem => {
            cartActionDispatch({type: 'add_item', item: cartItem})
        })
    }, [cartItems])


    useEffect(() => {
        cartActionDispatch({type: 'update_shipping_amount', shippingAmount: shippingAmount})
    }, [shippingAmount])

    useEffect(() => {
        cartActionDispatch({type: 'update_invoice_discount', invoiceDiscount: invoiceDiscount})
    }, [invoiceDiscount])

    useEffect(() => {
        cartActionDispatch({type: 'update_other_charges', otherCharges: otherCharges})
    }, [otherCharges])



    useEffect(() => { 
        props.onCartStateChange(cartState); 
    }, [cartState])

    const handleQtyChange = item => (e) => {
        console.log('inside qty')
        cartActionDispatch({type: 'update_qty', item: { itemId: item.itemId, qty: parseInt(e.target.value)} })
    }

    const handleChecked = cartItem => (e) => {
        if (e.target.checked) {
            // add to the selected items
            cartActionDispatch({type: 'add_selected_item', item: cartItem})
        } else {
            cartActionDispatch({type: 'remove_unselected_item', item: cartItem})
            // remove from selected items
        }
    }

    const handleDiscountChange = item => (e) => {
        console.log('inside discount')
        cartActionDispatch({type: 'update_discount_percent', item: { itemId: item.itemId, discountPercent: parseInt(e.target.value) } })
    }

    const onItemUpdate = item => {

        const cartItem = {
          itemId: item.id,
          name: item.name,
          isbn: item.isbn,
          manufacturerId : item.manufacturerId,
          manufacturerName: item.manufacturerName,
          shelfCodes: item.shelfCodes,
          price: item.price,
          stockQty: item.stockQty
        }
        cartActionDispatch({type: 'update_item', item: cartItem})
      }
  
      const onItemAdd = item => {
          const cartItem = {
              itemId: item.id,
              name: item.name,
              isbn: item.isbn,
              manufacturerId : item.manufacturerId,
              manufacturerName: item.manufacturerName,
              shelfCodes: item.shelfCodes,
              price: item.price,
              stockQty: item.qty,
              avlQty: item.avlQty,
              qty: 1, 
              discountPercent: discountPercent,
              discountAmount: 0.0,
              total: 0.0
          }
          cartActionDispatch({type: 'add_item', item: cartItem})
        };

        return (
            <Fragment>
                { (disabled || hideCreateItem) ? null : 
                    <div style={{ width: "100%", marginTop: 15, marginBottom: 15, display: "inline-flex" }}>
                        <div style={{ marginLeft: 10, marginTop: 5, width: "90%" }}>
                            <ItemAutoSuggest onItemSuggestionClick={onItemAdd} />
                        </div>
                        
                        <div style={{ marginLeft: 30, width: "30%" }}>
                            <ItemQuickCreateEditButton onCreate={onItemAdd} />
                        </div>
                    </div>
                }

                <Table padding="dense" >
                    {/* <colgroup>
                        <col style={{width:'5%'}}/>
                        <col style={{width:'5%'}}/>
                        <col style={{width:'15%'}}/>
                        <col style={{width:'30%'}}/>
                        <col style={{width:'20%'}}/>
                        <col style={{width:'10%'}}/>
                        <col style={{width:'10%'}}/>
                        <col style={{width:'5%'}}/>
                        <col style={{width:'5%'}}/>
                    </colgroup> */}
                    <TableHead>
                        <TableRow>
                            {!hideSelect ?  <TableCell style={{width: 10}} padding="checkbox"></TableCell> : null }
                            {!hideId ? <TableCell align="right">Id</TableCell> : null }
                            {!hideISBN ? <TableCell align="right">ISBN</TableCell> : null }
                            <TableCell align="right">Name</TableCell>
                            <TableCell align="right">Publisher</TableCell>
                            <TableCell align="right">Price ₹</TableCell>
                            <TableCell align="right">Qty</TableCell>
                            {!hideDiscount ? <TableCell align="right">Disc %</TableCell> : null}
                            {!hideTotal ? <TableCell align="right">Total Amt</TableCell> : null}
                            {!hideEdit ? <TableCell align="right"></TableCell> : null }
                            {!hideDelete ? <TableCell align="right"></TableCell> : null }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {cartState.cartItems.map(item => (
                            <TableRow key={"i_" + item.rowid}>
                                 {!hideSelect ? <TableCell style={{width: 5}} align="right">
                                    <Checkbox
                                        //checked={this.state.checkedA}
                                        onChange={handleChecked(item)}
                                        //value={row.productId}
                                    />
                                    </TableCell> : null }
                                {!hideId ? <TableCell align="right">{item.itemId}</TableCell> : null }
                                {!hideISBN ? <TableCell align="right">{item.isbn}</TableCell> : null }
                                <TableCell style={{ width: 300 }} align="right">{item.name} 
                                    {!hideStockDetails ? <div><span style={{ fontSize: 12, color: "grey" }}>Stock Qty: {item.stockQty} Avl Qty: {item.avlQty} Shelf: {item.shelfCodes} </span> </div>: null }
                                </TableCell>
                                <TableCell style={{ width: 300 }} align="right">{item.manufacturerName}</TableCell>
                                <TableCell align="right">₹{item.price} </TableCell>
                                <TableCell align="right">
                                {!readOnly ?
                                    <TextField disabled={disabled} style={{ width: 30 }} value={item.qty} onKeyDown={onKeyDown} onChange={handleQtyChange(item)}/>
                                    :
                                    <span>{item.qty}</span>
                                }
                                </TableCell>
                                {!hideDiscount ? 
                                    <TableCell align="right">
                                    {!readOnly ?
                                        <TextField disabled={disabled}  style={{ width: 30 }} value={item.discountPercent} onKeyDown={onKeyDown} onChange={handleDiscountChange(item)} />
                                        :
                                        <span>{item.discountPercent}</span>
                                    }
                                    </TableCell>
                                : null
                                }
                                
                                {!hideTotal ? <TableCell align="right">₹{item.total} </TableCell> : null }
                                {!hideEdit ? <TableCell align="right">{disabled ? null : (item.stockQty === 0 ? <ItemQuickCreateEditButton itemId={item.itemId} onUpdate={onItemUpdate} /> : null) } </TableCell> : null }
                                {!hideDelete ? <TableCell align="right"> {disabled ? null: <DeleteButton onDelete={() => cartActionDispatch({type: 'remove_item', item: item}) } /> } </TableCell> : null }
                            </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </Fragment>
        )
}

export default CartArea