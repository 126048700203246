import BillIcon from '@material-ui/icons/Receipt';
import SupplierBillList from './SupplierBillList';
import SupplierBillCreate from './SupplierBillCreate';
import SupplierBillShow from './SupplierBillShow';


export default {
    list: SupplierBillList,
    edit: SupplierBillCreate,
    create: SupplierBillCreate,
    show: SupplierBillShow,
    icon: BillIcon,
    name: "receiving/bill"
};
