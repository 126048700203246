// hooks to maintain state
// submit using dataProvider
//

import React, { useState, Fragment, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import {
    showNotification,
    withDataProvider,
    CREATE,
    UPDATE,
    GET_ONE,
} from 'react-admin';

import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField';

const PaymentTypes = [
    { value: 'CASH', label: 'Cash' },
    { value: 'CARD', label: 'Card Swipe' },
    { value: 'BANK_TRANSFER', label: 'Bank Transfer' },
    { value: 'UPI_TRANSFER', label: 'GPay (Panuval)' },
    { value: 'PERSONAL', label: 'GPay (Saravanan)' },
    { value: 'CHECK', label: 'Check' }
  ];

  

const EditOrderDetailButton = (props) => {

    const { disabled, onSubmit, amount, orderId, _discountPercent, _shippingAmount, _invoiceDiscount, _totalAmount, _otherCharges, _comment } = props
    const [showDialog, setShowDialog] = useState(false)
    const [submitClicked, setSubmitClicked] = useState(false)
    const [discountPercent, setDiscountPercent] = useState(_discountPercent)
    const [shippingAmount, setShippingAmount] = useState(_shippingAmount)
    const [invoiceDiscount, setInvoiceDiscount] = useState(_invoiceDiscount)
    const [otherCharges, setOtherCharges] = useState(_otherCharges)
    const [comment, setComment] = useState(_otherCharges)

    const resetState = () => {
        setDiscountPercent(_discountPercent)
        setShippingAmount(_shippingAmount)
        setInvoiceDiscount(_invoiceDiscount)
        setOtherCharges(_otherCharges)
        setComment(_comment)
    }

    const handleSubmit = () => {

            const orderDetail = {
                // TODO: paidDate as input
                orderId: orderId,
                discountPercent: discountPercent,
                shippingAmount: shippingAmount,
                invoiceDiscount : invoiceDiscount, 
                otherCharges : otherCharges,
                totalAmount: _totalAmount,
                comment: comment
            }

            onSubmit(orderDetail)
    }

    const onKeyDown = (e) => {
        if (e.keyCode === 8 || e.keyCode === 46) {
          e.target.value = ""
          e.preventDefault();
        }
    }
 
    
    useEffect(() => {
        setDiscountPercent(_discountPercent)
        setShippingAmount(_shippingAmount)
        setInvoiceDiscount(_invoiceDiscount)
        setOtherCharges(_otherCharges)
        setComment(_comment)
    }, [showDialog]);

    const validate = () => {
        return true
    }

    return (
        <Fragment>
            <Button size="small" disabled={disabled} style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }} variant="contained" color="primary" onClick={(event) => { event.preventDefault(); setShowDialog(true) }}>
    EDIT DETAILS</Button>
            <Dialog maxWidth="md" open={showDialog} onClose={() => { setShowDialog(false); resetState() }} aria-label="Payment and Delivery Details">
                    <Fragment>
                            <DialogTitle>Edit Details for Order #{orderId}</DialogTitle>
                            <DialogContent>
                            <div>
                                <div style={{  marginLeft: 10 }}>
                                    <TextField
                                        id="discountPercent"
                                        label="Discount %"
                                        value={discountPercent}
                                        onChange={(e) => setDiscountPercent(e.target.value)}
                                        margin="normal"
                                        onKeyDown={onKeyDown}
                                        style={{ width: 100 }}
                                    />

                                    <TextField
                                        id="shippingAmount"
                                        label="Shipping Amount"
                                        value={shippingAmount}
                                        onChange={(e) => setShippingAmount(e.target.value)}
                                        margin="normal"
                                        onKeyDown={onKeyDown}
                                        style={{ marginLeft: 20, width: 150 }}
                                    />
                                    <TextField
                                        id="invoiceDiscount"
                                        label="Bill Discount"
                                        value={invoiceDiscount}
                                        onChange={(e) => setInvoiceDiscount(e.target.value)}
                                        style={{ marginLeft: 20 }}
                                        margin="normal"
                                        onKeyDown={onKeyDown}
                                        style={{ marginLeft: 20, width: 150 }}
                                    />
                                    <TextField
                                        id="otherCharges"
                                        label="Other Charges"
                                        value={otherCharges}
                                        onChange={(e) => setOtherCharges(e.target.value)}
                                        style={{ marginLeft: 20 }}
                                        margin="normal"
                                        onKeyDown={onKeyDown}
                                        style={{ marginLeft: 20, width: 150 }}
                                    />
                                </div>
                                <div style={{marginLeft: 10, marginRight: 10}}>
                                    <TextField
                                        id="comment"
                                        label="Notes"
                                        value={comment}
                                        onChange={(e) => setComment(e.target.value)}
                                        margin="normal"
                                        style={{ width: "100%" }}
                                        multiline={true}
                                        rows={3}
                                        rowsMax={100}
                                        />
                                </div>
                            </div>
                            </DialogContent>
                            <DialogActions>
                                <Button variant="contained" color="primary" disabled={ !validate()} onClick={handleSubmit}>SUBMIT</Button>
                                <Button variant="contained" onClick={() => { setShowDialog(false); resetState() }}> CANCEL </Button>
                            </DialogActions>
                    </Fragment>
            </Dialog>
        </Fragment>
    )

}

export default EditOrderDetailButton