
import { useReducer } from 'react';

function sortProperties(obj, sortedBy, isNumericSort, reverse) {
    sortedBy = sortedBy || 1; // by default first key
    isNumericSort = isNumericSort || false; // by default text sort
    reverse = reverse || false; // by default no reverse
  
    var reversed = (reverse) ? -1 : 1;
  
    var sortable = [];
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        sortable.push([key, obj[key]]);
      }
    }
    if (isNumericSort)
      sortable.sort(function (a, b) {
        return reversed * (a[1][sortedBy] - b[1][sortedBy]);
      });
    else
      sortable.sort(function (a, b) {
        var x = a[1][sortedBy].toLowerCase(),
          y = b[1][sortedBy].toLowerCase();
        return x < y ? reversed * -1 : x > y ? reversed : 0;
      });
    return sortable; // array in format [ [ key1, val1 ], [ key2, val2 ], ... ]
}

const summaryReducer = (_summary, _item) => {
    _summary.totalItems++;
    return _summary;
  }


function reduceState(manifestState) {
    var summary = {
        totalItems: 0
    }
    const _summary = manifestState.manifestItems.reduce(summaryReducer, summary);
    manifestState.totalItems = _summary.totalItems
    return manifestState
}

function manifestItemsReducer(oldManifestState, action) {
  console.log("action=", action)
    switch (action.type) {
        case 'add_item': {
            const item = action.item
            console.log(item)
            let newManifestState = JSON.parse(JSON.stringify(oldManifestState))
            newManifestState.rowCounter = newManifestState.rowCounter + 1
            let _itemExists = false
            newManifestState.manifestItems.map((_item) => {
                if (_item.id === item.id) {
                    _item.customerName = item.customerName
                    _item.city = item.city
                    _item.pincode = item.pincode
                    _item.trackingId = item.trackingId
                    _item.shippingWeight = item.shippingWeight
                    _item.shippingAmount = item.shippingAmount
                    _itemExists = true
                }
                return _item
            })

            if (!_itemExists) {
                item.rowid = newManifestState.rowCounter
                newManifestState.manifestItems.push(item)
            }

            const arr = sortProperties(newManifestState.manifestItems, 'rowid', true, true);
            newManifestState.manifestItems = arr.map(x => x[1])

            return reduceState(newManifestState)
        }

        case 'update_item': {
          const item = action.item
          let newManifestState = JSON.parse(JSON.stringify(oldManifestState))
          newManifestState.manifestItems.map((_item) => {
              if (_item.id === item.id) {
                  _item.customerName = item.customerName
                  _item.city = item.city
                  _item.pincode = item.pincode
                  _item.trackingId = item.trackingId
                  _item.shippingWeight = item.shippingWeight
                  _item.shippingAmount = item.shippingAmount
              }
              return _item
          })
          return reduceState(newManifestState)
    }

        case 'remove_item': {
            const item = action.item
            let newManifestState = JSON.parse(JSON.stringify(oldManifestState))
            newManifestState.manifestItems = newManifestState.manifestItems.filter(_item => item.id != _item.id)
            return reduceState(newManifestState)
        }

        default:
          throw new Error();
      }
}



function useManifestState(initialManifestState) {
    const [manifestState, manifestActionDispatch] = useReducer(manifestItemsReducer, initialManifestState);
    return [manifestState, manifestActionDispatch]
}


export default useManifestState