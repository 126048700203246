import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { change, reset, submit, isSubmitting } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import {
    fetchEnd,
    fetchStart,
    required,
    showNotification,
    crudGetMatching,
    Button,
    SaveButton,
    SimpleForm,
    TextInput,
    LongTextInput,
    CREATE,
    REDUX_FORM_NAME,
    ReferenceInput,
    AutocompleteInput,
    NumberInput, 
    withDataProvider
} from 'react-admin';

import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import Drawer from '@material-ui/core/Drawer';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import DrawerAutocompleteInput from '../../common/DrawerAutocompleteInput'
import dataProvider from '../../dataProvider';

const styles = {
    drawerContent: {
        width: 450
    }
    // name: { display: 'inline-block' },
    // englishName: { display: 'inline-block', marginLeft: 32 },
    // manufactuerName: { display: 'inline-block', marginLeft: 32 },
};


class QuickItemCreateButton extends Component {
    state = {
        error: false,
        showDialog: false, 
        submitClicked: false
    };

    handleClick = () => {
        this.setState({ showDialog: true });
    };

    handleCloseClick = () => {
        this.setState({ showDialog: false });
    };

    handleSaveClick = () => {
        const { submit } = this.props;

        // Trigger a submit of our custom quick create form
        // This is needed because our modal action buttons are oustide the form
        submit('post-quick-create');
    };

    handleSubmit = values => {
        if (!this.state.submitClicked) {
            this.setState({ submitClicked: true });
            const {
                change,
                fetchStart,
                fetchEnd,
                showNotification,
                dispatch,
                dataProvider,
                onCreate
            } = this.props;
            var payload = { data: values }
            dataProvider(CREATE, 'v2/catalog/item', payload)
            .then((json) => {
                dispatch(showNotification('Item created with id:' + json.data.id)) 
                console.log(json)
                onCreate(json.data);
                this.setState({ showDialog: false, submitClicked: false });
                dispatch(reset('post-quick-create'));
            })
            .catch((e) => {
                this.setState({ submitClicked: false });
            dispatch(showNotification('Error: Something went wrong', 'warning'))
            });
        }
       
    };

    render() {
        const { showDialog } = this.state;
        const { isSubmitting, onCreate, classes } = this.props;
        console.log(this.props)
        return (
            <Fragment>
                <Button onClick={this.handleClick} label="QUICK CREATE ITEM">
                    <IconContentAdd />
                </Button>

                <Drawer open={showDialog} className={classes.drawerContent} anchor="right" onClose={this.handleCloseClick}>
                    <SimpleForm form="post-quick-create" resource="v2/catalog/item" onSubmit={this.handleSubmit} toolbar={null}>
                        <TextInput autoFocus source="name" style={{ width: 400 }} validate={required()} />
                        <TextInput source="englishName" style={{ width: 400 }} validate={required()} />
                        <ReferenceInput required label="Publisher" source="manufacturerId" reference="v2/catalog/manufacturer">
                            <DrawerAutocompleteInput  optionText={choice => `${choice.name}` } />
                        </ReferenceInput>
                        <TextInput source="isbn" label="ISBN"/>
                        <NumberInput source="price" style={{ width: 150 }} validate={required()} label="Price"/>
                    </SimpleForm>
                    <DialogActions>
                    <SaveButton saving={isSubmitting} onClick={this.handleSaveClick}/>
                    <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
                            <IconCancel />
                    </Button>
                    </DialogActions>
                </Drawer>

                {/* <Dialog fullWidth open={showDialog} onClose={this.handleCloseClick} aria-label="Create Item">
                    <DialogTitle>Create Item</DialogTitle>
                    <DialogContent>
                        <SimpleForm form="post-quick-create" resource="v2/catalog/item" onSubmit={this.handleSubmit} toolbar={null}>
                            <TextInput autoFocus source="name" style={{ width: 400 }} validate={required()} />
                            <TextInput source="englishName" style={{ width: 400 }} validate={required()} />
                            <ReferenceInput required label="Publisher" source="manufacturerId" reference="v2/catalog/manufacturer">
                                <DrawerAutocompleteInput  optionText={choice => `${choice.name}` } />
                            </ReferenceInput>
                            <TextInput source="isbn" label="ISBN"/>
                            <NumberInput source="price" style={{ width: 150 }} validate={required()} label="Price"/>
                        </SimpleForm>
                    </DialogContent>
                    <DialogActions>
                        <SaveButton saving={isSubmitting} onClick={this.handleSaveClick}/>
                        <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
                            <IconCancel />
                        </Button>
                    </DialogActions>
                </Dialog> */}
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    isSubmitting: isSubmitting('post-quick-create')(state)
});

const mapDispatchToProps = {
    change,
    showNotification,
    submit
};

QuickItemCreateButton.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  
  
const enhance = compose(
    withDataProvider,
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
  );
  
  
export default enhance(QuickItemCreateButton);
