import React, { Fragment } from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    Filter,
    SearchInput,SelectInput, CardActions
} from 'react-admin';
import Typography from '@material-ui/core/Typography';

import PriceQtyField from '../../common/PriceQtyField'
import RefreshPendingPurchasesButtton from '../pendingpurchases/RefreshPendingPurchasesButtton'
import CreatePurchaseOrderButton from './CreatePurchaseOrderButton'

import { Link } from 'react-router-dom';

const Publisher = ({ record = {} }) => 
<Link to={'/inventory/pending-purchase-item?filter={ "manufacturerId": ' + record.id + '}&order=DESC&page=1&perPage=25&sort=id'}><span>{record.manufacturerName}</span></Link>

//http://localhost:3000/#/inventory/pending-purchase-item?filter=%7B%22manufacturerId%22%3A1002%7D&order=DESC&page=1&perPage=25&sort=id

const CustomerListActions = ({
    bulkActions,
    basePath,
    displayedFilters,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter}) => (
    <CardActions>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        }) }

    </CardActions>
);


const Aside = (data) => {
    console.log('inside aside - ')
    console.log(data)

    return (<div style={{ width: 200, margin: '1em' }}>
        <Typography variant="title">Post details</Typography>
        <Typography variant="body1">
            Posts will only be published one an editor approves them
        </Typography>
    </div>)
};


const PendingPurchaseFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <SelectInput allowEmpty={true} label="Pending Reason" source="pendingPurchaseType" alwaysOn choices={[
            { id: 'ORDER',  name: 'Order' },
            { id: 'QUOTE',  name: 'Quote' },
            { id: 'STOCK',  name: 'Stock' },
        ]} />

        <SelectInput allowEmpty={true} label="Supplier Location" source="inLocal" alwaysOn choices={[
            { id: 'true',  name: 'Chennai' },
            { id: 'false',  name: 'Non Chennai' },
        ]} />
    </Filter>
);

const PendingPurchaseList = props => (
    <List filters={<PendingPurchaseFilter />}  exporter={false}  sort={{ field: 'totalAmount', order: 'DESC' }} {...props}  
    perPage={25}
    //aside={<Aside data={props} />}
    actions={<CustomerListActions />}
    >
        <Datagrid>
            <Publisher />
            <PriceQtyField qty="orderQty" price="orderAmount" label="Order" />
            {/* <PriceQtyField qty="quoteQty" price="quoteAmount" label="Quote" /> */}
            <PriceQtyField qty="reStockQty" price="reStockAmount" label="ReStock" />
            <PriceQtyField qty="totalQty" price="totalAmount" label="Total" />
            <PriceQtyField qty="orderedQty" price="orderedAmount" label="In Purchase" />
            {/* <PriceQtyField qty="pendingQty" price="pendingAmount" label="Pending" /> */}
            <TextField source="defaultSupplier.name" label="Default Supplier" />\
            <CreatePurchaseOrderButton />
        </Datagrid>
    </List>
);

export default PendingPurchaseList;
