import QuoteIcon from '@material-ui/icons/Receipt';
import ShippingList from './ShippingList';
// import QuoteCreate from './QuoteCreate';
// import QuoteShow from './ReceiptShow';
// import QuoteEdit from './ReceiptEdit';


export default {
    // edit: QuoteCreate,
    list: ShippingList,
    //create: QuoteCreate,
    // show: QuoteShow,
    // icon: QuoteIcon,
    name: "order/shipping"
};
