import React, { Component } from 'react';
import { Fragment } from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import MultiAutoSuggest from '../../common/MultiAutoSuggest'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { showNotification, withDataProvider, GET_ONE, CREATE, UPDATE  } from 'react-admin';
import AddAuthorButton from './AddAuthorButton'
import Switch from '@material-ui/core/Switch';

import { push } from 'react-router-redux';


const sideEffects = {
  onSuccess: {
    notification: {
      body: 'New Item Created',
      level: 'info',
    },
    redirectTo: '/v2/catalog/item',
  },
  onFailure: {
    notification: {
      body: 'Item Creation Failed',
      level: 'warning',
    },
  },
};


const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 2,
    overflowX: 'auto',
  },
  textField: {
    marginBottom: 20,
    width: 200,
  },
  table: {
    minWidth: 700,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 2,
    //textAlign: 'center',
    //color: theme.palette.text.secondary,
  },
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
});

const bookFormat = [
  {
    value: 'Paper Back',
    label: 'Paper Back',
  },
  {
    value: 'Hard Bound',
    label: 'Hard Bound',
  },
];

const itemAvailability = [
  {
    //value: '11',
    value: 'COMING_SOON',
    label: 'Coming Soon',
  },
  {
    //value: '8',
    value: 'PRE_ORDER',
    label: 'Pre Order',
  },
  {
    //value: '5',
    value: 'OUT_OF_STOCK',
    label: 'Out Of Stock',
  },
  {
    //value: '9',
    value: 'AVAILABLE',
    label: 'Available',
  },
  {
    //value: '10',
    value: 'OUT_OF_PRINT',
    label: 'Out Of Print',
  },
];


const bookLanguage = [
  {
    value: 'Tamil',
    label: 'Tamil',
  },
  {
    value: 'English',
    label: 'English',
  },
  {
    value: 'BiLingual (Tamil & English)',
    label: 'BiLingual (Tamil & English)',
  },
];


function getSuggestionValue(suggestion) {
  //console.log(suggestion)
  return suggestion.name;
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.name, query);
  const parts = parse(suggestion.name, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) =>
          part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
          ) : (
              <strong key={String(index)} style={{ fontWeight: 300 }}>
                {part.text}
              </strong>
            ),
        )}
      </div>
    </MenuItem>
  );
}


function generatePayload(state) {
  console.log(state)
  const _newAuthorList = []
  const newAuthorList = _newAuthorList.concat(state.authorList.map( element => { return {authorId: element.value, authorType: 'AUTHOR'} }),
                        state.translatorList.map( element => { return {authorId: element.value, authorType: 'TRANSLATOR'} }),
                        state.compilerList.map( element => { return {authorId: element.value, authorType: 'COMPILER'} }),
                        state.editorList.map( element => { return {authorId: element.value, authorType: 'EDITOR'} }),
                        state.illustratorList.map( element => { return {authorId: element.value, authorType: 'ILLUSTRATOR'} })
                      )
 
  var payload = {
    id: state.id,
    name: state.name,
    englishName: state.englishName,
    isbn: state.isbn,
    ocId: state.ocId,
    webProduct: {
      id: state.webProductId,
      friendlyUrl: state.friendlyUrl,
      manufacturerId: state.manufacturerId,
      name: state.name,
      subtitle: state.subtitle,
      isbn: state.isbn,
      price: state.price,
      englishName: state.englishName,
      webQty: state.webQty,
      year: state.year,
      format: state.format,
      edition: state.edition,
      page: state.page,
      bookLanguage: state.bookLanguage,
      datePublished: state.datePublished,
      description: state.description,
      weight: state.weight,
      length: state.length,
      width: state.width,
      height: state.height,
      manufacturerCategoryList: [],
      categoryList: state.categoryList.map(element => element.value),
      authorList: newAuthorList,
      relatedProducts: state.relatedProductsList.map(element => element.value),
      itemAvailability: state.itemAvailability,
      defaultDiscount: state.defaultDiscount
    }
  }

  console.log(payload)

  if (state.ocId === 0) {
    return { data: payload }
  } else {
    return { id: state.id, data: payload }
  } 

}

function convertToFriendlyUrl(name) {
   return name.toLowerCase()
						.replace(/\s+|:+/g, '-')
            .replace(/[^a-z0-9-]/ig,'')
            .replace(/-+/g, '-');
}


class WebItemCreate extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      submitClicked: false,
      id: 0,
      ocId: 0,
      webProductId: 0,
      manufacturer : { },
      friendlyUrl: '',
      manufacturerId: '',
      manufacturerName: '',
      name: '',
      isbn: '',
      price: '',
      englishName: '',
      webQty: '10',
      year: '',
      format: 'Paper Back',
      edition: '1',
      page: '',
      bookLanguage: 'Tamil',
      itemAvailability: '',
      defaultDiscount: true,
      datePublished: '',
      description: '',
      weight: '',
      length: '',
      width: '',
      height: '',
      manufacturerCategoryList: [],
      categories: [],
      categoryList: [],
      _authorList: [],
      authorList: [],
      translatorList: [],
      compilerList: [],
      editorList: [],
      illustratorList: [],
      coverImage: '',
      relatedProductsList: [],
      // files: [
      //   {
      //     source: "07179946-f0a5-4c9d-b4b8-27851077ad58.jpeg",
      //     options: {
      //       type: "local"
      //     }
      //   }
      // ]
    };
  }

  handleInit() {
    console.log("FilePond instance has initialised", this.pond);
  }

  // onManufacturerSelection1 = manufacturer => {
  //   this.setState({
  //     manufacturer: manufacturer
  //   })
  // };

  onManufacturerSelection = (selectedManufacturer) => {
    this.setState({
      manufacturerId: selectedManufacturer.value
    })
  }

  onTranslatorSelection = (selectedAuthors) => {
    if (selectedAuthors) {
      this.setState({
        translatorList: selectedAuthors
      })
    } else {
      this.setState({
        translatorList: []
      })
    }
};

  onAuthorSelection = (selectedAuthors) => {
    if (selectedAuthors) {
      this.setState({
        authorList: selectedAuthors
      })
    } else {
      this.setState({
        authorList: []
      })
    }

  };

  onCompilerSelection = (selectedAuthors) => {
    if (selectedAuthors) {
      this.setState({
        compilerList: selectedAuthors
      })
    } else {
      this.setState({
        compilerList: []
      })
    }

  };

  onEditorSelection = (selectedAuthors) => {
    if (selectedAuthors) {
      this.setState({
        editorList: selectedAuthors
      })
    } else {
      this.setState({
        editorList: []
      })
    }

  };

  onIllustratorSelection = (selectedAuthors) => {
    if (selectedAuthors) {
      this.setState({
        illustratorList: selectedAuthors
      })
    } else {
      this.setState({
        illustratorList: []
      })
    }

  };

  onCategorySelection = (selectedCategories) => {
    if (selectedCategories) {
      this.setState({
        categoryList: selectedCategories
      })
    } else {
      this.setState({
        categoryList: []
      })
    }
  }

  onRelatedItemsSelection = (selectedItems) => {
    console.log(selectedItems)
    if (selectedItems) {
      this.setState({
        relatedProductsList: selectedItems
      })
    } else {
      this.setState({
        relatedProductsList: []
      })
    }
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleDefaultDiscountChange = event => {
    this.setState({
      defaultDiscount: event.target.checked,
    });
  };

  handleEnglishNameChange = name => event => {
    this.setState({
      englishName: event.target.value,
    });
    if (this.state.ocId == 0 ) {
      this.setState({
        friendlyUrl: convertToFriendlyUrl(event.target.value)
      });
    } 
  };

  getManufacturer = () => {
    const manufacturer = { value: this.state.manufacturerId, label: this.state.manufacturerName }
    return manufacturer
  }

  componentDidMount() {
    const {
      dataProvider,
    } = this.props;
    const _id = this.props.match.params.id;
    if (_id) {
      var params = { id: _id }
      dataProvider(GET_ONE, 'v2/catalog/product/web', params)
      .then((json) => {
          //dispatch(showNotification('Fetched Receipt :' + json.data.id))
          if (json.data) {
            if (json.data.webProduct ) {
              this.setState({
                id: json.data.id,
                ocId: json.data.ocId,
                name: json.data.webProduct.name,
                englishName: json.data.webProduct.englishName,
                subtitle: json.data.webProduct.subtitle,
                manufacturerName: json.data.manufacturerName,
                manufacturerId: json.data.manufacturerId,
                isbn: json.data.isbn,
                price: json.data.price,
                itemAvailability: json.data.itemAvailability,
                defaultDiscount: json.data.webProduct.defaultDiscount,
                loading: false,
                webProductId: json.data.webProduct.id,
                friendlyUrl: json.data.webProduct.friendlyUrl,
                webQty: json.data.webProduct.webQty,
                stockSync: json.data.stockSync,
                year: json.data.webProduct.year,
                format: json.data.webProduct.format,
                edition: json.data.webProduct.edition,
                page: json.data.webProduct.page,
                bookLanguage: json.data.webProduct.bookLanguage,
                datePublished: json.data.webProduct.datePublished,
                description: json.data.webProduct.description,
                weight: json.data.webProduct.weight,
                length: json.data.webProduct.length,
                width: json.data.webProduct.width,
                height: json.data.webProduct.height,
                categories: json.data.webProduct.categories,
                categoryList: json.data.webProduct.categories.map((category) => { return { label: category.name , value: category.id} }), 
                manufacturerCategoryList: [],
                
                _authorList: json.data.webProduct.authorList,

                authorList: json.data.webProduct.authorList.filter(author => author.authorType === 'AUTHOR').map(author => { return { label: author.author.name , value: author.author.id} }),

                translatorList: json.data.webProduct.authorList.filter(author => author.authorType === 'TRANSLATOR').map(author => { return { label: author.author.name , value: author.author.id} }),

                compilerList: json.data.webProduct.authorList.filter(author => author.authorType === 'COMPILER').map(author => { return { label: author.author.name , value: author.author.id} }),

                editorList: json.data.webProduct.authorList.filter(author => author.authorType === 'EDITOR').map(author => { return { label: author.author.name , value: author.author.id} }),

                illustratorList: json.data.webProduct.authorList.filter(author => author.authorType === 'ILLUSTRATOR').map(author => { return { label: author.author.name , value: author.author.id} }),

                _relatedProductsList: json.data.webProduct.relatedProductsList,

                relatedProductsList:  json.data.webProduct.relatedProductsList.map((product) => { return { label: product.name , value: product.id} }),
              
                coverImage: '',

              })
              
            } else {
              this.setState({
                id: json.data.id,
                name: json.data.name,
                englishName: json.data.englishName,
                friendlyUrl: convertToFriendlyUrl(json.data.englishName),
                manufacturerName: json.data.manufacturerName,
                manufacturerId: json.data.manufacturerId,
                isbn: json.data.isbn,
                price: json.data.price,
                itemAvailability: json.data.itemAvailability,
                loading: false,
                webQty: json.data.stockSync === true ? json.data.avlQty : 10,
                stockSync: json.data.stockSync
              })
            }
        }
      })
      .catch((e) => {
          //dispatch(showNotification('Error: Something went wrong', 'warning'))
      });  
    }
  }

  handleSubmit = () => {
    if (!this.state.submitClicked) {
        this.setState({ submitClicked: true });
        const {
            dispatch,
            dataProvider
        } = this.props;

        var payload = generatePayload(this.state)
        
        if (this.state.ocId == 0) {
          dataProvider(CREATE, 'v2/catalog/item/web', payload)
          .then((json) => {
              console.log(json)
              dispatch(showNotification('Item created with id:' + json.data.id)) 
              this.setState({ submitClicked: false });
              dispatch(push('/v2/catalog/item'));
          })
          .catch((e) => {
              this.setState({ submitClicked: false });
              dispatch(showNotification('Error: Something went wrong', 'warning'))
          });
      } else {
          dataProvider(UPDATE, 'v2/catalog/item/web', payload)
          .then((json) => {
              console.log(json)
              dispatch(showNotification('Item Updated with id:' + json.data.id)) 
              this.setState({ submitClicked: false });
              dispatch(push('/v2/catalog/item'));
          })
          .catch((e) => {
              this.setState({ submitClicked: false });
              dispatch(showNotification('Error: Something went wrong', 'warning'))
          });
      }
    }
   
};
  
  render() {
    const { classes, cartObj, ...props } = this.props;
    
    console.log(this.props)
    if (this.state.loading) {
      return null;
    }
    return (
      <Fragment>
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
            <div style={{  marginTop: 10,  width: "37%", display: "inline-flex"}}>
              <div style={{ marginLeft: 10, width: "150%" }}>

              <MultiAutoSuggest isDisabled={true} isMulti={false} inputId="MANUFACTURER" placeholder="Select Publisher" apiUrl="/v2/catalog/manufacturer" selectedValue={this.getManufacturer()} onSelected={this.onManufacturerSelection} />
{/*               
                <AutoSuggest initialValue="Kalachuvadu" placeholder="Type Publisher Name.." fetchUrl="v2/catalog/manufacturer" onItemSuggestionClick={this.onManufacturerSelection} renderSuggestion={renderSuggestion} getSuggestionValue={getSuggestionValue} parameterName='name' /> */}
              </div>
              <div style={{ marginLeft: 10, width: "30%" }}>
              <TextField
                  id="isbn"
                  label="ISBN"
                  className={classes.textField}
                  value={this.state.isbn}
                  onChange={this.handleChange('isbn')}
                  style={{ marginLeft: 10, marginTop: "-10px" }}
                  margin="normal"
                />
              </div>
            </div>

              <div style={{ marginTop: "-15px"}}>
                <TextField
                  id="name"
                  label="Name"
                  className={classes.textField}
                  value={this.state.name}
                  onChange={this.handleChange('name')}
                  style={{ marginLeft: 10, width: "40%" }}
                  margin="normal"
                  required
                />

                <TextField
                  id="subtitle"
                  label="Sub Title"
                  className={classes.textField}
                  value={this.state.subtitle}
                  onChange={this.handleChange('subtitle')}
                  style={{ marginLeft: 10, width: "40%" }}
                  margin="normal"
                />

              </div>

              <div style={{ marginTop: "-15px"}}>
                <TextField
                  id="englishName"
                  label="English Name (with subtitle)"
                  className={classes.textField}
                  value={this.state.englishName}
                  onChange={this.handleEnglishNameChange('englishName')}
                  style={{ marginLeft: 10, width: "40%" }}
                  margin="normal"
                  required
                />
                <TextField
                  id="friendlyUrl"
                  label="Friendly Url"
                  className={classes.textField}
                  value={this.state.friendlyUrl}
                  onChange={this.handleChange('friendlyUrl')}
                  style={{ marginLeft: 10, width: "40%" }}
                  margin="normal"
                  disabled={this.state.ocId === 0}
                />
              </div>

              <div style={{ marginTop: "-10px"}}>
                <TextField
                  id="price"
                  label="Price"
                  className={classes.textField}
                  value={this.state.price}
                  onChange={this.handleChange('price')}
                  style={{ marginLeft: 10, width: "7%" }}
                  margin="normal"
                  disabled
                />

              <TextField
                  id="webQty"
                  label="Qty"
                  className={classes.textField}
                  value={this.state.webQty}
                  onChange={this.handleChange('webQty')}
                  style={{ marginLeft: 10, width: "5%" }}
                  margin="normal"
                  disabled={this.state.stockSync === true}
                />
                

                <TextField
                    id="select-availability"
                    select
                    label="Availability"
                    className={classes.textField}
                    value={this.state.itemAvailability}
                    onChange={this.handleChange('itemAvailability')}
                    SelectProps={{
                      native: true,
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    //helperText="Please select your currency"
                    margin="normal"
                    style={{ marginLeft: 10 }}
                    disabled
                  >
                    {itemAvailability.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                  <span  style={{ color: "red" }}> 5% Disc</span>
                  <Switch id="defaultDiscount" 
                    checked={this.state.defaultDiscount} 
                    onChange={this.handleDefaultDiscountChange} 
                    inputProps={{ 'aria-label': 'primary checkbox' }}/> 
              </div>

              <div style={{ width: "100%", marginTop: 15, display: "inline-flex" }}>
                <div style={{ marginLeft: 9, width: "30%" }}>
                  <MultiAutoSuggest isMulti={true} selectedValue={this.state.authorList} inputId="AUTHOR" placeholder="Select Author" apiUrl="/v2/author" onSelected={this.onAuthorSelection}  />
                </div>
                <div style={{  marginLeft: 10, width: "30%" }}>
                  <MultiAutoSuggest isMulti={true} selectedValue={this.state.translatorList} inputId="TRANSLATOR" placeholder="Select Translator" apiUrl="/v2/author" onSelected={this.onTranslatorSelection} />
                </div>
                <div style={{  marginLeft: 10, width: "30%" }}>
                  <AddAuthorButton/>
                  </div>

              </div>

              <div style={{ width: "100%", marginTop: 20, display: "inline-flex" }}>
              <div style={{ marginLeft: 10, width: "30%" }}>
                  <MultiAutoSuggest isMulti={true} selectedValue={this.state.compilerList} inputId="COMPILER" placeholder="Select Compiler" apiUrl="/v2/author" onSelected={this.onCompilerSelection} />
                </div>
                <div style={{ marginLeft: 9, width: "30%" }}>
                  <MultiAutoSuggest isMulti={true} selectedValue={this.state.editorList} inputId="EDITOR" placeholder="Select Editor" apiUrl="/v2/author" onSelected={this.onEditorSelection} />
                </div>
                <div style={{  marginLeft: 10, width: "30%" }}>
                  <MultiAutoSuggest isMulti={true} selectedValue={this.state.illustratorList} inputId="ILLUSTRATOR" placeholder="Select Illustrator" apiUrl="/v2/author" onSelected={this.onIllustratorSelection}/>
                </div>
              </div>

              <div style={{ width: "76%",  marginTop: 20, display: "inline-flex" }}>
                <div style={{ marginLeft: 9, width: "80%" }}>
                  <MultiAutoSuggest isMulti={true} selectedValue={this.state.categoryList} inputId="CATEGORY" placeholder="Select Category" apiUrl="/v2/catalog/category" onSelected={this.onCategorySelection} />
                </div>
              </div>

              <div>
                <TextField
                  id="year"
                  label="Year"
                  className={classes.textField}
                  value={this.state.year}
                  onChange={this.handleChange('year')}
                  style={{ marginLeft: 10, width: "5%" }}
                  margin="normal"
                />

                <TextField
                    id="datePublished"
                    label="Publication Date"
                    type="date"
                    className={classes.textField}
                    onChange={this.handleChange('datePublished')}
                    value={this.state.datePublished}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ marginLeft: 10, width: "10%" }}
                    margin="normal"
                  />


              <TextField
                  id="page"
                  label="Pages"
                  className={classes.textField}
                  value={this.state.page}
                  onChange={this.handleChange('page')}
                  style={{ marginLeft: 10, width: "5%" }}
                  margin="normal"
                />
                
              <TextField
                  id="edition"
                  label="Edition"
                  className={classes.textField}
                  value={this.state.edition}
                  onChange={this.handleChange('edition')}
                  style={{ marginLeft: 10, width: "5%" }}
                  margin="normal"
                />
                <TextField
                    id="select-format"
                    select
                    label="Book Format"
                    className={classes.textField}
                    value={this.state.format}
                    onChange={this.handleChange('format')}
                    SelectProps={{
                      native: true,
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    //helperText="Please select your currency"
                    margin="normal"
                    style={{ marginLeft: 10 }}
                  >
                    {bookFormat.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>

                  <TextField
                    id="select-format"
                    select
                    label="Language"
                    className={classes.textField}
                    value={this.state.bookLanguage}
                    onChange={this.handleChange('bookLanguage')}
                    SelectProps={{
                      native: true,
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    //helperText="Please select your currency"
                    margin="normal"
                    style={{ marginLeft: 10 }}
                  >
                    {bookLanguage.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
              </div>          

              <div style={{ marginTop: "-10px"}}>
                <TextField
                  id="weight"
                  label="Weight (in grams)"
                  className={classes.textField}
                  value={this.state.weight}
                  onChange={this.handleChange('weight')}
                  style={{ marginLeft: 10, width: "10%" }}
                  margin="normal"
                />

              <TextField
                  id="length"
                  label="Length (in cms)"
                  className={classes.textField}
                  value={this.state.length}
                  onChange={this.handleChange('length')}
                  style={{ marginLeft: 10, width: "10%" }}
                  margin="normal"
                />
                
              <TextField
                  id="width"
                  label="Width (in cms)"
                  className={classes.textField}
                  value={this.state.width}
                  onChange={this.handleChange('width')}
                  style={{ marginLeft: 10, width: "10%" }}
                  margin="normal"
                />
                
              <TextField
                  id="height"
                  label="Height (in cms)"
                  className={classes.textField}
                  value={this.state.height}
                  onChange={this.handleChange('height')}
                  style={{ marginLeft: 10, width: "10%" }}
                  margin="normal"
                />
              </div>

              <div style={{ marginTop: "-20px"}}>
                <TextField
                  id="Description"
                  label="description"
                  className={classes.textField}
                  value={this.state.description}
                  onChange={this.handleChange('description')}
                  style={{ marginLeft: 10, width: "100%" }}
                  margin="normal"
                  multiline={true}
                  rows={5}
                  rowsMax={100}
                />
              </div>

              <div style={{ width: "100%",  marginTop: 10 }}>
                <div style={{ marginLeft: 9, width: "80%" }}>
                  <MultiAutoSuggest isMulti={true} selectedValue={this.state.relatedProductsList} inputId="RELATEDITEMS" placeholder="Select Related Books" apiUrl="/v2/catalog/product/minimal"  onSelected={this.onRelatedItemsSelection}/>
                </div>
              </div>

              {/* <div style={{ marginTop: 10, marginLeft: 5}}>
              
              </div> */}
              <div style={{ width: "100%",  marginTop: 20, display: "inline-flex" }}>
                {/* <SubmitButton classes={classes} disabled={false} payload={generatePayload(this.state)} /> */}
                <Button disabled={this.state.submitClicked} style={{ marginLeft: 8 }} variant="contained" color="primary" onClick={this.handleSubmit}>{this.state.ocId === 0 ? 'SUBMIT' : 'UPDATE' }</Button>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}


WebItemCreate.propTypes = {
  classes: PropTypes.object.isRequired,
  dataProvider: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const enhance = compose(
  withDataProvider,
  withStyles(styles),
);

export default enhance(WebItemCreate);