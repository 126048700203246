
import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    NumberField,
    BooleanField,
    Filter,
    BooleanInput,   
    EditButton,
    SearchInput,
    DateInput,
    SelectInput,
    RadioButtonGroupInput
    
} from 'react-admin';
import { Link } from 'react-router-dom';

const CreateDateField = ({ record = {} }) => <span>{record.createDate}</span>;
CreateDateField.defaultProps = { label: 'Create Date' };

function getStatus(param) {
    switch(param) {
      case 1:
        return 'Complete';
      case 2:
        return 'Suspended';
      default:
        return 'Unkonwn';
    }
  }


const StatusField = ({ record = {} }) => <span>{getStatus(record.status)}</span>;
StatusField.defaultProps = { label: 'Status' };

const PriceField = ({ record = {} }) => <span>₹ {record.total} </span>;
StatusField.defaultProps = { label: 'Total' };

const ProcessButton = ({ record = {} }) => 
<Link to={"/order/web/"+record.id+"/process"}>Process</Link>

const WebsiteOrderFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <DateInput source="orderDate" alwaysOn />
        {/* <SelectInput source="salesPaymentType" alwaysOn choices={[
        { id: 'CASH',        name: 'Cash' },
        { id: 'CARD',        name: 'Card' },
        { id: 'BANK_TRANSFER', name: 'Bank Transfer' },
        { id: 'PAYTM',       name: 'Paytm' },
        { id: 'PAYUBIZ',     name: 'PayuBiz' },
        { id: 'CHECK',       name: 'Check' },
        { id: 'CREDIT',      name: 'Credit' },
        { id: 'VPP',         name: 'VPP' },
        { id: 'MONEY_ORDER', name: 'Money Order' },
        { id: 'GIVE_AWAY',   name: 'Gift' },
        { id: 'AMAZON',      name: 'Amazon' },
        { id: 'FLIPKART',    name: 'Flipkart' },
        { id: 'OTHERS',      name: 'Others' },
        ]
        } />
        <SelectInput source="salesChannelType" alwaysOn choices={ [
        { id: 'SHOP',        name: 'Shop' },
        { id: 'PANUVAL_COM', name: 'Panuval.Com' },
        { id: 'PHONE',       name: 'Phone' },
        { id: 'WHATSAPP',    name: 'WhatsApp' },
        { id: 'AMAZON',      name: 'Amazon' },
        { id: 'REQUEST_PORTAL', name: 'Request Portal' },
        { id: 'REFERRAL',    name: 'Referral' },
        { id: 'FLIPKART',    name: 'Flipkart' },
        { id: 'SOCIAL_MEDIA',name: 'Social Media' },
        { id: 'ANDROID_APP', name: 'Android App' },
        { id: 'IOS_APP',     name: 'iOS App' },
        { id: 'BOOK_FAIR',   name: 'Book Fair' },
        { id: 'OTHERS',      name: 'Others' },
        ]
        } /> */}

    </Filter>
);

const WebsiteOrderList = props => (
    <List filters={<WebsiteOrderFilter />}  exporter={false}  {...props}  perPage={25}>
        <Datagrid >
            <TextField source="id" />
            <DateField source="orderDate" showTime />
            <TextField source="customerName" />
            <TextField source="telephone" />
            <TextField source="comment" />       
            <PriceField />
            <TextField source="orderStatus" />
            <ProcessButton/>
        </Datagrid>
    </List>
);

export default WebsiteOrderList;
