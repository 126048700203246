import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import { OrderChannelTypes } from './OrderTypes'

const ChannelField = ({ source, record = {} }) => {
    let channel = {}
    OrderChannelTypes.forEach( _channel => {
        if (_channel.id === record[source] )
            channel = _channel
    } )
    return (<span><Chip label={channel.name} style={{ backgroundColor: channel.color, color: "AliceBlue" }} /></span>)
}

ChannelField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default ChannelField;
