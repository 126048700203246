import React from 'react';
import {
    Edit,
    TextInput,
    SimpleForm,
    required,
    SaveButton,
    Toolbar,
    translate
} from 'react-admin';
import Button from '@material-ui/core/Button';

const ManufacturerEditToolbar = translate(({ onCancel, translate, ...props }) => (
    <Toolbar {...props}>
        <SaveButton />
        <Button onClick={onCancel}>{translate('ra.action.cancel')}</Button>
    </Toolbar>
));

const ManufacturerEdit = ({ onCancel, ...props }) => (
    <Edit title=" " {...props}>
        <SimpleForm toolbar={<ManufacturerEditToolbar onCancel={onCancel} />}>
            <TextInput source="name" validate={required()} />
            <TextInput source="englishName" validate={required()} />
            <TextInput disabled source="friendlyUrl" validate={required()} />
        </SimpleForm>
    </Edit>
);

export default ManufacturerEdit;
