import React, { Component } from 'react';
import { fetchUtils, Admin, Resource, ListGuesser } from 'react-admin';

import './App.css';

import authProvider from './authProvider';
import themeReducer from './themeReducer';
import { Login, Layout, Menu } from './layout';
import { Dashboard } from './dashboard';
import customRoutes from './routes';
import englishMessages from './i18n/en';

import items from './catalog/items';
import manufacturer from './catalog/manufacturer';
import suppliers from './receivings/suppliers'
import customers from './sales/customers'
import receipts from './sales/receipts'
import stocktaking from './receivings/stocktaking';
import inventoryStockTaking from './inventory/stocktaking';
import toBookFair from './inventory/tobookfair';
import ShopToOffice from './inventory/shop-to-office';
import OfficeToShop from './inventory/office-to-shop';
import purchaseOrders from './receivings/purchaseorders';
import pendingPurchases from './inventory/pendingpurchases';
import pendingPurchaseItems from './inventory/pendingpurchase-items'
import supplierBills from './receivings/supplierbills';
import WebsiteOrders from './sales/WebsiteOrders'
import shipping from './sales/shipping'
import Quotes from './sales/quotes'
import Orders from './sales/orders'
import cartReducer from './common/cart.reducer'
import stockTakingReducer from './receivings/stocktaking/stocktaking.reducer'
// import orders from './orders';
// import invoices from './invoices';
// import categories from './categories';
// import reviews from './reviews';

import restServer from './dataProvider.js';
import supplierbill from './receivings/supplierbill';
import { PANUVAL_API_URL } from './common/constants'; 
import manifests from './sales/manifests';

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
}

const dataProvider = restServer(PANUVAL_API_URL, httpClient);

const i18nProvider = locale => {
    // if (locale === 'fr') {
    //   return import('./i18n/fr').then(messages => messages.default);
    // }
    // Always fallback on english
    return englishMessages;
};

class App extends Component {
    render() {
        if (!dataProvider) {
            return (
                <div className="loader-container">
                    <div className="loader">Loading...</div>
                </div>
            );
        }
        return (                
                    <Admin
                        title=""
                        dataProvider={dataProvider}
                        customReducers={{ theme: themeReducer, stockTaking: stockTakingReducer, cart: cartReducer }}
                        customRoutes={customRoutes}
                        authProvider={authProvider}
                        dashboard={Dashboard}
                        loginPage={Login}
                        appLayout={Layout}
                        locale="en"
                        menu={Menu}
                        i18nProvider={i18nProvider}
                    >
                        <Resource options={{ label: 'Items' }} title="items" {...items} />
                        <Resource options={{ label: 'Manufacturer' }}  {...manufacturer} />
                        {/* <Resource options={{ label: 'Stock Taking' }}  {...stocktaking} /> */}
                        <Resource options={{ label: 'Suppliers' }}  {...suppliers} />
                        <Resource options={{ label: 'Customers' }}  {...customers} />
                        <Resource options={{ label: 'Receipts' }}  {...receipts} />
                        <Resource options={{ label: 'Bills' }}  {...supplierBills} />
                        <Resource options={{ label: 'Web Orders' }}  {...WebsiteOrders} />
                        <Resource options={{ label: 'Quotes' }}  {...Quotes} />
                        <Resource options={{ label: 'Shipping' }}  {...shipping} />
                        <Resource options={{ label: 'Manifests' }}  {...manifests} />
                        <Resource options={{ label: 'Pending Purchases.' }}  {...pendingPurchases} />
                        <Resource options={{ label: 'Pending Items' }}  {...pendingPurchaseItems} />
                        <Resource options={{ label: 'Orders' }}  {...Orders} />
                        <Resource options={{ label: 'Stock Taking' }}  {...inventoryStockTaking} />
                        {/* <Resource options={{ label: 'Stock Transfer from Shop to Office' }}  {...toBookFair} /> */}
                        <Resource options={{ label: 'To Bookfair' }}  {...ShopToOffice} />
                        {/* <Resource options={{ label: 'Stock Transfer from Office to Shop' }}  {...OfficeToShop} /> */}
                        <Resource options={{ label: 'Purchase Orders' }}  {...purchaseOrders} />
                        <Resource name="v2/author" />

                    </Admin>
        );
    }
}

export default App;
