import ManufacturerIcon from '@material-ui/icons/Store';
import ManufacturerList from './ManufacturerList';
// import ManufacturerEdit from './ManufacturerEdit';
//import ManufacturerCreate from './ManufacturerCreate';

export default {
    list: ManufacturerList,
    //create: ItemCreate,
    // edit: ItemEdit,
    icon: ManufacturerIcon,    
    name: "v2/catalog/manufacturer"
};
