/* eslint-disable react/prop-types */

import React from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import { PANUVAL_API_URL } from './constants'; 

const _mapOptionsToValues = options => {
  return options.map(option => ({
    value: option.id,
    label: option.name
  }));
};

class MultiAutoSuggest extends React.Component {
  state = {
    selectedOption: null,
  };

  componentDidMount() {
    this.setState({
        selectedOption: this.props.selectedValue
      });
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.selectedValue !== prevProps.selectedValue) {
      this.setState({
        selectedOption: this.props.selectedValue
      });
    }
  }

  handleChange = selectedOption => {
    // console.log(selectedOption)
    this.setState({
      selectedOption: selectedOption
    });
    this.props.onSelected(selectedOption)
    // if (this.props.actionOnSelectedOption) {
    //   this.props.actionOnSelectedOption(selectedOption.value);
    // }
  };


  getOptions = (inputValue, callback) => {
    if (!inputValue) {
      return callback([]);
    }

   // const fetchURL = `${searchApiUrl}${queryAdder}search=${inputValue}&limit=${limit}`;
    const api = this.props.apiUrl
    const fetchURL = PANUVAL_API_URL + `${api}?filter=%7B%22q%22%3A%22${inputValue}%22%7D&page=1&perPage=10&sort=%5B%22id%22%2C%22DESC%22%5D`

    const token = localStorage.getItem('token');
    
    fetch(fetchURL,  {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      }).then(response => {
          response.json().then(data => {
            const results = data.results;
            callback(this.mapOptionsToValues(results));
          });
    });
  };

  render() {
    const {  isDisabled, placeholder, inputId, selectedValue, isMulti, setValue, displayOptions } = this.props;
    
    if (displayOptions) {
      this.mapOptionsToValues = displayOptions;
    } else {
      this.mapOptionsToValues = _mapOptionsToValues;
    }

    return (
      <AsyncSelect
          isDisabled={isDisabled}
          isMulti={isMulti}
          cacheOptions
          value={this.state.selectedOption}
          loadOptions={this.getOptions}
          placeholder={placeholder}
          onChange={this.handleChange}
          isClearable={true}
      />  
    );
  }
}

export default MultiAutoSuggest;

