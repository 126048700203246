import BillIcon from '@material-ui/icons/Receipt';
import SupplierBillList from './SupplierBillList';
import SupplierBillShow from './SupplierBillShow';


export default {
    list: SupplierBillList,
    show: SupplierBillShow,
    icon: BillIcon,
    name: "suppliers/bill"
};
