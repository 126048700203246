import React, { Fragment, Component } from 'react';
import {
    List,
    Datagrid,
    TextField,
    Filter,
    SearchInput,
    CardActions,
    CreateButton,
    EditButton,
    ReferenceInput,
    AutocompleteInput,
    NumberField,
    RefreshButton, SelectInput
} from 'react-admin';

import Chip from '@material-ui/core/Chip';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { push } from 'react-router-redux';
import { withStyles } from '@material-ui/core';
import { Route, Switch } from 'react-router';
import { Drawer, Button } from '@material-ui/core';
import ItemCreate from './ItemCreate';
import ItemEdit from './ItemEdit';
import UpdateShelfButton from './UpdateShelfButton'
import { Link } from 'react-router-dom';
import ItemQuickCreateEditButton from '../../common/ItemQuickCreateEditButton'
import CurrencyField from '../../common/CurrencyField';
import { ItemAvailabilityTypes } from '../../common/ItemTypes';

const styles = {
    drawerContent: {
        width: 450
    }
};

// import FullNameField from '../visitors/FullNameField';
// import AddressField from '../visitors/AddressField';
// import InvoiceShow from './InvoiceShow';

// const ListFilters = props => (
//     <Filter {...props}>
//         <DateInput source="date_gte" alwaysOn />
//         <DateInput source="date_lte" alwaysOn />
//     </Filter>
// );

// const ItemsBulkActionButtons = props => (
//     <Fragment>
//         <OutOfStockButton {...props} />
//     </Fragment>
// );


const ProductButton = ({ record = {} }) => 
<Link to={"/v2/catalog/item/web/"+record.id}>{record.ocId == 0 ? <span style={{ color: "red"}}>Add to OC </span> : <span>Edit in OC</span> }</Link>
 
const EditImages = ({ record = {} }) => { 
    if (record.ocId != 0 ) { 
        if(record.coverImage == null || record.coverImage === '') {
            return <Link to={"/v2/catalog/item/web/images/"+record.ocId}><span style={{ color: "red" }}>Add images</span></Link>
        } else {
            return <Link to={"/v2/catalog/item/web/images/"+record.ocId}>Edit images</Link>
        }
    }
    return null
}

// const ProductButton = ({ record = {} }) =>  <Link to={"/v2/catalog/item/web/"+record.ocId}>Add to Web</Link>


// const ProductButton = ({ record = {} }) =>  <Button component={ Link } to={"/v2/catalog/item/web/"+id} color="primary">Add to Web</Button>

const ItemNameField = ({ record = {} }) => 
<div>
    <div> 
        <a rel="noopener noreferrer" target="_blank" href={record.ocUrl}> {record.name}</a>
    </div>
    <div> 
    <span style={{ fontSize: 12, color: "grey" }}>ISBN: {record.isbn}</span>
    </div>
</div>;
ItemNameField.defaultProps = { label: 'Name' };

export const ItemFilter = props => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <ReferenceInput alwaysOn source="manufacturerId" reference="v2/catalog/manufacturer">
            <AutocompleteInput
                optionText={choice =>
                    `${choice.name}`
                }
            />
        </ReferenceInput>

        <ReferenceInput alwaysOn source="authorId" reference="v2/author">
            <AutocompleteInput
                optionText={choice =>
                    `${choice.name}`
                }
            />
        </ReferenceInput>

        <SelectInput allowEmpty={false} label="Location" source="locationId" alwaysOn choices={[
        { id: '1',  name: 'Shop' },
        { id: '2',  name: 'Office' },
        ]
        } />

        <SelectInput source="itemAvailability" alwaysOn choices={[
        { id: 'AVAILABLE',        name: 'Available' },
        { id: 'OUT_OF_STOCK',      name: 'Out of Stock' },
        { id: 'OUT_OF_PRINT',        name: 'Out of Print' },
        { id: 'PRE_ORDER',      name: 'Pre Order' },
        { id: 'COMING_SOON',        name: 'Coming Soon' },
    ]
        } />

        <SelectInput label="Stock Status" source="inStock" alwaysOn choices={[
        { id: 'true',        name: 'InStock' },
        { id: 'false',      name: 'OutOfStock' },]
        } />
        <SelectInput label="StockInWebsite" source="inWebStock" choices={[
        { id: 'true',        name: 'InStock' },
        { id: 'false',      name: 'OutOfStock' },]
        } />
        <SelectInput source="inWebsite" alwaysOn choices={[
        { id: 'true',        name: 'In Website' },
        { id: 'false',      name: 'Not in Website' },]
        } />
         <SelectInput source="hasCoverImage" choices={[
        { id: 'true',        name: 'Has Cover Image' },
        { id: 'false',      name: 'No Cover Image' },]
        } />

        <SelectInput source="hasDescription"  choices={[
        { id: 'true',        name: 'Has Description' },
        { id: 'false',      name: 'No Description' },]
        } />

        <SelectInput source="stockSync"  choices={[
        { id: 'true',        name: 'ON' },
        { id: 'false',      name: 'OFF' },]
        } />

    </Filter>
);

const onItemAdd = () => {
    window.location.reload(true)
}

const CustomerListActions = ({
    bulkActions,
    basePath,
    displayedFilters,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter}) => (
    <CardActions>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        }) }
        
        <RefreshButton />
        <ItemQuickCreateEditButton onCreate={onItemAdd}/>
    </CardActions>
);



const getLocation = () => {
    const initialValue = 1
    try {
        // Get from local storage by key
        const item = window.localStorage.getItem('locationId');
        // Parse stored json or if none return initialValue
        return item ? JSON.parse(item) : initialValue;
      } catch (error) {
        // If error also return initialValue
        console.log(error);
        return initialValue;
      }
}

const ItemAvailabilityField = ({ source, record = {} }) => {
    let status = {}
    ItemAvailabilityTypes.forEach( item => {
        if (item.id === record[source]) {
            status = item;
        }
    } )
    return (<span><Chip label={status.name} style={{ backgroundColor: status.color }} /></span>)
}

const StockSyncField = ({ source, record = {} }) => {
    let status = {}
    if (record.stockSync)
    {
        status.name = "ON"
        status.color = "LimeGreen"
    }
    else
    {
        status.name = "OFF"
        status.color = "MistyRose"
    }
    return (<span><Chip label={status.name} style={{ backgroundColor: status.color }} /></span>)
}

const EditItemButton = ({ record = {} }) =>  <ItemQuickCreateEditButton itemId={record.id} onUpdate={onItemAdd}/>

class ItemList extends React.Component {
    
    render() {
        const { push, classes, ...props } = this.props;
        return (
            <Fragment>
                <List
                    title="Items"
                    {...props}  
                    exporter={false} 
                    perPage={25}
                    filters={<ItemFilter />}
                    sort={{ field: 'id', order: 'DESC' }}
                    actions={<CustomerListActions />}
                    filterDefaultValues={{ locationId: '1' }}
                >
                    <Datagrid>
                        <TextField  source="id" />
                        <ItemNameField  />
                        {/* <TextField  source="isbn" /> */}
                        <TextField source="manufacturerName" label="Publisher" />
                        <CurrencyField source="price" />
                        {/* <TextField  source="itemAvailability" /> */}
                        <ItemAvailabilityField source="itemAvailability" />
                        {/* <NumberField style={{ width: "5%" }} source="shelfCodes" /> */}
                        {/* <NumberField  source="reorderQty" label="MinQty" /> */}
                        <NumberField  source="qty" label="Stock Qty"/>
                        <StockSyncField source="stockSync" />
                        <NumberField  source="avlQty" /> 
                        <UpdateShelfButton label="Shelf Codes" />
                        <EditItemButton />
                        <ProductButton />
                        <EditImages />
                        {/* <UploadImageButton  style={{ width: "5%" }}/> */}
                    </Datagrid>
                </List>
            </Fragment>
        );
    }

}

export default compose(
    connect(
        undefined,
        { push }
    ),
    withStyles(styles)
)(ItemList);

