// hooks to maintain state
// submit using dataProvider
//

import React, { Fragment, useState } from 'react';
import {
    showNotification,
    withDataProvider,
    CREATE,
} from 'react-admin';

import Button from '@material-ui/core/Button';
import { push } from 'react-router-redux';

const RefreshPendingPurchasesButtton = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    
    const refreshPendingPurchases = () => {
        const {
          dispatch,
          dataProvider
          } = props;
          setIsLoading(true);
          var payload = {}
          
          dataProvider(CREATE, 'inventory/pending-purchases/refresh', payload)
          .then((json) => {
              dispatch(showNotification('Pending Purchase Items Refreshed'))
              window.location.reload()
              setIsLoading(false);
          })
          .catch(() => {
              dispatch(showNotification('Error: Something went wrong', 'warning'))
              window.location.reload()
              setIsLoading(false);
          });
        
      }

    return (
        <Fragment>
            <Button disabled={isLoading} style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }} variant="contained" color="primary"
             onClick={(event) => { refreshPendingPurchases() }}>
            REFRESH PENDING PURCHASES</Button>
            </Fragment>
    )

}

export default withDataProvider(RefreshPendingPurchasesButtton)