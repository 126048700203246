// hooks to maintain state
// submit using dataProvider
//

import React, { useState, Fragment, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import {
    showNotification,
    Button,
    withDataProvider,
    CREATE,
    UPDATE,
    GET_ONE
} from 'react-admin';

import TextField from '@material-ui/core/TextField';

const AddressCreateEditButton = (props) => {

    const { customer, onCreateEdit, address } = props

    const [showDialog, setShowDialog] = useState(false)
    const [submitClicked, setSubmitClicked] = useState(false)

    const [id, setId] = useState(null)
    const [name, setName] = useState('')
    const [address1, setAddress1] = useState('')
    const [address2, setAddress2] = useState('')
    const [address3, setAddress3] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [pincode, setPincode] = useState('')
    const [mobileNumber, setMobileNumber] = useState('')
    const [country, setCountry] = useState('')

    const setData = (address) => {
            setId(address.id)
            setName(address.name)
            setAddress1(address.address1)
            setAddress2(address.address2)
            setAddress3(address.address3)
            setCity(address.city)
            setPincode(address.pincode)
            setState(address.state)
            setCountry(address.country)
            setMobileNumber(address.mobileNumber)
    }

    const resetState = () => {
        setId(null);
        setName('')
        setAddress1('')
        setAddress2('')
        setAddress3('')
        setCity('')
        setPincode('')
        setState('')
        setCountry('')
        setMobileNumber('')
    }

    const getAddress = () => {
        const address = {
                id : id,
                name: name,
                address1: address1,
                address2: address2,
                address3: address3,
                city: city,
                pincode: pincode,
                state: state,
                country: country,
                mobileNumber: mobileNumber
        }
        return address
    }

    const isAddressEmpty = address => {
        return (address == null || (strlength(address.name) === 0 && 
        strlength(address.address1) === 0 &&
        strlength(address.address2) === 0 &&
        strlength(address.address3) === 0 &&
        strlength(address.city) === 0 &&
        strlength(address.pincode) === 0 &&
        strlength(address.state) === 0 &&
        strlength(address.country) === 0 && 
        strlength(address.mobileNumber) === 0))
    }
   
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    useEffect(() => {

        if (showDialog && address != null) {
            setData(address)
        }
        
        if (showDialog && isAddressEmpty(address)) {
            resetState()
            setName(customer.name)
            setCountry('India')
            setMobileNumber(customer.mobileNumber)
        }

    }, [showDialog]);


     const handleSubmit = () => {
        if (!submitClicked) {
            setSubmitClicked(true)
            onCreateEdit(getAddress()).then((msg) => {
                setShowDialog(false)
                setSubmitClicked(false)
                resetState()
            })
            .catch((e) => {
                setSubmitClicked(false)
            });
        }
     }

    const strlength = (string) => {
        if (string != null) {
            return string.length
        } else {
            return 0
        }
    }

    const validate = () => {
        return ( strlength(name) > 1 && strlength(name) <= 70 &&
        strlength(address1) > 1 && strlength(address1) <= 70 &&
        strlength(address2) > 1 && strlength(address2) <= 70 &&
        strlength(address3) <= 70 &&
        strlength(city) > 1 && strlength(city) <= 40 &&
        strlength(state) > 1 && strlength(city) <= 40 &&
        strlength(country) > 1 && strlength(city) <= 20 &&
        strlength(pincode) > 1 && strlength(pincode) <= 10 &&
        strlength(mobileNumber) > 1 && strlength(mobileNumber) <= 30) 
    }

    return (
        <Fragment>
            <a href="#" onClick={(event) => { event.preventDefault(); setShowDialog(true) }}>Add/Edit</a>
            <Dialog maxWidth="sm" open={showDialog} onClose={() => { setShowDialog(false); resetState() }} aria-label="Add/Edit Address">
                    <Fragment>
                            <DialogTitle>Create/Edit Address for Customer </DialogTitle>
                            <DialogContent>
                            <div>
                                <TextField
                                    required
                                    onChange={(event) => setName(event.target.value)} 
                                    label="Customer Name"
                                    value={name}
                                    style={{ width: 550 }}
                                    helperText={ strlength(name) > 70 ? "Length should be less than 70 characters" : ""}
                                />
                            </div>
                            <div style={{ marginTop: 7 }}>
                                <TextField
                                    required
                                    onChange={(event) => setAddress1(event.target.value)} 
                                    error={ strlength(address1) > 70}
                                    helperText={ strlength(address1) > 70 ? "Length should be less than 70 characters" : ""}
                                    label="Address 1"
                                    value={address1}
                                    style={{ width: 550 }}
                                />
                            </div>
                            <div style={{ marginTop: 7 }}>
                                <TextField
                                    required
                                    onChange={(event) => setAddress2(event.target.value)} 
                                    error={ strlength(address2) > 70}
                                    helperText={ strlength(address2) > 70 ? "Length should be less than 70 characters" : ""}
                                    label="Address 2"
                                    value={address2}
                                    style={{ width: 550 }}
                                />
                            </div>
                            <div style={{ marginTop: 7 }}>
                                <TextField
                                    onChange={(event) => setAddress3(event.target.value)} 
                                    error={ strlength(address3) > 70}
                                    helperText={ strlength(address3) > 70 ? "Length should be less than 70 characters" : ""}
                                    label="Address 3"
                                    value={address3}
                                    style={{ width: 550 }}
                                />
                            </div>
                            <div style={{ marginTop: 7 }}>
                                <TextField
                                    required
                                    error={ strlength(city)  > 40}
                                    onChange={(event) => setCity(event.target.value)} 
                                    label="City"
                                    value={city}
                                    style={{ width: 300 }}
                                    helperText={strlength(city) > 40 ? "Length should be less than 40 characters" : ""}
                                />
                            </div>
                            <div style={{ marginTop: 7 }}>
                                <TextField
                                    required
                                    error={ strlength(pincode) > 20}
                                    onChange={(event) => setPincode(event.target.value)} 
                                    label="Pin Code"
                                    value={pincode}
                                    style={{ width: 150 }}
                                    helperText={strlength(pincode) > 20 ? "Length should be less than 40 characters" : ""}
                                />
                                <TextField
                                    required
                                    error={ strlength(state) > 40}
                                    onChange={(event) => setState(event.target.value)}
                                    label="State"
                                    value={state}
                                    style={{ width: 200, marginLeft: 5 }}
                                    helperText={strlength(state) > 40 ? "Length should be less than 40 characters" : ""}
                                />
                                <TextField
                                    required
                                    error={ strlength(country) > 20}
                                    onChange={(event) => setCountry(event.target.value)}
                                    label="Country"
                                    value={country}
                                    style={{ width: 150, marginLeft: 5 }}
                                    helperText={strlength(country) > 20 ? "Length should be less than 20 characters" : ""}
                                />
                            </div>
                            <div style={{ marginTop: 7 }}>
                                <TextField
                                    required
                                    error={ strlength(mobileNumber) > 30}
                                    onChange={(event) => setMobileNumber(event.target.value)}
                                    label="Mobile Number"
                                    value={mobileNumber}
                                    style={{ width: 400 }}
                                    helperText={ strlength(mobileNumber) > 30 ? "Length should not me more than 30 characters" : ""}
                                />
                            </div>
                            </DialogContent>
                            <DialogActions>
                                <Button label="Submit" disabled={!validate()} onClick={handleSubmit} />
                                <Button label="Cancel" onClick={() => { setShowDialog(false); resetState() }}></Button>
                            </DialogActions>
                    </Fragment>
            </Dialog>
        </Fragment>
    )

}


export default AddressCreateEditButton