// hooks to maintain state
// submit using dataProvider
//

import React, { useState, Fragment, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import {
    showNotification,
    Button,
    withDataProvider,
    CREATE,
    UPDATE,
    GET_ONE,
} from 'react-admin';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';

import TextField from '@material-ui/core/TextField';
import MultiAutoSuggest from './MultiAutoSuggest'
import useLocalStorage from './hooks/useLocalStorage'
import { push } from 'react-router-redux';
import locations from './location'

const DeliveryTypes = [
    { value: 'STORE_PICKUP', label: 'Store Pickup' },
    { value: 'DOOR_DELIVERY', label: 'In Person Delivery' },
    { value: 'SHIPPING', label: 'Shipping' },
    { value: 'POS', label: 'POS' },
  ];


  const strlength = (string) => {
    if (string != null) {
        return string.length
    } else {
        return 0
    }
  }
  
  const isAddressEmpty = address => {
    return (address == null || (strlength(address.name) === 0 && 
    strlength(address.address1) === 0 &&
    strlength(address.address2) === 0 &&
    strlength(address.address3) === 0 &&
    strlength(address.city) === 0 &&
    strlength(address.pincode) === 0 &&
    strlength(address.state) === 0 &&
    strlength(address.country) === 0 && 
    strlength(address.mobileNumber) === 0))
  }

const FulfillOrderItem = (props) => {

    const { fulfillmentId, selectedItems, orderId, address } = props

    const [deliveryType, setDeliveryType] = useState('SHIPPING')
    const [locationId, setLocationId] = useLocalStorage('locationId', '1');

    const [showDialog, setShowDialog] = useState(false)
    const [submitClicked, setSubmitClicked] = useState(false)

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const resetState = () => {

    }
    // useEffect(() => {
    //     const fetchData = async () => {
    //       setIsError(false);
    //       setIsLoading(true);

    //       var params = { id: fulfillmentId}

    //       const {
    //         dispatch,
    //         dataProvider
    //         } = props;

    //       dataProvider(GET_ONE, 'v2/catalog/item', params)
    //         .then((json) => {
    //             setData(json.data);
    //             setIsLoading(false);
    //         })
    //         .catch((e) => {
    //             setIsError(true);
    //             dispatch(showNotification('Error: Something went wrong', 'warning'))
    //             setIsLoading(false);
    //         });
    //     };
    //     if (showDialog && fulfillmentId) {
    //         fetchData();
    //     }
        
    //   }, [showDialog]);

    const handleUpdate = () => {

    }

    // const handleUpdate = () => {
    //     if (!submitClicked) {
    //         setSubmitClicked(true)
    //         const {
    //             dispatch,
    //             dataProvider
    //         } = props;
    
    //         var payload = generateUpdatePayload()
        
    //         dataProvider(UPDATE, 'v2/catalog/item', payload)
    //         .then((json) => {
    //             onUpdate(json.data)
    //             dispatch(showNotification('Item updated with id:' + json.data.id)) 
    //             setShowDialog(false)
    //             setSubmitClicked(false)
    //             resetState()
    //         })
    //         .catch((e) => {
    //             setSubmitClicked(false)
    //             dispatch(showNotification('Error: Something went wrong', 'warning'))
    //         });
    //         };
    // }

    const validate = () => {
        if (deliveryType === 'SHIPPING')
            return !isAddressEmpty(address)
        else
            return true
    }

    const handleSubmit = () => {
        if (!submitClicked) {
            setSubmitClicked(true)
            const {
                dispatch,
                dataProvider,
            } = props;
    
            var fulfillment = {
              orderId: orderId,
              locationId: locationId,
              deliveryType: deliveryType,
              totalQty: 0,
              fulfilledItems: []
            }
  
            let totalQty = 0
            selectedItems.forEach(function (item) {
              var fulfilledItem = {
                "orderItemId" : item.id,
                "itemId" : item.itemId,
                "name" : item.name,
                "manufacturerName" : item.manufacturerName,
                "qty" : item.qty,
                "price" : item.price
              }
              totalQty = totalQty + item.qty
              fulfillment.fulfilledItems.push(fulfilledItem);
            });
  
            fulfillment.totalQty = totalQty
        
            console.log(fulfillment)
            var shippingDetail = {
                orderId: orderId,
                customerName: address.name,
                city: address.city,
                pincode: address.pincode
            }

            fulfillment.shippingDetail = shippingDetail
            
            dataProvider(CREATE, 'order/'+ orderId +'/fulfillment', { data: fulfillment })
            .then((json) => {
                dispatch(showNotification('Fulfillment created with id:' + json.data.id)) 
                setSubmitClicked(false)
                setShowDialog(false)
                window.location.reload(true);
            })
            .catch((e) => {
                setSubmitClicked(false)
                dispatch(showNotification('Error: Something went wrong', 'warning'))
            });
            };
    }

    return (
        <Fragment>
                {fulfillmentId ? 
                    <Button size="small" variant="contained" onClick={() => setShowDialog(true)} label="Edit Fulfillment"></Button>
                    : 
                    <Button disabled={selectedItems.length === 0 } size="small" variant="contained" onClick={() => setShowDialog(true)} label="Create Fulfillment"></Button>
                }

                <Dialog fullWidth={true} maxWidth="md" open={showDialog} onClose={() => { setShowDialog(false); resetState() }} aria-label="Quick Create Item">
                    { isLoading ? <h2>Loading..</h2> : 
                    <Fragment>
                    <DialogTitle style={{ marginTop: -15}}>Fulfill Items for Order# {orderId} </DialogTitle>
                    <DialogContent style={{marginTop: -15}}>
                        <div style={{ display: 'inline-flex' }}>
                            {/* <div style={{ width: 150 }}>
                                <TextField
                                    id="select-currency-native"
                                    select
                                    label="Location"                         
                                    onChange={(e) => setLocationId(e.target.value)}
                                    value={locationId}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    margin="normal"
                                    style={{ marginLeft: 10 }}
                                >
                                    {locations.map(option => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                    ))}
                                </TextField>
                            </div> */}

                            <div style={{  width: 150 }}>
                                <TextField
                                id="deliveryType"
                                fullWidth
                                select
                                label="Delivery Type"                         
                                onChange={(e) => setDeliveryType(e.target.value)}
                                value={deliveryType}
                                SelectProps={{
                                    native: true,
                                }}
                                margin="normal"
                                >
                                {DeliveryTypes.map(option => (
                                    <option key={option.value} value={option.value}>
                                    {option.label}
                                    </option>
                                ))}
                                </TextField>
                            </div>
                        </div>
                        <Table padding="dense">
                            <TableHead>
                                <TableRow>
                                    <TableCell >Item Id</TableCell>
                                    <TableCell align="right">Name</TableCell>
                                    <TableCell align="right">Publisher</TableCell>
                                    <TableCell align="right">Price</TableCell>
                                    <TableCell align="right">Qty</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {selectedItems.map(item => (
                                    <TableRow key={"i_" + item.itemId}>
                                        <TableCell  align="right">{item.itemId}</TableCell>
                                        <TableCell  align="right">{item.name}</TableCell>
                                        <TableCell  align="right">{item.manufacturerName}</TableCell>
                                        <TableCell  align="right">₹ {item.price}</TableCell>
                                        <TableCell  align="right">{item.qty}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </DialogContent>
                    <DialogActions>
                        {fulfillmentId ? 
                            <Button disabled={!validate()} label="Update" onClick={handleUpdate} />
                            : 
                            <Button disabled={!validate()}  label="Submit" onClick={handleSubmit} />
                        }
                    <Button label="Cancel" onClick={() => { setShowDialog(false); resetState() }}></Button>
                    </DialogActions>
                    </Fragment>
                    }
                </Dialog>
            </Fragment>
    )

}

export default withDataProvider(FulfillOrderItem)