import React, { useState, Fragment, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import PanuvalLogo from './PanuvalLogo'
import currency from "currency.js";

const printIt = (elementId, uniqueIframeId) => {
    const content = document.getElementById(elementId)
    let pri
    if (document.getElementById(uniqueIframeId)) {
        pri = document.getElementById(uniqueIframeId).contentWindow
    } else {
        const iframe = document.createElement('iframe')
        iframe.setAttribute('title', uniqueIframeId)
        iframe.setAttribute('id', uniqueIframeId)
        iframe.setAttribute('style', 'height: 0px; width: 0px; position: absolute;')
        document.body.appendChild(iframe)
        pri = iframe.contentWindow
    }
    pri.document.open()
    pri.document.write(content.innerHTML)
    pri.document.close()
    pri.focus()
    pri.print()

}

const PrintFulfillmentButton = (props) => {

    const {label, elementId, fulfillment, order} = props
    // print depend on the flags
    // prepare html in a hidden div and print it

    const handlePrint = () => {
        printIt(order.id +"-print", order.id +"-print-unique-id")
    }

    return (
        <Fragment>
            <Button disabled={false} size="small" variant="contained" color="primary" onClick={(event) => { event.preventDefault(); handlePrint(); }}>{label}</Button>
            { fulfillment ? 
            <div id={order.id +"-print"} style={{ display:"none", maxWidth: 600, width: "100%", fontSize: "10px" }}>
                <div style={{ textAlign: "center" }}>
                    <div style={{ verticalAlign: "middle", border: 0 }}>
                        <PanuvalLogo/>   
                    </div>
                    <div style={{ fontSize: "12px", fontWeight: "700", fontSize: "120%" }}>Panuval Bookstore</div>
                    <div style={{ fontSize: "12px"}}>
                        PANUVAL<br />
                        112, First Floor, Thiruvalluvar Salai<br />
                        Thiruvanmiyur. Chennai - 41
                    </div>
                    <div style={{ fontSize: "12px", marginBottom: "5px" }}>9789009666</div>
                    <div style={{ fontSize: "12px"}}> Packing Slip </div>
                    {/* <div style={{ fontSize: "12px", marginBottom: "5px" }}>{controllerProps.record && controllerProps.record.createDate}</div> */}
                </div>
                <div style={{ fontSize: "12px", marginBottom: "5px" }}>
                    <div>Order Id: {order.id}</div>
                    <div>Fulfillment Id: {fulfillment.id}</div>
                    {order.name ? <div>Customer: {order.name}</div> : null}
                    </div>
                        <table style={{ fontSize: "10px", borderSpacing: 0, backgroundColor: "transparent", marginBottom: "5px", marginTop: "15px", width: "100%", borderCollapse: "collapse", position: "relative" }}>
                            <tbody>
                                <tr>
                                    <th style={{ width: "60%", textAlign: "center", padding: 0 }}>Item</th>
                                    <th style={{ width: "20%", textAlign: "left", padding: 0  }}>Price</th>
                                    <th style={{ width: "5%", textAlign: "left", padding: 0  }}>Qty</th>
                                    <th style={{ width: "20%", textAlign: "right", padding: 0  }}>Total </th>
                                </tr>
                                {fulfillment.fulfilledItems && fulfillment.fulfilledItems.map(item => (
                                    <Fragment key={"id_" + item.id}>
                                    <tr style={{ height: 20 }}>
                                        <td style={{  padding: "3px", position: "relative"}}>{item.name}</td>
                                        <td style={{  padding: "3px", position: "relative"}}>₹{item.price}</td>
                                        <td style={{  padding: "3px", position: "relative"}}>{item.qty}</td>
                                        <td style={{  padding: "3px", position: "relative", textAlign: "right" }}>{currency(item.price).multiply(item.qty).value}₹</td>
                                    </tr>
                                    </Fragment>
                                ))}
                                <tr/>

                            </tbody>
                        </table>
            </div>
            : null }

        </Fragment>
    )

}

export default PrintFulfillmentButton