import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import SettingsIcon from '@material-ui/icons/Settings';
import { withRouter } from 'react-router-dom';
import {
    translate,
    DashboardMenuItem,
    MenuItemLink,
    Responsive,
} from 'react-admin';

// import visitors from '../visitors';
// import orders from '../orders';
// import invoices from '../invoices';
import items from '../catalog/items';
import customers from '../sales/customers';
import receipts from '../sales/receipts'
import supplierbill from '../receivings/supplierbill'
// import categories from '../categories';
// import reviews from '../reviews';
import SubMenu from './SubMenu';

class Menu extends Component {
    state = {
        menuInventory: false,
        menuCatalog: false,
        menuSales: false,
        menuExpenses: false,
    };

    static propTypes = {
        onMenuClick: PropTypes.func,
        logout: PropTypes.object,
    };

    handleToggle = menu => {
        this.setState(state => ({ [menu]: !state[menu] }));
    };

    render() {
        const { onMenuClick, open, logout, translate } = this.props;
        return (
            <div>
                {' '}
                <DashboardMenuItem onClick={onMenuClick} />
                <SubMenu
                    handleToggle={() => this.handleToggle('menuCatalog')}
                    isOpen={this.state.menuCatalog}
                    sidebarIsOpen={open}
                    name="pos.menu.catalog"
                    icon={<items.icon />}
                >
                    <MenuItemLink
                        to={`/v2/catalog/item`}
                        primaryText={translate(`resources.items.name`, {
                            smart_count: 2,
                        })}
                        leftIcon={<items.icon />}
                        onClick={onMenuClick}
                    />

                    <MenuItemLink
                        to={`/v2/catalog/manufacturer`}
                        primaryText={translate(`resources.manufacturer.name`, {
                            smart_count: 2,
                        })}
                        leftIcon={<items.icon />}
                        onClick={onMenuClick}
                    />

                </SubMenu>
                <SubMenu
                    handleToggle={() => this.handleToggle('menuSales')}
                    isOpen={this.state.menuSales}
                    sidebarIsOpen={open}
                    name="Sales"
                    icon={<receipts.icon />}
                >
                    <MenuItemLink
                        to={`/sale/customer`}
                        primaryText={translate(`resources.customers.name`, {
                            smart_count: 2,
                        })}
                        leftIcon={<customers.icon />}
                        onClick={onMenuClick}
                    />
                    {/* <MenuItemLink
                        to={`/sale/receipt`}
                        primaryText={translate(`resources.sales_receipt.name`, {
                            smart_count: 2,
                        })}
                        leftIcon={<receipts.icon />}
                        onClick={onMenuClick}
                    /> */}
                     {/* <MenuItemLink
                        to={`/order/web`}
                        primaryText={'Web Orders'}
                        leftIcon={<receipts.icon />}
                    /> */}

                    <MenuItemLink
                        to={`/order/quote`}
                        primaryText={'Drafts'}
                        leftIcon={<receipts.icon />}
                        onClick={onMenuClick}
                    />

                    <MenuItemLink
                        to={`/order`}
                        primaryText={'Orders'}
                        leftIcon={<receipts.icon />}
                        onClick={onMenuClick}
                    />

                    <MenuItemLink
                        to={`/order/shipping`}
                        primaryText={'Shipping'}
                        leftIcon={<receipts.icon />}
                        onClick={onMenuClick}
                    />
                    
                    <MenuItemLink
                        to={`/order/shipping-manifests`}
                        primaryText={'Manifests'}
                        leftIcon={<receipts.icon />}
                        onClick={onMenuClick}
                    />

                </SubMenu>
                <SubMenu
                    handleToggle={() => this.handleToggle('menuExpenses')}
                    isOpen={this.state.menuExpenses}
                    sidebarIsOpen={open}
                    name="pos.menu.inventory"
                    icon={<items.icon />}
                >
                    {/* <MenuItemLink
                        to={`/receiving/stocktaking`}
                        primaryText='Stock Taking'
                        leftIcon={<items.icon />}
                        onClick={onMenuClick}
                    /> */}
                    <MenuItemLink
                        to={`/suppliers/supplier`}
                        primaryText='Suppliers'
                        leftIcon={<items.icon />}
                        onClick={onMenuClick}
                    />
                    
                    <MenuItemLink
                        to={`/suppliers/order`}
                        primaryText='Drafts' 
                        leftIcon={<supplierbill.icon />}
                        onClick={onMenuClick}
                    /> 

                    <MenuItemLink
                        to={`/suppliers/bill`}
                        primaryText='Bills' 
                        leftIcon={<supplierbill.icon />}
                        onClick={onMenuClick}
                    /> 
                </SubMenu>

                <SubMenu
                    handleToggle={() => this.handleToggle('menuInventory')}
                    isOpen={this.state.menuInventory}
                    sidebarIsOpen={open}
                    name="Inventory"
                    icon={<items.icon />}
                >
                    <MenuItemLink
                        to={`/inventory/stocktaking`}
                        primaryText='Stock Taking'
                        leftIcon={<items.icon />}
                        onClick={onMenuClick}
                    />

                    <MenuItemLink
                        to={`/inventory/transfer/shop-to-office`}
                        primaryText='Shop to BookFair'
                        leftIcon={<items.icon />}
                        onClick={onMenuClick}
                    />

                    {/* <MenuItemLink
                        to={`/inventory/transfer/office-to-shop`}
                        primaryText='BookFair to Shop'
                        leftIcon={<items.icon />}
                        onClick={onMenuClick}
                    /> */}
{/* 
                    <MenuItemLink
                        to={`/inventory/pending-purchase`}
                        primaryText='Pending Purch'
                        leftIcon={<items.icon />}
                        onClick={onMenuClick}
                    /> */}

                    <MenuItemLink
                        to={`/order/pending-items`}
                        primaryText='Pending Items'
                        leftIcon={<items.icon />}
                        onClick={onMenuClick}
                    />

                </SubMenu>

                <Responsive
                    xsmall={
                        <MenuItemLink
                            to="/configuration"
                            primaryText={translate('pos.configuration')}
                            leftIcon={<SettingsIcon />}
                            onClick={onMenuClick}
                        />
                    }
                    medium={null}
                />
                <Responsive
                    small={logout}
                    medium={null} // Pass null to render nothing on larger devices
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    open: state.admin.ui.sidebarOpen,
    theme: state.theme,
    locale: state.i18n.locale,
});

const enhance = compose(
    withRouter,
    connect(
        mapStateToProps,
        {}
    ),
    translate
);

export default enhance(Menu);
