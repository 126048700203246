import React from 'react';
import {
    Edit,
    TextInput,
    SimpleForm,
    required,
    SaveButton,
    Toolbar,
    translate,
    SelectInput,
    BooleanInput,
    ReferenceInput,
    AutocompleteInput,
    NumberInput
} from 'react-admin';
import Button from '@material-ui/core/Button';
import DrawerAutocompleteInput from '../../common/DrawerAutocompleteInput'


const CustomerEditToolbar = translate(({ onCancel, translate, ...props }) => (
    <Toolbar {...props}>
        <SaveButton />
        <Button onClick={onCancel}>{translate('ra.action.cancel')}</Button>
    </Toolbar>
));

const CustomerEdit = ({ onCancel, ...props }) => (
    <Edit title=" " {...props}>
        <SimpleForm toolbar={<CustomerEditToolbar onCancel={onCancel} />}>
            <SelectInput source="customerType" defaultValue={"CUSTOMER"} choices={[
                { id: 'CUSTOMER', name: 'Customer' },
                { id: 'ORGANISATION', name: 'Organisation' },
                { id: 'SELLER', name: 'Seller' },
                { id: 'PUBLISHER', name: 'Publisher' },
                { id: 'OTHERS', name: 'Others' },
            ]} />
            <SelectInput source="customerIntroType" choices={[
                    { id: 'SHOP', name: 'Shop' },
                    { id: 'PANUVAL_COM', name: 'Panuval.com' },
                ]} />

            <TextInput autoFocus source="name" style={{ width: 400 }} validate={required()} />
            <TextInput source="mobileNumber" style={{ width: 400 }} validate={required()} />
            <TextInput source="email" style={{ width: 400 }} />
            <BooleanInput label="Send SMS/WhatsApp" source="sendWhatsAppSms" />
            <BooleanInput label="Send Email" source="sendNewsletter" />
            <TextInput source="area" style={{ width: 400 }}  />
            <TextInput source="notes" style={{ width: 400 }}  />
            <TextInput source="whatsAppNumber" style={{ width: 400 }}  />
        </SimpleForm>
    </Edit>
);

export default CustomerEdit;
