import React, { Fragment } from 'react';
import { ShowController, ShowView, SimpleShowLayout, TextField } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
// import compose from 'recompose/compose';
import currency from "currency.js";
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
// import { ReactComponent as Logo } from '../../panuval-logo.svg';
import PanuvalLogo from '../../common/PanuvalLogo'


const cardActionStyle = {

    zIndex: 2,
    display: 'flex',
    padding: 0,
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-end'

};

const printIt = (elementId, uniqueIframeId) => {
    const content = document.getElementById(elementId)
    let pri
    if (document.getElementById(uniqueIframeId)) {
        pri = document.getElementById(uniqueIframeId).contentWindow
    } else {
        const iframe = document.createElement('iframe')
        iframe.setAttribute('title', uniqueIframeId)
        iframe.setAttribute('id', uniqueIframeId)
        iframe.setAttribute('style', 'height: 0px; width: 0px; position: absolute;')
        document.body.appendChild(iframe)
        pri = iframe.contentWindow
    }
    pri.document.open()
    pri.document.write(content.innerHTML)
    pri.document.close()
    pri.focus()
    pri.print()

}

// const printIt = () => {
//     var content = document.getElementById("print-me");
//     var pri = document.getElementById("ifmcontentstoprint").contentWindow;
//     pri.document.open();
//     pri.document.write(content.innerHTML);
//     pri.document.close();
//     pri.focus();
//     pri.print();
// };



const Actions = ({ basePath, data, resource }) => {
    return (
        <CardActions style={cardActionStyle}>
            {/* Add your custom actions */}
            <Button variant="contained" color="primary" onClick={() => printIt('print-order', 'unique-iframeid-qswde')}>Print</Button>
        </CardActions>
    )
};

const OrderShow = (props) => {
    console.log(props)
    // return null
    return (
    <ShowController   {...props}>
        {controllerProps => {
            const order = controllerProps.record

            if (order) {
                return (
                <ShowView actions={<Actions />} {...props} {...controllerProps}>
                    <SimpleShowLayout>
                        <div id="print-order" style={{ maxWidth: 600, width: "100%", fontSize: "10px" }}>
                            <div style={{ textAlign: "center" }}>
                                <div style={{ verticalAlign: "middle", border: 0 }}>
                                    <PanuvalLogo/>   
                                </div>
                                <div style={{ fontSize: "12px", fontWeight: "700", fontSize: "120%" }}>Panuval Bookstore</div>
                                <div style={{ fontSize: "12px"}}>
                                    PANUVAL<br />
                                    112, First Floor, Thiruvalluvar Salai<br />
                                    Thiruvanmiyur. Chennai - 41
                                </div>
                                <div style={{ fontSize: "12px", marginBottom: "5px" }}>9789009666</div>
                                <div style={{ fontSize: "12px"}}> Order Invoice </div>
                            </div>
                            <div style={{ fontSize: "12px", marginBottom: "5px" }}> 
                                {order.referenceId ? <div>Reference Id: {order.referenceId}</div> : null}
                                <div>Order Id: {order.id}</div>
                                {order.name ? <div>Customer: {order.name}</div> : null}
                                {order.orderDate ? <div>Purchase Date: {new Date(order.orderDate).toLocaleString('en-IN')}</div> : null}
                            </div>
                            <table style={{ fontSize: "10px", borderSpacing: 0, backgroundColor: "transparent", marginBottom: "5px", marginTop: "15px", width: "100%", borderCollapse: "collapse", position: "relative" }}>
                                <tbody>
                                    <tr>
                                        <th style={{ width: "5%", textAlign: "left", padding: 0 }}>No</th>
                                        <th style={{ width: "60%", textAlign: "center", padding: 0 }}>Item</th>
                                        <th style={{ width: "20%", textAlign: "left", padding: 0  }}>Price</th>
                                        <th style={{ width: "5%", textAlign: "left", padding: 0  }}>Qty</th>
                                        <th style={{ width: "20%", textAlign: "right", padding: 0  }}>Total </th>
                                    </tr>
                                    {order.cartItems && order.cartItems.map(item => (
                                        <Fragment key={"id_" + item.id}>
                                        <tr style={{ height: 20 }}>
                                            <td style={{  padding: "3px", position: "relative"}}>{item.line}</td>
                                            <td style={{  padding: "3px", position: "relative"}}>{item.name}</td>
                                            <td style={{  padding: "3px", position: "relative"}}>₹{item.price}</td>
                                            <td style={{  padding: "3px", position: "relative"}}>{item.qty}</td>
                                            <td style={{  padding: "3px", position: "relative", textAlign: "right" }}>{currency(item.price).multiply(item.qty).value}₹</td>
                                        </tr>
                                        {item.discountAmount != 0 && 
                                            <tr>
                                                <td style={{  padding: "3px", position: "relative"}} colSpan={4} className="discount">
                                                Discount Amount 
                                                </td>
                                                <td style={{  padding: "3px", position: "relative", textAlign: "right" }}>{currency(item.discountAmount).value}₹</td>
                                            </tr>
                                        }

                                        {item.discountAmount == 0 && item.discountPercent != 0 && 
                                            <tr>
                                                <td style={{  padding: "3px", position: "relative"}} colSpan={4} className="discount">
                                                {item.discountPercent} % Discount
                                                </td>
                                                <td style={{  padding: "3px", position: "relative", textAlign: "right" }}>{currency(item.price).multiply(item.qty).multiply(item.discountPercent).divide(100).value}₹</td>
                                            </tr>
                                        }
                                        </Fragment>
                                    ))}
                                    <tr/>

                                </tbody>
                            </table>
                            <table style={{ fontSize: "10px", borderSpacing: 0, backgroundColor: "transparent", marginBottom: "15px", marginTop: "5px", width: "100%", borderCollapse: "collapse", position: "relative" }}>
                                <tbody>
                                    {order.cartTotals.map(orderTotal => (
                                        <Fragment>
                                         {orderTotal.amount != 0 && 
                                            <tr key={"otid_" + orderTotal.id} style={{ height: 10 }}> 
                                                <td style={{ width:"90%", padding: "3px", position: "relative", textAlign: "right"}} >{orderTotal.title}</td>
                                                <td style={{ width:"10%", padding: "3px", position: "relative", textAlign: "right"}} >{orderTotal.amount}₹</td>
                                            </tr>
                                         }
                                         </Fragment>
                                    ))}
                                    <tr/>
                                </tbody>
                            </table>
                            <table style={{ fontSize: "10px", borderSpacing: 0, backgroundColor: "transparent", marginBottom: "15px", marginTop: "5px", width: "100%", borderCollapse: "collapse", position: "relative" }}>
                                <tbody>
                                    <tr key={"opidpr"} style={{ height: 10 }}>
                                        <td style={{ width:"90%", padding: "3px", position: "relative", textAlign: "right"}} >Amount Received</td>
                                        <td style={{ width:"10%", padding: "3px", position: "relative", textAlign: "right"}} >{order.totalPayment}₹</td>
                                    </tr>

                                    { order.pendingPayment > 0 ? 
                                        <tr key={"opidpp"} style={{ height: 10 }}>
                                            <td style={{ width:"90%", padding: "3px", position: "relative", textAlign: "right"}} >Customer to Pay</td>
                                            <td style={{ width:"10%", padding: "3px", position: "relative", textAlign: "right"}} >{order.pendingPayment}₹</td>
                                        </tr>
                                            : null 
                                    }
                                    { order.pendingPayment < 0 ?
                                        <tr key={"opidpr"} style={{ height: 10 }}>
                                            <td style={{ width:"90%", padding: "3px", position: "relative", textAlign: "right"}} >To Refund</td>
                                            <td style={{ width:"10%", padding: "3px", position: "relative", textAlign: "right"}} >{order.pendingPayment}₹</td>
                                        </tr> 
                                        : null
                                    }
                                    <tr/>
                                </tbody>
                            </table>
                        </div>
                    </SimpleShowLayout>
                </ShowView> )
            } else {
                return null
            }
        }
        }
    </ShowController>
    )
};


export default OrderShow;
