import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    showNotification,
    Button,
    withDataProvider,
    CREATE
} from 'react-admin';
import TextField from '@material-ui/core/TextField';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';

const styles = {
    drawerContent: {
        width: 450
    }, 
    left: { display: 'inline-block' },
    right: { display: 'inline-block', marginLeft: 32 },
    // name: { display: 'inline-block' },
    // englishName: { display: 'inline-block', marginLeft: 32 },
    // manufactuerName: { display: 'inline-block', marginLeft: 32 },
};


class AddAuthorButton extends Component {
    state = {
        error: false,
        showDialog: false, 
        submitClicked: false,
        authorName: ''
    };

    handleClick = () => {
        this.setState({ showDialog: true });
    };

    handleCloseClick = () => {
        this.setState({ showDialog: false });
    };

    handleChange = name => event => {
        this.setState({
          [name]: event.target.value,
        });
      };

    generatePayload = () => {
        var payload = {
            data : {
                name: this.state.authorName
            }
        }
        return payload
    }
    handleSubmit = () => {
        if (!this.state.submitClicked) {
            this.setState({ submitClicked: true });
            const {
                dispatch,
                dataProvider
            } = this.props;
    
            var payload = this.generatePayload(this.state)
            console.log(payload)
            
            dataProvider(CREATE, 'author/simple', payload)
            .then((json) => {
                console.log(json)
                dispatch(showNotification('Author created with id:' + json.data.id)) 
                this.setState({ showDialog: false, submitClicked: false });
            })
            .catch((e) => {
                this.setState({ submitClicked: false });
                dispatch(showNotification('Error: Something went wrong', 'warning'))
            });
            };
    }


    render() {
        const { classes } = this.props
        const { showDialog } = this.state;
        return (
            <Fragment>
                <Button size="small" variant="contained" onClick={this.handleClick} label="Add Author">
                </Button>
                <Dialog open={showDialog} onClose={this.handleCloseClick} aria-label="Create Author">
                    <DialogContent>
                    <TextField
                        id="author"
                        label="Author Name"
                        className={classes.textField}
                        value={this.state.authorName}
                        onChange={this.handleChange('authorName')}
                        style={{ width: "300px", marginLeft: 5, marginTop: 5 }}
                        margin="normal"
                        />     
                    </DialogContent>
                    <DialogActions>
                        <Button label="Save" onClick={this.handleSubmit}/>
                        <Button label="Cancel" onClick={this.handleCloseClick}></Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

AddAuthorButton.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  
  
const enhance = compose(
    withDataProvider,
    withStyles(styles),
  );
  
  
export default enhance(AddAuthorButton);