
import React, { useState, useEffect, Fragment } from 'react';
import TextField from '@material-ui/core/TextField';
import {
    showNotification,
    Button,
    withDataProvider,
    CREATE,
    UPDATE,
    GET_ONE
} from 'react-admin';
import OrderCustomerEditButton from './OrderCustomerEditButton'



function CustomerDetails(props) {
    const { customer, onChange, onLoad, address  } = props
    
    const [id, setId] = useState(null)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [mobileNumber, setMobileNumber] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const setData = (customer) => {
        if (customer) {
            setId(customer.id)
            setName(customer.name)
            setEmail(customer.email)
            setMobileNumber(customer.mobileNumber)
        }
    }

    const getCustomer = () => {
        const customer = {
            id : id,
            name: name,
            email: email,
            mobileNumber: mobileNumber
        }
        return customer
    }

    const resetState = () => {
        setId(null);
        setName('')
        setEmail('')
        setMobileNumber('')
    }

    useEffect(() => {
        if (customer) {
            setData(customer)
        }

    }, [customer])

    return (
        <Fragment>
            <div style={{marginTop: 15, marginLeft: 10}}>
                <div><b>Customer Details</b> <OrderCustomerEditButton customer={customer} onCreateEdit={onChange} address={getCustomer()} /></div>
                <div style={{marginTop: 7}}><b>{name}</b></div>
                <div style={{marginTop: 3}}>{mobileNumber}</div>
                <div style={{marginTop: 3}}>{email}</div>
            </div>
        </Fragment>
    )
}

export default withDataProvider(CustomerDetails)