// hooks to maintain state
// submit using dataProvider
//

import React, { useState, Fragment, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import {
    showNotification,
    Button,
    withDataProvider,
    CREATE,
    UPDATE,
    GET_ONE
} from 'react-admin';

import TextField from '@material-ui/core/TextField';
import MultiAutoSuggest from './MultiAutoSuggest'
import { ItemAvailabilityTypes } from './ItemTypes'

const ManufacturerCreateEditButton = (props) => {

    const { manufacturerId, onCreate, onUpdate } = props

    const [manufacturer, setManufacturer] = useState({})
    const [name, setName] = useState('');
    const [englishName, setEnglishName] = useState('');
    const [friendlyUrl, setFriendlyUrl] = useState('')
    const [defaultSupplier, setDefaultSupplier] = useState(null)

    const [showDialog, setShowDialog] = useState(false)
    const [submitClicked, setSubmitClicked] = useState(false)


    const setData = (m) => {
        setManufacturer(m)
        setName(m.name)
        setEnglishName(m.englishName)
        setFriendlyUrl(m.friendlyUrl)
        const _supplier = { id: m.defaultSupplierId, name: m.defaultSupplierName }
        setDefaultSupplier(_supplier)
    }

    const resetState = () => {
        setManufacturer({})
        setName('')
        setEnglishName('')
        setFriendlyUrl('')
        setDefaultSupplier(null)
    }

    const generatePayload = () => {
        var payload = {
            data : {
                name: name,
                englishName: englishName,
                defaultSupplierId: defaultSupplier ? defaultSupplier.id : null
            }
        }
        return payload
    }

    const generateUpdatePayload = () => {
        var payload = {
            id: manufacturer.id,
            data : {
                manufacturerId:  manufacturer.id,
                name: name,
                englishName: englishName,
                defaultSupplierId: defaultSupplier ? defaultSupplier.id : null,
                friendlyUrl: friendlyUrl
            }
        }
        return payload
    }

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
          setIsError(false);
          setIsLoading(true);

          var params = { id: manufacturerId}

          const {
            dispatch,
            dataProvider
            } = props;

          dataProvider(GET_ONE, 'v2/catalog/manufacturer', params)
            .then((json) => {
                setData(json.data);
                setIsLoading(false);
            })
            .catch((e) => {
                setIsError(true);
                dispatch(showNotification('Error: Something went wrong', 'warning'))
                setIsLoading(false);
            });
        };
        if (showDialog && manufacturerId) {
            fetchData();
        }
        
      }, [showDialog]);

    const handleUpdate = () => {
        if (!submitClicked) {
            setSubmitClicked(true)
            const {
                dispatch,
                dataProvider
            } = props;
    
            var payload = generateUpdatePayload()
        
            dataProvider(UPDATE, 'v2/catalog/manufacturer', payload)
            .then((json) => {
                onUpdate(json.data)
                dispatch(showNotification('Manufacturer updated with id:' + json.data.id)) 
                setShowDialog(false)
                setSubmitClicked(false)
                resetState()
            })
            .catch((e) => {
                console.log(e)
                setSubmitClicked(false)
                dispatch(showNotification('Error: Something went wrong', 'warning'))
            });
            };
    }

    const handleSubmit = () => {
        if (!submitClicked) {
            setSubmitClicked(true)
            const {
                dispatch,
                dataProvider
            } = props;
    
            var payload = generatePayload()
            console.log(payload)
        
            dataProvider(CREATE, 'v2/catalog/manufacturer', payload)
            .then((json) => {
                console.log(json)
                onCreate(json.data)
                dispatch(showNotification('Manufacturer created with id:' + json.data.id)) 
                setShowDialog(false)
                setSubmitClicked(false)
                resetState()
            })
            .catch((e) => {
                setSubmitClicked(false)
                dispatch(showNotification(e.message, 'warning'))
            });
            };
    }

    const onSupplierSelection = (selectedSupplier) => {
        //console.log(selectedSupplier)
        if (selectedSupplier) {
            const _supplier = { id: selectedSupplier.value, name: selectedSupplier.label }
            setDefaultSupplier(_supplier)
        } else {
            setDefaultSupplier(null)
        }
      }

    const  validate = () => {
        let flag =  (
                name != null && name.length > 0 &&
                englishName != null && englishName.length > 0
                ) 
        // console.log(flag)
        return flag
    }

    const getSupplier = () => {
        if (defaultSupplier) {
            const _supplier = { value: defaultSupplier.id, label: defaultSupplier.name }
            return [_supplier]
        } else {
            return []
        }
      }

    return (
        <Fragment>
                {manufacturerId ? 
                    <a href="#" onClick={(event) => { event.preventDefault(); setShowDialog(true) } }>Edit</a>
                    : 
                    <Button size="small" variant="contained" onClick={() => setShowDialog(true)} label="CREATE PUBLISHER"></Button>
                }

                <Dialog maxWidth="md" open={showDialog} onClose={() => { setShowDialog(false); resetState() }} aria-label="Quick Create Publisher">
                    { isLoading ? <h2>Loading..</h2> : 
                    <Fragment>
                    <DialogTitle>Create New Publisher</DialogTitle>
                    <DialogContent style={{marginTop: -10}}>
                        <div style={{marginTop: 10}}>
                            <TextField  style={{marginTop: 10}}
                                required
                                onChange={(event) => setName(event.target.value)} 
                                label="Name"
                                value={name}
                                style={{ width: 300 }}
                            />
                        </div>
                        <div style={{marginTop: 10}}>
                            <TextField style={{marginTop: 10}}
                            required
                            onChange={(event) => setEnglishName(event.target.value)} 
                            label="English Name"
                            value={englishName}
                            style={{ width: 300 }}
                        />
                        </div>
                        { friendlyUrl != '' ? 
                        <div style={{marginTop: 10}}>
                            <TextField style={{marginTop: 10}}
                            required
                            onChange={(event) => setFriendlyUrl(event.target.value)} 
                            label="Friendly Url"
                            value={friendlyUrl}
                            style={{ width: 300 }}
                        />
                        </div> : null }
                        <div style={{marginTop: 20, marginBottom: 50}}>
                            <div style={{marginTop: 10, marginBottom: 10}}>Select Default Supplier</div>
                            <MultiAutoSuggest inputId="defaultSupplier" placeholder="Select Supplier" apiUrl="/suppliers/supplier"  selectedValue={getSupplier()} onSelected={onSupplierSelection} />
                        </div>
                    </DialogContent>
                    <DialogActions>
                    {manufacturerId ? 
                        <Button label="Update" disabled={!validate()} onClick={handleUpdate} />
                        : 
                        <Button label="Submit" disabled={!validate()} onClick={handleSubmit} />
                    }   
                    <Button label="Cancel" onClick={() => { setShowDialog(false); resetState() }}></Button>
                    </DialogActions>
                    </Fragment>
                    }
                </Dialog>
            </Fragment>
    )

}

export default withDataProvider(ManufacturerCreateEditButton)