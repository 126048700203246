import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    showNotification,
    Button,
    withDataProvider,
    CREATE
} from 'react-admin';
import TextField from '@material-ui/core/TextField';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';


const styles = {
    drawerContent: {
        width: 450
    },
    left: { display: 'inline-block' },
    right: { display: 'inline-block', marginLeft: 32 },
    // name: { display: 'inline-block' },
    // englishName: { display: 'inline-block', marginLeft: 32 },
    // manufactuerName: { display: 'inline-block', marginLeft: 32 },
};


const shippingCarrier = [
    {
      value: 'PROFESSIONAL_COURIER',
      label: 'Professional Courier',
    },
    {
      value: 'FRANCH_EXPRESS',
      label: 'Franch Express',
    },
    {
      value: 'REGISTERED_POST',
      label: 'Registered Post',
    },
    {
      value: 'SPEED_POST',
      label: 'Speed Post',
    },
    ,
    {
      value: 'KPN_PARCEL',
      label: 'KPN Parcel',
    },
    {
      value: 'OTHERS',
      label: 'Other',
    },
  ];


class CreateReceiptButton extends Component {
    state = {
        error: false,
        showDialog: false,
        submitClicked: false,
        shippingWeight : '',
        shippingCarrier: 'FRANCH_EXPRESS',
        customerName: '',
        address1 : '',
        address2 : '',
        address3 : '',
        city: '',
        pinCode: '',
        mobileNumber: '',
    };

    handleClick = () => {
        this.setState({ showDialog: true });
    };

    handleCloseClick = () => {
        this.setState({ showDialog: false });
    };

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    validate = () => {
        return ( this.state.customerName.length > 1 && this.state.customerName.length <= 70 &&
            this.state.customerName.length > 1 && this.state.address1.length <= 70 &&
            this.state.customerName.length > 1 &&this.state.address2.length <= 70 &&
            this.state.address3.length <= 70 &&
            this.state.city.length > 1 && this.state.city.length <= 40 &&
            this.state.pinCode.length > 1 && 
            this.state.mobileNumber.length > 10) 
    }

    generatePayload = () => {
        var payload = {
            data: {
                name: this.state.authorName
            }
        }
        return payload
    }
    
    handleSubmit = () => {
        if (!this.state.submitClicked) {
            this.setState({ submitClicked: true });
            const {
                dispatch,
                dataProvider,
                data
            } = this.props;

            var payload = { data: data}

            dataProvider(CREATE, 'sale/receipt', payload)
                .then((json) => {
                    console.log(json)
                    dispatch(showNotification('Receipt created with id:' + json.data.id))
                    this.setState({ showDialog: false, submitClicked: false });
                })
                .catch((e) => {
                    this.setState({ submitClicked: false });
                    dispatch(showNotification('Error: Something went wrong', 'warning'))
                });
        };
    }


    render() {
        const { classes, address, receiptId  } = this.props
        const { showDialog } = this.state;

        var data = {}
        
        return (
            <Fragment>
                {/* <Button size="small" variant="contained" onClick={this.handleClick} label="Add Author">
                </Button> */}

                <Button variant="contained" color="primary" onClick={this.handleClick} label="CREATE SHIPPING LABEL"></Button>

                <Dialog fullWidth open={showDialog} onClose={this.handleCloseClick} aria-label="Shipping Details">
                    <DialogTitle>Shipping Details for Receipt #{receiptId} </DialogTitle>
                    <DialogContent>
                        <div style={{marginTop: 5}}>
                            <TextField 
                                required
                                onChange={this.handleChange('customerName')} 
                                label="Customer Name"
                                value={this.state.customerName}
                                defaultValue={data.customerName}
                                style={{ width: 550 }}
                                helperText={ this.state.address1.length > 70 ?  "Length should be less than 70 characters" : ""}
                            />
                        </div>
                        <div style={{marginTop: 5}}>
                            <TextField
                                required
                                onChange={this.handleChange('address1')}
                                error={ this.state.address1.length > 70 }
                                helperText={ this.state.address1.length > 70 ?  "Length should be less than 70 characters" : ""}
                                label="Address 1" 
                                value={this.state.address1}
                                defaultValue={data.address1}
                                style={{ width: 550 }} 
                            />
                        </div>
                        <div style={{marginTop: 5}}>
                            <TextField
                                required
                                error={ this.state.address2.length > 70 }
                                onChange={this.handleChange('address2')}
                                label="Address 2"
                                value={this.state.address2}
                                defaultValue={data.address2}
                                style={{ width: 550 }}
                                helperText={ this.state.address2.length > 70 ?  "Length should be less than 70 characters" : ""}
                            />
                        </div>
                        <div style={{marginTop: 5}}>
                            <TextField
                                error={ this.state.address3.length > 70 }
                                onChange={this.handleChange('address3')} 
                                label="Address 3" value={this.state.address3}
                                defaultValue={data.address3}
                                style={{ width: 550 }}
                                helperText={ this.state.address3.length > 70 ?  "Length should be less than 70 characters" : ""}
                            />
                        </div>
                        <div style={{marginTop: 5}}>
                            <TextField
                                required
                                error={ this.state.city.length > 40 }
                                onChange={this.handleChange('city')}
                                label="City"
                                value={this.state.city}
                                defaultValue={data.city}
                                style={{ width: 550 }}
                                helperText={ this.state.city.length > 40 ?  "Length should be less than 40 characters" : ""}
                            />
                        </div>
                        <div style={{marginTop: 5}}>
                            <TextField
                                required 
                                onChange={this.handleChange('pinCode')}
                                label="Pin Code"
                                value={this.state.pinCode}
                                defaultValue={data.pinCode}
                                style={{ width: 150 }}  
                            /> 
                            <TextField 
                                label="State" 
                                disabled 
                                value={this.state.state}
                                defaultValue={data.state}
                                style={{ width: 200, marginLeft: 5 }} 
                            />
                            <TextField 
                                label="Country"
                                disabled
                                value={this.state.country}
                                defaultValue={data.country}
                                style={{ width: 100, marginLeft: 5 }} 
                            />
                        </div>
                        <div style={{marginTop: 5}}>
                            <TextField 
                                error={ this.state.mobileNumber.length < 10 }
                                onChange={this.handleChange('mobileNumber')}
                                label="Mobile Number"
                                value={this.state.mobileNumber}
                                defaultValue={data.mobileNumber}
                                style={{ width: 400 }}
                                helperText={ this.state.mobileNumber.length > 10 ?  "Length should me more than 10 characters" : ""} 
                            />
                        </div>
                        <div style={{marginTop: 5, marginLeft: -13}}>
                            <TextField
                                id="select-shipping-carrier"
                                select
                                label="Shipping Carrier"
                                className={classes.textField}
                                value={this.state.shippingCarrier}
                                onChange={this.handleChange('shippingCarrier')}
                                SelectProps={{
                                native: true,
                                MenuProps: {
                                    className: classes.menu,
                                },
                                }}
                                //helperText="Please select your currency"
                                margin="normal"
                                style={{ marginLeft: 10 }}
                            >
                                {shippingCarrier.map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                                ))}
                            </TextField>&nbsp;&nbsp;

                            <TextField 
                                label="Weight (in grams)" 
                                value={this.state.shippingWeight} 
                                defaultValue={this.state.shippingWeight} 
                                style={{ width: 150 }}  
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button label="Submit" disabled={!this.validate()} onClick={this.handleSubmit} />
                        <Button label="Cancel" onClick={this.handleCloseClick}></Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

CreateReceiptButton.propTypes = {
    classes: PropTypes.object.isRequired,
};


const enhance = compose(
    withDataProvider,
    withStyles(styles),
);


export default enhance(CreateReceiptButton);