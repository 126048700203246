import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { change, reset, submit, isSubmitting } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import {
    required,
    showNotification,
    Button,
    SaveButton,
    SimpleForm,
    TextInput,
    CREATE,
    ReferenceInput,
    NumberInput, 
    withDataProvider,
    Create,
    Toolbar,
    SelectInput,
    translate,
    BooleanInput
} from 'react-admin';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
 
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import Drawer from '@material-ui/core/Drawer';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import DrawerAutocompleteInput from './DrawerAutocompleteInput'
import dataProvider from '../dataProvider';

const styles = {
    drawerContent: {
        width: 450
    }, 
    left: { display: 'inline-block' },
    right: { display: 'inline-block', marginLeft: 32 },
    // name: { display: 'inline-block' },
    // englishName: { display: 'inline-block', marginLeft: 32 },
    // manufactuerName: { display: 'inline-block', marginLeft: 32 },
};


class CustomerQuickCreateButton extends Component {
    state = {
        addAddress: false,
        error: false,
        showDialog: false, 
        submitClicked: false
    };

    handleClick = () => {
        this.setState({ showDialog: true });
    };

    handleCloseClick = () => {
        this.setState({ showDialog: false });
    };

    handleSaveClick = () => {
        const { submit } = this.props;

        // Trigger a submit of our custom quick create form
        // This is needed because our modal action buttons are oustide the form
        submit('post-quick-create-customer');
    };

    handleSubmit = values => {
        if (!this.state.submitClicked) {
            this.state.submitClicked = true
            const {
                showNotification,
                dispatch,
                dataProvider,
                onCreate
            } = this.props;
            var payload = { data: values }
            dataProvider(CREATE, 'sale/customer', payload)
            .then((json) => {
                dispatch(showNotification('Customer created with id:' + json.data.id)) 
                onCreate(json.data);
                this.setState({ showDialog: false, submitClicked: false });
                dispatch(reset('post-quick-create-customer'));
            })
            .catch((e) => {
                this.setState({ submitClicked: false });
                dispatch(showNotification('Error: Something went wrong', 'warning'))
            });
        }
       
    };

    render() {
        const { showDialog } = this.state;
        const { isSubmitting, onCreate, classes } = this.props;

        return (
            <Fragment>

                <Button size="small" variant="contained" onClick={this.handleClick} label="QUICK CREATE CUSTOMER">
                </Button>

                {/* <Button onClick={this.handleClick} label="QUICK CREATE CUSTOMER">
                    <IconContentAdd />
                </Button> */}
                <Dialog fullWidth open={showDialog} onClose={this.handleCloseClick} aria-label="Create Customer">
                    <DialogContent>
                         <SimpleForm form="post-quick-create-customer" resource="sale/customer" onSubmit={this.handleSubmit} toolbar={null}>
                                <SelectInput source="customerType" defaultValue={"CUSTOMER"} 
                                 formClassName={classes.left}
                                 style={{ width: 200 }} 
                                 choices={[
                                    { id: 'CUSTOMER', name: 'Customer' },
                                    { id: 'ORGANISATION', name: 'Organisation' },
                                    { id: 'SELLER', name: 'Seller' },
                                    { id: 'PUBLISHER', name: 'Publisher' },
                                    { id: 'OTHERS', name: 'Others' },
                                ]} />

                                <SelectInput source="customerIntroType"  defaultValue="SHOP"
                                formClassName={classes.right}
                                style={{ width: 200 }} 
                                choices={[
                                        { id: 'OTHERS', name: 'Others' },
                                        { id: 'SHOP', name: 'Shop' },
                                        { id: 'BOOK_FAIR', name: 'Book Fair' },
                                        { id: 'PANUVAL_COM', name: 'Panuval.com' },
                                        { id: 'PHONE', name: 'Phone' },
                                        { id: 'WHATSAPP', name: 'WhatsApp' },
                                        { id: 'SOCIAL_MEDIA', name: 'Social Media' },
                                        { id: 'REFERRAL', name: 'Referral' },
                                        { id: 'REQUEST_PORTAL', name: 'Request Portal' },
                                        { id: 'ANDROID_APP', name: 'Android App' },
                                        { id: 'IOS_APP', name: 'iOS App' },
                                        { id: 'THADAGAM', name: 'Thadagam' },
                                        { id: 'EMAIL', name: 'Email' },
                                    ]} />

                                <TextInput autoFocus source="name" style={{ width: 350 }} formClassName={classes.left}  validate={required()} />
                                <NumberInput label="Disc %" source="discountPercent" formClassName={classes.right} style={{ width: 100 }}  />
                                <TextInput source="mobileNumber"  formClassName={classes.left} style={{ width: 250 }} validate={required()} />
                                <BooleanInput formClassName={classes.right} label="Send SMS/WhatsApp" style={{ width: 140 }} source="sendWhatsAppSms" />
                                <TextInput source="email" formClassName={classes.left} style={{ width: 250 }} />
                                <BooleanInput label="Send Email" formClassName={classes.right} style={{ width: 140 }} source="sendNewsletter" />
                                {/* <FormControlLabel
                                    control={
                                    <Checkbox
                                        checked={this.state.addAddress}
                                        defaultValue={this.state.addAddress}
                                        onChange={(event,checked) =>  { console.log(checked);     this.setState({ addAddress: checked }) }}
                                    />
                                    }
                                    label="Add Address"
                                />
                                
                                { this.state.addAddress ? 
                                <Fragment>
                                    <div><b>Address</b></div>
                                    <TextInput source="defaultAddress.name"  label="Name" style={{ width: 400 }}  /> 
                                    <TextInput source="defaultAddress.mobileNumber"  label="Mobile Number" style={{ width: 400 }}  />
                                    <TextInput source="defaultAddress.address1" label="Address 1" style={{ width: 400 }}  />
                                    <TextInput source="defaultAddress.address2" label="Address 2"  style={{ width: 400 }}  />
                                    <TextInput source="defaultAddress.address3" label="Address 3"  style={{ width: 400 }}  />
                                    <div style={{ width: "100%", marginTop: 15, display: "inline-flex" }}>
                                    <div style={{ width: "70%" }}>
                                        <TextInput fullWidth source="defaultAddress.city" label="City" />
                                    </div>
                                    <div style={{  marginLeft: 30, width: "30%" }}>
                                        <TextInput fullWidth source="defaultAddress.pincode" label="Pin Code" />
                                        </div>
                                    </div>
                                    <div style={{ width: "100%", marginTop: 15, display: "inline-flex" }}>
                                    <div style={{ width: "70%" }}>
                                        <TextInput fullWidth source="defaultAddress.state" label="State" />
                                    </div>
                                    <div style={{  marginLeft: 30, width: "30%" }}>
                                        <TextInput fullWidth source="defaultAddress.country" label="Country" />
                                    </div>
                                    </div>
                                </Fragment> 
                                : null } */}

                                </SimpleForm>
                    </DialogContent>
                    <DialogActions>
                        <SaveButton saving={isSubmitting} onClick={this.handleSaveClick}/>
                        <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
                            <IconCancel />
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    isSubmitting: isSubmitting('post-quick-create-customer')(state)
});

const mapDispatchToProps = {
    change,
    showNotification,
    submit
};

CustomerQuickCreateButton.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  
  
const enhance = compose(
    withDataProvider,
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
  );
  
  
export default enhance(CustomerQuickCreateButton);
