import React, {Fragment} from 'react';

const strlen = (string) => {
    if (string != null) {
        return string.length
    } else {
        return 0
    }
}

function DisplayField(props)  {

    const { fieldName, fieldValue, canBeEmpty, maxChars } = props

    return (
        <Fragment>
           { (!canBeEmpty || strlen(fieldValue) > 0) ? <Fragment>{fieldValue}</Fragment>: null }
           { (strlen(fieldValue) === 0 && !canBeEmpty) ? <Fragment> &nbsp; &nbsp; <span style={{ color: "red"}}>{fieldName} cannot be empty </span> </Fragment> : null}
           { strlen(fieldValue) > maxChars ? <Fragment> <span style={{ color: "red"}}>{fieldName} cannot exceed {maxChars} chars </span> </Fragment>: null }
        </Fragment>
    )
}

export default DisplayField
