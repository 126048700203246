import React, { Fragment, Component } from 'react';
import {
    List,
    Datagrid,
    TextField,
    NumberField,
    Filter,
    SearchInput,
    CardActions,
    CreateButton,
    EditButton
} from 'react-admin';

import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core';
export const SupplierFilter = props => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
);

const styles = {
    drawerContent: {
        width: 370
    }
};

const SupplierListActions = ({ basePath }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
    </CardActions>
);

class SupplierList extends React.Component {
    render() {
        const { push, classes, ...props } = this.props;
        return (
                <List
                    exporter={false}  {...props}  
                    perPage={25}
                    filters={<SupplierFilter />}
                    sort={{ field: 'id', order: 'DESC' }}
                    actions={<SupplierListActions />}
                >
                    <Datagrid>
                        <TextField source="id" />
                        <TextField style={{ width: 300 }}  source="name" label="Supplier" />
                        {/* <TextField source="englishName" /> */}
                        <TextField source="contactName" />
                        <TextField source="mobileNumber" />
                        <EditButton />
                    </Datagrid>
                </List>
        );
    }

    handleClose = () => {
        this.props.push('/v2/catalog/manufacturer');
    };
}

export default compose(
    withStyles(styles)
)(SupplierList);

