// hooks to maintain state
// submit using dataProvider
//

import React, { useState, Fragment, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import useLocalStorage from './hooks/useLocalStorage'
import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField';
import currency from "currency.js";

const ConfirmAddItem = (props) => {

    const { orderId, item, openDialog, onConfirmAddItem, _discountPercent } = props
    const [showDialog, setShowDialog] = useState(false)
    const [submitClicked, setSubmitClicked] = useState(false)

    const [discountPercent, setDiscountPercent] = useState(_discountPercent)
    const [discountAmount, setDiscountAmount] = useState(0)
    const [qty, setQty] = useState(1)
    const [total, setTotal] = useState(0)

    const resetState = () => {

    }
    useEffect(() => {
        if (openDialog) {
            setShowDialog(true)
        }
        
    }, [openDialog, item])


    useEffect(() => {
        setDiscountPercent(_discountPercent)
    }, [_discountPercent])



    useEffect(() => {
        if (item) {
            if (discountAmount > 0) {
                setTotal(currency(item.price).multiply(qty).subtract(currency(discountAmount)).value)
            } else if (discountPercent > 0) {
                setTotal(currency(item.price).multiply(qty).multiply(100 - discountPercent).divide(100).value)
            } else {
                setTotal(currency(item.price).multiply(qty).value)
            }
        }
    }, [qty, item, openDialog, discountAmount, discountPercent])


    const handleAdd = () => {
        const cartItem = {
            line: 1,
            itemId: item.id,
            name: item.name,
            manufacturerId : item.manufacturerId,
            manufacturerName: item.manufacturerName,
            price: item.price,
            qty: qty, 
            discountPercent: discountPercent,
            discountAmount: discountAmount,
            total: total
        }

        onConfirmAddItem(cartItem)
        setShowDialog(false)
    }

    const validate = () => {
        return true
    }

    return (          
            <Dialog fullWidth maxWidth="sm" open={showDialog} onClose={() => { setShowDialog(false); }} aria-label="Quick Create Item">
                { item ? 
                <Fragment>
                <DialogTitle style={{ marginTop: -15}}>Add Item for Order# {orderId} </DialogTitle>
                <DialogContent style={{marginTop: -15}}>
                    <div style={{marginTop: 10}}>
                        <TextField disabled value={item.name} style={{marginTop: 10}} label="Name" style={{ width: 300 }} />
                    </div>

                    <div style={{marginTop: 10}}>
                        <TextField disabled value={item.manufacturerName}  style={{marginTop: 10}} label="Publisher" style={{ width: 300 }} />
                    </div>

                    <div style={{marginTop: 10}}>
                        <TextField disabled value={item.price} style={{marginTop: 10}} label="Price" style={{ width: 300 }} />
                    </div>
                    <div style={{marginTop: 10}}>
                        <TextField style={{marginTop: 10}}
                        required
                        onChange={(event) => setQty(event.target.value)} 
                        label="Qty"
                        value={qty}
                        style={{ width: 50 }}
                        />
                    </div>
                    <div style={{marginTop: 10}}>
                        <TextField 
                        onChange={(event) => setDiscountPercent(event.target.value)} 
                        label="Discount %"
                        value={discountPercent}
                        style={{ width: 100 }}
                    /> 
                    
                    &nbsp;&nbsp; OR &nbsp;&nbsp;
                    
                    <TextField 
                        onChange={(event) => setDiscountAmount(event.target.value)} 
                        label="Discount ₹"
                        value={discountAmount}
                        style={{ width: 100 }}
                    />
                    </div>
                    <div style={{marginTop: 10}}>
                        <TextField disabled
                        label="Total"
                        value={total}
                        style={{ width: 100 }}
                    />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" disabled={ !validate()} onClick={handleAdd}> ADD ITEM </Button>
                    <Button variant="contained" onClick={() => { setShowDialog(false); resetState() }}> CANCEL </Button>
                </DialogActions>
                </Fragment>
                : null}
            </Dialog>
    )

}

export default ConfirmAddItem