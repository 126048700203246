import QuoteIcon from '@material-ui/icons/Receipt';
import OrderList from './OrderList';
import OrderProcess from './OrderProcess';
import OrderCreate from '../quotes/QuoteCreate';
import OrderShow from './OrderShow';


export default {
    edit: OrderProcess,
    list: OrderList,
    //create: OrderCreate,
    show: OrderShow,
    icon: QuoteIcon,
    name: "order"
};
 