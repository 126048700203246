// hooks to maintain state
// submit using dataProvider
//

import React, { useState, Fragment, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import {
    showNotification,
    withDataProvider,
    CREATE,
} from 'react-admin';

import TextField from '@material-ui/core/TextField';

import Button from '@material-ui/core/Button';


const UncapturedOrdersButton = (props) => {

    const [orderIds, setOrderIds] = useState('');

    const [showDialog, setShowDialog] = useState(false)
    const [submitClicked, setSubmitClicked] = useState(false)

    const [isLoading, setIsLoading] = useState(false);

    // const resetState = () => {
    //     setSelectedItems([])
    // }

    const handleSubmit = () => {
        if (!submitClicked) {
            setSubmitClicked(true)
            const {
                dispatch,
                dataProvider,
            } = props;
            // console.log(fulfillmentIds)
            dataProvider(CREATE, 'order/uncaptured', { data: { orderIds: orderIds } })
            .then(() => {
                dispatch(showNotification('Updated un-captured orders')) 
                setSubmitClicked(false)
                setShowDialog(false)
                setOrderIds("")
            })
            .catch((e) => {
                setSubmitClicked(false)
                dispatch(showNotification('Error: Something went wrong', 'warning'))
            });
            };
    }

    return (
        <Fragment>
                <Button variant="contained" color="primary" onClick={() => setShowDialog(true)}> UNCAPTURED ORDERS </Button>

                <Dialog maxWidth="md" open={showDialog} onClose={() => { setShowDialog(false); }} aria-label="Quick Create Item">
                    { isLoading ? <h2>Loading..</h2> : 
                    <Fragment>
                    <DialogTitle style={{ marginTop: 0}}>Uncaptured Orders (Comma separated) </DialogTitle>
                    <DialogContent style={{marginTop: 0}}>
                    <div style={{marginTop: 10}}>
                            <TextField  style={{marginTop: 10}}
                                required
                                onChange={(event) => setOrderIds(event.target.value)} 
                                label="Order Ids"
                                value={orderIds}
                                style={{ width: 500 }}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="primary" onClick={handleSubmit}> SUBMIT </Button>
                        <Button variant="contained" onClick={() => { setShowDialog(false); }}> CANCEL </Button>
                    </DialogActions>
                    </Fragment>
                    }
                </Dialog>
            </Fragment>
    )

}

export default withDataProvider(UncapturedOrdersButton)