// hooks to maintain state
// submit using dataProvider
//

import React, { useState, Fragment, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import {
    showNotification,
    withDataProvider,
    CREATE,
    UPDATE,
    GET_ONE,
} from 'react-admin';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';

import TextField from '@material-ui/core/TextField';
import MultiAutoSuggest from './MultiAutoSuggest'
import useLocalStorage from './hooks/useLocalStorage'
import { push } from 'react-router-redux';
import locations from './location'
import AutoSuggest from './AutoSuggest'
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteButton from './DeleteButton'
import Button from '@material-ui/core/Button';

const DeliveryTypes = [
    { value: 'STORE_PICKUP', label: 'Store Pickup' },
    { value: 'DOOR_DELIVERY', label: 'In Person Delivery' },
    { value: 'SHIPPING', label: 'Shipping' },
    { value: 'POS', label: 'POS' },
  ];


  function renderSuggestion(suggestion, { query, isHighlighted }) {
    const matches = match(suggestion.customerName, query);
    const parts = parse(suggestion.fulfillmentId + " " + suggestion.customerName + " [" + suggestion.city + "] " + "[" + suggestion.pincode + "]", matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) =>
          part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
          ) : (
              <strong key={String(index)} style={{ fontWeight: 300 }}>
                {part.text}
              </strong>
            ),
        )}
      </div>
    </MenuItem>
  );
}

function getSuggestionValue(suggestion) {
  console.log(suggestion)
  return suggestion.fulfillmentId + " " + suggestion.customerName + " [" + suggestion.city + "] " + "[" + suggestion.pincode + "]"
}


const CreateHandoverButton = (props) => {

    const { record } = props

    console.log(props)

    const [selectedItems, setSelectedItems] = useState([])

    const [showDialog, setShowDialog] = useState(false)
    const [submitClicked, setSubmitClicked] = useState(false)

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const resetState = () => {
        setSelectedItems([])
    }

    // useEffect(() => {

    // }, [record])
    // useEffect(() => {
    //     const fetchData = async () => {
    //       setIsError(false);
    //       setIsLoading(true);

    //       var params = { id: fulfillmentId}

    //       const {
    //         dispatch,
    //         dataProvider
    //         } = props;

    //       dataProvider(GET_ONE, 'v2/catalog/item', params)
    //         .then((json) => {
    //             setData(json.data);
    //             setIsLoading(false);
    //         })
    //         .catch((e) => {
    //             setIsError(true);
    //             dispatch(showNotification('Error: Something went wrong', 'warning'))
    //             setIsLoading(false);
    //         });
    //     };
    //     if (showDialog && fulfillmentId) {
    //         fetchData();
    //     }
        
    //   }, [showDialog]);

    const handleUpdate = () => {

    }

    
    const validate = () => {
        return selectedItems.length > 0
    }


    const onDelete = (item) => {
        let _selectedItems = JSON.parse(JSON.stringify(selectedItems))
        _selectedItems = _selectedItems.filter(_item => item.id != _item.id)
        setSelectedItems(_selectedItems)
    }

    const onItemSelection = item => {
        let _selectedItems = JSON.parse(JSON.stringify(selectedItems))
        _selectedItems.push(item)
        setSelectedItems(_selectedItems)
        
    };


    // const handleUpdate = () => {
    //     if (!submitClicked) {
    //         setSubmitClicked(true)
    //         const {
    //             dispatch,
    //             dataProvider
    //         } = props;
    
    //         var payload = generateUpdatePayload()
        
    //         dataProvider(UPDATE, 'v2/catalog/item', payload)
    //         .then((json) => {
    //             onUpdate(json.data)
    //             dispatch(showNotification('Item updated with id:' + json.data.id)) 
    //             setShowDialog(false)
    //             setSubmitClicked(false)
    //             resetState()
    //         })
    //         .catch((e) => {
    //             setSubmitClicked(false)
    //             dispatch(showNotification('Error: Something went wrong', 'warning'))
    //         });
    //         };
    // }

    const handleSubmit = () => {
        if (!submitClicked) {
            setSubmitClicked(true)
            const {
                dispatch,
                dataProvider,
            } = props;
    
            let fulfillmentIds = []
            selectedItems.forEach(function (item) {
                fulfillmentIds.push(item.fulfillmentId)
            });
        
            console.log(fulfillmentIds)
            dataProvider(CREATE, 'order/shipping/handover', { data: { fulfillmentIds: fulfillmentIds } })
            .then((json) => {
                dispatch(showNotification('Items handed over')) 
                setSubmitClicked(false)
                setShowDialog(false)
                window.location.reload(true);
            })
            .catch((e) => {
                setSubmitClicked(false)
                dispatch(showNotification('Error: Something went wrong', 'warning'))
            });
            };
    }

    return (
        <Fragment>
                <Button variant="contained" color="primary" onClick={() => setShowDialog(true)}> HANDOVER ITEMS </Button>

                <Dialog maxWidth="md" open={showDialog} onClose={() => { setShowDialog(false); resetState() }} aria-label="Quick Create Item">
                    { isLoading ? <h2>Loading..</h2> : 
                    <Fragment>
                    <DialogTitle style={{ marginTop: -15}}>Handover Packed Items to Shipping Partner </DialogTitle>
                    <DialogContent style={{marginTop: -15}}>

                        <div style={{ marginTop: 10, display: "flex"}}>
                            <div style={{width: "100%"}}>
                                <AutoSuggest placeholder="Type fulfillment id or scan.." fetchUrl="order/shipping" onItemSuggestionClick={onItemSelection} renderSuggestion={renderSuggestion} getSuggestionValue={getSuggestionValue} />
                            </div>
                        </div>
                        
                        <Table padding="dense">
                            <TableHead>
                                <TableRow>
                                    <TableCell >Id </TableCell>
                                    <TableCell align="right">Fulfillment #</TableCell>
                                    <TableCell align="right">Customer Name</TableCell>
                                    <TableCell align="right">City</TableCell>
                                    <TableCell align="right">Pincode</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {selectedItems.map(item => (
                                    <TableRow key={"i_" + item.id}>
                                        <TableCell  align="right">{item.id}</TableCell>
                                        <TableCell  align="right">{item.fulfillmentId}</TableCell>
                                        <TableCell  align="right">{item.customerName}</TableCell>
                                        <TableCell  align="right">₹ {item.city}</TableCell>
                                        <TableCell  align="right">{item.pincode}</TableCell>
                                        <TableCell  align="right"><DeleteButton onDelete={() => onDelete(item)}/></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="primary" disabled={!validate()} onClick={handleSubmit}> SUBMIT </Button>
                        <Button variant="contained" onClick={() => { setShowDialog(false); resetState() }}> CANCEL </Button>
                    </DialogActions>
                    </Fragment>
                    }
                </Dialog>
            </Fragment>
    )

}

export default withDataProvider(CreateHandoverButton)