import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import { OrderFulfillmentStatusTypes } from './OrderTypes'

const FulfillmentStatusField = ({ source, record = {} }) => {
    let status = {}
    OrderFulfillmentStatusTypes.forEach( item => {
        if (item.id === record[source]) {
            status = item;
        }
    } )
    return (<span><Chip label={status.name} style={{ backgroundColor: status.color, color: "AliceBlue" }} /></span>)
}

FulfillmentStatusField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default FulfillmentStatusField;

