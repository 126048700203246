import React from "react";
import { AutocompleteInput } from 'react-admin';
import { withStyles } from "@material-ui/core/styles";


// https://material-ui.com/layout/basics/
// z-index

// Several Material-UI components utilize z-index, the CSS property that helps control layout by providing a third axis to arrange content. We utilize a default z-index scale in Material-UI that's been designed to properly layer drawers, modals, snackbars, tooltips, and more.

// These values start at an arbitrary number, high and specific enough to ideally avoid conflicts.

//     mobile stepper: 1000
//     app bar: 1100
//     drawer: 1200
//     modal: 1300
//     snackbar: 1400
//     tooltip: 1500

// These values can always be customized. You will find them in the theme under the zIndex key. We don’t encourage customization of individual values; should you change one, you likely need to change them all.

// https://github.com/marmelab/react-admin/issues/2456
// https://github.com/marmelab/react-admin/issues/2695

  export default withStyles(theme => ({ suggestionsContainerOpen: { zIndex: 3000 }}))(AutocompleteInput)

  