
import React, { useState, useReducer } from 'react';
import currency from "currency.js";

function sortProperties(obj, sortedBy, isNumericSort, reverse) {
    sortedBy = sortedBy || 1; // by default first key
    isNumericSort = isNumericSort || false; // by default text sort
    reverse = reverse || false; // by default no reverse
  
    var reversed = (reverse) ? -1 : 1;
  
    var sortable = [];
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        sortable.push([key, obj[key]]);
      }
    }
    if (isNumericSort)
      sortable.sort(function (a, b) {
        return reversed * (a[1][sortedBy] - b[1][sortedBy]);
      });
    else
      sortable.sort(function (a, b) {
        var x = a[1][sortedBy].toLowerCase(),
          y = b[1][sortedBy].toLowerCase();
        return x < y ? reversed * -1 : x > y ? reversed : 0;
      });
    return sortable; // array in format [ [ key1, val1 ], [ key2, val2 ], ... ]
}

const summaryReducer = (_summary, _item) => {
    _summary.totalItems++;
    _summary.totalQty = _summary.totalQty + _item.qty
    _summary.totalAmount = currency(_summary.totalAmount).add(currency(_item.total)).value
    
    if (_item.discountAmount > 0 ) {
      _summary.totalDiscount = currency(_summary.totalDiscount).add(currency(_item.discountAmount)).value
    } else {
      const __discountAmount = currency(_item.price).multiply(_item.qty).multiply(_item.discountPercent).divide(100).value
      _summary.totalDiscount = currency(_summary.totalDiscount).add(currency(__discountAmount)).value
    }

    _summary.subTotal = currency(_summary.subTotal).add(currency(_item.price).multiply(_item.qty)).value
    return _summary;
  }


function reduceCart(cartState) {

    var summary = {
        totalItems: 0,
        totalQty: 0,
        totalAmount: 0.0,
        totalDiscount: 0.0,
        subTotal: 0.0,
      }

    const _summary = cartState.cartItems.reduce(summaryReducer, summary);


    cartState.totalItems = _summary.totalItems
    cartState.totalQty = _summary.totalQty
    cartState.subTotal = _summary.subTotal 
    cartState.totalDiscount = _summary.totalDiscount
    cartState.totalAmount = currency(_summary.totalAmount).subtract(currency(cartState.invoiceDiscount)).add(currency(cartState.shippingAmount)).add(currency(cartState.otherCharges)).value

    return cartState

}
  
function cartItemsReducer(oldCartState, action) {
    switch (action.type) {
        case 'add_item': {
            const item = action.item
            let newCartState = JSON.parse(JSON.stringify(oldCartState))
            newCartState.rowCounter = newCartState.rowCounter + 1
            let _itemExists = false
            newCartState.cartItems.map((_item) => {
                if (_item.itemId === item.itemId) {
                    _item.rowid = newCartState.rowCounter
                    _item.qty = _item.qty + item.qty
                    _item.price = currency(_item.price).value
                    //_item.discountAmount = currency(_item.price).multiply(_item.qty).multiply(_item.discountPercent).divide(100).value 
                    _item.discountAmount = currency(_item.discountAmount).value 
                    _item.total = currency(_item.price).multiply(_item.qty).multiply(100 - _item.discountPercent).divide(100).value 
                    _itemExists = true
                }
                return _item
            })
            
            if (!_itemExists) {
                item.rowid = newCartState.rowCounter
                item.price = currency(item.price).value
                //item.discountAmount = currency(item.price).multiply(item.qty).multiply(item.discountPercent).divide(100).value
                item.discountAmount = currency(item.discountAmount).value
                item.total = currency(item.price).multiply(item.qty).multiply(100 - item.discountPercent).divide(100).value 
                newCartState.cartItems.push(item)
            }

            const arr = sortProperties(newCartState.cartItems, 'rowid', true, true);
            newCartState.cartItems = arr.map(x => x[1])
        
            return reduceCart(newCartState)
        }

        case 'update_item': {
            const item = action.item
            let newCartState = JSON.parse(JSON.stringify(oldCartState))
            newCartState.cartItems.map((_item) => {
                if (_item.itemId === item.itemId) {
                    _item.name = item.name
                    _item.isbn = item.isbn
                    _item.price = item.price
                    _item.manufacturerId = item.manufacturerId
                    _item.manufacturerName = item.manufacturerName
                    _item.stockQty = item.stockQty
                    _item.shelfCodes = item.shelfCodes
                    //_item.discountAmount = currency(_item.price).multiply(_item.qty).multiply(_item.discountPercent).divide(100).value
                    _item.discountAmount = currency(_item.discountAmount).value
                    _item.total = currency(_item.price).multiply(_item.qty).multiply(100 - _item.discountPercent).divide(100).value
                }
                return _item
            })
            return reduceCart(newCartState)
      }

          
        case 'remove_item': {
            const item = action.item
            let newCartState = JSON.parse(JSON.stringify(oldCartState))
            newCartState.cartItems = newCartState.cartItems.filter(_item => item.itemId != _item.itemId)
            return reduceCart(newCartState)
        }

        case 'update_discount_percent': {
            const item = action.item
            let newCartState = JSON.parse(JSON.stringify(oldCartState))
            newCartState.cartItems.map((_item) => {
                if (_item.itemId === item.itemId) {
                    _item.discountPercent = item.discountPercent
                    //_item.discountAmount = currency(_item.price).multiply(_item.qty).multiply(_item.discountPercent).divide(100).value 
                    _item.discountAmount = currency(_item.discountAmount).value
                    _item.total = currency(_item.price).multiply(_item.qty).multiply(100 - _item.discountPercent).divide(100).value 
                }
                return _item
            })
            return reduceCart(newCartState)
        }

        case 'update_qty': {
            const item = action.item 
            let newCartState = JSON.parse(JSON.stringify(oldCartState))
            newCartState.cartItems.map((_item) => {
                if (_item.itemId === item.itemId) {
                    _item.qty = item.qty
                    //_item.discountAmount = currency(_item.price).multiply(_item.qty).multiply(_item.discountPercent).divide(100).value 
                    _item.discountAmount = currency(_item.discountAmount).value
                    _item.total = currency(_item.price).multiply(_item.qty).multiply(100 - _item.discountPercent).divide(100).value 
                }
                return _item
            })

            return reduceCart(newCartState)
        }

        case 'update_invoice_discount': {
            let newCartState = JSON.parse(JSON.stringify(oldCartState))
            newCartState.invoiceDiscount = action.invoiceDiscount
            return reduceCart(newCartState)
        }

        case 'update_other_charges': {
          let newCartState = JSON.parse(JSON.stringify(oldCartState))
          newCartState.otherCharges = action.otherCharges
          return reduceCart(newCartState)
      }


        case 'update_shipping_amount': {
          let newCartState = JSON.parse(JSON.stringify(oldCartState))
          newCartState.shippingAmount = action.shippingAmount
          return reduceCart(newCartState)
        }


        case 'add_selected_item': {
          const item = action.item 
          console.log(item)
          let newCartState = JSON.parse(JSON.stringify(oldCartState))
          newCartState.selectedItems.push(item)
          return reduceCart(newCartState)
        }

        case 'remove_unselected_item': {
          const item = action.item 
          let newCartState = JSON.parse(JSON.stringify(oldCartState))
          newCartState.selectedItems = newCartState.selectedItems.filter(_item => item.itemId != _item.itemId)
          return reduceCart(newCartState)
        }

        // case 'remove_all_items':
        //         return {count: state.count - 1};
        default:
          throw new Error();
      }
}



function useCartState(initalCartState) {

    const [cartState, cartActionDispatch] = useReducer(cartItemsReducer, initalCartState);
    
    return [cartState, cartActionDispatch]

}


export default useCartState