import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    showNotification,
    Button,
    withDataProvider,
    CREATE
} from 'react-admin';
import TextField from '@material-ui/core/TextField';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';


const styles = {
    drawerContent: {
        width: 450
    },
    left: { display: 'inline-block' },
    right: { display: 'inline-block', marginLeft: 32 },
    // name: { display: 'inline-block' },
    // englishName: { display: 'inline-block', marginLeft: 32 },
    // manufactuerName: { display: 'inline-block', marginLeft: 32 },
};


class CreateReceiptButton extends Component {
    state = {
        error: false,
        showDialog: false,
        submitClicked: false,
        authorName: ''
    };

    handleClick = () => {
        this.setState({ showDialog: true });
    };

    handleCloseClick = () => {
        this.setState({ showDialog: false });
    };

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    generatePayload = () => {
        var payload = {
            data: {
                name: this.state.authorName
            }
        }
        return payload
    }
    handleSubmit = () => {
        if (!this.state.submitClicked) {
            this.setState({ submitClicked: true });
            const {
                dispatch,
                dataProvider,
                data
            } = this.props;

            var payload = { data: data}

            dataProvider(CREATE, 'sale/receipt', payload)
                .then((json) => {
                    console.log(json)
                    dispatch(showNotification('Receipt created with id:' + json.data.id))
                    this.setState({ showDialog: false, submitClicked: false });
                    window.location.reload();

                })
                .catch((e) => {
                    this.setState({ submitClicked: false });
                    dispatch(showNotification('Error: Something went wrong', 'warning'))
                });
        };
    }


    render() {
        const { classes, data } = this.props
        const { showDialog } = this.state;
        
        return (
            <Fragment>
                {/* <Button size="small" variant="contained" onClick={this.handleClick} label="Add Author">
                </Button> */}

                <Button disabled={ data.totalQty < 1}variant="contained" color="primary" onClick={this.handleClick} label="CREATE RECEIPT"></Button>

                <Dialog fullWidth={true} maxWidth="md" open={showDialog} onClose={this.handleCloseClick} aria-label="Create Author">
                    <DialogContent>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell >Item Id</TableCell>
                                    <TableCell align="right">Name</TableCell>
                                    <TableCell align="right">Publisher</TableCell>
                                    <TableCell align="right">Item Price</TableCell>
                                    <TableCell align="right">Qty</TableCell>
                                    <TableCell align="right">Disc Amt</TableCell>
                                    <TableCell align="right">Total Amt</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.salesReceiptItems.map(row => (
                                    <TableRow key={"i_" + row.itemId}>
                                        <TableCell  align="right">{row.itemId}</TableCell>
                                        <TableCell  align="right">{row.name}</TableCell>
                                        <TableCell  align="right">{row.manufacturerName}</TableCell>
                                        <TableCell  align="right">₹ {row.price}</TableCell>
                                        <TableCell  align="right">{row.qty}</TableCell>
                                        <TableCell  align="right">{row.discountAmount}</TableCell>
                                        <TableCell  align="right">₹ {row.salePrice}</TableCell>
                                    </TableRow>
                                ))}

                                    <TableRow>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right">Sub Total</TableCell>
                                            <TableCell align="right">₹ {data.subTotal}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right">Discount</TableCell>
                                            <TableCell align="right">₹ {data.totalDiscount}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right">Shipping</TableCell>
                                            <TableCell align="right">₹ {data.shippingAmount}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right">Total</TableCell>
                                            <TableCell align="right">₹ {data.totalAmount}</TableCell>
                                    </TableRow>


                            </TableBody>
                        </Table>
                    </DialogContent>
                    <DialogActions>
                        <Button label="Submit" onClick={this.handleSubmit} />
                        <Button label="Cancel" onClick={this.handleCloseClick}></Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

CreateReceiptButton.propTypes = {
    classes: PropTypes.object.isRequired,
};


const enhance = compose(
    withDataProvider,
    withStyles(styles),
);


export default enhance(CreateReceiptButton);