import React, { Fragment, PureComponent, Component } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import MonthlyRevenue from './MonthlyRevenue';
import StockReport from './StockReport'
import PendingOrders from './PendingOrders'
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, PieChart, Pie, Legend, Cell
} from 'recharts';

import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { PANUVAL_API_URL } from '../common/constants'; 

import { withDataProvider, GET_LIST } from 'react-admin';

const styles = {
  flex: { display: 'flex', marginTop: '1em', marginBottom: '1em' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  leftCol: { flex: 1, marginRight: '1em' },
  rightCol: { flex: 1, marginLeft: '1em' },
  singleCol: { marginTop: '2em', marginBottom: '2em' },
};

const COLORS = ['#82e0aa', '#5dade2', '#c39bd3', '#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const renderCustomizedLabel = function (entry) {
  return entry.name + ' (₹ ' + entry.value + ')' + `${(entry.percent * 100).toFixed(0)}%`;
}

class Dashboard extends Component {

  state = {
    channelSalesData: [],
    dailySalesData: [],
    totalSales: 0.0,
    totalPurchases: 0.0,
    supplierBillByType: [],
    supplierBillByDay: [],
    inventoryData: {},
    bfInventoryData: {},
    pendingOrders: {}
  };

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    // handle refresh
    if (this.props.version !== prevProps.version) {
      this.fetchData();
    }
  }

  fetchData() {
    this._fetchSalesByDay();
    this._fetchSalesByChannel();
    this._fetchSupplierBillByDay();
    this._fetchSupplierBillByType();
    this._fetchTotalInventory(1);
    this._fetchTotalInventory(2);
    this._fetchPendingOrders();
  }

  async _fetchSalesByChannel() {
    const data = await this._fetch('/order/report/channel');
    this.setState({ channelSalesData: data })
    const total = data.reduce((total, channelData) => {
      return total + channelData.value;
    }, 0)
    this.setState({ totalSales: total })
  }


  async _fetchSalesByDay() {
    const data = await this._fetch('/order/report/day');
    this.setState({ dailySalesData: data })
  }

  async _fetchTotalInventory(locationId) {
    const data = await this._fetch('/inventory/' + locationId + '/report');
    if (locationId === 1) 
    {
      this.setState({ inventoryData: data })
    }
    else
    {
      this.setState({ bfInventoryData: data })
    }
  }

  async _fetchPendingOrders() {
    const data = await this._fetch('/order/report/pending');
    this.setState({ pendingOrders: data })
  }

  async _fetchSupplierBillByType() {
    const data = await this._fetch('/suppliers/bill/report/type');
    this.setState({ supplierBillByType: data })
    const total = data.reduce((total, channelData) => {
      return total + channelData.value;
    }, 0)
    this.setState({ totalPurchases: total })
  }


  async _fetchSupplierBillByDay() {
    const data = await this._fetch('/suppliers/bill/report/day');
    this.setState({ supplierBillByDay: data })
  }

  async _fetch(url = '') {
    // Default options are marked with *
    const token = localStorage.getItem('token');
    const response = await fetch(PANUVAL_API_URL + url, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    return await response.json(); // parses JSON response into native JavaScript objects
  }

  

//total discounts given
  render() {
    const {
      dailySalesData, channelSalesData, totalSales, totalPurchases, supplierBillByType, supplierBillByDay, inventoryData, bfInventoryData, pendingOrders
    } = this.state;

    return (
      <div style={styles.flexColumn}>
        
        <div style={styles.flex}>
          <MonthlyRevenue value={totalSales} label="Sales (Current Month)" />
          <MonthlyRevenue value={totalPurchases} label="Purchase (Current Month)"/>
          <PendingOrders pendingOrders={pendingOrders} label="Pending Orders" />
          <StockReport stockReport={inventoryData} label="Stock in Shop" />
          <StockReport stockReport={bfInventoryData} label="Stock in Bookfair" />
        </div>

        <div><h3>Sales Report</h3></div>
        <div style={styles.flex}>
        <Card>
            <AreaChart
              width={900}
              height={400}
              data={dailySalesData}
              margin={{
                top: 10, right: 10, left: 0, bottom: 0,
              }}
            >x
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Area type="monotone" dataKey="SHOP" stackId="1" stroke="#f1948a " fill="#f1948a" />
              <Area type="monotone" dataKey="SOCIAL_MEDIA" stackId="1" stroke="#c39bd3" fill="#c39bd3 " />
              <Area type="monotone" dataKey="PHONE" stackId="1" stroke="#7fb3d5" fill="#7fb3d5" />
              <Area type="monotone" dataKey="WHATSAPP" stackId="1" stroke="#85c1e9" fill="#85c1e9" />
              <Area type="monotone" dataKey="REQUEST_PORTAL" stackId="1" stroke="#76d7c4 " fill="#76d7c4" />
              <Area type="monotone" dataKey="REFERRAL" stackId="1" stroke="#82e0aa" fill="#82e0aa" />
              <Area type="monotone" dataKey="PANUVAL_COM" stackId="1" stroke="#f8c471" fill="#f8c471" />
              <Area type="monotone" dataKey="OTHERS" stackId="1" stroke="#edbb99" fill="#edbb99" />
            </AreaChart>
          </Card>

          <Card style={{ marginLeft: "20px" }}>
            <PieChart width={550} height={400}>
              <Pie dataKey="value" isAnimationActive={true} data={channelSalesData} cx={287} cy={200} outerRadius={80} fill="#8884d8" label={renderCustomizedLabel}>
                {
                  channelSalesData.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} />)
                }
              </Pie>
              <Tooltip />
            </PieChart>
          </Card>
        </div>
        <div><h3>Purchase Report</h3></div>
        <div style={styles.flex}>
          <Card>
            <AreaChart
              width={900}
              height={400}
              data={supplierBillByDay}
              margin={{
                top: 10, right: 10, left: 0, bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Area type="monotone" dataKey="IMMEDIATE" stackId="1" stroke="#8884d8" fill="#8884d8" />
              <Area type="monotone" dataKey="CREDIT" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
              <Area type="monotone" dataKey="POST_DATED_CHECK" stackId="1" stroke="#ffc658" fill="#ffc658" />
              <Area type="monotone" dataKey="BIL_ON_BILL" stackId="1" stroke="#00c658" fill="#00c658" />
              <Area type="monotone" dataKey="OTHER" stackId="1" stroke="#f8c471" fill="#f8c471" />
              <Area type="monotone" dataKey="NOT_KNOWN" stackId="1" stroke="#c39bd3 " fill="#c39bd3 " />
            </AreaChart>
          </Card>
          
          <Card style={{ marginLeft: "20px" }}>
            <PieChart width={550} height={400}>
              <Pie dataKey="value" isAnimationActive={true} data={supplierBillByType} cx={287} cy={200} outerRadius={80} fill="#8884d8" label={renderCustomizedLabel}>
                {
                  supplierBillByType.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} />)
                }
              </Pie>
              <Tooltip />
            </PieChart>
          </Card>

          </div>
    </div>
    

      // <div style={styles.flex}>

      //   <div style={styles.singleCol}>
      //     <MonthlyRevenue value={totalSales} />
      //   </div>

      //   <div style={styles.flex}>
      //     <Card>
      //       <AreaChart
      //         width={900}
      //         height={400}
      //         data={dailySalesData}
      //         margin={{
      //           top: 10, right: 30, left: 0, bottom: 0,
      //         }}
      //       >
      //         <CartesianGrid strokeDasharray="2 2" />
      //         <XAxis dataKey="name" />
      //         <YAxis />
      //         <Tooltip />
      //         <Area type="monotone" dataKey="SHOP" stackId="1" stroke="#8884d8" fill="#8884d8" />
      //         <Area type="monotone" dataKey="WEBSITE" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
      //         <Area type="monotone" dataKey="PANUVAL_COM" stackId="1" stroke="#ffc658" fill="#ffc658" />
      //         <Area type="monotone" dataKey="BANK_TRANSFER" stackId="1" stroke="#00c658" fill="#00c658" />
      //       </AreaChart>
      //     </Card>

      //     <Card style={{ marginLeft: "20px" }}>
      //       <PieChart width={500} height={400}>
      //         <Pie dataKey="value" isAnimationActive={true} data={channelSalesData} cx={250} cy={200} outerRadius={80} fill="#8884d8" label={renderCustomizedLabel}>

      //           {
      //             channelSalesData.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} />)
      //           }
      //         </Pie>
      //         <Tooltip />
      //       </PieChart>
      //     </Card>
      //   </div>
      // </div>
    );
  }

}

const mapStateToProps = state => ({
  version: state.admin.ui.viewVersion,
});

export default compose(
  connect(mapStateToProps),
  withDataProvider
)(Dashboard);
