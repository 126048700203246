import {
  ADD_ITEM_STOCKTAKING,
  UPDATE_ITEM_STOCKTAKING,
  REMOVE_ITEM_STOCKTAKING,
  REMOVE_ALLITEMS_STOCKTAKING
} from "./stocktaking.types";
import currency from "currency.js";

const initialState = {
  items: {
  },
  rowCounter: 0
};

const cloneObj = state => {
  const clone = {
    items: {},
    rowCounter: 0
  };
  Object.assign(clone.items, state.items);
  //Object.assign(clone.rowCounter, state.rowCounter);
  return clone;
};

const setCartItem = (state, item) => {
  // eslint-disable-next-line no-param-reassign
  state.items[item.id] = {};
  const newItem = state.items[item.id];
  newItem.id = item.id;
  newItem.rowid = item.rowid;
  newItem.isbn = item.isbn;
  newItem.name = item.name;
  newItem.qty = item.qty;
  newItem.manufacturerId = item.manufacturerId
  newItem.manufacturerName = item.manufacturerName
  newItem.price = currency(item.price).value;
};

const updateItemStockTaking = (oldState, item) => {
  const state = cloneObj(oldState);
  setCartItem(state, item);
  state.rowCounter =  oldState.rowCounter
  return state;
};

const addItemStockTaking = (oldState, item) => {
  const state = cloneObj(oldState);
  const _item = {}
  _item.isbn = item.isbn
  _item.name = item.name
  _item.id = item.id
  _item.price = item.price
  _item.manufacturerId = item.manufacturerId
  _item.manufacturerName = item.manufacturerName
  _item.qty = 1
  _item.rowid = oldState.rowCounter + 1;

  if (oldState.items[item.id]) {
    _item.qty = state.items[item.id].qty + 1
  } 
  setCartItem(state, _item);
  state.rowCounter =  oldState.rowCounter + 1
  return state;
};

const removeItemStockTaking = (oldState, item) => {
  const state = cloneObj(oldState);
  delete state.items[item.id];
  const isCartEmpty = state.items.length === 0;
  if (isCartEmpty) {
    return initialState;
  }
  return state;
};

export default function stockTaking(state = initialState, action = {}) {
  switch (action.type) {
    case ADD_ITEM_STOCKTAKING:
      return addItemStockTaking(state, action.data);

    case UPDATE_ITEM_STOCKTAKING:
      return updateItemStockTaking(state, action.data);

    case REMOVE_ITEM_STOCKTAKING:
      return removeItemStockTaking(state, action.data);

    case REMOVE_ALLITEMS_STOCKTAKING:
      return initialState;

    default:
      return state;
  }
}
