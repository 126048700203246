import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    NumberField,
    BooleanField,
    Filter,
    BooleanInput,   
    EditButton,
    SearchInput,
    DateInput,
    SelectInput,
    RadioButtonGroupInput
    
} from 'react-admin';

import CurrencyField from '../../common/CurrencyField'
import ChannelField from '../../common/ChannelField'
import FulfillmentStatusField from '../../common/FulfillmentStatusField'
import OrderPaymentStatusField from '../../common/OrderPaymentStatusField'
import OrderStockStatusField from '../../common/OrderStockStatusField'
import OrderDeliveryTypeField from '../../common/OrderDeliveryTypeField'
import OrderPaymentTypeField from '../../common/OrderPaymentTypeField'
import { OrderChannelTypes, OrderFulfillmentStatusTypes, OrderPaymentStatusTypes, OrderItemsAvailability, OrderPaymentTypes } from '../../common/OrderTypes'
import { Link } from 'react-router-dom';

const CreateDateField = ({ record = {} }) => <span>{record.createDate}</span>;
CreateDateField.defaultProps = { label: 'Create Date' };

const EditOrder = ({ record = {} }) => 
<Link to={"/order/"+record.id}><span>OPEN</span></Link>

//   const getLocation = () => {
//     const initialValue = 1
//     try {
//         // Get from local storage by key
//         const item = window.localStorage.getItem('locationId');
//         // Parse stored json or if none return initialValue
//         return item ? JSON.parse(item) : initialValue;
//       } catch (error) {
//         // If error also return initialValue
//         console.log(error);
//         return initialValue;
//       }
// }


const OrderFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <DateInput source="orderDate" alwaysOn />
        {/* <SelectInput allowEmpty={false} label="Location" source="locationId" alwaysOn choices={[
        { id: '1',  name: 'SHOP' },
        { id: '2',  name: 'BOOKFAIR' },]
        } />
         */}

        <SelectInput source="channelType" alwaysOn choices={OrderChannelTypes} />
        <SelectInput source="paymentType" alwaysOn choices={OrderPaymentTypes} />

        <SelectInput allowEmpty={true} label="Date Range" source="dateRange" alwaysOn choices={[
            { id: 'TODAY',  name: 'Today' },
            { id: 'YESTERDAY',  name: 'Yesterday' },
            { id: 'PAST_7_DAYS',  name: 'Past 7 days' },
            { id: 'PAST_30_DAYS',  name: 'Past 30 days' },
            { id: 'PAST_90_DAYS',  name: 'Past 90 days' },
            { id: 'BEFORE_90_DAYS',  name: 'Before 90 days' },
        ]} />

        <SelectInput source="paymentStatus"  choices={OrderPaymentStatusTypes} />
        <SelectInput source="fulfillmentStatus" alwaysOn choices={OrderFulfillmentStatusTypes} />
        <SelectInput source="itemsAvailability" alwaysOn choices={OrderItemsAvailability} />

        <SelectInput source="status" alwaysOn choices={[
        { id: 'OPEN',        name: 'Open' },
        { id: 'COMPLETE',      name: 'Complete' }, 
        { id: 'CANCELLED', name: 'Cancelled' },
        ]} />

    </Filter>
);

const OrderList = props => (
    <List filters={<OrderFilter />}  exporter={false}  {...props}  
    filterDefaultValues={{ status: "OPEN", dateRange: "PAST_90_DAYS"}} 
    perPage={25}>
        <Datagrid>
            <EditOrder/>
            <TextField source="id" />
            <TextField label="Online#" source="referenceId" />
            <DateField source="orderDate" showTime/>
            <TextField source="name" />
            <TextField source="mobileNumber" />
            <ChannelField source="channelType" />
            <OrderPaymentStatusField source="paymentStatus" />
            <OrderPaymentTypeField source="paymentType" />
            <FulfillmentStatusField source="fulfillmentStatus" />
            <CurrencyField source="total" />
            <TextField source="totalQty" />
            <TextField source="unFulfilledQty" label="Pending Qty"/>
            <OrderStockStatusField label="Stock Status" />
            {/* <OrderDeliveryTypeField source="deliveryType" /> */}
            {/* <TextField source="status" /> */}
        </Datagrid>
    </List>
);

export default OrderList;
