import React from 'react';
import PropTypes from 'prop-types';

const CurrencyField = ({ source, record = {} }) => record[source] ? <span>₹ {record[source]}</span> : <span> - </span> ;

CurrencyField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default CurrencyField;
