import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import { SupplierOrderDeliveryType } from './SupplierOrderTypes'

const SupplierBillDeliveryTypeField = ({ source, record = {} }) => {
    let status = {}
    SupplierOrderDeliveryType.forEach( item => {
        if (item.id === record[source]) {
            status = item;
        }
    } )
    return (<span><Chip label={status.name} style={{ backgroundColor: status.color }} /></span>)
}

SupplierBillDeliveryTypeField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default SupplierBillDeliveryTypeField;
