import React  from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    NumberField,
    ReferenceField,
    CardActions,
    EditButton, 
    BooleanField,
    Filter,
    SearchInput,
    DateInput,
    SelectInput,
    ReferenceInput,
    AutocompleteInput
} from 'react-admin';

const NoneActions = props => (
    <CardActions />
);


const getLocation = () => {
    const initialValue = 1
    try {
        // Get from local storage by key
        const item = window.localStorage.getItem('locationId');
        // Parse stored json or if none return initialValue
        return item ? JSON.parse(item) : initialValue;
      } catch (error) {
        // If error also return initialValue
        console.log(error);
        return initialValue;
      }
}

const SupplierFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <ReferenceInput alwaysOn source="supplierId" reference="suppliers/supplier">
            <AutocompleteInput
                optionText={choice =>
                    `${choice.name}`
                }
            />
        </ReferenceInput>
        <DateInput source="receiptDate" alwaysOn />
        {/* <SelectInput allowEmpty={false} label="Location" source="locationId" alwaysOn choices={[
        { id: '1',  name: 'SHOP' },
        // { id: '2',  name: 'BOOKFAIR' },
    ]
        } /> */}

        <SelectInput source="status" alwaysOn choices={[
        { id: 'COMPLETE',        name: 'Complete' },
        { id: 'SUSPENDED',      name: 'Suspended' },    ]
        } />
        <SelectInput source="settlementType" alwaysOn choices={[
        { id: 'CASH',        name: 'Cash' },
        { id: 'CHECK',        name: 'Check' },
        { id: 'BANK_TRANSFER', name: 'Bank Transfer' },
        { id: 'CREDIT',      name: 'Credit' },
        { id: 'CREDIT_CARD', name: 'Credit Card' },
        { id: 'POST_DATED_CHECK', name: 'Post Dated Check' }
        ]
        } />
    </Filter>
);

function getStatus(param) {
    switch(param) {
      case 1:
        return 'Complete';
      case 2:
        return 'Suspended';
      default:
        return 'Unkonwn';
    }
  }


const StatusField = ({ record = {} }) => <span>{getStatus(record.status)}</span>;
StatusField.defaultProps = { label: 'Status' };

const SupplierBillList = props => (
    // bulkActionButtons={<NoneActions />} 
    <List exporter={false}  filters={<SupplierFilter />}   {...props}  filterDefaultValues={{ locationId: getLocation() }} perPage={25}>
        <Datagrid rowClick="show" >
                <TextField source="id" />
                <DateField source="createDate" showTime />
                <DateField source="receiptDate"/>
                <ReferenceField label="Supplier" source="supplierId" reference="suppliers/supplier">
                    <TextField source="name" />
                </ReferenceField>
                <TextField source="invoiceNumber" />
                <TextField source="receivingType" />
                <StatusField />
                <TextField source="settlementType" />
                <TextField source="createdByName" label="Received By"/>
                <NumberField source="totalQty" />
                <NumberField source="invoiceAmount" />
            <EditButton />
        </Datagrid>
    </List>
);

export default SupplierBillList;
