const locations = [
    {
      value: '1',
      label: 'Shop',
    },
    {
      value: '2',
      label: 'Office',
    },
  ];


export default locations