import React  from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    NumberField,
    Filter,
    SelectInput,
} from 'react-admin';
import ShippingManifestStatusField from './ShippingManifestStatusField'
import ShippingCarrierField from '../../common/ShippingCarrierField'

import {  ShippingCarrierTypes, ManifestStatusTypes} from '../../common/ShippingDetailTypes'

const ManifestFilter = (props) => (
    <Filter {...props}>
        <SelectInput source="shippingCarrier" alwaysOn choices={ShippingCarrierTypes} />
        <SelectInput source="status" alwaysOn choices={ManifestStatusTypes} />
    </Filter>
);

const GoogleSpreadsheetField = ({ record = {} }) => 
    record.spreadSheetId ? 
    <span> 
        <a rel="noopener noreferrer" target="_blank" href={"https://docs.google.com/spreadsheets/d/" +  record.spreadSheetId + "/edit#gid=0"}>Download Manifest</a>
    </span> : <span>-</span>

GoogleSpreadsheetField.defaultProps = { label: 'Download Manifest' };


const ManifestList = props => (
    <List exporter={false}  
            filters={<ManifestFilter />}   {...props}  
            //filterDefaultValues={{ locationId: getLocation() }} 
            perPage={25}>
        <Datagrid rowClick="edit" >
                <TextField source="id" />
                <DateField source="createDate" label="Created Date"/>
                <ShippingCarrierField source="shippingCarrier" />
                <NumberField source="totalPackages" />
                <ShippingManifestStatusField source="manifestStatus"/>
                <GoogleSpreadsheetField />
                <DateField source="handedOverAt" label="Handover Date"/>
                <DateField source="markedShippedAt" label="Shipped Date"/>
        </Datagrid>
    </List>
);

export default ManifestList;
