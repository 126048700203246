import React from 'react';
import {
    Create,
    TextInput,
    SimpleForm,
    required,
    Toolbar,
    translate
} from 'react-admin';
import SaveWithRefreshButton from '../../common/SaveWithRefreshButton'
import Button from '@material-ui/core/Button';


const ManufacturerCreateToolbar = translate(({ onCancel, translate, ...props }) => (
    <Toolbar {...props}>
        <SaveWithRefreshButton
            label="Create"
            redirect="list"
            submitOnEnter={false}
            variant="flat"
        />
        <Button onClick={onCancel}>{translate('ra.action.cancel')}</Button>
    </Toolbar>
));

const ManufacturerCreate = ({ onCancel, ...props }) => (
    <Create title=" " {...props}>
        <SimpleForm redirect="list" toolbar={<ManufacturerCreateToolbar onCancel={onCancel} />}>
            <TextInput source="name" validate={required()} />
            <TextInput source="englishName" validate={required()} />
        </SimpleForm>
    </Create>
);

export default ManufacturerCreate;
