import React, { Component } from 'react';
import { Fragment } from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ItemAutoSuggest from "./ItemAutoSuggest"
import Typography from '@material-ui/core/Typography';
import * as stockTakingActions from "./stocktaking.actions";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import DeleteButton from '../../common/DeleteButton'
import compose from 'recompose/compose';
import { Mutation, EditButton } from 'react-admin';
import currency from "currency.js";
import ItemQuickCreateButton from './ItemQuickCreateButton'
import ItemQuickEditButton from './ItemQuickEditButton'
import useCartState from '../../common/hooks/useCartState'
import { push } from 'react-router-redux';
import { Route, Switch } from 'react-router';
import { Drawer } from '@material-ui/core';
import ItemCreate from '../../catalog/items/ItemCreate';
import ItemEdit from '../../catalog/items/ItemEdit';

const sideEffects = {
  onSuccess: {
      notification: {
          body: 'Receiving Submitted',
          level: 'info',
      },
      redirectTo: '/inventory/stocktaking',
  },
  onFailure: {
      notification: {
          body: 'Receiving failed',
          level: 'warning',
      },
  },
};


const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 2,
    overflowX: 'auto',
  },
  textField: {
    marginBottom: 20,
    width: 200,
  },
  table: {
    minWidth: 700,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});


function sortProperties(obj, sortedBy, isNumericSort, reverse) {
  sortedBy = sortedBy || 1; // by default first key
  isNumericSort = isNumericSort || false; // by default text sort
  reverse = reverse || false; // by default no reverse

  var reversed = (reverse) ? -1 : 1;

  var sortable = [];
  for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
          sortable.push([key, obj[key]]);
      }
  }
  if (isNumericSort)
      sortable.sort(function (a, b) {
          return reversed * (a[1][sortedBy] - b[1][sortedBy]);
      });
  else
      sortable.sort(function (a, b) {
          var x = a[1][sortedBy].toLowerCase(),
              y = b[1][sortedBy].toLowerCase();
          return x < y ? reversed * -1 : x > y ? reversed : 0;
      });
  return sortable; // array in format [ [ key1, val1 ], [ key2, val2 ], ... ]
}

const summaryReducer = (_summary, cur) => {
  var _item = cur[1];
  _summary.totalItems++;
  _summary.totalQty = _summary.totalQty + _item.qty;
  _summary.totalAmount = currency(_summary.totalAmount).add(currency(_item.price).multiply(_item.qty)).value;
  return _summary;
}

const getStockTakingArraySelector = createSelector(
  state => state.stockTaking.items,
  stockTaking => {
    const arr = sortProperties(stockTaking, 'rowid', true, true);
    const _arr = arr.map(x => x[1])
    var summary = {
      totalItems: 0,
      totalQty: 0,
      totalAmount: "0.0"
    }
    const _summary = arr.reduce(summaryReducer, summary);
    var _obj = {
      summary: _summary,
      items: _arr
    }
    return _obj
  }
);

class StockTakingCreate extends Component {
  
  state = {
    shelfCode : ''
  }
  
  onDeleteCartItemClick = row => {
    this.props.removeItemStockTaking(row)    
  };

  onItemSuggestionClick = item => {
    this.props.addItemStockTaking(item)    
  };

  handlePriceChange = item => (e) => {
   //var obj = { id: item.id, isbn: item.isbn, rowid: item.rowid, name: item.name, price: e.target.value, qty: item.qty }
   var obj = { ...item , price: e.target.value }
   this.props.updateItemStockTaking(obj)
  }

  handleQtyChange = item => (e) => {
    //var obj = { id: item.id, isbn: item.isbn, rowid: item.rowid, name: item.name, price: item.price , qty: parseInt(e.target.value) }
    var obj = { ...item , qty: parseInt(e.target.value) }
    this.props.updateItemStockTaking(obj)
   }

  onKeyDown = (e) => {
    if (e.keyCode === 8 || e.keyCode === 46 ) {
      e.target.value = ""
      e.preventDefault();
    }
  }

  componentWillUnmount() {
     this.props.removeAllItemsStockTaking()
  
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

   
  render() {
    const { classes, receivingObj, ...props } = this.props;
    return (
      <Fragment>
        <Grid container direction="row" justify="flex-end" alignItems="flex-start" >
          <ItemQuickCreateButton onCreate={this.props.addItemStockTaking}/>
          {/* <Button variant="contained" color="secondary" className={classes.button}>
              Quick Add Item
          </Button> */}
        </Grid>

        <TextField
          id="shelfCode"
          label="Shelf Code"
          className={classes.textField}
          value={this.state.shelfCode}
          onChange={this.handleChange('shelfCode')}
          margin="normal"
        />

      <ItemAutoSuggest onItemSuggestionClick={this.onItemSuggestionClick} />

      <Grid container spacing={24}>
        <Grid item xs={4}>
          <Paper className={classes.paper}>
          <Typography variant="subheading">
            Total Titles:  {receivingObj.summary.totalItems}
          </Typography>
          </Paper>
        </Grid>
        <Grid item xs={4}>
        <Paper className={classes.paper}>
          <Typography variant="subheading">
            Total Quantity: {receivingObj.summary.totalQty}
          </Typography>
          </Paper>
        </Grid>
        <Grid item xs={4}>
        <Paper className={classes.paper}>
          <Typography variant="subheading">
            Total Amount: {receivingObj.summary.totalAmount}
          </Typography>
          </Paper>
        </Grid>
      </Grid>
      
      <Paper className={classes.root}>
      
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell align="right">ISBN</TableCell>
              <TableCell align="right">Name</TableCell>
              <TableCell align="right">Publisher</TableCell>
              <TableCell align="right">Price</TableCell>
              <TableCell align="right">Qty</TableCell>
              <TableCell align="right">Total</TableCell>
              <TableCell align="right">Edit</TableCell>
              <TableCell align="right">Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {receivingObj.items.map(row => (
              <TableRow key={row.id}>
                <TableCell>{row.id}</TableCell>
                <TableCell align="right">{row.isbn}</TableCell>
                <TableCell align="right">{row.name}</TableCell>
                <TableCell align="right">{row.manufacturerName}</TableCell>
                {/* <TableCell align="right"> */}
                {/* <input defaultValue={row.price} onChange={this.handleChange}/> */}
                <TableCell style={{ width: 20 }} align="right">{row.price}</TableCell>
                {/* <TextField value={row.price} onChange={this.handlePriceChange(row)}/> */}
                {/* </TableCell> */}
                <TableCell align="right">
                    <TextField style={{ width: 30 }}  value={row.qty} onKeyDown={this.onKeyDown} onChange={this.handleQtyChange(row)}/>
                </TableCell>
                <TableCell style={{ width: 10 }} align="right">{currency(row.price).multiply(row.qty).value}</TableCell>
                <TableCell style={{ width: 10 }}  align="right"><ItemQuickEditButton onUpdate={this.props.updateItemStockTaking} data={row}/></TableCell>
                <TableCell style={{ width: 10 }}  align="right"><DeleteButton onDelete={() => this.onDeleteCartItemClick(row)} /></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <Paper className={classes.paper}>
            
       <SubmitButton payload = { generateReceivingPayload(this.props.receivingObj, this.state.shelfCode)} classes={ classes } />
         
      </Paper>
      </Fragment>
    );
  }

}


const SubmitButton = ( { payload,  classes }) => (
    <Mutation
    type="CREATE"
    resource="inventory/stocktaking"
    payload={ payload }
    options={ sideEffects }>
    {(approve) => (
        <Button  variant="contained" color="secondary" className={classes.button} onClick={approve}>
        SUBMIT
    </Button>
    )}
  </Mutation>
)

function generateReceivingPayload(receivingObj, shelfCode) {

  var receiving = {
		"totalAmount" : receivingObj.summary.totalAmount,
		"totalQty" : receivingObj.summary.totalQty,
    "totalUnitsQty" : receivingObj.summary.totalItems,
    "stockTakingItems" : [],
    "shelfCode" : shelfCode
  };


  //var receivingItems = [];

  var line = 1;

  receivingObj.items.forEach(function (item) {
    var receivingItem = {
      "line" : line,
      "itemId" : item.id,
      "name" : item.name,
      "qty" : item.qty,
      "price" : item.price
    }
    receiving.stockTakingItems.push(receivingItem);
    line++;
  });

  // var obj =  { 
  //   "receiving" : receiving,
  //   "receivingItems" : receivingItems
  // }

  // console.log(obj);

  return { "data" : receiving };

}

function mapStateToProps(state) {
  return {
    receivingObj: getStockTakingArraySelector(state),
    stockTakingArray: getStockTakingArraySelector(state),
    stockTakingObj: state.stockTaking
  };
}

const mapDispatchToProps = {
  addItemStockTaking: stockTakingActions.addItemStockTaking,
  removeAllItemsStockTaking: stockTakingActions.removeAllItemsStockTaking,
  removeItemStockTaking: stockTakingActions.removeItemStockTaking,
  updateItemStockTaking: stockTakingActions.updateItemStockTaking,
};


StockTakingCreate.propTypes = {
  classes: PropTypes.object.isRequired,
};


const enhance = compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(StockTakingCreate);