// hooks to maintain state
// submit using dataProvider
//

import React, { useState, Fragment, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import {
    showNotification,
    Button,
    withDataProvider,
    CREATE,
    UPDATE,
    GET_ONE,
} from 'react-admin';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';

import TextField from '@material-ui/core/TextField';
import MultiAutoSuggest from './MultiAutoSuggest'
import useLocalStorage from './hooks/useLocalStorage'
import { push } from 'react-router-redux';
import locations from './location'

const DeliveryTypes = [
    { value: 'STORE_PICKUP', label: 'Store Pickup' },
    { value: 'DOOR_DELIVERY', label: 'In Person Delivery' },
    { value: 'SHIPPING', label: 'Shipping' },
    { value: 'POS', label: 'POS' },
  ];

const RemoveOrderItem = (props) => {

    const { selectedItems, orderId } = props

    const [showDialog, setShowDialog] = useState(false)
    const [submitClicked, setSubmitClicked] = useState(false)

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const resetState = () => {

    }

    const handleDelete = () => {
        if (!submitClicked) {
            setSubmitClicked(true)
            const {
                dispatch,
                dataProvider,
            } = props;

            // const payload = {
            //     id: 1, 
            //     items: selectedItems
            // }
    
            dataProvider(CREATE, 'order/'+ orderId +'/remove-items', { data: selectedItems })
            .then((json) => {
                dispatch(showNotification('Removed selected Items')) 
                setSubmitClicked(false)
                setShowDialog(false)
                window.location.reload(true);
            })
            .catch((e) => {
                setSubmitClicked(false)
                dispatch(showNotification('Error: Something went wrong', 'warning'))
            });
            };
    }

    return (
        <Fragment>
                <Button disabled={selectedItems.length === 0 } size="small" variant="contained" onClick={() => setShowDialog(true)} label="Remove Items"></Button>

                <Dialog fullWidth={true} maxWidth="md" open={showDialog} onClose={() => { setShowDialog(false); resetState() }} aria-label="Quick Create Item">
                    { isLoading ? <h2>Loading..</h2> : 
                    <Fragment>
                    <DialogTitle style={{ marginTop: -15}}>Remove Items for Order# {orderId} </DialogTitle>
                    <DialogContent style={{marginTop: -15}}>

                        <Table padding="dense">
                            <TableHead>
                                <TableRow>
                                    <TableCell >Item Id</TableCell>
                                    <TableCell align="right">Name</TableCell>
                                    <TableCell align="right">Publisher</TableCell>
                                    <TableCell align="right">Price</TableCell>
                                    <TableCell align="right">Qty</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {selectedItems.map(item => (
                                    <TableRow key={"i_" + item.itemId}>
                                        <TableCell  align="right">{item.itemId}</TableCell>
                                        <TableCell  align="right">{item.name}</TableCell>
                                        <TableCell  align="right">{item.manufacturerName}</TableCell>
                                        <TableCell  align="right">₹ {item.price}</TableCell>
                                        <TableCell  align="right">{item.qty}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </DialogContent>
                    <DialogActions>

                    <Button label="Delete" onClick={handleDelete} />

                    <Button label="Cancel" onClick={() => { setShowDialog(false); resetState() }}></Button>
                    </DialogActions>
                    </Fragment>
                    }
                </Dialog>
            </Fragment>
    )

}

export default withDataProvider(RemoveOrderItem)