// hooks to maintain state
// submit using dataProvider
//

import React, { useState, Fragment, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import {
    showNotification,
    withDataProvider,
    CREATE,
    UPDATE,
    GET_ONE,
} from 'react-admin';

import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField';

const PaymentTypes = [
    { value: 'CASH', label: 'Cash' },
    { value: 'PAYTM', label: 'Paytm Machine' },
    { value: 'BANK_TRANSFER', label: 'UPI/Bank Transfer' },
    { value: 'MULTIPLE', label: 'GPay Scan' },
    { value: 'CHECK', label: 'Check' },
    { value: 'PAYUBIZ', label: 'Razorpay/PayuBiz' },
    { value: 'GIVE_AWAY', label: 'Gift/Free' }
  ];

  

const AddPaymentRefundButton = (props) => {

    const { type, disabled, onSubmit, amount, orderId } = props
    const [showDialog, setShowDialog] = useState(false)
    const [submitClicked, setSubmitClicked] = useState(false)
    const [paymentType, setPaymentType] = useState('CASH')
    const [paymentMessage, setPaymentMessage] = useState('')
    const [paymentAmount, setPaymentAmount] = useState('')

    const resetState = () => {
        setPaymentType('CASH')
        setPaymentAmount('')
        setPaymentMessage('')
    }

    const handleSubmit = () => {

            const orderPayment = {
                // TODO: paidDate as input
                orderId: orderId,
                paidDate: new Date(),
                type: type,
                paymentType : paymentType, 
                amount : paymentAmount,
                message: paymentMessage
            }

            onSubmit(orderPayment)
    }

    useEffect(() => {
        setPaymentAmount(amount)
    }, [showDialog]);

    const validate = () => {
        return !(paymentAmount <= 0 || paymentAmount === '')
    }

    return (
        <Fragment>
            <Button disabled={disabled} style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }} variant="contained" color="primary" onClick={(event) => { event.preventDefault(); setShowDialog(true) }}>
    {type === 'SALE' ? 'ADD PAYMENT' : 'REFUND' }</Button>
            <Dialog maxWidth="sm" open={showDialog} onClose={() => { setShowDialog(false); resetState() }} aria-label="Payment and Delivery Details">
                    <Fragment>
                            <DialogTitle>{type === 'SALE' ? 'Add Payment' : 'Refund'} for Order #{orderId}</DialogTitle>
                            <DialogContent>
                            <div style={{ display: 'inline-flex' }}>
                                <div style={{  width: 150 }}>
                                    <TextField
                                    id="paymentType"
                                    fullWidth
                                    select
                                    label="Payment Type"                         
                                    onChange={(e) => setPaymentType(e.target.value)}
                                    value={paymentType}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    margin="normal"
                                    >
                                    {PaymentTypes.map(option => (
                                        <option key={option.value} value={option.value}>
                                        {option.label}
                                        </option>
                                    ))}
                                    </TextField>
                                </div>
                                <div style={{ marginLeft: 10,  width: 100 }}>
                                    <TextField
                                        fullWidth
                                        id="paymentAmount"
                                        label="Amount"
                                        value={paymentAmount}
                                        onChange={(e) => setPaymentAmount(e.target.value)}
                                        margin="normal"
                                        //onKeyDown={onKeyDown}
                                    />
                                </div>
                                 <div style={{ marginLeft: 20,  width: 200 }}>
                                    <TextField
                                        fullWidth
                                        id="message"
                                        label="Message"
                                        value={paymentMessage}
                                        onChange={(e) => setPaymentMessage(e.target.value)}
                                        margin="normal"
                                    />
                                </div>
                            </div>
                            </DialogContent>
                            <DialogActions>
                                <Button variant="contained" color="primary" disabled={ !validate()} onClick={handleSubmit}> {type === 'SALE' ? 'ADD PAYMENT' : 'REFUND' } </Button>
                                <Button variant="contained" onClick={() => { setShowDialog(false); resetState() }}> CANCEL </Button>
                            </DialogActions>
                    </Fragment>
            </Dialog>
        </Fragment>
    )

}

export default AddPaymentRefundButton