import {
    ADD_ITEM_STOCKTAKING,
    UPDATE_ITEM_STOCKTAKING,
    REMOVE_ITEM_STOCKTAKING,
    REMOVE_ALLITEMS_STOCKTAKING
  } from "./stocktaking.types";
  
  export const addItemStockTaking = data => ({
    type: ADD_ITEM_STOCKTAKING,
    data
  });
  
  export const updateItemStockTaking = data => ({
    type: UPDATE_ITEM_STOCKTAKING,
    data
  });
  
  export const removeItemStockTaking = data => ({
    type: REMOVE_ITEM_STOCKTAKING,
    data
  });
  
  export const removeAllItemsStockTaking = data => ({
    type: REMOVE_ALLITEMS_STOCKTAKING
  });
  