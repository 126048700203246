import React from 'react';
import {
    Edit,
    TextInput,
    SimpleForm,
    required,
    SaveButton,
    Toolbar,
    translate,
    ReferenceInput,
    AutocompleteInput,
    NumberInput
} from 'react-admin';
import Button from '@material-ui/core/Button';
import DrawerAutocompleteInput from '../../common/DrawerAutocompleteInput'


const ItemEditToolbar = translate(({ onCancel, translate, ...props }) => (
    <Toolbar {...props}>
        <SaveButton />
        <Button onClick={onCancel}>{translate('ra.action.cancel')}</Button>
    </Toolbar>
));

const ItemEdit = ({ onCancel, ...props }) => (
    <Edit title=" " {...props}>
        <SimpleForm toolbar={<ItemEditToolbar onCancel={onCancel} />}>
            <TextInput autoFocus source="name" style={{ width: 400 }} validate={required()} />
            <TextInput source="englishName" style={{ width: 400 }} validate={required()} />
            <ReferenceInput required label="Publisher" source="manufacturerId" reference="v2/catalog/manufacturer">
                <DrawerAutocompleteInput optionText={choice => `${choice.name}` } />
            </ReferenceInput>
{/*             
            <ReferenceInput required label="Publisher" source="manufacturerId" reference="v2/catalog/manufacturer">
                <AutocompleteInput options={{ suggestionsContainerProps: { disablePortal: true, modifiers: { keepTogether: { enabled: true } } } }} optionText={choice => `${choice.name}` } />
            </ReferenceInput> */}
            <TextInput source="isbn" label="ISBN"/>
            <NumberInput source="price" style={{ width: 150 }} validate={required()} label="Price"/>
        </SimpleForm>
    </Edit>
);

export default ItemEdit;
