import React, { useState, useEffect } from 'react';
import { Fragment } from 'react'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { showNotification, withDataProvider, GET_ONE, CREATE } from 'react-admin';
import AutoSuggest from '../../common/AutoSuggest'
import CartArea from '../../common/CartArea'
import { push } from 'react-router-redux';
import useLocalStorage from '../../common/hooks/useLocalStorage'
import CreateSupplierOrderButton from './CreateSupplierOrderButton'
import CreateSupplierBillButton from './CreateSupplierBillButton'
import ReceiveSupplierOrderButton from './NoItemsAvailableButton'
import { SupplierInformedType, SupplierOrderClosedReason, SupplierOrderDeliveryType, SupplierOrderPaymentType, SupplierOrderSettlementType, SupplierOrderStatus } from './SupplierOrderTypes'

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.name, query);
  const parts = parse(suggestion.name, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) =>
          part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
          ) : (
              <strong key={String(index)} style={{ fontWeight: 300 }}>
                {part.text}
              </strong>
            ),
        )}
      </div>
    </MenuItem>
  );
}

function getSuggestionValue(suggestion) {
  //console.log(suggestion)
  return suggestion.name;
}

const PurchaseOrderCreate = (props) => {

    const { id } = props
    const orderId = props.match.params.orderId;
    const [supplier, setSupplier] = useState(null);
    const [cartState, setCartState] = useState({})
    const [orderDate, setOrderDate] = useState(new Date().toISOString().substring(0, 10));
    const [deliveryType, setDeliveryType] = useState('NOT_KNOWN')
    const [settlementType, setSettlementType] = useState('NOT_KNOWN')

    const [submitClicked, setSubmitClicked] = useState(false)
    // receiptDate invoiceNumber invoiceAmount settlementType comment
    const [comment, setComment] = useState('')
    const [status, setStatus] = useState('DRAFT')
    const [cartItems, setCartItems] = useState([])
    const [locationId, setLocationId] = useLocalStorage('locationId', '1');

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const [receiveItems, setReceiveItems] = useState([])


    const  validate = () => {
      return (supplier != null && 
              (comment.length > 5 || cartState.totalItems > 0)
              ) 
    }

    const  _validate = () => {
        return (supplier != null && 
                cartState.totalItems > 0
                ) 
      }

    useEffect(() => {
      //receive items
      const fetchData = async () => {
        setIsError(false);
        setIsLoading(true);

        var params = { id: orderId}

        const {
          dispatch,
          dataProvider
          } = props;

        dataProvider(GET_ONE, 'suppliers/order', params)
          .then((json) => {
              setReceivingData(json.data);
              setIsLoading(false);
          })
          .catch(() => {
              setIsError(true);
              dispatch(showNotification('Error: Something went wrong', 'warning'))
              setIsLoading(false);
          });
      };

      if (orderId) {
        fetchData()
      }
      
    }, [orderId]);

    useEffect(() => {
      const fetchData = async () => {
        setIsError(false);
        setIsLoading(true);

        var params = { id: id}

        const {
          dispatch,
          dataProvider
          } = props;

        dataProvider(GET_ONE, 'suppliers/order', params)
          .then((json) => {
              setData(json.data);
              setIsLoading(false);
          })
          .catch(() => {
              setIsError(true);
              dispatch(showNotification('Error: Something went wrong', 'warning'))
              setIsLoading(false);
          });
      };

      if (id) {
        fetchData()
      }
      
    }, []);

    const setReceivingData = (supplierOrder) => {
      setSupplier(supplierOrder.supplier)
     // setOrderDate(new Date().toISOString().substring(0, 10))
      setComment(supplierOrder.comment)
      setStatus('RECEIVED')
      setDeliveryType(supplierOrder.deliveryType)
      setSettlementType(supplierOrder.settlementType)

      const _cartItems = []

      supplierOrder.lineItems.forEach((lineItem) => {
        const  _item = { }
        _item.itemId = lineItem.itemId
        _item.name = lineItem.name
        _item.manufacturerName = lineItem.manufacturerName
        _item.qty = lineItem.qty
        _item.price = lineItem.price
        if (lineItem.discountType === 'PERCENT') {
          _item.discountPercent = lineItem.discountValue
        } else if (lineItem.discountType === 'AMOUNT') {
          _item.discountAmount = lineItem.discountAmount
        }
        _item.total = lineItem.total 
        _item.shelfCodes = lineItem.stockDetail.shelfCodes
        _item.stockQty = lineItem.stockDetail.stockQty
        _item.avlQty = lineItem.stockDetail.avlQty
        _cartItems.push(_item)
      });
      setReceiveItems(_cartItems)

    }


    const setData = (supplierOrder) => {
      setSupplier(supplierOrder.supplier)
      setOrderDate(new Date(supplierOrder.orderDate).toISOString().substring(0, 10))
      setComment(supplierOrder.comment)
      setStatus(supplierOrder.status)
      setDeliveryType(supplierOrder.deliveryType)
      setSettlementType(supplierOrder.settlementType)

      const _cartItems = []

      supplierOrder.lineItems.forEach((lineItem) => {
        const  _item = { }
        _item.itemId = lineItem.itemId
        _item.name = lineItem.name
        _item.manufacturerName = lineItem.manufacturerName
        _item.qty = lineItem.qty
        _item.price = lineItem.price
        if (lineItem.discountType === 'PERCENT') {
          _item.discountPercent = lineItem.discountValue
        } else if (lineItem.discountType === 'AMOUNT') {
          _item.discountAmount = lineItem.discountAmount
        }
        _item.total = lineItem.total 
        _item.shelfCodes = lineItem.stockDetail.shelfCodes
        _item.stockQty = lineItem.stockDetail.stockQty
        _item.avlQty = lineItem.stockDetail.avlQty
        _cartItems.push(_item)
      });
      setCartItems(_cartItems)

    }

    const handleSupplierBillCreation = (billDetail, setDialogSubmitClicked) => {
      var payload = generateBillPayload(billDetail);
      const {
        dispatch,
        dataProvider
        } = props;

        dataProvider(CREATE, 'suppliers/bill', payload)
        .then((json) => {
            dispatch(showNotification('Supplier Bill created with id:' + json.data.id))
            setDialogSubmitClicked(false)
            dispatch(push('/suppliers/order'));
        })
        .catch(() => {
            setDialogSubmitClicked(false)
            dispatch(showNotification('Error: Something went wrong', 'warning'))
        });
      
    }

    const handlePurchaseOrderCreation = (_settlementType, _deliveryType, setDialogSubmitClicked) => {
          var payload = generateOrderPayload('CREATED', _settlementType, _deliveryType)
          supplierOrderCreateUpdate(payload, setDialogSubmitClicked)
    }

    const handleSubmit = (action) => {
      if (!submitClicked) {
        setSubmitClicked(true)
        var payload = generateOrderPayload(action, settlementType, deliveryType)
        supplierOrderCreateUpdate(payload, setSubmitClicked)
      }
    }

    const supplierOrderCreateUpdate = (payload, setSubmitClicked) => {

        const {
            dispatch,
            dataProvider
        } = props;

        dataProvider(CREATE, 'suppliers/order', payload)
        .then((json) => {
            dispatch(showNotification('Purchase Draft/Order created/updated with id:' + json.data.id)) 
            setSubmitClicked(false)
            dispatch(push('/suppliers/order'));
        })
        .catch(() => {
            setSubmitClicked(false)
            dispatch(showNotification('Error: Something went wrong', 'warning'))
        });
    };


    function generateBillPayload(billDetail) {

      var payload = {        
        "billDate": new Date(billDetail.billDate),
        "status": orderId ? 'RECEIVED' : 'DIRECT',
        "supplierId": supplier.id,
        "supplierName" : supplier.name,
        "discountPercent" : supplier.discountPercent,
        "total": cartState.totalAmount,
        "billNumber": billDetail.billNumber,
        "billAmount": billDetail.billAmount,
        "locationId" : locationId,
        "settlementType" : billDetail.settlementType,
        "deliveryType" : billDetail.deliveryType,
        "comment": comment,
        "lineItems": [],
        "billPayments" : billDetail.billPayments,
        "missingItems" : billDetail.missingItems,
        "orderId" : orderId
      }

      var line = 1;
    
      cartState.cartItems.forEach(function (item) {
        var lineItem = {
          "line" : line,
          "itemId" : item.itemId,
          "name" : item.name,
          "manufacturerName" : item.manufacturerName,
          "qty" : item.qty,
          "price" : item.price,
          "discountType": "PERCENT",
          "discountValue": item.discountPercent,
          "total": item.total
        }
        payload.lineItems.push(lineItem);
        line++;
      });

      return { "data" : payload };
    }


    function generateOrderPayload(action, _settlementType, _deliveryType) {

      var payload = {
        "id" : id,
        "supplierId": supplier.id,
        "orderDate": new Date(orderDate),
        "total": cartState.totalAmount,
        "settlementType" : _settlementType,
        "deliveryType" : _deliveryType,
        "comment": comment,
        "locationId": locationId,
        "lineItems": [],
        "status": action
      }

      var line = 1;
    
      cartState.cartItems.forEach(function (item) {
        var lineItem = {
          "line" : line,
          "itemId" : item.itemId,
          "name" : item.name,
          "manufacturerName" : item.manufacturerName,
          "qty" : item.qty,
          "price" : item.price,
          "discountType": "PERCENT",
          "discountValue": item.discountPercent,
          "total": item.total
        }
        payload.lineItems.push(lineItem);
        line++;
      });

      return { "data" : payload };
    }

      const onSupplierSelection = supplier => {
        console.log(supplier)
        setSupplier(supplier)
      };


      const onCartStateChange = cartState => {
        setCartState(cartState)
      }


    return (
            <div>
                <h2>{status === 'RECEIVED' ? 'Receiving for ' : null } Supplier Order/Bill { status === 'DRAFT' ? 'Draft' : null } {id ? '#' + id : null} {orderId ? '#' + orderId : null} ({supplier? supplier.name : null}) </h2>           
                <Grid container spacing={16}>
                    <Grid item xs={8}>
                      <Paper style={{ paddingTop: 10, paddingBottom: 10, marginBottom: 10 }}>
                        <div style={{ marginTop: 5, display: "flex"}}>
                          <div style={{ marginTop: 10, marginLeft: 10 }}>
                                <TextField
                                    id="orderDate"
                                    label="Order Date"
                                    type="date"
                                    onChange={(e) => setOrderDate(e.target.value)}
                                    value={orderDate}
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                />
                            </div>
                            { status === 'DRAFT' ? 
                            <div style={{marginTop: 25, marginLeft: 20, width: "50%"}}>
                              <AutoSuggest placeholder="Type Supplier Name.." fetchUrl="suppliers/supplier" onItemSuggestionClick={onSupplierSelection} renderSuggestion={renderSuggestion} getSuggestionValue={getSuggestionValue} parameterName='name'  />
                           </div> : 
                           <Fragment>
                           <div style={{  marginTop: -5, marginLeft : 20, width: 200 }}>
                                    <TextField
                                    id="settlementType"
                                    fullWidth
                                    select
                                    label="Settlement Type"                         
                                    onChange={(e) => setSettlementType(e.target.value)}
                                    value={settlementType}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    margin="normal"
                                    >
                                    {SupplierOrderSettlementType.map(option => (
                                        <option key={option.id} value={option.id}>
                                        {option.name}
                                        </option>
                                    ))}
                                    </TextField>
                                </div>
                                <div style={{  marginTop: -5, marginLeft: 20,  width: 200 }}>
                                    <TextField
                                    id="deliveryType"
                                    fullWidth
                                    select
                                    label="Delivery Type"                         
                                    onChange={(e) => setDeliveryType(e.target.value)}
                                    value={deliveryType}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    margin="normal"
                                    >
                                    {SupplierOrderDeliveryType.map(option => (
                                        <option key={option.id} value={option.id}>
                                        {option.name}
                                        </option>
                                    ))}
                                    </TextField>
                                </div>
                              </Fragment> }
                        </div>
                        {/* <div style={{ display: 'inline-flex' }}>
                            
                        </div>                       */}
                        <div style={{marginLeft: 10, marginRight: 10}}>
                        <TextField
                            id="comment"
                            label="Notes"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            margin="normal"
                            style={{ width: "100%" }}
                            multiline={true}
                            rows={5}
                            rowsMax={100}
                            />
                        </div>
                    
                      </Paper>
                      <Paper>
                      { supplier ? 
                            <CartArea 
                                hideISBN={true}
                                hideSelect={true}
                                disabled={ false }
                                cartItems={cartItems} 
                                onCartStateChange={onCartStateChange} 
                                discountPercent={supplier ? supplier.discountPercent : 0} 
                            />
                        : <Fragment> <div style={{padding: 20,  }}> <h2> Please select a supplier</h2></div> </Fragment>
                      }
                      </Paper>
                    </Grid>

                    <Grid item xs={4}>
                        <Paper>
                            <div style={{ paddingTop: 1, paddingBottom: 5, marginBottom: 10}}>
                            <div style={{marginTop: 15, marginLeft: 10}}>
                                <div><b>Supplier Details</b></div>
                                { supplier ? 
                                    <Fragment>
                                        <div style={{marginTop: 7}}><b>{ supplier.name }</b></div>
                                        <div style={{marginTop: 3}}>Discount: {supplier.discountPercent}%</div>
                                        <div style={{marginTop: 3}}>Email: {supplier.email}</div>
                                        <div style={{marginTop: 3}}>Mobile: {supplier.mobileNumber} ({supplier.contactName}) </div>
                                        <div style={{marginTop: 3}}>Landline: {supplier.landline}</div>
                                    </Fragment>
                                : null
                                }
                            </div>          
                            </div>
                        </Paper>

                        <Paper>
                        <div style={{ paddingTop: 10, paddingBottom: 20,  marginBottom: 10}}>
                              <div style={{marginLeft: 10}}> 
                                  <div style={{paddingTop: 5}}><b>Order Summary</b></div>
                                  <div style={{marginTop: 5}}>Total Items: {cartState.totalItems} &nbsp;&nbsp;|&nbsp;&nbsp;<span>Total Qty: {cartState.totalQty}</span></div>
                                  <div style={{marginTop: 10}}>Sub Total: ₹ {cartState.subTotal} </div>
                                  <div style={{marginTop: 10}}>Discount: ₹ {cartState.totalDiscount} </div>
                                  <div style={{marginTop: 10}}>Total Amount: ₹ {cartState.totalAmount} </div>
                                  
                              </div>
                        </div>
                        </Paper>
                        
                        <Paper>
                          <div style={{marginLeft: 10}}>
                            { status === "DRAFT" ? 
                            <Fragment><Button disabled={submitClicked || !validate() } style={{ marginTop: 10, marginBottom: 10 }} onClick={() => handleSubmit("DRAFT")} variant="contained" color="primary">SAVE</Button></Fragment>
                            : null }
                             {/* { status === "DRAFT" ?
                             <CreateSupplierOrderButton supplier={supplier} cartState={cartState} disabled={submitClicked || !validate() } label="CREATE P.O" onSubmit={handlePurchaseOrderCreation}/> : null }
                             { (status === "CREATED" || status === "DEFAULT_CREATED") ?
                            <Button disabled={submitClicked || !_validate() } style={{ marginTop: 10, marginLeft : 10, marginBottom: 10 }} onClick={() => handleSubmit(status)} variant="contained" color="primary">UPDATE ORDER</Button> : null } */}
                            { (status === "DRAFT" || status === "RECEIVED")  ?
                             <CreateSupplierBillButton _settlementType={settlementType} _deliveryType={deliveryType} receiveItems={receiveItems} supplier={supplier} orderDate={orderDate} cartState={cartState} disabled={submitClicked || !validate() } label="CREATE BILL" onSubmit={handleSupplierBillCreation}/> : null }
                             {/* { status === "RECEIVING" ?
                             <ReceiveSupplierOrderButton  cartState={cartState} disabled={submitClicked || !validate() } label="CREATE BILL" onSubmit={handleResolveItems}/> : null } */}
                            </div>
                        </Paper>
                    </Grid>
                    
                </Grid>
            </div>
    )
}

export default withDataProvider(PurchaseOrderCreate)