import React, { useState, Fragment, useEffect } from 'react';
import Switch from '@material-ui/core/Switch';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import {
    showNotification,
    Button,
    withDataProvider,
    CREATE
} from 'react-admin';
import { action } from '@storybook/addon-actions';

const StockSyncSwitch = (props) => {
  const [isStockSync, setIsStockSync] = useState(props.record.stockSync)
  const [showDialog, setShowDialog] = useState(false)
  const [submitClicked, setSubmitClicked] = useState(false)

  const handleSwitchChange = (event) => {
    console.log(event.target.checked)
    setShowDialog(true)
    setIsStockSync(event.target.checked);
  };

  const handleCancel = () => {
    console.log('canceled');
    setShowDialog(false);
  }



  const handleStockSync = () => {
    console.log(props.record.manufacturerId);
    var action = "enabled"
    if (!isStockSync)
    {
      action = "disabled"
    }
    if (!submitClicked) {
        setSubmitClicked(true)
        const {
            dispatch,
            dataProvider,
        } = props;
        // console.log(fulfillmentIds)
        dataProvider(CREATE, 'v2/catalog/manufacturer/stock-sync/' + props.record.manufacturerId + '/' + action, { data: { } })
        .then(() => {
            dispatch(showNotification('Stock sync ' + action))
            setSubmitClicked(false)
            setShowDialog(false)
        })
        .catch((e) => {
            setSubmitClicked(false)
            dispatch(showNotification('Error: Something went wrong', 'warning'))
        });
        };
}


  return (
    <Fragment>
        <Switch id="defaultDiscount" 
                    checked={isStockSync} 
                    onChange={handleSwitchChange} 
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    /> 
        {/* <Button size="small" variant="contained" onClick={() => setShowDialog(true)} label="ZERO STOCK"></Button> */}
        <Dialog maxWidth="sm" open={showDialog} onClose={() => { setShowDialog(false); }} aria-label="Quick Create Item">
            <Fragment>
            <DialogTitle style={{ marginTop: -15}}>Stock Sync </DialogTitle>
            <DialogContent style={{marginTop: -15}}>
                <p>Are you sure you want to { isStockSync ? "enable" : "disable" } stock sync?</p>
            </DialogContent>
            <DialogActions>
                <Button label="Ok" onClick={handleStockSync} />
                <Button label="Cancel" onClick={handleCancel}></Button>
            </DialogActions>
            </Fragment>
        </Dialog>
    </Fragment>
  );
}

export default withDataProvider(StockSyncSwitch)
