import React from 'react';
import {
    Create,
    TextInput,
    SimpleForm,
    required,
    Toolbar,
    translate,
    ReferenceInput,
    NumberInput
} from 'react-admin';
import Button from '@material-ui/core/Button';
import DrawerAutocompleteInput from '../../common/DrawerAutocompleteInput'
import SaveWithRefreshButton from '../../common/SaveWithRefreshButton'


const ItemCreateToolbar = translate(({ onCancel, translate, ...props }) => (
    <Toolbar {...props}>
        <SaveWithRefreshButton
            label="Create"
            redirect="list"
            submitOnEnter={false}
            variant="flat"
        />
        <Button onClick={onCancel}>{translate('ra.action.cancel')}</Button>
    </Toolbar>
));

const ItemCreate = ({ onCancel, ...props }) => (
    <Create title=" " {...props}>
        <SimpleForm redirect="list" toolbar={<ItemCreateToolbar onCancel={onCancel} />}>
            <TextInput autoFocus source="name" style={{ width: 400 }} validate={required()} />
            <TextInput source="englishName" style={{ width: 400 }} validate={required()} />
            <ReferenceInput required label="Publisher" source="manufacturerId" reference="v2/catalog/manufacturer">
                <DrawerAutocompleteInput optionText={choice => `${choice.name}` } />
            </ReferenceInput>
            <TextInput source="isbn" label="ISBN"/>
            <NumberInput source="price" style={{ width: 150 }} validate={required()} label="Price"/>
        </SimpleForm>
    </Create>
);

export default ItemCreate;
