import ItemIcon from '@material-ui/icons/Collections';
import ToBookFairList from './ToBookFairList';
import ToBookFairCreate from './ToBookFairCreate';
import ToBookFairShow from './ToBookFairShow';


export default {
    list: ToBookFairList,
    create: ToBookFairCreate,
    show: ToBookFairShow,
    icon: ItemIcon,
    name: "inventory/tobookfair"
};
