export const OrderChannelTypes = [
    { id: 'SHOP', name: 'Shop', color: 'IndianRed' }, // name for drop down in order list; name for select field.
    { id: 'SOCIAL_MEDIA', name: 'Social Media', color: 'YellowGreen' },
    { id: 'PHONE', name: 'Phone', color: 'Plum' },
    { id: 'WHATSAPP', name: 'WhatsApp', color: 'LightSalmon' },
    { id: 'REQUEST_PORTAL', name: 'Request Portal', color: 'SeaGreen' },
    { id: 'REFERRAL', name: 'Referral', color: 'RosyBrown' },
    { id: 'PANUVAL_COM', name: 'panuval.com', color: 'Teal' },
    { id: 'BOOK_FAIR', name: 'Book Fair', color: 'LightCoral' },
    { id: 'FLIPKART', name: 'Flipkart', color: 'Gold' },
    { id: 'AMAZON', name: 'Amazon', color: 'Coral' },
    { id: 'INTERNATIONAL', name: 'Overseas', color: 'Bisque' },
    { id: 'OTHERS', name: 'Others', color: 'RoyalBlue' },
  ];


export const OrderFulfillmentStatusTypes = [
  { id: 'UNFUFILLED', name: 'UnFulfilled', color: 'IndianRed'  },
  { id: 'FULFILLED', name: 'Fulfilled', color: 'LimeGreen'  },
  { id: 'PARTIALLY_FUFILLED', name: 'Partially Fulfilled', color: 'Orange'  },
];


export const OrderPaymentStatusTypes =  [
  { id: 'PAID',           name: 'Paid', color: 'YellowGreen'},
  { id: 'UNPAID',         name: 'Unpaid', color: 'IndianRed' },
  { id: 'OVERPAID',       name: 'Overpaid', color: 'DarkGreen' },
  { id: 'PARTIALLY_PAID', name: 'Partially Paid', color: 'Orange' },
  { id: 'REFUNDED',       name: 'Refunded', color: 'DarkSlateGray' },
  { id: 'PARTIALLY_REFUNDED', name: 'Partially Refunded', color: 'SlateGray' },
  { id: 'VOIDED',         name: 'Voided', color: '' },
  { id: 'PAID_LATE_CAPTURE', name: 'Paid (Missed)', color: 'DarkKhaki'},
  ]

  export const OrderPaymentTypes =  [
    { id: 'OTHERS',           name: 'Others', color: 'YellowGreen'},
    { id: 'CASH',             name: 'Cash', color: 'IndianRed' },
    { id: 'CARD',             name: 'Card', color: 'DarkGreen' },
    { id: 'CHECK',            name: 'Check', color: 'Orange' },
    { id: 'BANK_TRANSFER',    name: 'UPI/BankTransfer', color: 'Lavender' },
    { id: 'PAYTM',            name: 'Paytm Machine', color: 'Coral' },
    { id: 'MULTIPLE',         name: 'Gpay Scan', color: 'RosyBrown' },
    { id: 'PAYUBIZ',          name: 'PayuBiz', color: 'SteelBlue' },
    { id: 'UPI_TRANSFER',     name: 'GPay', color: 'LimeGreen' },
    { id: 'NOT_KNOWN',        name: 'Unknown', color: 'DarkKhaki'},
    ]

export const OrderDeliveryTypes = [
  { id: 'STORE_PICKUP', name: 'Store Pickup', color: 'Cornsilk'  },
  { id: 'DOOR_DELIVERY', name: 'Personal Delivery', color: 'MistyRose'  },
  { id: 'SHIPPING', name: 'Shipping', color: 'Moccasin'  },
  { id: 'POS', name: 'POS', color: 'Lavender'  },
];


export const OrderItemsAvailability = [
  { id: 'ALL_IN_STOCK', name: 'All In Stock', color: 'Cornsilk'  },
  { id: 'PARTIALLY_AVAIALBLE', name: 'Partial Stock', color: 'MistyRose'  },
  { id: 'NO_STOCK', name: 'No Stock', color: 'Moccasin'  },
];
