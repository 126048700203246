import React  from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    CardActions,
    Filter,
    SearchInput,
    DateInput,
    SelectInput,
    ReferenceInput,
    AutocompleteInput
} from 'react-admin';

import { Link } from 'react-router-dom';
import SupplierBillSettlementTypeField from '../purchaseorders/SupplierBillSettlementTypeField'
import SupplierBillDeliveryTypeField from '../purchaseorders/SupplierBillDeliveryTypeField'
import SupplierBillPaymentTypeField from '../purchaseorders/SupplierBillPaymentTypeField'
import SupplierBillEditButton  from './SupplierBillEditButton';

import CurrencyField from '../../common/CurrencyField'
import {  SupplierOrderDeliveryType,SupplierOrderSettlementType, SupplierOrderPaymentType, SupplierBillPaymentStatus, SupplierBillStatus } from '../purchaseorders/SupplierOrderTypes'

const EditButton = ({ record = {} }) =>  <SupplierBillEditButton itemId={record.id} onUpdate={onItemAdd}/>

const onItemAdd = () => {
    window.location.reload(true)
}

const SupplierFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <ReferenceInput alwaysOn source="supplierId" reference="suppliers/supplier">
            <AutocompleteInput
                optionText={choice =>
                    `${choice.name}`
                }
            />
        </ReferenceInput>
        <DateInput source="billDate" alwaysOn />
        {/* <SelectInput allowEmpty={false} label="Location" source="locationId" alwaysOn choices={[
        { id: '1',  name: 'SHOP' },
        // { id: '2',  name: 'BOOKFAIR' },
    ]
        } /> */}
        
        {/* <SelectInput source="status" alwaysOn choices={SupplierBillStatus} /> */}
        <SelectInput source="settlementType" alwaysOn choices={SupplierOrderSettlementType} />
        <SelectInput source="paymentType" alwaysOn choices={SupplierOrderPaymentType} />
        {/* <SelectInput source="deliveryType" choices={SupplierOrderDeliveryType} /> */}
        {/* <SelectInput source="paymentStatus" alwaysOn choices={SupplierBillPaymentStatus} /> */}
        <DateInput source="createDate" alwaysOn />

    </Filter>
);


const OpenSupplierBill = ({ record = {} }) => 
<Link to={"/suppliers/bill/"+record.id+"/show"}><span>OPEN</span></Link>


const SupplierBillList = props => (
    <List exporter={false}  
            filters={<SupplierFilter />}   {...props}  
            //filterDefaultValues={{ locationId: getLocation() }} 
            perPage={25}>
        <Datagrid >
                <OpenSupplierBill/>
                <TextField source="id" />
                <DateField source="billDate"/>
                <TextField source="billNumber" />
                <TextField source="supplierName"/>
                <CurrencyField source="total" label="Total Amt" />
                <SupplierBillSettlementTypeField source="settlementType" />
                <CurrencyField source="billAmount" />
                <SupplierBillPaymentTypeField source="paymentType" />
                <SupplierBillDeliveryTypeField source="deliveryType" />
                <DateField source="createDate"/>
                <EditButton/>
                {/* <TextField source="status"/> */}
                {/* <TextField source="orderId" label="P.O#"/> */}
                {/* <TextField source="paymentStatus" /> */}
        </Datagrid>
    </List>
);

export default SupplierBillList;
