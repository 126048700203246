import ItemIcon from '@material-ui/icons/Collections';
import StockTakingList from './StockTakingList';
import StockTakingCreate from './StockTakingCreate';
import StockTakingShow from './StockTakingShow';


export default {
    list: StockTakingList,
    create: StockTakingCreate,
    show: StockTakingShow,
    icon: ItemIcon,
    name: "inventory/stocktaking"
};
