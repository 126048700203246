// hooks to maintain state
// submit using dataProvider
//

import React, { useState, Fragment, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import {
    showNotification,
    withDataProvider,
    CREATE,
    UPDATE,
    GET_ONE,
} from 'react-admin';

import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField';

const PaymentTypes = [
    { value: 'CASH', label: 'Cash' },
    { value: 'PAYTM', label: 'Paytm Machine' },
    { value: 'BANK_TRANSFER', label: 'UPI/Bank Transfer' },
    { value: 'MULTIPLE', label: 'GPay Scan' },
    { value: 'CHECK', label: 'Check' },
    { value: 'PAYUBIZ', label: 'Razorpay/PayuBiz' },
    { value: 'GIVE_AWAY', label: 'Gift/Free' }
  ];

  const DeliveryTypes = [
    { value: 'STORE_PICKUP', label: 'Store Pickup' },
    { value: 'DOOR_DELIVERY', label: 'In Person Delivery' },
    { value: 'SHIPPING', label: 'Shipping' },
    { value: 'POS', label: 'POS' },
  ];

  const ShippingTypes = [
    { value: 'FRANCH_EXPRESS', label: 'Franch Express' },
    { value: 'REGISTERED_POST', label: 'Registered Post' },
    { value: 'PROFESSIONAL_COURIER', label: 'Professional Courier' },
    { value: 'TRACKON_COURIER', label: 'Trackon Courier' },
    { value: 'SPEED_POST', label: 'Speed Post' },
    { value: 'KPN_PARCEL', label: 'KPN Parcel Service' },
    { value: 'NOT_KNOWN', label: 'Not Known' },
    { value: 'OTHERS', label: 'Others' },
  ];


const QuoteToOrderButton = (props) => {

    const { paid, label, disabled, onSubmit, totalAmount } = props
    const [showDialog, setShowDialog] = useState(false)
    const [submitClicked, setSubmitClicked] = useState(false)
    const [paymentType, setPaymentType] = useState('CASH')
    const [paymentMessage, setPaymentMessage] = useState('')
    const [paymentAmount, setPaymentAmount] = useState(totalAmount)
    const [deliveryType, setDeliveryType] = useState('POS')
    const [shippingType, setShippingType] = useState('FRANCH_EXPRESS')

    const resetState = () => {
        setPaymentType('CASH')
        setPaymentAmount('')
        setPaymentMessage('')
        setDeliveryType('POS')
    }

    useEffect(() => {
      setPaymentAmount(totalAmount)
    }, [showDialog, totalAmount]);

    const handleSubmit = (print) => {
        if (paid) {
            const orderPayment = {
                paidDate: new Date(),
                type: 'SALE',
                paymentType : paymentType, 
                amount : paymentAmount,
                message: paymentMessage
            }
            onSubmit(orderPayment, deliveryType, shippingType, print)
        } else {
            onSubmit(null, deliveryType, shippingType, print)
        }
    }

    const validate = () => {
        return true
        // return ( name.length > 1 && name.length <= 70 &&
        //     address1.length > 1 && address1.length <= 70 &&
        //     address2.length > 1 && address2.length <= 70 &&
        //     address3.length <= 70 &&
        //     city.length > 1 && city.length <= 40 &&
        //     pincode.length > 1 && pincode.length <= 10 &&
        //     mobileNumber.length <= 20) 
    }

    return (
        <Fragment>
            <Button disabled={disabled} style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }} variant="contained" color="primary" onClick={(event) => { event.preventDefault(); setShowDialog(true) }}>
            {label}</Button>
            <Dialog maxWidth="sm" open={showDialog} onClose={() => { setShowDialog(false); resetState() }} aria-label="Payment and Delivery Details">
                    <Fragment>
                            <DialogTitle>Payment and Delivery Details</DialogTitle>
                            <DialogContent>
                            { paid ? 
                            <div style={{ display: 'inline-flex' }}>
                                <div style={{  width: 150 }}>
                                    <TextField
                                    id="paymentType"
                                    fullWidth
                                    select
                                    label="Payment Type"                         
                                    onChange={(e) => setPaymentType(e.target.value)}
                                    value={paymentType}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    margin="normal"
                                    >
                                    {PaymentTypes.map(option => (
                                        <option key={option.value} value={option.value}>
                                        {option.label}
                                        </option>
                                    ))}
                                    </TextField>
                                </div>
                                <div style={{ marginLeft: 10,  width: 100 }}>
                                    <TextField
                                        fullWidth
                                        id="paymentAmount"
                                        label="Amount"
                                        value={paymentAmount}
                                        onChange={(e) => setPaymentAmount(e.target.value)}
                                        margin="normal"
                                        //onKeyDown={onKeyDown}
                                    />
                                </div>
                                 <div style={{ marginLeft: 20,  width: 200 }}>
                                    <TextField
                                        fullWidth
                                        id="message"
                                        label="Message"
                                        value={paymentMessage}
                                        onChange={(e) => setPaymentMessage(e.target.value)}
                                        margin="normal"
                                    />
                                </div>
                            </div>
                            : null }
                            <div style={{ marginTop: 10, display: 'inline-flex' }}>
                                <div style={{  width: 150 }}>
                                    <TextField
                                    id="deliveryType"
                                    fullWidth
                                    select
                                    label="Delivery Type"                         
                                    onChange={(e) => setDeliveryType(e.target.value)}
                                    value={deliveryType}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    margin="normal"
                                    >
                                    {DeliveryTypes.map(option => (
                                        <option key={option.value} value={option.value}>
                                        {option.label}
                                        </option>
                                    ))}
                                    </TextField>
                                </div>
                                {/* { deliveryType === "SHIPPING" ? 
                                    <div style={{ marginLeft: 10,  width: 150 }}>
                                        <TextField
                                        id="shippingType"
                                        fullWidth
                                        select
                                        label="Shipping Type"                         
                                        onChange={(e) => setShippingType(e.target.value)}
                                        value={shippingType}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        margin="normal"
                                        >
                                        {ShippingTypes.map(option => (
                                            <option key={option.value} value={option.value}>
                                            {option.label}
                                            </option>
                                        ))}
                                        </TextField>
                                    </div>
                                    : null } */}
                            </div>
                            </DialogContent>
                            <DialogActions>
                                <Button variant="contained" style={{ backgroundColor: "maroon"}} color="secondary" disabled={!validate()} onClick={() => handleSubmit(true)}> CREATE ORDER & PRINT </Button>
                                <Button variant="contained" color="primary" disabled={!validate()} onClick={() =>  handleSubmit(false)}> CREATE ORDER </Button>
                                <Button variant="contained" onClick={() => { setShowDialog(false); resetState() }}> CANCEL </Button>
                            </DialogActions>
                    </Fragment>
            </Dialog>
        </Fragment>
    )

}

export default QuoteToOrderButton